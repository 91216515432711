import { isEmpty, merge } from "lodash";
import * as React from "react";
import { AssetInfoWidgetConfigSerialized } from "../../widgets/asset_info_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import {
  AssetInfoWidgetProps,
  AssetInfoWidgetState,
} from "./asset_info_widget.types";
import { WidgetBox } from "./widget_box";

import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { loadAsset } from "../../json_api/asset";
import { AttributeRow } from "../common/attribute_row";
import { OrganizationCard } from "../organizations/organization_card";
import { UseQuery } from "../common/use_query";
import { DashboardContextType } from "../dashboard/dashboard_context.types";
import { DashboardContext } from "../dashboard/dashboard_context";

export class AssetInfoWidget extends React.Component<
  AssetInfoWidgetProps,
  AssetInfoWidgetState
> {
  constructor(props: AssetInfoWidgetProps) {
    super(props);
  }

  static serializedConfigToProps(
    config: AssetInfoWidgetConfigSerialized,
  ): AssetInfoWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      assetId: config.asset_id,
      assetType: config.asset_type,
      assetTypeId: config.asset_type_id,
    });
  }

  static contextType?: React.Context<DashboardContextType> = DashboardContext;
  context!: React.ContextType<typeof DashboardContext>;

  render() {
    return (
      <WidgetBox
        {...this.props}
        title={I18n.t("frontend.widgets.asset_info_widget.title")}
      >
        <Box m={1}>
          <UseQuery
            queryKey={[
              "asset",
              this.props.assetId,
              { include: ["asset_type", "operator", "manufacturer"] },
            ]}
            queryFn={() =>
              loadAsset(this.props.assetId, [
                "asset_type",
                "operator",
                "manufacturer",
              ])
            }
            children={(res) =>
              res.isLoading ? (
                <Skeleton height={200} />
              ) : (
                <Grid container spacing={1}>
                  <AttributeRow
                    attributeName={I18n.t("activerecord.attributes.asset.name")}
                    value={res.data?.name}
                    divider
                  />
                  <AttributeRow
                    attributeName={I18n.t(
                      "activerecord.attributes.asset.asset_type",
                    )}
                    value={res.data?.asset_type_name}
                    divider
                  />

                  {isEmpty(res.data?.serial) ? null : (
                    <AttributeRow
                      attributeName={I18n.t(
                        "activerecord.attributes.asset.serial",
                      )}
                      value={res.data?.serial}
                      divider
                    />
                  )}
                  {res.data?.product_model_name ? (
                    <AttributeRow
                      attributeName={I18n.t(
                        "activerecord.attributes.asset.product_model",
                      )}
                      value={res.data?.product_model_name}
                      divider
                    />
                  ) : null}
                  {res.data?.manufacturer?.name ? (
                    <AttributeRow
                      attributeName={I18n.t(
                        "activerecord.attributes.asset.manufacturer",
                      )}
                      value={res.data?.manufacturer.name}
                      divider
                    />
                  ) : null}
                  {res.data?.operator ? (
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {I18n.t("activerecord.attributes.asset.operator")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container justifyContent="center">
                        <Grid item xs={10}>
                          <OrganizationCard organization={res.data?.operator} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              )
            }
          />
        </Box>
      </WidgetBox>
    );
  }
}
