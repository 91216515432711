import { EventNotification } from "../../../models/event_notification";
import NotificationDispatcher from "./notification_dispatcher";

export interface ResetStateAction {
  type: "RESET_STATE";
}

export interface LoadInitialStateAction {
  type: "LOAD_INITIAL_STATE";
  newNotifications: EventNotification[];
  previousNotifications: EventNotification[];
}

export interface AddNewNotification {
  type: "ADD_NEW_NOTIFICATION";
  notification: EventNotification;
}

export interface MarkNotificationsAsNoticed {
  type: "MARK_NOTIFICATIONS_AS_NOTICED";
  eventIds: number[];
}

export type NotificationAction =
  | ResetStateAction
  | LoadInitialStateAction
  | AddNewNotification
  | MarkNotificationsAsNoticed;

/**
 * Resets the state of the store.
 */
export function resetState(): void {
  NotificationDispatcher.dispatch({
    type: "RESET_STATE",
  });
}

/**
 * Load initial state of store
 * @param newNotifications A list of unnoticed event notifications
 * @param previousNotifications A list of previous event notifications
 */
export function loadInitialState(
  newNotifications: EventNotification[],
  previousNotifications: EventNotification[],
): void {
  NotificationDispatcher.dispatch({
    type: "LOAD_INITIAL_STATE",
    newNotifications,
    previousNotifications,
  });
}

/**
 * Adds a notification to the list of new event notifications
 * @param notification The new event notification
 */
export function addNewNotification(notification: EventNotification): void {
  NotificationDispatcher.dispatch({
    type: "ADD_NEW_NOTIFICATION",
    notification,
  });
}

/**
 * Marks all new notifications as noticed ,i.e. , moves them to the list of previous notifications
 * @param eventIds Ids of event notifications that should be marked as read
 */
export function markNotificationsAsNoticed(eventIds: number[]): void {
  NotificationDispatcher.dispatch({
    type: "MARK_NOTIFICATIONS_AS_NOTICED",
    eventIds,
  });
}
