import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import * as JSONAPI from "jsonapi-typescript";
import { clone, findIndex, isEmpty, isNil, map } from "lodash";
import * as React from "react";
import { jsonApiPagingParamsArray } from "../../../json_api/jsonapi_tools";
import {
  MeasurementPlanJSONAPIAttributesObject,
  MeasurementPlanJSONApiAttributes,
} from "../../../json_api/measurement_plan";
import { MeasurementPlan } from "../../../models/measurement_plan";
import { loadDataFromUrl, sendData } from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import { redirectTo } from "../../../utils/redirection";
import { success } from "../../../utils/toasts";
import {
  assetMeasurementPlanSubscribePath,
  assetMeasurementPlanUnsubscribePath,
  newAssetMeasurementPlanPath,
} from "../../../utils/urls";
import { applyParamsDataToBaseUrl } from "../../../utils/urls/url_utils";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxFooter } from "../../common/ibox";
import { LoadingIcon } from "../../common/icon";
import { MeasurementPlanItem } from "./measurement_plan_item";

interface MeasurementPlanListProperties {
  assetId?: string | number;
  measurementPlansUrl?: string;
  measurementPlans?: MeasurementPlan[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
}

interface MeasurementPlanListState {
  isFetching: boolean;
  measurementPlans?: MeasurementPlanJSONApiAttributes[];
  loadedPage?: number;
  currentPage?: number;
  totalPages?: number;
}

export class MeasurementPlanList extends React.Component<
  MeasurementPlanListProperties,
  MeasurementPlanListState
> {
  constructor(props: MeasurementPlanListProperties) {
    super(props);
    this.state = {
      isFetching: false,
      measurementPlans: props.measurementPlans,
      loadedPage: !isNil(props.measurementPlans) ? props.currentPage : null,
      currentPage: props.currentPage,
      totalPages: props.totalPages,
    };
  }
  componentDidMount(): void {
    if (
      !isNil(this.props.measurementPlansUrl) &&
      isNil(this.state.measurementPlans)
    ) {
      void this.fetchMeasurementPlans();
    }
  }

  render(): React.ReactNode {
    return (
      <IBox>
        <IBoxContent>
          <div className="text-center">
            {this.state.isFetching ? (
              <LoadingIcon size="6x"></LoadingIcon>
            ) : null}
            {isEmpty(this.state.measurementPlans)
              ? I18n.t("frontend.measurement_plan_list.no_measurement_plans")
              : null}
          </div>
          {isEmpty(this.state.measurementPlans)
            ? null
            : map(this.state.measurementPlans, (measurementPlan, index) => {
                return (
                  <MeasurementPlanItem
                    measurementPlan={measurementPlan}
                    onSubscribe={(plan) => {
                      this.onSubscribe(plan);
                    }}
                    onUnsubscribe={(plan) => {
                      this.onUnsubscribe(plan);
                    }}
                    key={index}
                  />
                );
              })}
        </IBoxContent>
        {this.state.totalPages > 1 ? (
          <IBoxFooter>
            <Pagination
              page={this.state.currentPage}
              count={this.state.totalPages}
              onChange={(event, pageNumber) => {
                this.onSelectPage(pageNumber);
              }}
            />
          </IBoxFooter>
        ) : null}
        <FixedBottomArea key="bot-buttons">
          <FloatingButtons showScrollToTopBtn={true} isProcessing={false}>
            {isNil(this.props.assetId) ? null : (
              <Fab
                title={I18n.t(
                  "frontend.measurement_plan_list.add_measurement_plan",
                )}
                color="primary"
                onClick={() => {
                  redirectTo(newAssetMeasurementPlanPath(this.props.assetId));
                }}
              >
                <AddIcon fontSize="inherit" />
              </Fab>
            )}
          </FloatingButtons>
        </FixedBottomArea>
      </IBox>
    );
  }

  onSelectPage(pageNumber: number): void {
    if (this.state.loadedPage != pageNumber) {
      this.setState(
        (prevState) => {
          return {
            loadedPage: prevState.currentPage,
            currentPage: pageNumber,
            isFetching: true,
            measurementPlans: null,
          };
        },
        () => {
          this.fetchMeasurementPlans();
        },
      );
    }
  }

  onSubscribe(measurementPlan: MeasurementPlanJSONApiAttributes): void {
    sendData(
      assetMeasurementPlanSubscribePath(
        measurementPlan.asset_id,
        measurementPlan.id,
        "json",
      ),
      "",
      "PATCH",
    )
      .then(
        (
          result: JSONAPI.SingleResourceDoc<
            string,
            MeasurementPlanJSONAPIAttributesObject
          >,
        ) => {
          this.handlPlanUpdate(result);
          return success(
            I18n.t("frontend.measurement_plan_list.subscribe_successful", {
              name: measurementPlan?.measurement_type_title,
            }),
          );
        },
      )
      .catch((err) => {
        logger.error(err);
      });
  }

  onUnsubscribe(measurementPlan: MeasurementPlanJSONApiAttributes): void {
    sendData(
      assetMeasurementPlanUnsubscribePath(
        measurementPlan.asset_id,
        measurementPlan.id,
        "json",
      ),
      "",
      "PATCH",
    )
      .then(
        (
          result: JSONAPI.SingleResourceDoc<
            string,
            MeasurementPlanJSONAPIAttributesObject
          >,
        ) => {
          this.handlPlanUpdate(result);
          return success(
            I18n.t("frontend.measurement_plan_list.unsubscribe_successful", {
              name: measurementPlan?.measurement_type_title,
            }),
          );
        },
      )
      .catch((err) => {
        logger.error(err);
      });
  }

  handlPlanUpdate(
    planDoc: JSONAPI.SingleResourceDoc<
      string,
      MeasurementPlanJSONAPIAttributesObject
    >,
  ): void {
    const index = findIndex(
      this.state.measurementPlans,
      (plan) => plan.id == planDoc.data.id,
    );

    if (isNil(index)) return;

    const plans = clone(this.state.measurementPlans);
    plans[index] = {
      ...planDoc.data.attributes,
      id: planDoc.data.id,
      subscribed: planDoc.data.attributes.subscribed,
    };
    this.setState({ ...this.state, measurementPlans: plans });
  }

  fetchMeasurementPlans(force = false): void {
    if (this.state.currentPage != this.state.loadedPage) {
      loadDataFromUrl<
        JSONAPI.CollectionResourceDoc<
          string,
          MeasurementPlanJSONAPIAttributesObject
        >
      >(
        applyParamsDataToBaseUrl(
          `${this.props.measurementPlansUrl}.json`,
          jsonApiPagingParamsArray(this.state.currentPage, this.props.pageSize),
        ),
      )
        .then((fetchedMeasurementPlans) => {
          this.setState((prevState) => {
            return {
              measurementPlans: map(
                fetchedMeasurementPlans.data,
                (measurementPlanJSONAPI) => {
                  return {
                    ...measurementPlanJSONAPI.attributes,
                    id: measurementPlanJSONAPI.id,
                  };
                },
              ),
              loadedPage: prevState.currentPage,
              isFetching: false,
            };
          });
        })
        .catch((err) => {
          this.setState({
            measurementPlans: null,
            loadedPage: null,
            currentPage: null,
            isFetching: false,
          });
        });
    }
  }
}
