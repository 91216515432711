import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { DeviceLogTable } from "./device_log_table";

const deviceLogTableRoots: Root[] = [];
export function initializeDeviceLogTable(
  selector = $('[data-toggle="device-log-table"]'),
): void {
  $(selector).each((index, element) => {
    const deviceId = $(element).data("device-id") as string;
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <DeviceLogTable deviceId={deviceId} />
      </AppRoot>,
    );
    deviceLogTableRoots.push(root);
  });
}

/**
 * Remove react component from all elements
 */
export function destroyDeviceLogTable(
  selector = $('[data-toggle="device-log-table"]'),
): void {
  deviceLogTableRoots.forEach((root) => root.unmount());
  deviceLogTableRoots.length = 0;
}
