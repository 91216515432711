import * as React from "react";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import { useQuery } from "@tanstack/react-query";
import {
  ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
  AssetTemplateJSONObject,
} from "../../json_api/asset_template";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { api_asset_templates_path } from "../../routes";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";

interface AssetTemplateListProps {
  assetType?: AssetTypeJSONObject;
  assetTypeId?: number;
  canEdit: boolean;
  tableHeight?: number;
  maxTableHeight?: number | string;
  rowActions?: (template: AssetTemplateJSONObject) => React.ReactElement[];
}

const gridColDef = (
  rowActions?: (template: AssetTemplateJSONObject) => React.ReactElement[],
) => {
  const column = [
    { field: "id" },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "short_name",
      headerName: "Short Name",
    },
    { field: "key", headerName: "Key" },
    { field: "min_count", headerName: "Min Count" },
    { field: "max_count", headerName: "Max Count" },
    { field: "description", headerName: "Description" },
  ] as GridColDef<AssetTemplateJSONObject>[];

  if (rowActions) {
    column.push({
      field: "actions",
      headerName: I18n.t("frontend.actions"),
      type: "actions",
      getActions: (params) => {
        return rowActions(params.row);
      },
    });
  }

  return column;
};

export const AssetTemplateList: React.FC<AssetTemplateListProps> = ({
  assetType,
  assetTypeId,
  tableHeight = 600,
  maxTableHeight = "75vh",
  rowActions,
}) => {
  const [pageSettings, setPageSettings] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const theAssetTypeID = assetType ? assetType.id : assetTypeId;
  const assetTemplatesQuery = useQuery({
    queryKey: [
      ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
      theAssetTypeID,
      pageSettings.page,
      pageSettings.pageSize,
    ],
    queryFn: async () => {
      const options = {
        filter: { asset_type: theAssetTypeID },
      };
      const path = api_asset_templates_path(options);
      const assetTemplateDoc =
        await loadDataFromUrl<
          CollectionResourceDoc<string, AssetTemplateJSONObject>
        >(path);
      return jsonApiResourceCollectionToFlatObjects(assetTemplateDoc);
    },
  });

  const gridColDefs = React.useMemo(() => gridColDef(rowActions), [rowActions]);
  return (
    <Box height={tableHeight} width="100%" maxHeight={maxTableHeight}>
      <DataGrid
        columns={gridColDefs}
        rows={assetTemplatesQuery.data}
        onPaginationModelChange={(newModel) => {
          setPageSettings({ ...newModel });
        }}
        editMode="row"
        onRowEditStop={(newRow) => {
          console.log(newRow);
        }}
      />
    </Box>
  );
};
