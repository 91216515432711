import { isNil } from "lodash";
import * as React from "react";
import { logger } from "../../utils/logger";

import { Box, Paper } from "@mui/material";

export interface ErrorBoundaryProps extends React.PropsWithChildren {
  onError?(): void;
  errorComponent?(error: Error): React.ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Error;
}
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error(errorInfo);
    // You can also log the error to an error reporting service
    logger.logError(error);
  }

  render() {
    if (this.state.hasError) {
      let fallbackComponent;
      if (this.props?.errorComponent) {
        fallbackComponent = this.props?.errorComponent(this.state.error);
      }

      if (isNil(fallbackComponent)) {
        fallbackComponent = (
          <Paper>
            <Box p={1}>{this.state.error.message}</Box>
          </Paper>
        );
      }
      return fallbackComponent;
    }
    return this.props.children;
  }
}
