import { Autocomplete, TextField } from "@mui/material";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { isNil, sortBy } from "lodash";
import * as React from "react";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { SensorTypeJSONAPIAttributes } from "../../json_api/sensor_type";
import { api_sensor_types_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import { useQuery } from "@tanstack/react-query";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";
interface SensorTypeAutocompleteProps {
  assetId?: IDType;
  selectableSensorTypes?: SensorTypeJSONAPIAttributes[];

  onSelectionChange: (
    selectedSensorTypes: SensorTypeJSONAPIAttributes[],
  ) => void;
}
/** Autocomplete for loading and selecting sensors types for a partiular asset
 *
 * When an asset id is provided, the select will only load those types that are defined for the current asset and its subtree
 * @param {*} props
 * @return {*}
 */
export const SensorTypeAutocomplete: React.FunctionComponent<
  SensorTypeAutocompleteProps
> = (props) => {
  const [selectableSensorTypes, setSelectableSensorTypes] = React.useState<
    Array<SensorTypeJSONAPIAttributes | null>
  >(props.selectableSensorTypes);

  const [sensorTypesUrl, setSensorTypesUrl] = React.useState(null);

  const sensorTypesQuery = useQuery({
    queryKey: ["assetSensorTypes", sensorTypesUrl],
    queryFn: () => {
      return loadDataFromUrl<
        CollectionResourceDoc<string, SensorTypeJSONAPIAttributes>
      >(sensorTypesUrl).then((sensorTypesResponse) => {
        let sensorTypes =
          jsonApiResourceCollectionToFlatObjects(sensorTypesResponse);
        sensorTypes = sortBy(sensorTypes, (st) =>
          getSensorTypeNameTranslation(st.name),
        );

        return sensorTypes;
      });
    },
    // use the given values if provided
    placeholderData: props.selectableSensorTypes,

    // only load if offline selectable
    enabled: !isNil(props.selectableSensorTypes) || !isNil(sensorTypesUrl),
  });

  React.useEffect(() => {
    if (isNil(selectableSensorTypes)) {
      const urlOptions: Record<string, string | boolean | number> = {
        format: "json",
        _options: true,
      };
      const assetId = props.assetId;
      if (!isNil(assetId)) {
        urlOptions["filter[in_subtree_of_asset]"] = assetId;
      }
      setSensorTypesUrl(api_sensor_types_path(urlOptions));
    }
  }, [props.assetId]);

  return (
    <Autocomplete<SensorTypeJSONAPIAttributes | null, true>
      options={sensorTypesQuery.data ?? []}
      multiple
      loading={sensorTypesQuery.isLoading}
      loadingText={I18n.t(
        "frontend.sensor_types.sensor_types_autocomplete.loading_sensor_types",
      )}
      size="small"
      getOptionLabel={(o) => getSensorTypeNameTranslation(o.name)}
      getOptionKey={(o) => o.id}
      color="primary"
      renderInput={(props) => (
        <TextField
          {...props}
          size="small"
          label={I18n.t("frontend.sensors.sensor_filter.sensor_types", {
            count: 2,
          })}
          helperText={I18n.t(
            "frontend.sensors.sensor_filter.sensor_type_filter_helper_text",
          )}
        />
      )}
      onChange={(event, sensorTypes) => {
        props.onSelectionChange(sensorTypes);
      }}
    />
  );
};
