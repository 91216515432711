import { defaultTo, first, isEmpty, isNil, merge } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { PlotlyGaugeChart } from "../../charting/plotly_gauge_chart";
import { SensorValueType } from "../../models/sensor";
import {
  registerSensorUpdates,
  unregisterSensorUpdates,
} from "../../utils/sensor_updates";
import { sensorUrl } from "../../utils/urls";
import { WidgetBox } from "./widget_box";

import { Grid } from "@mui/material";
import { SensorLoader } from "../../json_api/sensor_loader";
import { logger } from "../../utils/logger";
import { getTimeString } from "../../utils/time_strings";
import { OffsetGaugeWidgetConfigSerialized } from "../../widgets/offset_gauge_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { computeOffsetValue } from "./algorithm/offset_values";
import {
  OffsetGaugeWidgetProps,
  OffsetGaugeWidgetState,
} from "./offset_gauge_widget.types";
import { WidgetTimestampGridItem } from "./widget_timestamp";

const MAX_NUM_LAST_VALUES = 5;

export class OffsetGaugeWidget
  extends React.Component<OffsetGaugeWidgetProps, OffsetGaugeWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<OffsetGaugeWidgetProps> = {
    updateEnabled: true,
    encloseInIBox: true,
    valueDisplay: "tick",
    allowFullscreen: true,
  };

  static serializedConfigToProps(
    config: OffsetGaugeWidgetConfigSerialized,
  ): OffsetGaugeWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      updateEnabled: !config.disable_update,
      calculationMode: config.calc_mode,
      totalValueRange: config.total_value_range,
      value: config.value,
      hideValue: config.hide_value,

      maxDelta: config.max_delta,
      baseValue: config.base_value,
      valueRanges: config.value_ranges,
      valueDisplay: config.value_display,
      unit: config.display_unit,
      sensorId: config.sensor_id,
      assetId: config.asset_id,
      offsetValue: config.offset_value,
      showTimeRange: config.show_time_range,
      gaugeHeight: config.gauge_height,
    } as OffsetGaugeWidgetProps);
  }

  constructor(props: OffsetGaugeWidgetProps) {
    super(props);
    this.state = {
      dataUpdateEnabled: props.updateEnabled,
      title: (props.title as string) ?? props.sensor?.name,
      value: props.value as number,
      offsetValue: defaultTo(
        this.props.offsetValue,
        computeOffsetValue(
          props.baseValue,
          this.props.value as number,
          defaultTo(props.calculationMode, "base_value_offset"),
        ),
      ),
      sensor: props.sensor,
      maxDelta: props.maxDelta,
      baseValue: props.baseValue,
      displayUnit: props.unit,
      lastValues: [0],
      displayTrend: false,
      timestamp: props.timestamp,
      fullscreen: false,
    };
  }

  componentDidMount(): void {
    setTimeout(() => {
      registerSensorUpdates(this, [this.props.sensorId as number]);
      if (isNil(this.state.sensor)) {
        this.loadSensor();
      }
    }, 10);
  }

  loadSensor() {
    void SensorLoader.getInstance()
      .getSensors([this.props.sensorId])
      .then((sensors) => {
        const sensor = first(sensors);
        this.setState({
          sensor,
          title: (this.props.title as string) ?? sensor?.name,
        });
      })
      .catch((e) => {
        logger.error(e);
      });
  }
  componentWillUnmount() {
    unregisterSensorUpdates(this, [this.props.sensorId as number]);
  }

  componentDidUpdate(oldProps: OffsetGaugeWidgetProps): void {
    if (this.props.updateEnabled !== oldProps.updateEnabled) {
      unregisterSensorUpdates(this, [oldProps.sensorId as number]);
      registerSensorUpdates(this, [this.props.sensorId as number]);
    }

    if (
      !isNil(this.state.sensor) &&
      this.props.sensorId != this.state.sensor?.id
    ) {
      this.loadSensor();
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    const newLastValues = this.state.lastValues.slice(
      0,
      MAX_NUM_LAST_VALUES - 1,
    );

    newLastValues.unshift(value as number);
    this.setState({
      value: value as number,
      offsetValue: computeOffsetValue(
        this.state.baseValue,
        value as number,
        defaultTo(this.props.calculationMode, "base_value_offset"),
      ),
      lastValues: newLastValues,
      timestamp: time,
      displayTrend:
        this.state.displayTrend ||
        (newLastValues.length >= MAX_NUM_LAST_VALUES &&
          newLastValues[MAX_NUM_LAST_VALUES - 1] != 0.0),
    });
  }

  getGaugeRange(): [number, number] {
    if (this.props.calculationMode === "value" && this.props.totalValueRange) {
      return [
        defaultTo(
          this.props.totalValueRange.min,
          -Math.abs(this.state.maxDelta),
        ),
        defaultTo(
          this.props.totalValueRange.max,
          Math.abs(this.state.maxDelta),
        ),
      ];
    }
    return [-Math.abs(this.state.maxDelta), Math.abs(this.state.maxDelta)];
  }

  render(): React.ReactNode {
    // implement display content here ...
    let trend = undefined;
    if (this.state.displayTrend) {
      trend =
        this.state.lastValues.reduce((value, acc) => value + acc) /
        this.state.lastValues.length;
    }
    let link = this.props.titleLinkUrl;
    if (isNil(link) || isEmpty(link)) {
      link = sensorUrl(
        {
          id: this.props.sensorId,
          assetId: this.props.assetId,
        },
        "html",
      );
    }

    const gaugeChart = (
      <PlotlyGaugeChart
        value={this.state.offsetValue}
        divId={`widget-${this.props.widgetId}-diagram-container`}
        unit={this.props.unit ?? this.state.sensor?.display_unit}
        height={this.state.fullscreen ? 600 : this.props.gaugeHeight}
        maxWidth={this.state.fullscreen ? "80%" : null}
        range={this.getGaugeRange()}
        valueRanges={this.props.valueRanges}
        displayValueByTick={this.props.valueDisplay === "tick"}
        trendValue={trend}
      />
    );
    return (
      <>
        {!this.props.encloseInIBox ? (
          gaugeChart
        ) : (
          <WidgetBox
            {...this.props}
            title={this.state.title}
            titleLinkUrl={link}
            contentLinkUrl={defaultTo(this.props.contentLinkUrl, link)}
            onFullscreen={(fullscreen) => this.setState({ fullscreen })}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                {gaugeChart}
              </Grid>
              {isNil(this.state.timestamp) ? null : (
                <WidgetTimestampGridItem
                  timestamp={getTimeString(null, this.state.timestamp)}
                  align="center"
                />
              )}
            </Grid>
          </WidgetBox>
        )}
      </>
    );
  }
}
