/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Container } from "flux/utils";
import * as React from "react";
const containerCreateOld = Container.create;

// Change `Container.create` implementation based on @lazy8's answer to support ES6 for flux
// https://github.com/facebook/flux/issues/351
Container.create = ((
  Component: React.Component,
  options: Container.RealOptions,
) => {
  const newComponent = (...args: any[]) => new (Component as any)(...args);

  newComponent.prototype = (Component as any).prototype;
  newComponent.defaultProps = (Component as any).defaultProps;
  newComponent.getStores = (Component as any).getStores;
  newComponent.calculateState = (Component as any).calculateState;

  return containerCreateOld(newComponent, options);
}) as any;
