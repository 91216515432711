import { isNil, split, toNumber } from "lodash";
import { SensorValueRange } from "./sensor_value_range";

export interface LocationValue {
  x: number;
  y: number;
  z?: number;
}

/**
 * Options to configure sampling rate of a dataset
 * e.g. { value: 15, unit: 'minutes' } for one value every 15 minutes
 */
export interface SamplingRate {
  /**
   * Sampling rate value
   */
  value: number;
  /**
   * Sampling rate unit, e.g., 'hours', 'minutes', 'seconds'
   */
  unit: SensorSamplingRateUnit;
}

export type SensorValueType = number | string | LocationValue;

export interface SensorValue {
  value: SensorValueType;
  timestamp: string;
}

export interface TimeSeriesPlainSensorValue {
  v: SensorValueType;
  t: string;
}

export type SensorSamplingRateUnit =
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months";

export type SensorDataSamplingMode =
  | "avg"
  | "min"
  | "max"
  | "median"
  | "sum"
  | "count";

export type SensorLineType =
  | "step"
  | "continous"
  | "binary"
  | "enumerated_value";

export interface Sensor {
  id?: string | number;
  name?: string;
  description?: string;
  short_name?: string;
  model?: string;
  manufacturer?: string;
  serial?: string;
  precision?: number;
  import_precision?: number;
  display_unit?: string;
  attribute_key_unit?: string;
  key?: string;
  enabled?: boolean;
  derived?: boolean;
  installed_at?: string;
  sensor_type_id?: number;
  exp_data_period?: number;
  sampling_rate_value?: number;
  sampling_rate_unit?: SensorSamplingRateUnit;
  last_value?: SensorValue;
  asset_id?: number | string;
  attribute_key_id?: number;
  total_value_range?: SensorValueRange;
  sensor_context?: string;
  sensor_context2?: string;
  sensor_type_name?: string;
  measurement_type?: string;
  value_ranges?: SensorValueRange[];
  value_type?: "influx" | "text" | "real" | "integer" | "location";
  line_shape?: SensorLineType;
  created_at?: string;
  updated_at?: string;
}

export interface SensorDataAttribute {
  key_id: number | string;
  feature_id: number | string;
  timestamp: string;
  value: SensorValueType;
  unit: string;
}

export function samplingRateFromString(
  samplingRateString: string,
): SamplingRate {
  const components = split(samplingRateString, " ");
  if (isNil(samplingRateString) || components.length != 2) {
    return null;
  } else {
    return {
      unit: components[1] as SensorSamplingRateUnit,
      value: toNumber(components[0]),
    };
  }
}

export function samplingRateToString(samplingRate: SamplingRate): string {
  if (isNil(samplingRate)) {
    return null;
  } else {
    return `${samplingRate.value} ${samplingRate.unit}`;
  }
}
