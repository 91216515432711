import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";

type Props<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = {
  children: (params: UseQueryResult<TData, TError>) => JSX.Element;
} & UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

/**
 * Utility component for using React-Query in class components
 */
export const UseQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  props: Props<TQueryFnData, TError, TData, TQueryKey>,
) => {
  const query = useQuery(props);
  return props.children(query);
};
