import ActionCable, { Subscription } from "@rails/actioncable";
import { each, isNil, values } from "lodash";

const WILDCARD_ATTRIBUTE_ID = -1;
export abstract class ModelDataChannel<
  EventSubscriberInterface,
  EventSubscriberCableValue,
> {
  subscriptionsByModelId: { [modelId: number]: Subscription };
  modelListeners: { [modelId: number]: EventSubscriberInterface[] };

  constructor() {
    this.subscriptionsByModelId = {};
    this.modelListeners = {};
  }

  /**
   * Subscribe to a context state machine channel
   * @param modelId The context state machine id to listen to
   */
  subscribe<T = any>(modelId: number, ...args: T[]) {
    if (isNil(this.subscriptionsByModelId[modelId])) {
      const channel = App.cable.subscriptions.create(
        this.getChannelNameWithParams(modelId, args),
        {
          connected: (...args: T[]) => this.onConnect(modelId, args),
          disconnected: (...args: T[]) => this.onDisconnect(modelId, args),
          received: (data: EventSubscriberCableValue) => {
            this.handleDataMessage(data, this.modelListeners[modelId]);
            this.handleDataMessage(
              data,
              this.modelListeners[WILDCARD_ATTRIBUTE_ID],
            );
          },
        },
      );

      this.subscriptionsByModelId[modelId] = channel;
    }
  }

  protected onConnect(modelId: number, ...args: any[]): void {}

  protected onDisconnect(modelId: number, ...args: any[]): void {}

  protected abstract handleDataMessage(
    data: EventSubscriberCableValue,
    listeners: EventSubscriberInterface[],
  ): void;

  protected abstract getChannelNameWithParams(
    modelId: number,
    ...args: any[]
  ): string | ActionCable.ChannelNameWithParams;
  /**
   * Unsubscribe from a context state machine channel
   * @param modelId The context state machine id to unsubscribe from
   */
  unsubscribe(modelId: number) {
    const channel = this.subscriptionsByModelId[modelId];
    if (!isNil(channel)) {
      channel.unsubscribe();
      delete this.subscriptionsByModelId[modelId];
    }
  }

  /**
   * Unsubscribe from all active context state machine channels
   */
  unsubscribeAll() {
    each(values(this.subscriptionsByModelId), (subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionsByModelId = {};
  }

  /**
   * Adds an Event listener to be notified if a certain value changes
   *
   * @param {EventSubscriberInterface} listener Listener to be notified
   * @param {number} [modelId] The model id to listen on. -1 for all
   */
  addEventListener(
    listener: EventSubscriberInterface,
    modelId: number = WILDCARD_ATTRIBUTE_ID,
  ) {
    let subscribers = this.modelListeners[modelId];
    if (isNil(subscribers)) {
      subscribers = [];
      this.modelListeners[modelId] = subscribers;
    }

    const index = subscribers.indexOf(listener);
    if (index === -1) {
      subscribers.push(listener);
    }
  }

  /**
   * Remove a event listener
   * @param {EventSubscriberInterface} listener Listener to be notified
   * @param {number} [modelId] The model id to listen on. -1 for all models
   */
  removeEventListener(
    listener: EventSubscriberInterface,
    modelId: number = WILDCARD_ATTRIBUTE_ID,
  ) {
    const subscribers = this.modelListeners[modelId];
    if (isNil(subscribers)) {
      return;
    }

    const index = subscribers.indexOf(listener);
    if (index !== -1) {
      subscribers.splice(index, 1);
    }
  }
}

export default ModelDataChannel;
