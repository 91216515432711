import React from "react";
import PropTypes from "prop-types";
import RepeatMonthlyOn from "./On";
import RepeatMonthlyOnThe from "./OnThe";
import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import { Grid, TextField } from "@mui/material";

const RepeatMonthly = ({
  id,
  monthly: { mode, interval, on, onThe, options },
  handleChange,
  translations,
}) => {
  const isTheOnlyOneMode = (option) => options.modes === option;
  const isOptionAvailable = (option) =>
    !options.modes || isTheOnlyOneMode(option);

  return (
    <Grid item container xs={12} spacing={2} marginTop={2}>
      <Grid item xs={0} sm={2} />
      <Grid item container xs={12} sm={10} spacing={2}>
        <Grid xs={12} sm={4}>
          <TextField
            id={`${id}-interval`}
            name="repeat.monthly.interval"
            aria-label="Repeat monthly interval"
            value={interval}
            onChange={numericalFieldHandler(handleChange)}
            label={translateLabel(translations, "repeat.monthly.every")}
            type="number"
            size="small"
            InputProps={{
              endAdornment: translateLabel(
                translations,
                "repeat.monthly.months",
              ),
            }}
          />
        </Grid>

        {isOptionAvailable("on") && (
          <RepeatMonthlyOn
            id={`${id}-on`}
            mode={mode}
            on={on}
            hasMoreModes={!isTheOnlyOneMode("on")}
            handleChange={handleChange}
            translations={translations}
          />
        )}
        {isOptionAvailable("on the") && (
          <RepeatMonthlyOnThe
            id={`${id}-onThe`}
            mode={mode}
            onThe={onThe}
            hasMoreModes={!isTheOnlyOneMode("on the")}
            handleChange={handleChange}
            translations={translations}
          />
        )}
      </Grid>
    </Grid>
  );
};

RepeatMonthly.propTypes = {
  id: PropTypes.string.isRequired,
  monthly: PropTypes.shape({
    mode: PropTypes.oneOf(["on", "on the"]).isRequired,
    interval: PropTypes.number.isRequired,
    on: PropTypes.object.isRequired,
    onThe: PropTypes.object.isRequired,
    options: PropTypes.shape({
      modes: PropTypes.oneOf(["on", "on the"]),
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatMonthly;
