import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { defaultTo, find, isNil, map, toString } from "lodash";
import * as React from "react";
import { translatedModelName } from "../../i18n/translation_helper";
import { AssetJSONAPIAttributes } from "../../json_api/asset";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { SIALOGICApiResultMessage } from "../../json_api/messages";
import { UserGroupJSONObject } from "../../json_api/user_group";
import { Asset } from "../../models/asset";
import { Organization } from "../../models/organization";
import { OrganizationRelationType } from "../../models/organization_relation";
import { UserGroup } from "../../models/user_group";
import { api_organization_user_groups_path } from "../../routes";
import { getTranslatedProp } from "../../utils/globalize";
import { loadDataFromUrl, sendData } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import {
  AssignAssetOrganizationBodyParams,
  assetPath,
  assignAssetOrganizationJsonApiPath,
  organizationsJsonApiSearchPath,
  relatedOrganizationJsonApiSearchPath,
} from "../../utils/urls";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { LoadingWrapper } from "../common/loading_wrapper";
import { OrganizationsAutocomplete } from "./organizations_autocomplete";

export interface OrganizationAssignAssetFormProps {
  ibox?: boolean;
  organizations: Organization[];
  relationType: OrganizationRelationType;
  asset?: Asset;
  assetId?: number;
  moveAsset?: boolean;

  onSubmitPressed?: (org: Organization) => void;
  onAssignFinished?: (org: Organization) => void;
  onAssignFailed?: (org: Organization, error?: Error) => void;
  onCancelPressed?: () => void;
}

interface OrganizationAssignAssetFormState {
  loading: boolean;
  asset: Asset;

  selectedOrganization: Organization;
  organizationGroups: UserGroup[];
  selectGroup: boolean;
  selectedOrganizationGroup: UserGroup;
  moveAsset: boolean;
  isOperator: boolean;
}

export class OrganizationAssignAssetForm extends React.Component<
  OrganizationAssignAssetFormProps,
  OrganizationAssignAssetFormState
> {
  static defaultProps = {
    loading: false,
    moveAsset: false,
  };
  constructor(props: OrganizationAssignAssetFormProps) {
    super(props);
    this.state = {
      loading: false,
      moveAsset: isNil(props.moveAsset) ? false : props.moveAsset,
      asset: this.props.asset,
      selectGroup: false,
      selectedOrganization: null,
      organizationGroups: null,
      selectedOrganizationGroup: null,
      isOperator: false,
    };
  }

  componentDidMount(): void {
    if (isNil(this.state.asset)) {
      this.setState({ loading: true });
      void loadDataFromUrl<SingleResourceDoc<string, AssetJSONAPIAttributes>>(
        assetPath(this.props.assetId, "json"),
      ).then((assetResponseDoc) => {
        const asset = jsonApiSingleResourceToFlatObject(assetResponseDoc);
        this.setState({ asset: asset, loading: false });
      });
    }
  }

  searchUrl(searchTerm: string, maxItems: number): string {
    return this.props.relationType
      ? relatedOrganizationJsonApiSearchPath(this.props.relationType, {
          searchTerm: searchTerm,
          pageNumber: 1,
          pageSize: maxItems,
          fields: ["icon_url", "name", "id", "organization_user_group_id"],
        })
      : organizationsJsonApiSearchPath(
          searchTerm,
          1,
          5,
          [],
          ["icon_url", "name", "id", "organization_user_group_id"],
        );
  }

  handleSubmit(): void {
    if (this.props.onSubmitPressed) {
      this.props.onSubmitPressed(this.state.selectedOrganization);
      return;
    }

    this.setState({ loading: true }, () => {
      void this.requestAssign();
    });
  }

  async loadOrganizationGroups() {
    const groupData = await loadDataFromUrl<
      CollectionResourceDoc<string, UserGroupJSONObject>
    >(api_organization_user_groups_path(this.state.selectedOrganization.id));

    const groups =
      jsonApiResourceCollectionToFlatObjects<UserGroupJSONObject>(groupData);
    // find the organization mail group
    const selectedOrganizationGroup = find(
      groups,
      (g) => g.id == this.state.selectedOrganization.organization_user_group_id,
    );

    this.setState({
      organizationGroups: groups,
      selectedOrganizationGroup,
    });
  }

  async requestAssign(): Promise<void> {
    try {
      const bodyParams: AssignAssetOrganizationBodyParams = {
        move: this.state.moveAsset,
        is_operator: this.state.isOperator,
      };
      if (this.state.selectedOrganizationGroup) {
        bodyParams.target_organization_user_group_id = toString(
          this.state.selectedOrganizationGroup?.id,
        );
      }
      const result = await sendData<
        object,
        { status?: string; message?: string }
      >(
        assignAssetOrganizationJsonApiPath(
          this.state.asset.id,
          this.state.selectedOrganization.id,
          null,
          this.props.relationType,
        ),
        bodyParams,
        "POST",
      );
      void toasts.success(
        defaultTo(
          result.message,
          I18n.t("frontend.process_successfully_completed"),
        ),
      );
      redirectTo();
    } catch (error) {
      void toasts.error(
        defaultTo(
          (error as SIALOGICApiResultMessage).message,
          I18n.t("frontend.process_failed"),
        ),
      );
      logger.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCancel(): void {
    if (this.props.onCancelPressed) {
      this.props.onCancelPressed();
      return;
    }

    redirectTo("back");
  }

  render(): React.ReactNode {
    const content = (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <LoadingWrapper size={"3x"} loading={this.state.loading}>
            {this.state.loading ? null : (
              <>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {translatedModelName("asset") +
                        ": " +
                        getTranslatedProp<Asset>(this.state.asset, "name")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <OrganizationsAutocomplete
                      variant="outlined"
                      label={I18n.t(
                        "frontend.organizations.organization_assign_asset_form.select_organization",
                      )}
                      width={"100%"}
                      onSelect={(o) => {
                        this.setState(
                          {
                            selectedOrganization: o,
                            selectedOrganizationGroup: null,
                          },
                          () => {
                            void this.loadOrganizationGroups();
                          },
                        );
                      }}
                      searchUrl={(searchTerm, maxItems) =>
                        this.searchUrl(searchTerm, maxItems)
                      }
                      inputId="organization-autocomplete"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.isOperator}
                            onChange={(event) => {
                              this.setState({
                                isOperator: event.target.checked,
                              });
                            }}
                          />
                        }
                        label={I18n.t(
                          "frontend.organizations.organization_assign_asset_form.is_operator",
                        )}
                      />
                      <FormHelperText>
                        {I18n.t(
                          "frontend.organizations.organization_assign_asset_form.is_operator_help",
                        )}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      disabled={isNil(this.state.selectedOrganization)}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.selectGroup}
                            onChange={(event) => {
                              this.setState({
                                selectGroup: event.target.checked,
                              });
                            }}
                          />
                        }
                        label={I18n.t(
                          "frontend.organizations.organization_assign_asset_form.select_group",
                        )}
                      />
                      <FormHelperText>
                        {I18n.t(
                          "frontend.organizations.organization_assign_asset_form.select_group_help",
                        )}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {this.state.selectGroup ? (
                    <Grid item xs={12}>
                      <FormControl
                        required
                        fullWidth={true}
                        disabled={isNil(this.state.organizationGroups)}
                      >
                        <InputLabel id="organization-group-label">
                          {I18n.t(
                            "frontend.organizations.organization_assign_asset_form.select_organization_group",
                          )}
                        </InputLabel>

                        <Select
                          fullWidth={true}
                          id="sensorType"
                          required={true}
                          value={toString(
                            this.state.selectedOrganizationGroup?.id,
                          )}
                          onChange={(e) => {
                            const selectedOrganizationGroup = find(
                              this.state.organizationGroups,
                              (g) => g.id == e.target.value,
                            );
                            this.setState({ selectedOrganizationGroup });
                          }}
                        >
                          {map(this.state.organizationGroups, (g) => (
                            <MenuItem key={g.id} value={toString(g.id)}>
                              {g.name}
                              {this.state.selectedOrganization
                                ?.organization_user_group_id == g.id
                                ? ` (${I18n.t(
                                    "activerecord.attributes.organization.organization_group",
                                  )})`
                                : null}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
              </>
            )}
          </LoadingWrapper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonGroup>
                <Button
                  onClick={() => this.handleCancel()}
                  variant="outlined"
                  color="secondary"
                >
                  {I18n.t("frontend.cancel")}
                </Button>
                <Button
                  onClick={() => this.handleSubmit()}
                  disabled={
                    this.state.loading || isNil(this.state.selectedOrganization)
                  }
                  variant="outlined"
                  color="primary"
                >
                  {I18n.t("frontend.ok")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    if (this.props.ibox) {
      return (
        <IBox>
          <IBoxTitle>
            <Typography variant="h5">
              {isNil(this.props.relationType)
                ? I18n.t(
                    `frontend.organizations.organization_assign_asset_form.${
                      this.state.moveAsset ? "move_" : ""
                    }heading`,
                  )
                : I18n.t(
                    `frontend.organizations.organization_assign_asset_form.heading_${
                      this.state.moveAsset ? "move_" : ""
                    }${this.props.relationType}`,
                  )}
            </Typography>
          </IBoxTitle>
          <IBoxContent>{content}</IBoxContent>
        </IBox>
      );
    } else {
      <>
        <Grid item xs={12}>
          <Typography variant="h6">
            {isNil(this.props.relationType)
              ? I18n.t(
                  "frontend.organizations.organization_assign_asset_form.heading",
                )
              : I18n.t(
                  `frontend.organizations.organization_assign_asset_form.heading_${this.props.relationType}`,
                )}
          </Typography>
        </Grid>
      </>;
      return content;
    }
  }
}
