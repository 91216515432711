import { AssetEventsWidget } from "../widgets/asset_events_widget";
import { AssetInfoWidget } from "../widgets/asset_info_widget";
import { AssetListWidget } from "../widgets/asset_list_widget";
import AssetMaintenanceInfoWidget from "../widgets/asset_maintenance_info_widget";
import { AssetMaintenanceTimelineWidget } from "../widgets/asset_maintenance_timeline_widget";
import { AssetMapWidget } from "../widgets/asset_map_widget";
import { AssetPartsWidgetDefinition } from "../widgets/asset_parts_widget";
import { BarChartWidgetDefinition } from "../widgets/bar_chart_widget";
import { ImageWidgetDefinition } from "../widgets/image_widget";
import { KpiWidget } from "../widgets/kpi_widget";
import { LevelIndicatorWidget } from "../widgets/level_indicator_widget";
import { LineDiagramWidget } from "../widgets/line_diagram_widget";
import { LinkWidgetDefinition } from "../widgets/link_widget";
import { ModelViewerWidget } from "../widgets/model_viewer_widget";
import { OffsetGaugeWidget } from "../widgets/offset_gauge_widget";
import { OrganizationsWidget } from "../widgets/organizations_widget";
import { PercentageWidget } from "../widgets/percentage_widget";
import { SensorAggregationWidget } from "../widgets/sensor_aggregation_widget";
import { SensorGroupWidget } from "../widgets/sensor_group_widget";
import { SensorValueTableWidget } from "../widgets/sensor_value_table_widget";
import { SensorValueWidget } from "../widgets/sensor_value_widget";
import { SvgAnimationWidget } from "../widgets/svg_animation_widget";
import { ValueDifferenceWidget } from "../widgets/value_difference_widget";
import { WeatherWidget } from "../widgets/weather_widget";

import { CommentWidgetDefinition } from "../widgets/comment_widget";
import { MaintenancesWidgetDefinition } from "../widgets/maintenances_widget";
import {
  SerializedConfigToProps,
  SialogicWidgetComponent,
  SialogicWidgetDefinition,
} from "../widgets/sialogic_widget_component";

import { WidgetConfigSerialized } from "../../widgets/widget.types";
import { StateWidgetDefinition } from "../widgets/state_widget";
import { WidgetBoxProps } from "../widgets/widget_box.types";

function widgetDefinition<
  T extends SialogicWidgetComponent<P>,
  C extends WidgetConfigSerialized = WidgetConfigSerialized,
  P extends WidgetBoxProps = WidgetBoxProps,
>(component: T): SialogicWidgetDefinition<T, SerializedConfigToProps<C, P>> {
  return {
    component,
    serializedConfigToProps: component.serializedConfigToProps,
  };
}

export const WidgetComponentsByType: Record<string, SialogicWidgetDefinition> =
  {
    "Widgets::AssetEventsWidget": widgetDefinition(AssetEventsWidget),
    "Widgets::AssetInfoWidget": widgetDefinition(AssetInfoWidget),
    "Widgets::AssetListWidget": widgetDefinition(AssetListWidget),
    "Widgets::AssetPartsWidget": AssetPartsWidgetDefinition,
    "Widgets::AssetMaintenanceInfoWidget": widgetDefinition(
      AssetMaintenanceInfoWidget,
    ),
    "Widgets::AssetMaintenanceTimelineWidget": widgetDefinition(
      AssetMaintenanceTimelineWidget,
    ),
    "Widgets::AssetMapWidget": widgetDefinition(AssetMapWidget),
    "Widgets::BarChartWidget": BarChartWidgetDefinition,
    "Widgets::CommentWidget": CommentWidgetDefinition,
    "Widgets::ImageWidget": ImageWidgetDefinition,
    "Widgets::KpiWidget": widgetDefinition(KpiWidget),
    "Widgets::LevelIndicatorWidget": widgetDefinition(LevelIndicatorWidget),
    "Widgets::LineDiagramWidget": widgetDefinition(LineDiagramWidget),
    "Widgets::LinkWidget": LinkWidgetDefinition,
    "Widgets::ModelViewerWidget": widgetDefinition(ModelViewerWidget),
    "Widgets::OffsetGaugeWidget": widgetDefinition(OffsetGaugeWidget),
    "Widgets::OrganizationsWidget": widgetDefinition(OrganizationsWidget),
    "Widgets::PercentageWidget": widgetDefinition(PercentageWidget),
    "Widgets::SensorAggregationWidget": widgetDefinition(
      SensorAggregationWidget,
    ),
    "Widgets::SensorGroupWidget": widgetDefinition(SensorGroupWidget),
    "Widgets::SensorValueTableWidget": widgetDefinition(SensorValueTableWidget),
    "Widgets::SensorValueWidget": widgetDefinition(SensorValueWidget),
    "Widgets::StateWidget": StateWidgetDefinition,
    "Widgets::SvgAnimationWidget": widgetDefinition(SvgAnimationWidget),
    "Widgets::ValueDifferenceWidget": widgetDefinition(ValueDifferenceWidget),
    "Widgets::ValueWidget": widgetDefinition(ValueDifferenceWidget),
    "Widgets::WeatherWidget": widgetDefinition(WeatherWidget),
    "Widgets::MaintenancesWidget": MaintenancesWidgetDefinition,

    // We cannot use widget group widget here as it builds up a circular dependency. Dashboard widgets handle this case separately
    //"Widgets::WidgetGroupWidget": WidgetGroup,
  };
