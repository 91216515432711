import * as React from "react";
import { FunctionComponent } from "react";
import { IDType } from "../../utils/urls/url_utils";
import { DashboardContainer } from "./dashboard_container";
import { DashboardContextProvider } from "./dashboard_context_provider";
import { DateRange } from "moment-range";
import { TTimescopeNames } from "../../utils/time_scopes";
import { DashboardType } from "../../models/dashboard";

interface DashboardRootProps {
  assetId: IDType;
  dashboardId: IDType;
  assetTypeId?: IDType;
  dashboardType: DashboardType;
  baseUrl?: string;
  initialTimeRange?: DateRange;
  initialTimeScopeName?: TTimescopeNames;
}

export const DashboardRoot: FunctionComponent<DashboardRootProps> = (props) => {
  return (
    <DashboardContextProvider
      assetId={props.assetId}
      dashboardId={props.dashboardId}
      dashboardType={props.dashboardType}
      assetTypeId={props.assetTypeId}
      baseUrl={props.baseUrl}
      initialTimeRange={props.initialTimeRange}
      initialTimeScopeName={props.initialTimeScopeName}
    >
      <DashboardContainer />
    </DashboardContextProvider>
  );
};
