import { Grid, Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";
import {
  dashboardGridSizeProps,
  propsForWidgetType,
  widgetComponentDefinitionForType,
} from "../../utils/dashboard_layout_tools";
import { ErrorBoundary } from "../common/error_boundary";
import { WidgetBox } from "../widgets/widget_box";

import { widgetMinHeight } from "../../utils/widget_height_class";
import { DashboardContext } from "./dashboard_context";
import { DashboardWidgetProps } from "./dashboard_widget.types";
import { DashboardWidgetWithErrors } from "./dashboard_widget_with_errors";

export const DashboardWidget: FunctionComponent<DashboardWidgetProps> = (
  props,
) => {
  const context = React.useContext(DashboardContext);
  const widgetComponentDefinition = widgetComponentDefinitionForType(
    props.config.widget_type,
  );
  // memoize the widget props to avoid re-rendering the widget when the time range changes
  const widgetProps = React.useMemo(() => {
    const widgetProps = propsForWidgetType(props.config);
    if (!isNil(widgetProps) && context.timeRange) {
      widgetProps.timeRange = context.timeRange;
      widgetProps.timeScopeName = context.timeScope;
    }
    return widgetProps;
  }, [
    props.config,
    props.config.widget_type,
    context.timeRange,
    context.timeScope,
  ]);

  return (
    <Grid
      {...dashboardGridSizeProps(props.config.size)}
      maxHeight={widgetMinHeight(props.config.height)}
      height={widgetMinHeight(props.config.height)}
      item
    >
      <ErrorBoundary
        errorComponent={(error) => (
          <WidgetBox
            encloseInIBox={true}
            dashboardSettings={props.config}
            title={I18n.t("frontend.dashboard_widget.heading_error")}
          >
            <Typography variant="body1" onClick={() => {}}>
              {error.message}
            </Typography>
          </WidgetBox>
        )}
      >
        {isNil(widgetComponentDefinition?.component) ? (
          <WidgetBox>
            <Typography>{props.config.widget_type}</Typography>
          </WidgetBox>
        ) : !isEmpty(props.config.errors) ? (
          <DashboardWidgetWithErrors
            errors={props.config.errors}
            widgetProps={widgetProps}
            config={props.config}
          />
        ) : (
          React.createElement(widgetComponentDefinition.component, widgetProps)
        )}
      </ErrorBoundary>
    </Grid>
  );
};
