import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { MaintenanceDueDropdownContainer } from "./container/maintenance_due_container";

const maintenanceDueDropdownContainerRoots: Root[] = [];
/**
 * Mount an event dropdown to all selected elements
 * @param selector A jquery selector for html elements
 */
export function initialize(selector: JQuery): void {
  if (isEmpty(selector)) return;
  selector.each((index: number, element: HTMLElement) => {
    const root = createRoot(element);
    maintenanceDueDropdownContainerRoots.push(root);

    root.render(
      <AppRoot>
        <MaintenanceDueDropdownContainer
          loadMaintenances={!(gon.disableNotificationLoad ?? false)}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove all react components from the selected elements
 * @param selector A jquery selector for html elements
 */
export function destroy(selector: JQuery): void {
  if (isEmpty(maintenanceDueDropdownContainerRoots)) return;
  each(maintenanceDueDropdownContainerRoots, (r) => r.unmount());
  maintenanceDueDropdownContainerRoots.length = 0;
  //Actions.resetState();
}
