import Promise, { CancellationError } from "bluebird";
import { defaultTo, isEmpty, isNaN, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { SensorLoader } from "../../json_api/sensor_loader";
import { SensorValueType } from "../../models/sensor";
import { SensorValueRange } from "../../models/sensor_value_range";
import { logger } from "../../utils/logger";
import {
  getStatusLabel,
  getValueRangeForValue,
} from "../../utils/status_helper";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { sensorUrl } from "../../utils/urls";
import { LoadingIcon } from "../common/icon";
import { ValueDisplay } from "../common/value_display";
import {
  SensorValueWidgetProps,
  SensorValueWidgetState,
} from "./sensor_value_widget.types";
import { WidgetBox } from "./widget_box";

import { Box } from "@mui/material";
import {
  getIconNameForMeasurementType,
  getIconNameForSensorType,
} from "../../utils/sensor_type_icons";
import {
  SensorValueDisplayMode,
  SensorValueWidgetConfigSerialized,
} from "../../widgets/sensor_value_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";

export const defaultPrecision = 2;

export class SensorValueWidget
  extends React.Component<SensorValueWidgetProps, SensorValueWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<SensorValueWidgetProps> = {
    mode: "rows",
    useValueRange: false,
    encloseInIBox: false,
  };

  static serializedConfigToProps(
    config: SensorValueWidgetConfigSerialized,
  ): SensorValueWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      sensorId: config.sensor_id,
      assetId: config.asset_id,
      sensorType: config.sensor_type,
      measurementType: config.measurement_type,
      iconName: config.icon_name,
      iconSize: config.icon_size,

      status: config.value_status,
      // load start value and timestamp
      value: config.value as number,
      hideValue: defaultTo(config.hide_value, false),

      unit: defaultTo(config.display_unit, config.unit),

      precision: defaultTo(config.precision, defaultPrecision),

      timestamp: isNil(config.timestamp)
        ? null
        : moment(config.timestamp).local(),

      timeScopeName: config.timescope,

      mode: defaultTo<SensorValueDisplayMode>(config.mode, "inline"),

      vertical: defaultTo(config.vertical, false),
      textShadow: defaultTo(config.text_shadow, false),
      updateEnabled: !defaultTo(config.disable_update, false),

      valueRanges: config.value_ranges,
      useValueRange: defaultTo(config.use_value_range, false),
    });
  }

  private loadSensorPromise: Promise<void>;

  constructor(props: SensorValueWidgetProps) {
    super(props);
    const value = (props.sensor?.last_value || props.value) as SensorValueType;
    const state = {
      sensor: props.sensor,
      title: (props.title as string) || props.sensor?.name,
      value,
      unit:
        props.sensor?.display_unit ||
        props.unit ||
        props.sensor?.display_unit ||
        props.sensor?.attribute_key_unit,

      timestamp: props.timestamp,
      //|| props.sensor?.last_value?.timestamp
      //  ? moment(props.sensor?.last_value?.timestamp)
      //  : null,
      timeScopeName: props.timeScopeName,
      status: props.status,
      titleLinkUrl: this.getSensorLink(),
      contentLinkUrl: this.getSensorLink(),
      loading: false,
      iconName: props.iconName,
      valueRanges: props.sensor?.value_ranges || props.valueRanges,

      currentValueRange: getValueRangeForValue(
        props.value as number,
        props.valueRanges,
      ),
    };

    if (props.sensor) {
      this.state = { ...state, ...this.stateFromSensor(props.sensor) };
    } else {
      this.state = state;
    }
    this.loadSensorPromise = null;
  }

  componentDidMount(): void {
    // initialize component
    if (isNil(this.props.sensor)) {
      this.loadSensorData(this.props);
    } else {
      this.setStateFromSensor(this.props.sensor);
      this.toggleSensorUpdates(this.props);
    }
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        this.getSensorId(),
      );
    }

    if (!isNil(this.loadSensorPromise)) {
      this.loadSensorPromise.cancel();
      this.loadSensorPromise = null;
    }
  }

  getSensorId(): number {
    return (this.props.sensor?.id as number) || (this.props.sensorId as number);
  }

  componentDidUpdate(oldProps: SensorValueWidgetProps): void {
    const oldSensorId = oldProps.sensor?.id || oldProps.sensorId;
    const newSensorId = this.getSensorId();
    if (newSensorId !== oldSensorId) {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        oldSensorId as number,
      );
      if (!isNil(this.props.sensor)) {
        this.setStateFromSensor(this.props.sensor);
        this.toggleSensorUpdates(this.props);
      } else {
        this.loadSensorData(this.props);
      }
      return;
    }

    if (this.props.updateEnabled !== oldProps.updateEnabled) {
      this.toggleSensorUpdates(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    if (isNumber(value) && !isNil(unit) && !isNil(this.props.unit)) {
      value = convertToUnit(value, unit, this.props.unit);
    }

    this.setState((oldState) => ({
      ...oldState,
      currentValueRange: getValueRangeForValue(
        value as number,
        this.state.valueRanges,
      ),
      value,
      timestamp: time,
    }));
  }

  render(): React.ReactNode {
    const valRange: SensorValueRange = this.props.useValueRange
      ? this.state.currentValueRange
      : null;
    const valueDisplay = (
      <>
        <ValueDisplay
          mode={this.props.mode}
          value={this.state.value}
          unit={this.state.unit}
          sensorType={
            this.props.sensorType ?? this.state.sensor?.sensor_type_name
          }
          measurementType={
            this.props.measurementType ?? this.state.sensor?.measurement_type
          }
          iconName={defaultTo(valRange?.icon_name, this.state.iconName)}
          iconSize={this.props.iconSize}
          hideValue={this.props.hideValue}
          precision={this.props.precision ?? this.state.sensor?.precision}
          timestamp={getTimeString(
            this.state.timeScopeName,
            this.state.timestamp,
          )}
          shadowText={this.props.textShadow}
          color={valRange?.color}
          status={getStatusLabel(
            this.props.status,
            this.props.useValueRange ? valRange?.name : null,
          )}
          vertical={this.props.vertical}
        />
        {this.state.loading ? (
          <LoadingIcon size={this.props.vertical ? "2x" : "1x"} />
        ) : null}
      </>
    );

    if (this.props.mode === "inline") {
      return <Box className="sensor-value-sm">{valueDisplay}</Box>;
    } else {
      return (
        <WidgetBox
          {...this.props}
          title={this.state.title}
          titleLinkUrl={this.state.titleLinkUrl}
          contentLinkUrl={this.state.contentLinkUrl}
        >
          {valueDisplay}
        </WidgetBox>
      );
    }
  }

  private loadSensorData(props: SensorValueWidgetProps): void {
    const sensorId = props.sensorId;
    if (isNil(sensorId) || isNaN(sensorId)) {
      return;
      //throw new Error("Invalid Sensor ID");
    }
    this.setState({ loading: true });
    this.loadSensorPromise = SensorLoader.getInstance()
      .getSensors([sensorId])
      .catch(CancellationError, (e) => {
        logger.info("Loading sensor data cancelled", e);
        return [];
      })
      .then(([sensorAttributes]) => {
        this.setStateFromSensor(sensorAttributes as SensorJSONAPIAttributes);
        this.toggleSensorUpdates(props);
      })

      .catch((e) => {
        logger.error("Error loading sensor data", e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  protected getSensorLink(sensor: SensorJSONAPIAttributes = null) {
    const theSensor = sensor ?? this.state?.sensor ?? this.props.sensor;
    const assetId = this.props.assetId ?? theSensor?.asset_id;
    if (isNil(assetId)) return null;

    const link =
      this.props.noLinks || this.props.mode == "inline"
        ? null
        : sensorUrl(
            {
              id: this.getSensorId(),
              assetId: this.props.assetId ?? theSensor?.asset_id,
            },
            "html",
          );

    return link;
  }
  protected setStateFromSensor(sensor: SensorJSONAPIAttributes) {
    this.setState(this.stateFromSensor(sensor));
  }

  protected stateFromSensor(sensor: SensorJSONAPIAttributes) {
    const value = isNil(sensor?.last_value) ? null : sensor?.last_value.value;

    const currentValueRange = getValueRangeForValue(
      value as number,
      sensor?.value_ranges,
    );

    const link = this.getSensorLink(sensor);
    let iconName = this.props.iconName;
    if (this.props.useValueRange && !isNil(currentValueRange?.icon_name)) {
      iconName = currentValueRange.icon_name;
    } else if (sensor?.sensor_type_name) {
      iconName = getIconNameForSensorType(sensor?.sensor_type_name);
    }
    if (!iconName) {
      iconName = getIconNameForMeasurementType(sensor?.measurement_type);
    }
    return {
      sensor,
      value,

      timestamp: isNil(sensor?.last_value)
        ? null
        : moment(sensor.last_value.timestamp),
      title: isEmpty(this.props.title)
        ? sensor?.name
        : (this.props.title as string),
      unit: isNil(sensor?.display_unit)
        ? sensor?.attribute_key_unit
        : sensor?.display_unit,

      iconName,

      titleLinkUrl: link,
      contentLinkUrl: link,
      valueRanges: sensor?.value_ranges,
      currentValueRange,
    } as SensorValueWidgetState;
  }

  protected toggleSensorUpdates(props: SensorValueWidgetProps): void {
    if (WidgetController.getInstance()) {
      const sensorId: number = this.getSensorId();
      if (props.updateEnabled) {
        WidgetController.getInstance().sensorDataChannel.subscribe(sensorId);
        WidgetController.getInstance().sensorDataChannel.addEventListener(
          this,
          sensorId,
        );
      } else {
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          sensorId,
        );
      }
    }
  }
}
