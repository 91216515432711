/// <reference types="../../../definitions/index" />;

import { defaultTo, find, get, has, isEmpty, isNil, isString } from "lodash";
import * as React from "react";
import { getTranslatedProp } from "../../../utils/globalize";
import { IconButton } from "../../common/icon_button";
import { MaintenancePlanIcon } from "../../common/maintenance/maintenance_plan_icon";
import { MaintenancePlan, MaintenanceType } from "../data/models";

import {
  Autocomplete,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";

interface MaintenacePlanNameInputProps {
  maintenancePlan: MaintenancePlan;
  maintenanceTypes: MaintenanceType[];
  onChange: (name: string, maintenanceType: MaintenanceType) => void;
}

interface MaintenacePlanNameInputState {
  name: string;
  maintenanceType: MaintenanceType;
  editName?: boolean;
  dropdownSuggestions: MaintenanceType[];
}
function getAutocompleteOptionLabel(
  option: Partial<MaintenanceType> | string,
): string {
  // Value selected with enter, right from the input
  if (typeof option === "string") {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.title) {
    return option.title;
  }
  // Regular option
  return getTranslatedProp(option, "name");
}
const filter = createFilterOptions<Partial<MaintenanceType>>();
/**
 * An input field for a maintenance plan name. The input field is toggable between only showing the name and enable editing.
 * The input has an autocomplete function the suggests existing maintenance types with the same/similar name.
 * The suggestions can be selected to apply the name and other settings from the maintenance type.
 */
export class MaintenacePlanNameInput extends React.Component<
  MaintenacePlanNameInputProps,
  MaintenacePlanNameInputState
> {
  nameFieldName: string;

  constructor(props: MaintenacePlanNameInputProps) {
    super(props);
    this.nameFieldName = `name_${I18n.locale}`;

    this.state = {
      name: getTranslatedProp(props.maintenancePlan, "name"),
      maintenanceType: find(
        props.maintenanceTypes,
        (type) => type.id === props.maintenancePlan.maintenance_type_id,
      ),
      editName: false,
      dropdownSuggestions: props.maintenanceTypes,
    };
  }

  componentDidUpdate(prevProps: MaintenacePlanNameInputProps): void {
    if (prevProps.maintenancePlan !== this.props.maintenancePlan) {
      this.setState({
        name: getTranslatedProp(this.props.maintenancePlan, "name"),
        maintenanceType: find(
          this.props.maintenanceTypes,
          (type) => type.id === this.props.maintenancePlan.maintenance_type_id,
        ),
      });
    }
  }

  render(): React.ReactNode {
    return this.state.editName ? (
      <Grid container>
        <Grid item xs={10}>
          <Autocomplete<Partial<MaintenanceType> | null, false, false, true>
            options={this.state.dropdownSuggestions}
            size={"small"}
            fullWidth={true}
            value={defaultTo(this.state.maintenanceType, null)}
            isOptionEqualToValue={(option, value) => {
              if (typeof option == "string" && typeof value == "string") {
                return option == value;
              } else if (
                typeof option == "string" &&
                typeof value != "string"
              ) {
                return getTranslatedProp(value, "name") == option;
              } else if (
                typeof option != "string" &&
                typeof value == "string"
              ) {
                return getTranslatedProp(option, "name") == value;
              } else {
                return (
                  getTranslatedProp(option, "name") ==
                  getTranslatedProp(value, "name")
                );
              }
            }}
            getOptionLabel={(option) => getAutocompleteOptionLabel(option)}
            renderOption={(props, option) => {
              const label = getAutocompleteOptionLabel(option);
              return (
                <li {...props} key={option.id || label}>
                  {label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={I18n.t("frontend.name")}
                error={has(this.props.maintenancePlan.errors, "name")}
              />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (!isEmpty(params.inputValue)) {
                filtered.unshift({
                  id: null,
                  name_de: params.inputValue,
                  name_en: params.inputValue,
                  title: I18n.t("frontend.maintenance_plan_name_input.add", {
                    name: params.inputValue,
                  }),
                });
              }

              return filtered;
            }}
            freeSolo={true}
            onChange={(event, value) => {
              if (isNil(value)) {
                this.props.onChange(null, null);
              } else if (isString(value)) {
                this.onChangeValue(value, null);
              } else {
                this.onSelectMaintenanceType(value as MaintenanceType);
              }
            }}
          />

          <FormHelperText>
            {get(this.props.maintenancePlan.errors, "name") as string}
          </FormHelperText>
        </Grid>
        <Grid item xs={2}>
          <div className="mini-btns">
            <IconButton
              icon="check"
              title={I18n.t("frontend.apply")}
              className="text-success align-middle ml-2"
              onClick={() => this.onApply()}
            />
            <IconButton
              icon="times"
              title={I18n.t("frontend.cancel")}
              className="ml-1 text-danger align-middle"
              onClick={() => this.onCancel()}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <h4
          className={
            has(this.props.maintenancePlan.errors, "name")
              ? "mb-1 is-invalid"
              : "mb-1"
          }
          title={I18n.t("frontend.click_to_edit")}
        >
          <MaintenancePlanIcon maintenancePlan={this.props.maintenancePlan} />
          <IconButton
            className="mr-1 text-success"
            icon="pencil"
            title={I18n.t("frontend.maintenance_plan_form.edit_name")}
            onClick={() => this.onChangeEditNameMode(true)}
          />
          {this.renderNameLabel(() => this.onChangeEditNameMode(true))}
        </h4>
        <FormHelperText>
          {get(this.props.maintenancePlan.errors, "name") as string}
        </FormHelperText>
      </>
    );
  }

  private renderNameLabel(onClick: () => void): JSX.Element {
    const fallbackName = getTranslatedProp(this.props.maintenancePlan, "name");
    if (isEmpty(this.state.name) && !isEmpty(fallbackName)) {
      if (!isEmpty(fallbackName))
        return (
          <Typography
            component="span"
            onClick={onClick}
            style={{ cursor: "pointer", fontStyle: "italic" }}
          >
            {fallbackName}
          </Typography>
        );
    } else if (isEmpty(this.state.name)) {
      return (
        <Typography
          component="span"
          onClick={onClick}
          style={{ cursor: "pointer", fontStyle: "italic" }}
        >
          {this.props.maintenancePlan.type === "MaintenancePlan"
            ? I18n.t("frontend.maintenance_plan_form.no_maintenance_plan_name")
            : I18n.t("frontend.maintenance_plan_form.no_inspection_plan_name")}
        </Typography>
      );
    } else {
      return (
        <Typography
          component="span"
          variant="subtitle2"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        >
          {this.state.name}
        </Typography>
      );
    }
  }

  private onApply(): void {
    this.onChangeEditNameMode(false);
    this.onChangeValue(this.state.name, this.state.maintenanceType);
  }

  private onCancel(): void {
    this.setState({
      name: getTranslatedProp(this.props.maintenancePlan, "name"),
      maintenanceType: find(
        this.props.maintenanceTypes,
        (type) => type.id === this.props.maintenancePlan.maintenance_type_id,
      ),
      editName: false,

      dropdownSuggestions: this.props.maintenanceTypes,
    });
  }

  private onSelectMaintenanceType(maintenanceType: MaintenanceType): void {
    const name = getTranslatedProp(maintenanceType, "name");
    this.setState({
      name,
      maintenanceType,

      editName: false,
    });

    // select and trigger change
    this.onChangeValue(name, maintenanceType);
  }

  private onChangeEditNameMode(active: boolean): void {
    this.setState({
      editName: active,
    });
  }

  private onChangeValue(name: string, maintenanceType: MaintenanceType): void {
    this.props.onChange(
      name,
      isNil(maintenanceType?.id) ? null : maintenanceType,
    );
  }
}
