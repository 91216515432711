import React from "react";
import PropTypes from "prop-types";
import numericalFieldHandler from "../../utils/numericalFieldHandler";
import translateLabel from "../../utils/translateLabel";
import { TextField } from "@mui/material";

const EndAfter = ({ id, after, handleChange, translations }) => (
  <div className="col-sm-4">
    <div className="row">
      <div className="col-3 col-sm-6 pl-0">
        <TextField
          type="number"
          id={id}
          size="small"
          name="end.after"
          aria-label="End after"
          label={translateLabel(translations, "end.executions")}
          value={after}
          onChange={numericalFieldHandler(handleChange)}
        />
      </div>
    </div>
  </div>
);

EndAfter.propTypes = {
  id: PropTypes.string.isRequired,
  after: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default EndAfter;
