import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { SingleResourceDoc } from "jsonapi-typescript";
import {
  defaultTo,
  isNil
} from "lodash";
import * as React from "react";
import { translatedModelName } from "../../i18n/translation_helper";
import { AssetJSONObject } from "../../json_api/asset";
import { DeviceJSONAPIAttributes } from "../../json_api/device";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { Asset } from "../../models/asset";
import { Device } from "../../models/device";
import {
  HttpError,
  loadDataFromUrl,
  sendData,
} from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { info } from "../../utils/toasts";
import {
  assetDevicesPath,
  assignAssetDeviceJsonApiPath,
  deviceJsonApiPath,
} from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { AssetsAutocomplete } from "../assets/assets_autocomplete";
import { AssignmentForm } from "../common/assignment_form";

export interface DeviceAssignAssetFormProps {
  ibox?: boolean;
  assets: AssetJSONObject[];
  device?: Device;
  deviceId?: number;
  asset?: AssetJSONObject;
  assetId?: number;

  onSubmitPressed?: (asset: Asset) => void;
  onAssignFinished?: (asset: Asset) => void;
  onAssignFailed?: (org: Asset, error?: Error) => void;
  onCancelPressed?: () => void;
}

interface DeviceAssignAssetFormState {
  loading: boolean;
  device?: Device;
  selectedAsset: AssetJSONObject;
  rootOnly: boolean;
  assetTreeOnly: boolean;
  contextAssetId: IDType;
}

export class DeviceAssignAssetForm extends React.Component<
  DeviceAssignAssetFormProps,
  DeviceAssignAssetFormState
> {
  static defaultProps = {
    loading: false,
  };
  constructor(props: DeviceAssignAssetFormProps) {
    super(props);
    this.state = {
      loading: false,
      device: props.device,
      selectedAsset: this.props.asset,
      rootOnly: true,
      assetTreeOnly: false,
      contextAssetId: defaultTo(this.props.asset?.id, this.props.assetId),
    };
  }

  componentDidMount(): void {
    if (isNil(this.state.device)) {
      this.setState({ loading: true });
      void loadDataFromUrl<SingleResourceDoc<string, DeviceJSONAPIAttributes>>(
        deviceJsonApiPath(this.props.deviceId),
      ).then((deviceResponseDoc) => {
        const device = jsonApiSingleResourceToFlatObject(deviceResponseDoc);
        this.setState({ device: device, loading: false });
      });
    }
  }

  handleSubmit(): void {
    if (this.props.onSubmitPressed) {
      this.props.onSubmitPressed(this.state.selectedAsset);
      return;
    }

    this.setState({ loading: true }, () => {
      void this.requestAssign();
    });
  }

  async requestAssign(): Promise<void> {
    try {
      const result = await sendData<any, { message: string }>(
        assignAssetDeviceJsonApiPath(
          this.state.device.id,
          this.state.selectedAsset.id as number,
        ),
        {},
        "POST",
      );
      const res = await info(I18n.t("frontend.success"), result.message);
      redirectTo(assetDevicesPath(this.state.selectedAsset.id as number));
    } catch (error) {
      logger.error(error);
      if (error instanceof HttpError) {
        void toasts.error(
          I18n.t(
            "frontend.devices.device_assign_asset_form.error_assigning_asset",
          ),
          error.response,
        );
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCancel(): void {
    if (this.props.onCancelPressed) {
      this.props.onCancelPressed();
      return;
    }

    redirectTo("back");
  }

  render(): React.ReactNode {
    return (
      <AssignmentForm
        ibox={this.props.ibox}
        loading={this.state.loading}
        iboxTitle={I18n.t("frontend.devices.device_assign_asset_form.heading")}
        submitEnabled={!this.state.loading && !isNil(this.state.selectedAsset)}
        onSubmitClick={() => {
          this.handleSubmit();
        }}
        onCancelClick={() => {
          this.handleCancel();
        }}
        heading={translatedModelName("device") + ": " + this.state.device?.name}
      >
        <AssetsAutocomplete
          variant="outlined"
          label={I18n.t(
            "frontend.devices.device_assign_asset_form.select_asset",
          )}
          width={"100%"}
          assets={this.props.assets}
          asset={this.state.selectedAsset}
          assetTreeOnly={this.state.assetTreeOnly}
          contextAssetId={this.state.contextAssetId}
          rootsOnly={this.state.rootOnly}
          onSelect={(a) => this.setState({ selectedAsset: a })}
          inputId="assign-asset-autocomplete"
        />
        <FormGroup>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.rootOnly}
                  onChange={(e) => {
                    this.setState({
                      rootOnly: e.currentTarget.checked,
                      assetTreeOnly: false,
                    });
                  }}
                />
              }
              label={I18n.t(
                "frontend.sensors.sensor_assign_asset_form.root_only",
              )}
            />
            <FormHelperText>
              {I18n.t(
                "frontend.sensors.sensor_assign_asset_form.root_only_help",
              )}
            </FormHelperText>
          </FormControl>
        </FormGroup>
        {isNil(this.state.contextAssetId) ? null : (
          <FormGroup>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.assetTreeOnly}
                    onChange={(e) => {
                      this.setState({
                        assetTreeOnly: e.currentTarget.checked,
                        rootOnly: !e.currentTarget.checked,
                      });
                    }}
                  />
                }
                label={I18n.t(
                  "frontend.sensors.sensor_assign_asset_form.asset_tree_only",
                )}
              />
              <FormHelperText>
                {I18n.t(
                  "frontend.sensors.sensor_assign_asset_form.asset_tree_only_help",
                )}
              </FormHelperText>
            </FormControl>
          </FormGroup>
        )}
      </AssignmentForm>
    );
  }
}
