import { PlotlyChart } from "../components/common/plotly_chart";
import {
  SensorValueRange,
  ValueRangeStatus,
} from "../models/sensor_value_range";
import { PlotlyFont } from "./plotly_styling";

import { defaultTo, isEmpty, isNil } from "lodash";
import * as React from "react";
import { ColorUtils } from "../utils/colors";
import { unitDisplayString } from "../utils/unit_conversion";
import { Plotly } from "./plotly_package";
import { Box } from "@mui/material";

export interface PlotlyGaugeChartProps {
  divId: string;
  value: number;
  maxWidth?: number | string;
  height?: number;
  range?: [number, number];
  unit?: string;
  delta?: number;
  deltaReference?: number;
  // d3 number format for value display
  numberFormat?: string;
  valueRanges?: SensorValueRange[];
  displayValueByTick?: boolean;
  trendValue?: number;
  axisType?: "linear" | "log";

  // if set to true the value ranges will not be used for drawing colored areas
  noStepIntervals?: boolean;
}

const valueRangeStatusToColor: Record<ValueRangeStatus, string> = {
  optimal: "#addd8e",
  normal: null,
  critical: "#fec44f",
  fatal: "#f03b20",
};

const INDICATION_BAR_WIDTH = 0.8;

export class PlotlyGaugeChart extends React.Component<PlotlyGaugeChartProps> {
  static defaultProps = {
    maxWidth: 250,
    height: 150,
    range: [0, 100],
    axisType: "linear",
    noStepIntervals: false,
  };

  render(): React.ReactNode {
    const heightValue = defaultTo(this.props.height, 150);
    let cssHeightValue;
    if (!isNil(heightValue)) {
      cssHeightValue = heightValue + 30;
    }
    return (
      <Box maxWidth={this.props.maxWidth} margin="auto">
        <PlotlyChart
          divId={this.props.divId}
          data={[this.getPlotlyData()]}
          layout={{
            height: heightValue,

            margin: {
              l: 50,
              t: 20,
              b: 10,
              r: 50,
              pad: 20,
            } as Plotly.Margin,

            font: PlotlyFont,
          }}
          config={{
            autosizable: true,
            responsive: true,
            displaylogo: false,
            displayModeBar: false,
            locale: I18n.locale,
          }}
        />
      </Box>
    );
  }

  private getPlotlyData(): Plotly.Data {
    let thresholdProps = {};
    const stepsProps = [];

    // Handle value ranges display
    if (this.props.valueRanges && this.props.noStepIntervals !== true) {
      for (const valueRange of this.props.valueRanges) {
        if (valueRange.status in valueRangeStatusToColor) {
          stepsProps.push({
            range: [valueRange.min, valueRange.max],
            color: defaultTo(
              valueRange.color,
              valueRangeStatusToColor[valueRange.status],
            ),
          });
        }
      }
    }

    if (this.props.displayValueByTick) {
      // Handle display of the value as bar or tick.
      thresholdProps = {
        value: this.props.value,
        line: { color: ColorUtils.getDefaultColorRgb(), width: 4 },
        thickness: INDICATION_BAR_WIDTH,
      };
    }
    let deltaReference = 0;

    if (!isNil(this.props.trendValue)) {
      // Set delta reference so that the displayed delta is equal to the trend value.
      deltaReference = this.props.value - this.props.trendValue;
    }

    let theUnitDisplayString = unitDisplayString(this.props.unit);
    if (!isEmpty(theUnitDisplayString)) {
      theUnitDisplayString = ` ${theUnitDisplayString}`;
    } else {
      theUnitDisplayString = null;
    }
    return {
      domain: { x: [0, 1], y: [0, 1] },
      gauge: {
        shape: "angular",
        borderwidth: 1,
        bgcolor: null,

        axis: {
          type: "linear",
          visible: true,
          range: this.props.range,
          ticksuffix: theUnitDisplayString,
        },
        bar: {
          color: this.props.displayValueByTick
            ? "#ffffff00"
            : ColorUtils.getDefaultColorRgb(),
        },

        steps: stepsProps,
        threshold: thresholdProps,
      },
      value: this.props.value,
      number: {
        suffix: theUnitDisplayString,
      } as Partial<Plotly.PlotNumber>,
      type: "indicator",
      delta: { reference: deltaReference } as Partial<Plotly.Delta>, // , increasing: { symbol: '+' }, decreasing: { symbol: '-' }
      mode: ("gauge+number" +
        (isNil(this.props.trendValue) ? "" : "+delta")) as
        | "gauge+number"
        | "gauge+number+delta",
    };
  }
}
