import Bluebird from "bluebird";
import { Container } from "flux/utils";
import { isNil, map, noop } from "lodash";
import * as React from "react";
import moment from "../../../initializers/moment";
import "../../../utils/flux_container_adapter";
import MaintenanceDueStore, {
  MaintenanceDueState,
} from "../data/maintenance_due_store";
//import { requestDesktopNotifications, createDesktopNotification, } from '../desktop_notifications';
import { loadDataFromUrl } from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import * as Actions from "../data/maintenance_due_actions";
import { MaintenanceDueDropdown } from "../views/maintenance_due_dropdown";
//import { notifyAirbrake } from '../../../utils/airbrake_error_handler';
import { notifyAirbrake } from "../../../utils/airbrake_error_handler";
import { getTranslatedProp } from "../../../utils/globalize";
import { AppContext } from "../../common/app_context/app_context_provider";
import {
  createDesktopNotification,
  requestDesktopNotifications,
} from "../../notifications_dropdown/desktop_notifications";
import { MaintenancePlan } from "../data/models";
import { Subscription } from "@rails/actioncable";

interface ChannelSubscription extends ActionCable.ChannelNameWithParams {
  identifier?: string;
}

interface MaintenanceMessage {
  type: "ADD_DUE_MAINTENANCE" | "REMOVE_DUE_MAINTENANCE";
  maintenancePlan: MaintenancePlan;
}

interface MaintenanceDueDropdownContainerBaseProps {
  loadMaintenances?: boolean;
}

class MaintenanceDueDropdownContainerBase extends React.Component<
  MaintenanceDueDropdownContainerBaseProps,
  MaintenanceDueState
> {
  notificationSound: HTMLAudioElement;
  maintenanceChannel: Subscription | null;
  maintenancesLoaded: Promise<void>;

  static defaultProps = {
    loadMaintenances: true,
  };

  static getStores() {
    return [MaintenanceDueStore];
  }

  static calculateState(prevState: MaintenanceDueState) {
    return MaintenanceDueStore.getState();
  }

  static contextType? = AppContext;

  context!: React.ContextType<typeof AppContext>;

  constructor(props = {}) {
    super(props);

    // context is not yet available here. Needs to render first
    this.notificationSound = new Audio(gon.notificationSound);
  }

  componentDidMount(): void {
    this.maintenancesLoaded = this.loadMaintenances();

    this.maintenanceChannel = this.props.loadMaintenances
      ? App.cable.subscriptions.create(
          {
            channel: "MaintenanceDueChannel",
            asset_id: this.context.currentAssetId,
          },
          {
            connected: noop,
            disconnected: noop,
            received: (message: MaintenanceMessage) =>
              this.onHandleMaintenanceMessage(message),
          },
        )
      : null;

    requestDesktopNotifications().catch((error) => {
      logger.warn("This browser does not support desktop notification");
    });
  }

  componentWillUnmount(): void {
    if (!isNil(this.maintenanceChannel)) {
      this.maintenanceChannel.unsubscribe();
      this.maintenanceChannel = null;
    }
    this.maintenancesLoaded = null;
  }

  render(): React.ReactNode {
    const state = this.state;

    return <MaintenanceDueDropdown dueMaintenances={state.dueMaintenances} />;
  }

  private loadMaintenances(): Bluebird<void> {
    if (!this.props.loadMaintenances) {
      return;
    } else {
      return loadDataFromUrl<MaintenancePlan[]>(
        url_helper.assetMaintenancePlansDueSoonPath(
          this.context.currentAssetId,
          "json",
        ),
      )
        .then((response) => {
          // do not handle responses when already unmounted
          if (!isNil(this.maintenancesLoaded)) {
            const dueMaintenances: MaintenancePlan[] = map(
              response,
              (maintenancePlan) => ({
                ...maintenancePlan,
                due_date: moment(maintenancePlan.due_date),
              }),
            );
            Actions.loadInitialState(dueMaintenances);
          }
        })
        .catch((error) => {
          if (!(error instanceof Bluebird.CancellationError)) {
            logger.logError(error as Error);
            void toast.error(
              I18n.t(
                "frontend.maintenance_due_dropdown.could_not_load_maintenances",
              ),
            );
          }
        });
    }
  }

  private onHandleMaintenanceMessage(message: MaintenanceMessage): void {
    switch (message.type) {
      case "ADD_DUE_MAINTENANCE":
        this.onAddDueMaintenance(message.maintenancePlan);
        break;
      case "REMOVE_DUE_MAINTENANCE":
        this.onRemoveDueMaintenance(message.maintenancePlan);
        break;
    }
  }

  private onAddDueMaintenance(maintenancePlan: MaintenancePlan): void {
    try {
      Actions.addDueMaintenance({
        ...maintenancePlan,
        due_date: moment(maintenancePlan.due_date),
      });

      if (
        this.context.user.notificationSoundEnabled &&
        !isNil(this.notificationSound)
      ) {
        void this.notificationSound.play();
      }
      createDesktopNotification({
        event_id: null,
        url: url_helper.assetMaintenancePlansPath(
          maintenancePlan.root_asset_id,
          maintenancePlan.id.toString(),
          "html",
        ),
        asset: maintenancePlan.asset,
        root_asset: maintenancePlan.asset,
        name: {
          de: I18n.t("frontend.maintenance_due_dropdown.notification_heading"),
          en: I18n.t("frontend.maintenance_due_dropdown.notification_heading"),
        },
        event_type_id: null,
        icon: null,
        severity_level: "info",
        read_by_user: false,
        timestamp: moment(maintenancePlan.due_date).toISOString(),
        description: {
          de: I18n.t(
            "frontend.maintenance_due_dropdown.notification_description",
            {
              maintenance: getTranslatedProp(maintenancePlan, "name", "de"),
              asset: getTranslatedProp(maintenancePlan.asset, "name", "de"),
            },
          ),
          en: I18n.t(
            "frontend.maintenance_due_dropdown.notification_description",
            {
              maintenance: getTranslatedProp(maintenancePlan, "name", "en"),
              asset: getTranslatedProp(maintenancePlan.asset, "name", "en"),
            },
          ),
        },
      });
    } catch (e) {
      void notifyAirbrake(e as Error);
    }
  }

  private onRemoveDueMaintenance(maintenancePlan: MaintenancePlan): void {
    Actions.removeDueMaintenance(maintenancePlan);
  }
}

/**
 * A Flux container component for event notifications
 */
export const MaintenanceDueDropdownContainer =
  Container.create<MaintenanceDueDropdownContainerBaseProps>(
    MaintenanceDueDropdownContainerBase,
  );
