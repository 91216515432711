import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FormControlLabel, Grid, Radio } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { MeasurementPlan } from "../../../models/measurement_plan";
import {
  AllMeasurementTypeTypes,
  AllMeasurementTypeTypesUnderscore,
  MeasurementType,
  MeasurementTypes,
} from "../../../models/measurement_type";
import { TypedErrorMap } from "../../../utils/error_map";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";

interface MeasurementPlanFormStep1Properties {
  templates: MeasurementType[];
  measurementPlan: MeasurementPlan;
  createBy: MeasurementPlanCreationMode;
  errors: TypedErrorMap<MeasurementPlan>;
  typeOfMeasurement?: MeasurementTypes;
  templateType: MeasurementType;
  onFinish: (
    createBy: MeasurementPlanCreationMode,
    plan: MeasurementPlan,
    templateType: MeasurementType,
    type: MeasurementTypes,
  ) => void;
  onUpdateMeasurementPlan: (mPlan: MeasurementPlan) => void;
  onSelectCreationMode: (createBy: MeasurementPlanCreationMode) => void;
  onSelectTypeOfMeasurement: (type: MeasurementTypes) => void;

  onSelectTemplate: (template: MeasurementType) => void;
  onCancel: () => void;
}

export type MeasurementPlanCreationMode = "template" | "measurementType";
interface MeasurementPlanFormStep1State {}

/**
 * A list of maintenace plans of an asset.
 */
export class MeasurementPlanFormStep1 extends React.Component<
  MeasurementPlanFormStep1Properties,
  MeasurementPlanFormStep1State
> {
  constructor(props: MeasurementPlanFormStep1Properties) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    return (
      <IBox>
        <IBoxTitle>
          <h4>
            {I18n.t(
              "frontend.measurement_plan_form.create_measurement_plan_step_1_heading",
              {},
            )}
          </h4>
        </IBoxTitle>
        <IBoxContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                id="name-text-field"
                label={I18n.t("activerecord.attributes.measurement_plan.title")}
                required={true}
                value={toString(
                  this.props.measurementPlan?.measurement_type.title,
                )}
                error={
                  !isNil((this.props.errors?.measurement_type as any)?.title)
                }
                helperText={(this.props.errors?.measurement_type as any)?.title}
                onKeyUp={(keyUpEvent) => {
                  if (keyUpEvent.key === "Enter") {
                    keyUpEvent.stopPropagation();
                    this.handleProceed();
                  }
                }}
                onChange={(changeEvent) => {
                  this.props.onUpdateMeasurementPlan({
                    ...this.props.measurementPlan,
                    measurement_type: {
                      ...this.props.measurementPlan?.measurement_type,
                      title: changeEvent.target.value,
                    },
                  });
                }}
              ></TextField>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} className="mt-2 mb-2">
                <FormControl size="small">
                  <FormControlLabel
                    label={I18n.t(
                      "frontend.measurement_plan_form.create_from_template",
                    )}
                    control={
                      <Radio
                        size="small"
                        disabled={isEmpty(this.props.templates)}
                        checked={this.props.createBy === "template"}
                        value="template"
                        onChange={(changeEvent) => {
                          if (changeEvent.target.checked) {
                            this.props.onSelectCreationMode("template");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </Grid>

              <Grid container item xs={12} className="mb-3">
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label={I18n.t(
                      "frontend.measurement_plan_form.select_template",
                    )}
                    disabled={this.props.createBy !== "template"}
                    id="select-template-select"
                    value={this.currentTemplateSelectionValue()}
                    onChange={(event) => {
                      this.handleTemplateSelection(
                        parseInt(event.target.value),
                      );
                    }}
                  >
                    {this.templateSelectionValues()}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <FormControl>
                  <FormControlLabel
                    label={I18n.t(
                      "frontend.measurement_plan_form.create_as_type",
                    )}
                    control={
                      <Radio
                        size="small"
                        checked={this.props.createBy === "measurementType"}
                        value="measurementType"
                        onChange={(changeEvent) => {
                          if (changeEvent.target.checked) {
                            this.props.onSelectCreationMode("measurementType");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    label={I18n.t("frontend.measurement_plan_form.select_type")}
                    disabled={this.props.createBy !== "measurementType"}
                    id="select-type-select"
                    value={toString(this.props.typeOfMeasurement)}
                    onChange={(event) => {
                      this.props.onSelectTypeOfMeasurement(
                        event.target.value as MeasurementTypes,
                      );
                    }}
                  >
                    {AllMeasurementTypeTypes.map((type, index) => (
                      <MenuItem value={type} key={index}>
                        {I18n.t(
                          `activerecord.models.measurement_types/${AllMeasurementTypeTypesUnderscore[index]}`,
                          { count: 1 },
                        )}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </IBoxContent>
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn={true}
            isProcessing={false}
            onCancel={this.props.onCancel}
            onSubmit={() => {
              this.handleProceed();
            }}
            submitBtnIcon={<NavigateNextIcon />}
          ></FloatingButtons>
        </FixedBottomArea>
      </IBox>
    );
  }

  currentTemplateSelectionValue() {
    if (isEmpty(this.props.templates)) return "";

    return !isNil(this.props.templateType)
      ? findIndex(
          this.props.templates,
          (t) => t.id == this.props.templateType.id,
        )
      : 0;
  }
  templateSelectionValues() {
    if (isEmpty(this.props.templates))
      return (
        <MenuItem value={-1} selected={true}>
          {I18n.t("frontend.measurement_plan_form.no_template")}
        </MenuItem>
      );

    return this.props.templates.map((type, index) => (
      <MenuItem key={index} value={index}>
        {type.title}
      </MenuItem>
    ));
  }

  handleTemplateSelection(templateIndex: number) {
    if (
      !isNaN(templateIndex) &&
      templateIndex != -1 &&
      templateIndex >= 0 &&
      templateIndex < this.props.templates.length
    ) {
      this.props.onSelectTemplate(this.props.templates[templateIndex]);
    }
  }

  handleProceed() {
    this.props.onFinish(
      this.props.createBy,
      this.props.measurementPlan,
      this.props.templateType,
      this.props.typeOfMeasurement,
    );
  }
}
