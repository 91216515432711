import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import * as JSONAPI from "jsonapi-typescript";
import { isEmpty, isNil, map, toInteger } from "lodash";
import * as React from "react";

import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiPagingParamsArray,
} from "../../../json_api/jsonapi_tools";
import { ReportJSONAPIAttributes } from "../../../json_api/report";
import { loadDataFromUrl } from "../../../utils/jquery_helper";
import { applyParamsDataToBaseUrl } from "../../../utils/urls/url_utils";
import { MaterialUiDateRangePicker } from "../../common/data_range_picker";
import { ReportAttributes } from "../data/models";
import { ReportItem } from "./report_item";

import { Close, GetApp } from "@mui/icons-material";
import { Moment } from "moment";

interface ReportListProperties {
  reportsUrl?: string;
  reports?: ReportAttributes[];
  page?: number;
  totalPages?: number;
  pageSize?: number;
}

interface ReportListState {
  isFetching: boolean;
  reports?: ReportAttributes[];
  searchPeriodFrom?: Moment;
  searchPeriodTo?: Moment;
  currentPage?: number;
  totalPages?: number;
  totalCount?: number;
  loadedPage?: number;
  dialogOpen: boolean;
  reportToShow?: ReportAttributes;
}

export class ReportList extends React.Component<
  ReportListProperties,
  ReportListState
> {
  constructor(props: ReportListProperties) {
    super(props);
    this.state = {
      isFetching: false,
      reports: props.reports,
      loadedPage: !isNil(props.reports) ? props.page : null,
      searchPeriodFrom: moment().subtract(1, "year").startOf("year"),
      searchPeriodTo: moment().endOf("day"),
      currentPage: props.page,
      totalPages: props.totalPages,

      dialogOpen: false,
      reportToShow: null,
    };
  }
  componentDidMount(): void {
    if (!isNil(this.props.reportsUrl) && isEmpty(this.state.reports)) {
      void this.fetchReports();
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item flexGrow={1}>
                    <MaterialUiDateRangePicker
                      size={"small"}
                      type="datetime"
                      opens={"left"}
                      dateFormat="L LT"
                      required={false}
                      value={[
                        this.state.searchPeriodFrom,
                        this.state.searchPeriodTo,
                      ]}
                      label={I18n.t("frontend.time_range_picker.time_range")}
                      onChange={(newValue) =>
                        this.setState(
                          {
                            searchPeriodFrom: newValue.dateRange[0],
                            searchPeriodTo: newValue.dateRange[1],
                          },
                          () => {
                            this.reloadReports();
                          },
                        )
                      }
                    />
                  </Grid>
                  {isNil(this.state.totalCount) ? null : (
                    <Grid item xs="auto">
                      <Box marginY="auto">
                        <Typography variant="caption" marginY="auto">
                          {I18n.t("frontend.report_list.total_item", {
                            total: this.state.totalCount,
                          })}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12} p={2} alignContent="center">
                <Grid item xs={5}>
                  <b>{I18n.t("frontend.report_list.report_period_header")}</b>
                </Grid>
                <Grid item xs={3}>
                  <b>{I18n.t("frontend.report_list.created_at_header")}</b>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>

              {this.state.isFetching ? (
                <Grid item xs={12}>
                  <Skeleton height={`${this.props.pageSize * 2}em`} />
                </Grid>
              ) : null}
              {isEmpty(this.state.reports)
                ? null
                : map(this.state.reports, (report, index) => {
                    return (
                      <ReportItem
                        report={report}
                        key={index}
                        onShowReport={(report) => {
                          this.setState({
                            reportToShow: report,
                            dialogOpen: true,
                          });
                        }}
                      />
                    );
                  })}
            </Grid>
          </CardContent>
          {this.state.totalPages > 1 ? (
            <CardActions>
              <Grid container justifyContent="left">
                <Grid item>
                  <Pagination
                    size="large"
                    page={this.state.currentPage}
                    count={this.state.totalPages}
                    siblingCount={2}
                    onChange={(event, pageNumber) => {
                      this.onSelectPage(pageNumber);
                    }}
                  />
                </Grid>
              </Grid>
            </CardActions>
          ) : null}
        </Card>
        {isNil(this.state.reportToShow) ? null : (
          <Dialog
            open={this.state.dialogOpen}
            onClose={() => {
              this.setState({ dialogOpen: false });
            }}
            fullScreen={false}
            maxWidth="xl"
            fullWidth={true}
          >
            <DialogTitle>
              {this.state.reportToShow.period_from}
              <Box displayPrint="none" position="absolute" right={8} top={8}>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    this.setState({ dialogOpen: false });
                  }}
                  size="large"
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <iframe
                src={this.state.reportToShow.report_pdf_url}
                style={{
                  border: "none",
                  width: "100%",
                  height: "80vh",
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                className="auto-hyphen"
                color="primary"
                size="small"
                href={this.state.reportToShow.report_pdf_url}
                target="_blank"
                title={I18n.t("frontend.report_list.download_pdf")}
                startIcon={<GetApp />}
                disabled={isEmpty(this.state.reportToShow.report_pdf_url)}
              >
                {I18n.t("frontend.report_list.download_pdf")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  reloadReports() {
    this.setState({ loadedPage: null }, () => {
      this.onSelectPage(1);
    });
  }

  onSelectPage(pageNumber: number): void {
    if (this.state.loadedPage != pageNumber) {
      this.setState(
        { currentPage: pageNumber, reports: null, isFetching: true },
        () => {
          void this.fetchReports();
        },
      );
    }
  }

  async fetchReports(): Promise<void> {
    if (this.state.loadedPage != this.state.currentPage) {
      const fetchedPage = this.state.currentPage;
      const params = jsonApiPagingParamsArray(fetchedPage, this.props.pageSize);
      if (!isNil(this.state.searchPeriodFrom)) {
        params.push(["from", this.state.searchPeriodFrom.toISOString()]);
      }
      if (!isNil(this.state.searchPeriodTo)) {
        params.push(["to", this.state.searchPeriodTo.toISOString()]);
      }
      const url = applyParamsDataToBaseUrl(
        `${this.props.reportsUrl}.json`,
        params,
      );
      try {
        const fetchedReports =
          await loadDataFromUrl<
            JSONAPI.CollectionResourceDoc<string, ReportJSONAPIAttributes>
          >(url);
        let totalPages = parseInt(fetchedReports.meta["page_count"] as string);
        const totalCount = toInteger(fetchedReports.meta.record_count);
        totalPages = isNaN(totalPages) ? this.props.totalPages : totalPages;
        this.setState({
          totalPages,
          loadedPage: fetchedPage,
          totalCount,
          reports: jsonApiResourceCollectionToFlatObjects(fetchedReports),
          isFetching: false,
        });
      } catch (err: any) {
        this.setState({ reports: null, isFetching: false });
      }
    }
  }
}
