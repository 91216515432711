import * as React from "react";
import { isEmpty } from "lodash";
import JQuery from "jquery";
import { AssetTypeHome } from "./home";
import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../common/app_root";
const assetTypeHomeRoots: Root[] = [];

export function initAssetTypeHome(
  itemOrSelector: JQuery | string = '[data-toggle="asset-type-home"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, formMountItem) => {
    const assetTypeId = $(formMountItem).data("asset-type-id") as number;
    const canEdit = $(formMountItem).data("can-edit") as boolean;
    const root = createRoot(formMountItem);
    assetTypeHomeRoots.push(root);
    root.render(
      <AppRoot>
        <AssetTypeHome assetTypeId={assetTypeId} canEdit={canEdit} />
      </AppRoot>,
    );
  });
}

/**
 *  Destroy Report List in given container
 * @param selector jquery selector to report list container
 */
export function destroyAssetTypesHome(selector: JQuery): void {
  if (isEmpty(selector)) return;

  assetTypeHomeRoots.forEach((root) => {
    root.unmount();
  });
  assetTypeHomeRoots.length = 0;
}
