import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  GridSize,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { Delete, Done } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import * as JSONAPI from "jsonapi-typescript";
import {
  each,
  find,
  isEmpty,
  isNil,
  last,
  map,
  toInteger,
  toNumber,
  toString,
} from "lodash";
import * as React from "react";
import {
  ASSETS_JSONAPI_RESOURCE_TYPE,
  ASSET_JSONAPI_CREATABLE_FIELDS,
  ASSET_JSONAPI_UPDATEABLE_FIELDS,
  AssetJSONObject,
} from "../../json_api/asset";

import {
  ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetTypeJSONObject,
} from "../../json_api/asset_type";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import {
  PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE,
  ProductModelJSONObject,
} from "../../json_api/product_model";
import { ASSET_SHORT_NAME_MAX_LENGTH } from "../../models/asset";
import {
  api_asset_path,
  api_asset_type_product_models_path,
  api_asset_types_path,
  api_assets_path,
  asset_asset_types_path,
  assets_path,
} from "../../routes";
import { dialog } from "../../utils/dialog";
import {
  HttpError,
  RequestMethod,
  loadDataFromUrl,
  sendData,
} from "../../utils/jquery_helper";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../../utils/jsonapi_form_tools";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { error, success } from "../../utils/toasts";
import { assetPath } from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { LoadingWrapper } from "../common/loading_wrapper";

export type AssetFormMode = "read" | "update" | "create";

export type AssetErrors = Record<keyof AssetJSONObject, string[]>;

export interface AssetPermissions {
  update: boolean;
  destroy: boolean;
}
export interface AssetFormProps {
  assetId?: number;
  parentAssetId?: number;
  parentAssetTypeId?: number;
  assetTypeId?: number;
  buttonPosition?: "card" | "bottom";
  readOnly?: boolean;
  mode?: AssetFormMode;
  permssions?: AssetPermissions;
  onCancel?: () => void;
  onSubmit?: (asset: AssetJSONObject) => Promise<unknown>;
  onDeleted?: (asset: AssetJSONObject) => void;
}

export const AssetForm: React.FunctionComponent<AssetFormProps> = ({
  readOnly = false,
  buttonPosition = "card",
  ...props
}) => {
  const context = React.useContext(AppContext);
  const [productModelsLoading, setProductModelsLoading] = React.useState(false);
  const [assetData, setAssetData] = React.useState<AssetJSONObject>(
    props.mode == "create" ? { name: "", short_name: "", id: null } : null,
  );
  const [parentAsset, setParentAsset] = React.useState<AssetJSONObject>(null);
  const [isProcessing, setIsProcessing] = React.useState(
    props.mode !== "create",
  );
  const [assetTypes, setAssetTypes] = React.useState<AssetTypeJSONObject[]>([]);

  const [assetType, setAssetType] = React.useState<AssetTypeJSONObject>(null);
  const [assetProductModel, setAssetProductModel] =
    React.useState<ProductModelJSONObject>(null);

  const [productModels, setProductModels] =
    React.useState<ProductModelJSONObject[]>(null);

  // initialize asset types

  React.useEffect(() => {
    let url = null;
    if (isNil(props.parentAssetId)) {
      url = api_asset_types_path();
    } else {
      url = asset_asset_types_path(props.parentAssetId);
    }
    void loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, AssetTypeJSONObject>
    >(url).then((loadedAssetTypes) => {
      const assetTypesObjects =
        jsonApiResourceCollectionToFlatObjects<AssetTypeJSONObject>(
          loadedAssetTypes,
        );
      setAssetTypes(assetTypesObjects);
      return null;
    });
  }, []);

  React.useEffect(() => {
    if (isNil(assetType)) return;

    setProductModelsLoading(true);
    setProductModels(null);
    void loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, AssetTypeJSONObject>
    >(api_asset_type_product_models_path(assetType.id))
      .then((loadedProductModels) => {
        const theProductModels =
          jsonApiResourceCollectionToFlatObjects<ProductModelJSONObject>(
            loadedProductModels,
          );

        setProductModels(isEmpty(theProductModels) ? null : theProductModels);
        if (
          !isNil(assetData) &&
          isNil(
            find(theProductModels, (pm) => pm.id != assetData.product_model_id),
          )
        ) {
          // Product model not found for asset type
          setAssetProductModel(null);
        }
      })
      .catch((err) => {
        logger.error(err);
      })
      .finally(() => {
        setProductModelsLoading(false);
      });
  }, [assetType]);

  if (props.mode !== "create") {
    // load the asset data to display
    React.useEffect(() => {
      setIsProcessing(true);
      void loadDataFromUrl<JSONAPI.SingleResourceDoc<string, AssetJSONObject>>(
        api_asset_path(props.assetId, {
          params: {
            id: props.assetId,
            include: "asset_type,product_model,parent,parent.asset_type",
          },
        }),
      )
        .then((loadedAssetData) => {
          const assetDataFlat =
            jsonApiSingleResourceToFlatObject<AssetJSONObject>(loadedAssetData);
          if (!isNil(assetDataFlat.asset_type)) {
            setAssetType(assetDataFlat.asset_type);
          }
          if (!isNil(assetDataFlat.product_model)) {
            setAssetProductModel(assetDataFlat.product_model);
          }

          if (!isNil(assetDataFlat.parent)) {
            setParentAsset(assetDataFlat.parent as AssetJSONObject);
          }
          setAssetData(assetDataFlat);
        })
        .catch((err) => {
          logger.error(err);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }, []);
  }
  const [assetErrors, setAssetErrors] = React.useState<AssetErrors>({});

  const commonErrorStyle = {
    bgcolor: "#FFCCCC",
    color: "#666",
    fontSize: "0.8rem",
    border: 1,
    borderRadius: 5,
  };

  const commonIconStyle = {
    bgcolor: "#FFFFFFFF",
    fontSize: "1rem",
    border: 1,
    borderRadius: 5,
  };

  const createErrorBox = React.useCallback(
    (errorKey: keyof AssetJSONObject): React.ReactElement => {
      if (isEmpty(assetErrors[errorKey])) return null;
      return (
        <Box sx={{ ...commonErrorStyle }} mt={"2px"}>
          <ul>
            {map(assetErrors[errorKey], (e, index) => (
              <li key={`${errorKey}_${index}`}>{e}</li>
            ))}
          </ul>
        </Box>
      );
    },
    [assetErrors],
  );

  const createFormElement = React.useCallback(
    (
      att: keyof AssetJSONObject,
      required = false,
      gridSize: GridSize = 12,
      maxLength: number = null,
    ) => {
      return (
        <Grid item xs={gridSize} container key={att}>
          <Grid item xs={12}>
            <TextField
              id={att as string}
              required={required}
              value={toString(assetData[att])}
              fullWidth={true}
              disabled={isProcessing}
              onChange={(e) => {
                const d = {
                  ...assetData,
                };
                d[att] = e.target.value;
                setAssetData(d);
              }}
              inputProps={{ maxLength: maxLength }}
              helperText={I18n.t(`frontend.assets.form.${att}_help`)}
              label={I18n.t(`activerecord.attributes.asset.${att}`)}
            />
          </Grid>
          <Grid item xs={12}>
            {createErrorBox(att)}
          </Grid>
        </Grid>
      );
    },
    [assetData, isProcessing],
  );

  return (
    <>
      <Card>
        <CardHeader title={I18n.t("frontend.assets.edit")} titl></CardHeader>
        <CardContent>
          <LoadingWrapper loading={isProcessing}>
            {isNil(assetData) ? null : (
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  {isEmpty(assetErrors) ? null : (
                    <Grid item xs={12}>
                      <Box sx={{ ...commonErrorStyle }} p={1}>
                        {I18n.t("base.errors")}:{" "}
                        {I18n.t("base.please_check_entries")}
                      </Box>
                    </Grid>
                  )}

                  {[
                    ["name", true],
                    ["short_name", true, ASSET_SHORT_NAME_MAX_LENGTH],
                    ["key", false],
                    ["mf_date", false],
                    ["serial", false],
                  ].map(([att, required, maxLength]) =>
                    createFormElement(
                      att as keyof AssetJSONObject,
                      required as boolean,
                      6,
                      maxLength as number,
                    ),
                  )}
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <TextField
                        multiline={true}
                        fullWidth={true}
                        id="description"
                        value={toString(assetData.description)}
                        label={I18n.t(
                          "activerecord.attributes.asset.description",
                        )}
                        helperText={I18n.t(
                          "frontend.assets.form.description_help",
                        )}
                        error={!isEmpty(assetErrors.description)}
                        onChange={(e) =>
                          setAssetData({
                            ...assetData,
                            description: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {createErrorBox("description")}
                    </Grid>
                  </Grid>

                  {isEmpty(assetTypes) ? null : (
                    <Grid item xs={12} container>
                      <Grid item xs={12}>
                        <FormControl
                          required
                          fullWidth={true}
                          disabled={isProcessing}
                        >
                          <InputLabel id="asset-type-label">
                            {I18n.t("activerecord.attributes.asset.asset_type")}
                          </InputLabel>

                          <Select
                            fullWidth={true}
                            id="asset-type"
                            required={true}
                            value={toString(assetData.asset_type_id)}
                            label={I18n.t(
                              "activerecord.attributes.asset.asset_type",
                            )}
                            onChange={(e) => {
                              const type = find(
                                assetTypes,
                                (at) => at.id == e.target.value,
                              );
                              setAssetType(type);
                              let prodMod = assetData.product_model;
                              if (
                                !isNil(assetData.product_model) &&
                                type.id != assetData.product_model.id
                              ) {
                                prodMod = null;
                              }
                              setAssetProductModel(prodMod);
                              setAssetData({
                                ...assetData,
                                asset_type: type,
                                product_model: prodMod,
                                product_model_id: isNil(prodMod)
                                  ? null
                                  : toInteger(prodMod?.id),
                                product_model_name: prodMod?.name,
                                asset_type_id: toNumber(type.id),
                                asset_type_name: type.name,
                              });
                            }}
                          >
                            {map(assetTypes, (t) => (
                              <MenuItem value={toString(t.id)} key={t.id}>
                                {t.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("asset_type_id")}
                      </Grid>
                    </Grid>
                  )}
                  {isEmpty(productModels) ? null : (
                    <Grid item xs={12} container>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth={true}
                          disabled={productModelsLoading || isProcessing}
                        >
                          <InputLabel id="product-model-label">
                            {I18n.t(
                              "activerecord.attributes.asset.product_model",
                            )}
                          </InputLabel>

                          <Select
                            fullWidth={true}
                            id="product-model"
                            required={true}
                            value={toString(assetData.product_model_id)}
                            label={I18n.t(
                              "activerecord.attributes.asset.product_model",
                            )}
                            onChange={(e) => {
                              const theModel = find(
                                productModels,
                                (pm) => pm.id == e.target.value,
                              );
                              setAssetProductModel(theModel);
                              setAssetData({
                                ...assetData,
                                product_model: theModel,
                                product_model_id: isNil(theModel)
                                  ? null
                                  : toInteger(theModel.id),
                                product_model_name: theModel?.name,
                              });
                            }}
                          >
                            <MenuItem value={""} key={"none"}>
                              {I18n.t("frontend.none")}
                            </MenuItem>
                            {map(productModels, (t) => (
                              <MenuItem value={toString(t.id)} key={t.id}>
                                {t.name}
                                {t.identifier}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {I18n.t("frontend.assets.form.product_model_help")}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("product_model")}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </LoadingWrapper>
        </CardContent>
        {buttonPosition !== "card" ? null : (
          <CardActions>
            <ButtonGroup>
              <Button
                onClick={() => {
                  void handleSubmit(
                    props,
                    assetData,
                    assetType,
                    assetProductModel,
                    setIsProcessing,
                    setAssetErrors,
                  );
                }}
              >
                <Done />
              </Button>
              <Button
                onClick={() => onCancel(setIsProcessing, context.referrer)}
              >
                <Close />
              </Button>
            </ButtonGroup>
          </CardActions>
        )}
      </Card>
      {buttonPosition !== "bottom" ? null : (
        <FixedBottomArea id="fixed-bottom-area">
          {!readOnly && (
            <FloatingButtons
              isProcessing={isProcessing}
              onSubmit={() => {
                void handleSubmit(
                  props,
                  assetData,
                  assetType,
                  assetProductModel,

                  setIsProcessing,
                  setAssetErrors,
                );
              }}
              onCancel={() => {
                onCancel(setIsProcessing, context.referrer);
              }}
              disableSave={isProcessing}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.sensors.form.submit_title")}
            >
              {!props.permssions?.destroy ? null : (
                <Tooltip title={I18n.t("frontend.delete")}>
                  <Fab
                    color="secondary"
                    size="medium"
                    aria-label={I18n.t("frontend.delete")}
                    onClick={() => {
                      void dialog
                        .fire({
                          title: I18n.t("frontend.assets.form.delete_asset"),
                          text: I18n.t(
                            "frontend.assets.form.sure_to_delete_asset",
                          ),
                          showConfirmButton: true,
                          showCancelButton: true,
                        })
                        .then((res) => {
                          if (res.isConfirmed) {
                            void sendData(
                              api_asset_path(assetData.id, {
                                locale: I18n.locale,
                                asset_id: assetData.id,
                                _options: true,
                              }),
                              null,
                              "DELETE",
                              null,
                              null,
                              "text",
                            )
                              .then(() => {
                                if (props.onDeleted) {
                                  props.onDeleted(assetData);
                                } else {
                                  redirectTo(assets_path());
                                }
                              })
                              .catch((err) => {
                                error(I18n.t("frontend.error"), err.message);
                              });
                          }
                        });
                    }}
                  >
                    <Delete />
                  </Fab>
                </Tooltip>
              )}
            </FloatingButtons>
          )}
        </FixedBottomArea>
      )}
    </>
  );
};

function handleError(e: JSONAPI.DocWithErrors) {
  logger.error(e);
  const errors: Record<keyof AssetJSONObject, string[]> = {};
  each(e.errors, (e) => {
    const attributePointer = e.source?.pointer as string;
    if (!isNil(attributePointer)) {
      const attributeName = last(
        attributePointer.split("/"),
      ) as keyof AssetJSONObject;
      const detail = e.detail;
      let errs = errors[attributeName];
      if (isNil(errs)) {
        errs = [];
        errors[attributeName] = errs;
      }
      errs.push(detail);
    }
  });

  void error(
    I18n.t("base.error"),
    I18n.t("frontend.assets.form.error_during_submit"),
  );
  return errors;
}

async function handleSubmit(
  props: AssetFormProps,
  assetData: AssetJSONObject,
  assetType: AssetTypeJSONObject,
  productModel: ProductModelJSONObject,
  setIsProcessing: (proc: boolean) => void,
  setAssetErrors: (errs: AssetErrors) => void,
) {
  setIsProcessing(true);
  try {
    if (props.onSubmit) {
      await props.onSubmit(assetData);
    } else {
      const assetResult = await submitChanges(
        assetData,
        assetType,
        productModel,
        props.mode,
      );

      await success(
        I18n.t("frontend.success"),
        I18n.t("frontend.assets.form.saved_successfully"),
      ).then(() => redirectTo(assetPath(assetResult.id, "html")));
    }
  } catch (e) {
    logger.warn(e);
    setAssetErrors(
      handleError(
        (e as HttpError).request?.responseJSON as JSONAPI.DocWithErrors,
      ),
    );
  } finally {
    setIsProcessing(false);
  }
  return;
}

async function submitChanges(
  assetData: AssetJSONObject,
  assetType: AssetTypeJSONObject,
  productModel: ProductModelJSONObject,
  mode: AssetFormMode,
): Promise<AssetJSONObject> {
  let jsonApiSubmitData = null;

  let httpMethod: RequestMethod = "PATCH";
  let url: string;
  let data: AssetJSONObject = null;
  switch (mode) {
    case "create":
      httpMethod = "POST";
      url = api_assets_path();
      data = { ...assetData };
      delete data.id;
      jsonApiSubmitData = buildJsonApiSubmitData(
        assetData,
        ASSETS_JSONAPI_RESOURCE_TYPE,
        ASSET_JSONAPI_CREATABLE_FIELDS,
      );
      addHasOneRelationToJsonApiSubmitData(
        jsonApiSubmitData.submitData,
        "asset_type",
        ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
        assetType.id,
        false,
      );

      if (!isNil(productModel)) {
        addHasOneRelationToJsonApiSubmitData(
          jsonApiSubmitData.submitData,
          "product_model",
          PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE,
          productModel?.id,
          true,
        );
      }
      break;
    case "update":
      httpMethod = "PATCH";
      jsonApiSubmitData = buildJsonApiSubmitData(
        assetData,
        ASSETS_JSONAPI_RESOURCE_TYPE,
        ASSET_JSONAPI_UPDATEABLE_FIELDS,
      );

      if (productModel?.id) {
        addHasOneRelationToJsonApiSubmitData(
          jsonApiSubmitData.submitData,
          "product_model",
          PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE,
          productModel?.id,
          true,
        );
      }

      addHasOneRelationToJsonApiSubmitData(
        jsonApiSubmitData.submitData,
        "asset_type",
        ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
        assetType.id,
        false,
      );

      url = api_asset_path(assetData.id);
      break;
  }

  const responseData = await sendData<
    JSONAPI.SingleResourceDoc<string, Partial<AssetJSONObject>>,
    JSONAPI.SingleResourceDoc<string, AssetJSONObject>
  >(url, jsonApiSubmitData.submitData, httpMethod, "application/vnd.api+json");

  return { ...responseData.data.attributes, id: responseData.data.id };
}

function onCancel(
  setIsProcessing: (boolean: boolean) => void,
  referrer: string,
) {
  setIsProcessing(false);
  redirectTo(referrer);
}
