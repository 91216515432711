/* eslint-disable @typescript-eslint/no-unused-vars */
import { Close, History } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import { defaultTo, find, isEmpty, isNil, merge } from "lodash";

import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { ContextStateMachineEventSubscriber } from "../../channels/context_state_machine_channel";
import { WidgetController } from "../../controller/widget_controller";
import { ContextStateMachine } from "../../models/context_state_machine";
import { State } from "../../models/state";
import { contextStateMachineFromSerializedConfig } from "../../widgets/state_widget";
import { StateWidgetConfigSerialized } from "../../widgets/state_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ContextStateChangeList } from "../context_state_changes/context_state_change_list";
import { StateDisplay } from "../states/state_display";
import { StateModifier } from "../states/state_modifier";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { StateWidgetProps } from "./state_widget.types";
import { WidgetBox } from "./widget_box";

export const StateWidget: React.FunctionComponent<StateWidgetProps> = ({
  enableStateUpdate = true,
  enableEditState = true,
  widgetBox = true,
  inline = false,
  ...props
}) => {
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [contextStateMachine, setContextStateMachine] = useState<
    ContextStateMachine | undefined
  >(() => {
    if (props.config) {
      return contextStateMachineFromSerializedConfig(props.config);
    } else {
      return props.contextStateMachine;
    }
  });

  const eventHandler: ContextStateMachineEventSubscriber = {
    handleContextStateMachineUpdate: (
      contextStateMachineId,
      stateContext,
      newState,
      time,
      stateful_item_id,
      stateful_item_type,
    ) => {
      if (isNil(props.timeRange) || props.timeRange?.contains(time)) {
        const newStateData = defaultTo(
          find(props.states, (s) => s.id === newState.id),
          newState,
        );

        const csm: ContextStateMachine = {
          ...contextStateMachine,
          current_state: {
            ...contextStateMachine?.current_state,
            ...newStateData,
          },
          current_state_since: time,
        };

        csm.current_state_since = time;

        setContextStateMachine(csm);
      }
    },
  };

  useEffect(() => {
    if (
      contextStateMachine &&
      !moment.isMoment(contextStateMachine.current_state_since)
    ) {
      contextStateMachine.current_state_since = moment(
        contextStateMachine.current_state_since,
      );
    }

    if (enableStateUpdate) {
      const csmId = contextStateMachine?.id ?? props.contextStateMachine?.id;
      if (isNil(csmId)) return;

      const instance = WidgetController.getInstance();
      instance.contextStateMachineChannel.addEventListener(eventHandler, csmId);
      instance.contextStateMachineChannel.subscribe(csmId);

      return () => {
        instance.contextStateMachineChannel.unsubscribe(csmId);
        instance.contextStateMachineChannel.removeEventListener(
          eventHandler,
          csmId,
        );
      };
    }
  }, [contextStateMachine, enableStateUpdate, props.contextStateMachine]);

  const handleStateSaved = (newState: State) => {
    // Add your logic here to handle the new state
  };

  const content = (
    <Grid container>
      <Grid item xs={12}>
        <StateDisplay
          inline={inline}
          stateName={contextStateMachine?.current_state?.name}
          criticality={contextStateMachine?.current_state?.criticality}
          stateColor={contextStateMachine?.current_state?.color}
          stateIcon={contextStateMachine?.current_state?.icon}
          imageUrl={contextStateMachine?.current_state?.image_url}
          stateDescription={contextStateMachine?.current_state?.description}
        />
      </Grid>

      {!(enableEditState && !isEmpty(props.states)) ? null : (
        <Grid item xs={12}>
          <StateModifier
            id={`context-state-machine-widget-${props.widgetId}`}
            contextStateMachine={contextStateMachine}
            states={props.states}
            onStateSaved={(newState) => handleStateSaved(newState)}
          />
        </Grid>
      )}
    </Grid>
  );

  if (widgetBox) {
    return (
      <WidgetBox
        {...props}
        title={defaultTo(props.contextStateMachine?.state_context?.name, "---")}
        footer={
          <>
            <IconButton
              size="small"
              onClick={() => setHistoryDialogOpen(true)}
              title="Show History"
            >
              <History />
            </IconButton>
            <small className="state-change-date">
              {defaultTo(
                moment(contextStateMachine?.current_state_since).format("L LT"),
                "---",
              )}
            </small>
          </>
        }
      >
        <>
          {content}
          {historyDialogOpen ? (
            <Dialog
              disableEscapeKeyDown
              open={historyDialogOpen}
              scroll="paper"
              onClose={() => setHistoryDialogOpen(false)}
            >
              <DialogTitle>
                <Box displayPrint="none" position="absolute" right={8} top={8}>
                  <IconButton
                    style={{ float: "right" }}
                    aria-label="close"
                    onClick={() => setHistoryDialogOpen(false)}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <ContextStateChangeList csmId={contextStateMachine.id} />
              </DialogContent>
            </Dialog>
          ) : null}
        </>
      </WidgetBox>
    );
  } else {
    return content;
  }
};

function serializedConfigToProps(
  config: StateWidgetConfigSerialized,
): StateWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    contextStateMachine: contextStateMachineFromSerializedConfig(config),
    states: config.states,
    enableStateUpdate: config.enable_state_edit,
  } as StateWidgetProps);
}

export const StateWidgetDefinition: SialogicWidgetDefinition<
  typeof StateWidget,
  typeof serializedConfigToProps
> = {
  component: StateWidget,
  serializedConfigToProps,
};
