import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";

import { RoleDefinition } from "../../models/role_definition";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { RoleDefinitionForm } from "./role_definition_form";

const roots: Root[] = [];
/**
 * Initialize react component RoleDefinitionForm within all elements with data-toggle="measurement-plan-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeRoleDefinitionForm(
  selector: JQuery = $('[data-toggle="role-definition-form"]'),
): void {
  selector.each((_i, element) => {
    const roleDefinition = $(element).data("role-definition") as RoleDefinition;
    const readOnly = $(element).data("read-only") as boolean;
    const organizationId = parseInt(
      $(element).data("organization-id") as string,
    );

    if (isNil(roleDefinition)) {
      throw new Error(
        "Could not initialize RoleDefinitionForm: roleDefinition missing.",
      );
    }
    const root = createRoot(element);
    roots.push(root);
    root.render(
      <AppRoot>
        <RoleDefinitionForm
          roleDefinition={roleDefinition}
          readOnly={readOnly}
          organizationId={organizationId}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all ellements
 */
export function destroyRoleDefinitionForm(
  selector: JQuery = $('[data-toggle="role-definition-form"]'),
): void {
  roots.forEach((root) => root.unmount());
  roots.length = 0;
}
