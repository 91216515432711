import { Container } from "flux/utils";
import { isNil, noop } from "lodash";
import moment from "moment";
import * as React from "react";
import { EventNotification } from "../../../models/event_notification";
import "../../../utils/flux_container_adapter";
import { loadDataFromUrl, sendData } from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import * as Actions from "../data/notification_actions";
import NotificationsStore, {
  NotificationsState,
} from "../data/notification_store";
import {
  createDesktopNotification,
  requestDesktopNotifications,
} from "../desktop_notifications";
import { NotificationDropdown } from "../views/notification_dropdown";

import { notifyAirbrake } from "../../../utils/airbrake_error_handler";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";

import { CancellationError } from "bluebird";
import { ErrorBoundary } from "../../common/error_boundary";
import { CancelledError } from "@tanstack/react-query";
import { SialogicQueryClient } from "../../common/sialogic_query_client";

type ChannelSubscription = ActionCable.Subscription;

interface NotificationDropdownContainerBaseProps {
  loadNotifications?: boolean;
}

interface NotificationsResponse {
  newNotifications: EventNotification[];
  previousNotifications: EventNotification[];
}
class NotificationsDropdownContainerBase extends React.Component<
  NotificationDropdownContainerBaseProps,
  NotificationsState
> {
  static defaultProps = { loadNotifications: true };

  static getStores() {
    return [NotificationsStore];
  }

  static calculateState(prevState: NotificationsState) {
    return NotificationsStore.getState();
  }

  static contextType?: React.Context<SialogicContext> = AppContext;

  declare context: React.ContextType<typeof AppContext>;
  channel: ChannelSubscription;
  notificationSound: HTMLAudioElement;

  notificationsLoaded: Promise<void>;

  constructor(props: NotificationDropdownContainerBaseProps) {
    super(props);
    this.state = {
      newNotifications: [],
      previousNotifications: [],
      originalTitle: document.title,
    };

    // use gon as the flux crap ruins contexts
  }

  componentDidMount(): void {
    void requestDesktopNotifications().catch((error) => {
      logger.warn("This browser does not support desktop notification");
    });

    this.notificationSound = new Audio(this.context.notificationSound);
    // Open web socket to rails channel
    this.channel = App.cable.subscriptions.create(
      {
        channel: "UserNotificationChannel",
      },
      {
        connected: noop,
        disconnected: noop,
        received: (notification: EventNotification) => {
          try {
            this.onAddNewNotification(notification);
          } catch (e) {
            void notifyAirbrake(e as Error);
          }
        },
      },
    );

    // load initial notifications
    this.notificationsLoaded = this.loadNotifications();
  }

  componentWillUnmount(): void {
    if (!isNil(this.channel)) {
      this.channel.unsubscribe();
      this.channel = null;
    }
    if (
      !isNil(this.notificationsLoaded) &&
      SialogicQueryClient.isFetching({
        queryKey: ["assetEventNotifications", gon.currentAssetId],
      })
    )
      void SialogicQueryClient.cancelQueries(
        {
          queryKey: ["assetEventNotifications", gon.currentAssetId],
          exact: true,
        },
        {
          silent: true,
        },
      )
        .then(() => {
          this.notificationsLoaded = null;
        })
        .catch((error) => {
          if (error instanceof CancelledError) {
            // do nothing
            return;
          }
          logger.logError(error as Error);
        });

    document.title = this.state.originalTitle;
  }

  render() {
    const state = this.state;
    return (
      <ErrorBoundary>
        <NotificationDropdown
          newNotifications={state.newNotifications}
          previousNotifications={state.previousNotifications}
          onMarkNotificationsAsNoticed={(eventIds: number[]) =>
            void this.onMarkNotificationsAsNoticed(eventIds)
          }
        />
      </ErrorBoundary>
    );
  }

  /**
   * Load notifications from server
   */
  private loadNotifications(): Promise<void> {
    if (!this.props.loadNotifications) {
      return Promise.resolve();
    } else {
      return SialogicQueryClient.fetchQuery({
        queryKey: ["assetEventNotifications", gon.currentAssetId],

        queryFn: () =>
          loadDataFromUrl<NotificationsResponse>(
            url_helper.assetEventsNotificationsPath(gon.currentAssetId),
          ),
        staleTime: 30000,
      })
        .then((response) => {
          // only change something if component did not unmount (resets notificationsLoaded to null)
          if (!isNil(this.notificationsLoaded)) {
            Actions.loadInitialState(
              response?.newNotifications,
              response?.previousNotifications,
            );
          }
        })
        .catch((error) => {
          // if the error is a cancellation error, we neither want to show a toast nor log it
          if (
            error instanceof CancellationError ||
            error instanceof CancelledError
          )
            return;

          logger.logError(error as Error);
          void toast.error(
            I18n.t("frontend.event_dropdown.could_not_load_notifications"),
          );
        });
    }
  }

  /**
   * Add a new notification to the list, send desktop notification and play sound
   * @param notification
   */
  private onAddNewNotification(notification: EventNotification): void {
    try {
      if (
        this.context.user?.notificationSoundEnabled &&
        !isNil(this.notificationSound)
      ) {
        void this.notificationSound.play();
      }
      createDesktopNotification(notification);

      Actions.addNewNotification(notification);
    } catch (e) {
      // we do not want that to throw an error
      void notifyAirbrake(e as Error);
    }
  }

  /**
   * Send mark as noticed request to server and dispatch mark as noticed action if request succeeds
   * @param eventIds The event notifications to mark as noticed
   */
  private onMarkNotificationsAsNoticed(eventIds: number[]): Promise<void> {
    return sendData(url_helper.assetEventsConfirmReadPath(), {
      asset_event_ids: eventIds,
      noticed_at: moment().toISOString(),
    })
      .then(() => {
        Actions.markNotificationsAsNoticed(eventIds);
      })
      .catch((error) => {
        logger.logError(error as Error);
        void toast.error(
          I18n.t("frontend.event_dropdown.could_not_mark_as_noticed"),
        );
      });
  }
}

/**
 * A Flux container component for event notifications
 */
export const NotificationsDropdownContainer = Container.create(
  NotificationsDropdownContainerBase,
);
