import { Grid, Link, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { OrganizationJSONAPIAttributes } from "../../json_api/organization";
import { OrganizationAvatar } from "../common/organization_icon";

interface OrganizationCardProps {
  // User to show. May be nillish to show just a placeholder
  organization?: OrganizationJSONAPIAttributes;
}

export const OrganizationCard: React.FunctionComponent<
  OrganizationCardProps
> = (props) => {
  return (
    <Paper>
      <Box m={2} p={2}>
        <Grid container spacing={2}>
          <Grid item xs textAlign="right">
            <OrganizationAvatar organization={props.organization} />
          </Grid>

          <Grid item xs>
            <Typography variant="body1" textAlign="left" my="auto">
              <Link underline="none">{props.organization?.name}</Link>
            </Typography>
            <Typography variant="caption" textAlign="left" my="auto">
              {props.organization.street ?? null}
              {props.organization.zip ?? null} {props.organization.city ?? null}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
