import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { each, isEmpty, isNil, noop } from "lodash";
import moment from "../../initializers/moment";
import { Moment } from "moment";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { TimeRanges } from "../../definitions/bootstrap-daterangepicker";
import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";
import {
  SamplingRate,
  SensorDataSamplingMode,
  samplingRateFromString,
  samplingRateToString,
} from "../../models/sensor";
import { updatePropInAnchor } from "../../utils/anchor_prop_store";
import { getCallback } from "../../utils/html_data_attributes";
import {
  createTimeRanges,
  TranslatedTimeScopeWithReverseMap,
} from "../../utils/time_scopes";
import { IDType } from "../../utils/urls/url_utils";
import { AppRoot } from "../common/app_root";
import { MaterialUiDateRangePicker } from "../common/data_range_picker";
import { ContextStateMachineSelect } from "../context_state_machines/context_state_machine_select";
import { MobileTimeRangePicker } from "./mobile_time_range_picker";
import { SamplingRateSelect } from "./sampling_rate_select";

export interface DiagramSettingsProps {
  showBeginAtZero?: boolean;
  showSamplingRate?: boolean;
  showTimeRange?: boolean;
  showCsmSelection?: boolean;
  dropdownParent?: string;
  startDate?: Moment;
  endDate?: Moment;
  beginAtZero?: boolean;
  samplingRate?: SamplingRate;
  samplingMode?: SensorDataSamplingMode;

  visible?: boolean;
  mobileOpen?: boolean;
  storeSettingsInAnchor?: boolean;
  onChangeTimeRange?: (
    startTime: Moment,
    endTime: Moment,
    label: string,
  ) => void;
  onChangeSamplingRate?: (
    samplingRate: SamplingRate,
    samplingMode: SensorDataSamplingMode,
  ) => void;
  onChangeBeginAtZero?: (beginAtZero: boolean) => void;
  onRequestHide?: () => void;

  onSelectContextStateMachine?: (csm: ContextStateMachineJSONObject) => void;
  /**
   * Update time ranges in faster cycle for unit tests
   */
  enableFastUpdateForTests?: boolean;

  contextStateMachines?: ContextStateMachineJSONObject[];
  selectedContextStateMachineId?: IDType;
}

function getDefaultSelectedRange(
  timeRanges: TranslatedTimeScopeWithReverseMap,
  startDate: Moment,
  endDate: Moment,
): string {
  let timeRange: string = I18n.t(
    "frontend.time_range_picker.select_custom_range",
  );
  each(timeRanges.ranges, (range: [Moment, Moment], name) => {
    if (range[0].isSame(startDate) && range[1].isSame(endDate)) {
      timeRange = name;
    }
  });

  return timeRange;
}

interface StateContextSelectionProps {
  showCsmSelection?: boolean;
  contextStateMachines?: ContextStateMachineJSONObject[];
  selectedContextStateMachineId?: IDType;
  storeSettingsInAnchor?: boolean;

  onSelectContextStateMachine?: (csm: ContextStateMachineJSONObject) => void;
}

const StateContextSelect: React.FunctionComponent<
  StateContextSelectionProps
> = (props) => {
  return !props.showCsmSelection ||
    isEmpty(props.contextStateMachines) ? null : (
    <Grid item className="float-right" xs>
      <ContextStateMachineSelect
        selectedContextStateMachineId={props.selectedContextStateMachineId}
        contextStateMachines={props.contextStateMachines}
        onSelectContextStateMachine={(csm) => {
          if (props.storeSettingsInAnchor) {
            updatePropInAnchor("active-context-state-machine-id", csm?.id);
          }
          if (!isNil(props.onSelectContextStateMachine)) {
            props.onSelectContextStateMachine(csm);
          }
        }}
      />
    </Grid>
  );
};

/**
 * A react component for diagram settings.
 */
export const DiagramSettings: React.FunctionComponent<DiagramSettingsProps> = ({
  startDate,
  endDate,
  samplingRate,
  showBeginAtZero = true,
  showSamplingRate = true,
  showTimeRange = true,
  showCsmSelection = false,
  onChangeTimeRange = noop,
  onChangeSamplingRate = noop,
  onChangeBeginAtZero = noop,
  onRequestHide = noop,
  enableFastUpdateForTests = false,
  visible = true,
  mobileOpen = false,
  storeSettingsInAnchor = false,
  samplingMode = "avg",

  ...props
}) => {
  const [ranges, setRanges] = React.useState(() => createTimeRanges());
  const updateTimeRanges = React.useCallback(() => {
    setRanges(createTimeRanges());
  }, []);

  const timeRangeUpdate = true;
  const [timeRange, setTimeRange] = React.useState<{
    startDate: Moment;
    endDate: Moment;
    label: string;
  }>(() => ({
    startDate: startDate,
    endDate: endDate,
    label: getDefaultSelectedRange(ranges, startDate, endDate),
  }));

  const [currentSamplingRate, setCurrentSamplingRate] =
    React.useState<SamplingRate>(samplingRate);
  const [currentSamplingMode, setCurrentSamplingMode] =
    React.useState<SensorDataSamplingMode>(samplingMode);

  const [valueSamplingEnabled, setValueSamplingEnabled] = React.useState(
    !isNil(currentSamplingRate?.unit) && !isNil(currentSamplingRate?.value),
  );

  const [beginAtZero, setBeginAtZero] = React.useState(
    isNil(props.beginAtZero) ? true : props.beginAtZero,
  );

  React.useEffect(() => {
    setValueSamplingEnabled(!isNil(currentSamplingRate?.value));
    if (!isNil(currentSamplingRate?.value)) {
      onChangeSamplingRate(currentSamplingRate, currentSamplingMode);
    } else {
      onChangeSamplingRate(null, currentSamplingMode);
    }
  }, [
    currentSamplingRate?.unit,
    currentSamplingRate?.value,
    currentSamplingMode,
  ]);

  const [timeRangeUpdateCount, setTimeRangeUpdateCount] = React.useState(0);
  React.useEffect(() => {
    if (timeRangeUpdate) {
      const updateInMs = enableFastUpdateForTests
        ? 100
        : moment().endOf("hour").diff(moment());

      const timer = setTimeout(() => {
        updateTimeRanges();
        setTimeRangeUpdateCount(timeRangeUpdateCount + 1);
      }, updateInMs);
      return () => clearTimeout(timer);
    }
  }, [timeRangeUpdateCount]);

  React.useEffect(() => {
    onChangeTimeRange(timeRange.startDate, timeRange.endDate, timeRange.label);
  }, [timeRange?.startDate, timeRange?.endDate, timeRange?.label]);

  React.useEffect(() => {
    if (onChangeBeginAtZero) {
      onChangeBeginAtZero(beginAtZero);
    }
  }, [beginAtZero]);

  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container>
      {!(mobileOpen && dialogFullScreen) ? null : (
        <Dialog
          className="d-md-none"
          fullScreen
          open={mobileOpen && dialogFullScreen}
        >
          <DialogTitle>{I18n.t("frontend.settings")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              {showBeginAtZero ? (
                <Grid item xs={12} className="mb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="begin_at_zero"
                        checked={beginAtZero}
                        onChange={(event) => {
                          setBeginAtZero(event.target.checked);
                        }}
                      />
                    }
                    label={I18n.t("frontend.time_range_picker.begin_at_zero")}
                  />
                </Grid>
              ) : null}
              <StateContextSelect
                contextStateMachines={props.contextStateMachines}
                selectedContextStateMachineId={
                  props.selectedContextStateMachineId
                }
                showCsmSelection={showCsmSelection}
              />
              {showSamplingRate ? (
                <>
                  <Grid item xs={12}>
                    <SamplingRateSelect
                      size="medium"
                      samplingRate={samplingRateToString(currentSamplingRate)}
                      samplingMode={currentSamplingMode}
                      onChange={(samplingRate) =>
                        setCurrentSamplingRate(
                          samplingRateFromString(samplingRate),
                        )
                      }
                      selectAggregateFunction={valueSamplingEnabled}
                      onSelectAggregateFunction={(newAggregateFunction) => {
                        setCurrentSamplingMode(newAggregateFunction);
                      }}
                    />
                  </Grid>
                </>
              ) : null}

              {showTimeRange ? (
                <Grid item xs={12} md={12} xl={5} className="mb-1 mb-xl-0">
                  <Paper>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <FormControl size="small" fullWidth>
                            <InputLabel>
                              {I18n.t("frontend.time_range_picker.time_range")}
                            </InputLabel>
                            <MobileTimeRangePicker
                              startDate={timeRange.startDate}
                              endDate={timeRange.endDate}
                              selectedRange={timeRange.label}
                              ranges={ranges.ranges}
                              onChange={(startDate, endDate, selectedRange) =>
                                setTimeRange({
                                  startDate,
                                  endDate,
                                  label: selectedRange,
                                })
                              }
                              onHideParent={() => onRequestHide()}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onRequestHide();
              }}
            >
              {I18n.t("frontend.close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {dialogFullScreen ? null : (
        <Collapse in={visible}>
          <Grid
            item
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
          >
            {showBeginAtZero ? (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="begin_at_zero"
                      checked={beginAtZero}
                      onChange={(event) => {
                        setBeginAtZero(event.target.checked);
                      }}
                    />
                  }
                  label={I18n.t("frontend.time_range_picker.begin_at_zero")}
                />
              </Grid>
            ) : null}
            <StateContextSelect
              contextStateMachines={props.contextStateMachines}
              selectedContextStateMachineId={
                props.selectedContextStateMachineId
              }
              onSelectContextStateMachine={props.onSelectContextStateMachine}
              showCsmSelection={showCsmSelection}
              storeSettingsInAnchor={storeSettingsInAnchor}
            />
            {showSamplingRate ? (
              <Grid item>
                <SamplingRateSelect
                  samplingRate={samplingRateToString(currentSamplingRate)}
                  samplingMode={currentSamplingMode}
                  onChange={(samplingRate) =>
                    setCurrentSamplingRate(samplingRateFromString(samplingRate))
                  }
                  selectAggregateFunction={valueSamplingEnabled}
                  onSelectAggregateFunction={(newAggregateFunction) => {
                    setCurrentSamplingMode(newAggregateFunction);
                  }}
                />
              </Grid>
            ) : null}
            {showTimeRange ? (
              <Grid item className="w-300">
                <MaterialUiDateRangePicker
                  ranges={ranges}
                  size="small"
                  type="datetime"
                  opens={"left"}
                  dateFormat="L LTS"
                  value={[timeRange.startDate, timeRange.endDate]}
                  label={I18n.t("frontend.time_range_picker.time_range")}
                  onChange={(newValue) => {
                    setTimeRange({
                      startDate: newValue.dateRange[0],
                      endDate: newValue.dateRange[1],
                      label: newValue.timeRangeId,
                    });
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        </Collapse>
      )}
    </Grid>
  );
};

const diagramSettingsRoots: Root[] = [];
/**
 * Initialize a diagram settings menu and load settings from data attributes
 * @param selector jquery selector with diagram settings container
 */
export function initializeDiagramSettings(
  selector: JQuery = $('[data-toggle="diagram-settings"]'),
): void {
  if (isEmpty(selector)) return;

  selector.each((index: number, element: HTMLInputElement) => {
    const settingsElement = $(element);
    const settingsElementId = settingsElement.first().attr("id");

    // settings_widget_#{id}_toggle"
    const startDate = moment(settingsElement.attr("data-start"));
    const endDate = moment(settingsElement.attr("data-end"));
    const samplingRate = settingsElement.attr("data-sampling-rate");
    const beginAtZeroString = settingsElement.attr("data-begin-at-zero");
    const beginAtZero = isNil(beginAtZeroString)
      ? true
      : beginAtZeroString == "true";
    const chartId = settingsElement.attr("data-chart-id");
    const initVisible = settingsElement.attr("data-init-visible") != "false";
    const onChangeBeginAtZero = getCallback(
      chartId,
      settingsElement.attr("data-begin-at-zero-callback"),
    );
    const onChangeSamplingRate = getCallback(
      chartId,
      settingsElement.attr("data-sampling-rate-callback"),
    );
    const onChangeTimeRange = getCallback(
      chartId,
      settingsElement.attr("data-time-range-callback"),
    );

    let visible = initVisible;
    const props: DiagramSettingsProps = {
      startDate,
      endDate,
      samplingRate: samplingRateFromString(samplingRate),
      beginAtZero,
      visible,
      onChangeBeginAtZero,
      onChangeTimeRange,
      onChangeSamplingRate,
    };

    const root = createRoot(element);
    diagramSettingsRoots.push(root);

    $("#" + settingsElementId + "_toggle").on("click", () => {
      visible = !visible;
      const theProps = { ...props, visible };
      renderDiagramSettings(element, theProps, root);
    });
  });
}

function renderDiagramSettings(
  element: HTMLElement,
  props: DiagramSettingsProps,
  root: Root,
) {
  root.render(
    <AppRoot>
      <DiagramSettings {...props} />
    </AppRoot>,
  );
}

/**
 *  Destroy diagram settings in given container
 * @param selector jquery selector to diagram settings container
 */
export function destroyDiagramSettings(selector: JQuery): void {
  diagramSettingsRoots.forEach((root) => {
    root.unmount();
  });
  diagramSettingsRoots.length = 0;
}
