import { isEmpty, isNil } from "lodash";
import { AssetTemplate } from "../../../models/asset_template";
import { AssetType } from "../../../models/asset_type";
import { Location } from "../../../models/location";
export interface AssetTemplateSensorItem {
  name: string;
  key: string;
}
export interface AssetTemplateConfig {
  assetType: AssetType;
  asset_type_id?: number;
  name: string;
  key: string;
  serial: string;
  short_name: string;
  location?: Location;
  enabled: boolean;
  sensors: AssetTemplateSensorItem[];
  exclude_keys: string[];
  assetTemplate: AssetTemplate;
  asset_template_id: number;
  product_model_id: number;
}
interface TreeItem<T> {
  children: (T & TreeItem<T>)[];
}
export interface AssetTemplateWithSensorProps extends AssetTemplate {
  exclude_keys: string[];
  sensors: AssetTemplateSensorItem[];
  asset_type: AssetType;
}
export type AssetTemplateTree = AssetTemplateWithSensorProps &
  TreeItem<AssetTemplateTree>;

function countAssetsInTree(tree: TreeItem<TreeItem<unknown>>): number {
  if (isNil(tree.children) || isEmpty(tree.children)) {
    return 1;
  } else {
    return tree.children.length + 1;
  }
}

export type AssetTemplateConfigsByAssetTemplate =
  Array<AssetTemplateConfigsForTemplate>;

export interface AssetTemplateConfigCounters {
  globalAssetIndex?: number;
  assetIndexByTemplate?: number;
  assetIndexByType?: number;
}
export interface AssetTemplateConfigTree extends AssetTemplateConfig {
  // each child (per AssetTemplate) can have multiple instances
  children?: AssetTemplateConfigsByAssetTemplate;

  counters?: AssetTemplateConfigCounters;
}

export interface AssetTemplateConfigsForTemplate {
  configs: Array<AssetTemplateConfigTree>;
  minCount?: number;
  maxCount?: number;

  assetTemplate: AssetTemplateWithSensorProps &
    TreeItem<AssetTemplateWithSensorProps>;
  enabled: boolean;
}

export const ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP = /%([A-z|_]*?)%/;
