import * as React from "react";

import SensorValueWidget from "./sensor_value_widget";
import Widget from "./widget";

import { AppRoot } from "../components/common/app_root";
import { PercentageWidget as PercentageWidgetComponent } from "../components/widgets/percentage_widget";
import { PercentageWidgetConfigSerialized } from "./percentage_widget.types";

/** Widget encapsulating a sensor value display that can be updated programmatically
 * requires a set of spans as child elements to set the values
 * <span class="value"></span><span class="unit"></span>
 * ans <small|span class="timestamp">
 *
 * reads initial values from data attributes if provided:
 * data-sensor-id: sensor id
 * data-attribute-key-id: attribute key id to subscribe
 * data-unit: unit string
 * data-value: value
 * data-time: timestamp in ISO 8601 format
 * data-disable-update: disables the data update
 * @class SensorValuePanel
 * @extends {Widget}
 */
export default class PercentageWidget extends SensorValueWidget {
  config: PercentageWidgetConfigSerialized;

  static getDomClassName(): string {
    return "percentage-widget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as PercentageWidgetConfigSerialized;
  }

  protected initComponent(element: JQuery): void {
    super.initComponent(element, false);
    const props = PercentageWidgetComponent.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <PercentageWidgetComponent
          {...props}
          dashboardSettings={this.dashboardSettings}
        />
      </AppRoot>,
    );
  }

  cleanup(): void {
    super.cleanup();
  }
}
