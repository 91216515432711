import { isNil } from "lodash";
import L from "../initializers/leaflet";
import { MarkerMappingMode } from "../widgets/asset_map_widget.types";
import { getMarkerIconForFeature } from "./feature_icons";
import { AssetFeatureType } from "./map.types";
import { LeafletMouseEventHandlerFn } from "leaflet";
export function getAssetMarker(
  feature: AssetFeatureType,
  markerMappingMode: MarkerMappingMode,
  markerMappingStateContextIdentifier: string,
  sensorKey: string,
  onMarkerClick: LeafletMouseEventHandlerFn = undefined,
): L.Marker {
  const markerTitle = `${feature.properties.asset_type}: ${feature.properties.name}`;
  const generalMarkerOptions: L.MarkerOptions = {
    riseOnHover: true,
    title: markerTitle,
  };
  const pointGeom = {
    lat: feature.geometry.coordinates[1],
    lng: feature.geometry.coordinates[0],
  };

  generalMarkerOptions.icon = getMarkerIconForFeature(
    feature,
    markerMappingMode,
    markerMappingStateContextIdentifier,
    sensorKey,
  );

  const marker: L.Marker = L.marker(
    [feature.geometry.coordinates[1], feature.geometry.coordinates[0]],
    generalMarkerOptions,
  );
  marker.bindTooltip(markerTitle);
  if (onMarkerClick) {
    marker.on("click", onMarkerClick);
  }

  return marker;
}

export function getIconForAsset(
  feature: AssetFeatureType,
  markerMappingMode: MarkerMappingMode,
  markerMappingStateContextIdentifier: string,
  markerMappingSensorKey: string,
): string {
  if (
    markerMappingMode === "state" &&
    feature.properties?.states?.[markerMappingStateContextIdentifier]
  ) {
    const state =
      feature.properties?.states?.[markerMappingStateContextIdentifier];
    return state.icon;
  } else if (
    markerMappingMode === "sensor" &&
    !isNil(feature.properties?.sensors?.[markerMappingSensorKey])
  ) {
    const sensor = feature.properties?.sensors?.[markerMappingSensorKey];
    if (!isNil(sensor?.range?.icon_name)) {
      return sensor.range.icon_name;
    }
  }

  return null;
}
