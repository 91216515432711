/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/// <reference types="../definitions/index" />;

import { defaultTo, isNil, isObject, toString } from "lodash";

export type TranslatedModel = Partial<Record<string, string>>;

const TranslationFallback: Record<string, string> = {
  de: "en",
  en: "de",
};

/** Queries a translated property from given model.
 *
 * Tries first the <prop>_<language_code> property. Falls back to default locale property <prop>_<default_locale>. Falls back to non localized property value.
 *
 * @export
 * @param {any} model
 * @param {string} prop
 * @param {string} [locale=I18n.locale]
 * @returns {string}
 */
export function getTranslatedProp<M = Record<string, string>>(
  model: M,
  prop: keyof M,
  locale: string = I18n.locale,
): string {
  if (isNil(model)) return null;
  // double check if locale is set
  locale ||= I18n.locale;

  const propValue = model[prop];

  let translation;

  const stringProp = toString(prop);
  translation = (model as Record<string, string>)[
    localizedPropName(stringProp, locale)
  ];

  // try fallback language
  translation = defaultTo(
    translation,
    (model as Record<string, string>)[
      localizedPropName(stringProp, TranslationFallback[locale])
    ],
  );
  return defaultTo(translation, propValue) as string;
}

function localizedPropName(propName: string, locale: string): string {
  return `${toString(propName)}_${locale}`;
}
