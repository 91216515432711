import { Business } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { defaultTo, isEmpty } from "lodash";
import * as React from "react";
import { Organization } from "../../models/organization";
const PREFIX = "OrganizationAvatar";

const classes = {
  root: `${PREFIX}-root`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  [`& .${classes.medium}`]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },

  [`& .${classes.large}`]: {
    width: 200,
    height: 200,
  },
}));

export interface OrganizationIconProps {
  organization: Organization;
  size?: "small" | "medium" | "large";
  variant?: "circular" | "rounded" | "square";
}

export const OrganizationAvatar: React.FunctionComponent<
  OrganizationIconProps
> = (props) => {
  const sizeClass = classes[defaultTo(props.size, "medium")];
  return (
    <StyledAvatar
      title={props.organization?.name}
      variant={defaultTo(props.variant, "rounded")}
      className={sizeClass}
      src={props.organization?.icon_url}
    >
      {isEmpty(props.organization?.icon_url) ? <Business /> : null}
    </StyledAvatar>
  );
};

export const OrganizationDefaultIcon = Business;
