import { defaultTo, isNil } from "lodash";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";
import {
  MappedSensor,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { unitDisplayString } from "../../utils/unit_conversion";
import { getValueString } from "../../utils/value_format";
import { BaseMapping } from "./mapping_base";
import { percentageValue } from "./utils";
import { getValueRangeForValue } from "../../utils/status_helper";

/*export interface LabelMappingData extends BaseMappingData {
  label_text_mode?: "percentage" | "value";
  label_precision?: number;
}*/

export type SVGLabelMappingTextModes =
  | "percentage"
  | "value"
  | "sensor_name"
  | "sensor_short_name"
  | "sensor_value_range_name"
  | "sensor_type"
  | "sensor_unit";

export class LabelMapping extends BaseMapping<
  SVGTextElement | SVGTSpanElement
> {
  element_selector: string;
  label_text_mode?: SVGLabelMappingTextModes;
  label_precision?: number;

  constructor(
    config: SensorMappingAttributes,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config, onClick);

    this.label_text_mode = defaultTo(config?.label_text_mode, "percentage");
    this.label_precision = defaultTo(config?.label_precision, 2);
  }

  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    super.setElementInfoFromSvg(svgElement);
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    if (!isNil(this.element) && !isNil(sensorConfig)) {
      const value = sensorConfig.value;
      let textContent = null;
      switch (this.label_text_mode) {
        case "value":
          textContent = this.textContentForValue(sensorConfig);
          break;
        case "sensor_name":
          textContent = sensorConfig.sensor?.name;
          break;
        case "sensor_short_name":
          textContent = sensorConfig.sensor?.short_name;
          break;
        case "sensor_unit":
          textContent = unitDisplayString(sensorConfig.sensor?.display_unit);
          break;
        case "sensor_type":
          textContent = getSensorTypeNameTranslation(sensorConfig.sensor_type);
          break;
        case "sensor_value_range_name":
          const vr = getValueRangeForValue(
            value,
            sensorConfig.sensor?.value_ranges,
          );
          if (vr) {
            textContent = vr.name;
          } else {
            // fallback to value
            textContent = this.textContentForValue(sensorConfig);
          }
          break;
        default:
          const min = defaultTo(sensorConfig?.range?.min, 0.0);
          const max = defaultTo(sensorConfig?.range?.max, 100.0);
          textContent = `${getValueString(
            percentageValue(value, min, max),
            0,
          )} %`;
      }

      // avoid "undefined" text content
      this.element.textContent = textContent ? textContent : "";
    }
  }

  textContentForValue(sensorConfig: MappedSensor): string {
    return `${getValueString(
      sensorConfig?.value,
      defaultTo(
        this.label_precision,
        defaultTo(sensorConfig?.sensor?.precision, 2),
      ),
    )} ${unitDisplayString(
      defaultTo(
        sensorConfig?.sensor?.display_unit,
        sensorConfig?.sensor?.attribute_key_unit,
      ),
    )}`;
  }
}
