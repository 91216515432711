/// <reference types="../../definitions/index" />;
import { Cancel, Check } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { find, first, isNil, map } from "lodash";
import * as React from "react";
import { TreeItem } from "../../models/tree_item";
import { getTranslatedProp } from "../../utils/globalize";
import { IDType } from "../../utils/urls/url_utils";
import { AssetTreeSelect } from "../assets/asset_tree_select";
import { AssetIdentifier } from "../maintenance_plan_form/data/maintenance_plan_actions";
import { Asset } from "../maintenance_plan_form/data/models";

export interface AssetWithIndex extends Partial<Asset>, AssetIdentifier {}

interface SelectAssetModalProps {
  title?: string;
  selectTitle?: string;
  submitButtonText?: string;
  assets: AssetWithIndex[];
  assetTree?: TreeItem[];
  isOpen: boolean;
  multiselect?: boolean;

  /**
   * @param [Array<AssetIdentifier>] An array of asset identifiers. Can be empty if nothing was selected.
   *
   * @memberof SelectAssetModalProps
   */
  onApply: (selectedAssets: AssetIdentifier[]) => void;
  onClosed: () => void;
}

interface SelectAssetModalState {
  selectedAssets: string[];
}

/** Computes the initial selection.
 *
 *
 * @private
 * @returns {IDType[]}
 */
function getInitialSelection(props: SelectAssetModalProps): IDType[] {
  return [];
}

/**
 * Modal to select assets for copy operation.
 */
export const SelectAssetModal: React.FunctionComponent<
  SelectAssetModalProps
> = ({ multiselect = false, ...props }: SelectAssetModalProps) => {
  const [selectedAssets, setSelectedAssets] = React.useState(
    getInitialSelection(props),
  );
  const [assetTree, setAssetTree] = React.useState<TreeItem[]>(
    props.assetTree
      ? props.assetTree
      : props.assets
        ? map(props.assets, (a) => ({
            title: getTranslatedProp(a, "name"),
            id: a.id.toString(),
            children: [],
          }))
        : null,
  );

  const onApplyChange = React.useCallback(
    (theProps: SelectAssetModalProps, selectedAssets: IDType[]) => {
      if (theProps.multiselect) {
        // multiple selections
        const assetIndices = selectedAssets.map((value) => {
          let asset;
          if (props.assets) {
            asset = find(theProps.assets, (asset) => asset.id == value);
          }

          if (isNil(asset)) asset = {};

          return {
            assetGroupIndex: asset.assetGroupIndex,
            assetIndex: asset.assetIndex,
            id: value,
          };
        });

        theProps.onApply(assetIndices);
      } else {
        const id = first(selectedAssets);
        if (isNil(id)) {
          theProps.onApply([]);
          return;
        }

        const asset = find(theProps.assets, (asset) => asset.id == id); // compare with double equality sign to handle type conflicts

        // asset not found !!!
        if (isNil(asset)) return;
        // single selection
        theProps.onApply([
          {
            id: asset.id.toString(),
            assetGroupIndex: asset.assetGroupIndex,
            assetIndex: asset.assetIndex,
          },
        ]);
      }

      setSelectedAssets(getInitialSelection(theProps));
    },
    [setSelectedAssets],
  );

  const onCancelChange = React.useCallback(() => {
    setSelectedAssets(getInitialSelection(props));
    props.onClosed();
  }, [props]);

  return (
    <Dialog open={props.isOpen} onClose={() => onCancelChange()}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Box padding={1}>
          <AssetTreeSelect
            label={props.selectTitle || props.title}
            assetTree={assetTree}
            selectedIds={selectedAssets}
            onSelectionChange={(newSelection) =>
              setSelectedAssets(newSelection)
            }
            multiselect={multiselect}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button
            color="primary"
            onClick={() => onApplyChange(props, selectedAssets)}
            startIcon={<Check fontSize="inherit" />}
          >
            {props.submitButtonText}
          </Button>
          <Button
            onClick={() => onCancelChange()}
            startIcon={<Cancel fontSize="inherit" />}
          >
            {I18n.t("frontend.cancel")}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
