/// <reference types="../../../definitions/index" />;
import { Delete, ExpandLess, ExpandMore, Wifi } from "@mui/icons-material";
import {
  Button,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { defaultTo, isEmpty, isNil, map, reject, toString, uniq } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { ASSET_SHORT_NAME_MAX_LENGTH } from "../../../models/asset";
import { replaceTemplate } from "../../../utils/string_tools";
import {
  AssetTemplateConfig,
  AssetTemplateConfigCounters,
} from "../models/models";
import { AssetTemplateFormContext } from "./asset_template_form_context";
import { ProductModelSelect } from "../../product_models/product_model_select";

export interface AssetTemplateItemProperties {
  assetTemplateConfig: AssetTemplateConfig;
  readonly?: boolean;
  showSensors?: boolean;
  onChange(newAssetTemplateConfig: AssetTemplateConfig): void;
  onDelete(assetTemplateConfig: AssetTemplateConfig): void;
  index: number;
  assetIndex: number;
  counters?: AssetTemplateConfigCounters;
}

export const AssetTemplateItem: FunctionComponent<
  AssetTemplateItemProperties
> = (props) => {
  const context = React.useContext(AssetTemplateFormContext);
  const [showSensors, setShowSensors] = React.useState(false);
  let assetName = defaultTo(
    props.assetTemplateConfig.name,
    props.assetTemplateConfig?.assetType?.name,
  );

  let assetShortName = defaultTo(
    props.assetTemplateConfig.short_name,
    props.assetTemplateConfig?.name,
  );
  let assetKey = props.assetTemplateConfig.key;

  const replacements = {
    "%ASSET_NAME%": assetName,
    "%ASSET_NAME_DE%": assetName,
    "%ASSET_NAME_EN%": assetName,
    "%ASSET_SHORT_NAME_DE%": assetShortName,
    "%ASSET_SHORT_NAME_EN%": assetShortName,
    "%ASSET_SHORT_NAME%": assetShortName,
    "%ASSET_INDEX%": toString(defaultTo(props.index, "")),
    "%ASSET_NUMBER%": isNil(props.index)
      ? ""
      : toString((props.index || 0) + 1),
    "%ASSET_NUMBER_INC%": isNil(props.index)
      ? ""
      : toString((props.index || 0) + 2),
    // as the template items are instanciated by template
    "%ASSET_FOR_TEMPLATE_INDEX%": toString(
      defaultTo(props.counters?.assetIndexByTemplate, ""),
    ),
    "%ASSET_FOR_TEMPLATE_NUMBER%": isNil(props.counters?.assetIndexByTemplate)
      ? ""
      : toString((props.counters?.assetIndexByTemplate || 0) + 1),
    "%ASSET_FOR_TEMPLATE_NUMBER_INC%": isNil(
      props.counters?.assetIndexByTemplate,
    )
      ? ""
      : toString((props.counters?.assetIndexByTemplate || 0) + 2),
    "%ASSET_KEY%": assetKey,
  };

  assetName = replaceTemplate(assetName, replacements);
  assetShortName = replaceTemplate(assetShortName, replacements);
  assetKey = replaceTemplate(assetKey, replacements);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid container item xs={10} spacing={2}>
          <Grid item xs="auto">
            <TextField
              fullWidth={true}
              autoComplete="off"
              disabled={!props.assetTemplateConfig.enabled || props.readonly}
              label={I18n.t("frontend.name")}
              value={assetName}
              onChange={(el) => {
                props.onChange({
                  ...props.assetTemplateConfig,
                  name: el.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <TextField
              fullWidth={true}
              autoComplete="off"
              disabled={!props.assetTemplateConfig.enabled || props.readonly}
              label={I18n.t("frontend.short_name")}
              helperText={I18n.t("frontend.max_length", {
                length: ASSET_SHORT_NAME_MAX_LENGTH,
              })}
              value={assetShortName}
              error={assetShortName.length > 15}
              onChange={(el) => {
                props.onChange({
                  ...props.assetTemplateConfig,
                  short_name: el.target.value.slice(0, 14),
                });
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <TextField
              autoComplete="off"
              fullWidth={true}
              disabled={!props.assetTemplateConfig.enabled || props.readonly}
              label={I18n.t("activerecord.attributes.asset.key")}
              value={defaultTo(assetKey, "")}
              type="text"
              onChange={(el) => {
                props.onChange({
                  ...props.assetTemplateConfig,
                  key: el.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <TextField
              autoComplete="off"
              fullWidth={true}
              disabled={!props.assetTemplateConfig.enabled || props.readonly}
              label={I18n.t("activerecord.attributes.asset.serial")}
              value={defaultTo(props.assetTemplateConfig.serial, "")}
              type="text"
              inputProps={{ maxLength: ASSET_SHORT_NAME_MAX_LENGTH }}
              onChange={(el) => {
                props.onChange({
                  ...props.assetTemplateConfig,
                  serial: el.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
        {isNil(props.onDelete) ? null : (
          <Grid item xs={2}>
            <Button
              size="small"
              color="secondary"
              className="float-right"
              disabled={props.readonly}
              onClick={() => props.onDelete(props.assetTemplateConfig)}
            >
              <Delete />
            </Button>
          </Grid>
        )}
        {context.productModelsForAssetTypeIds?.[
          props.assetTemplateConfig.asset_type_id
        ] ? (
          <Grid item xs={12} md={6}>
            <ProductModelSelect
              productModels={
                context.productModelsForAssetTypeIds?.[
                  props.assetTemplateConfig.asset_type_id
                ]
              }
              selectedProductModel={props.assetTemplateConfig.product_model_id}
              onChange={(productModel) => {
                props.onChange({
                  ...props.assetTemplateConfig,
                  product_model_id: productModel?.id as number,
                });
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      {isEmpty(props.assetTemplateConfig.sensors) ? null : (
        <>
          <Grid
            container
            item
            xs={12}
            className="mt-3"
            onClick={() => {
              setShowSensors(!showSensors);
            }}
          >
            <Grid item xs={10}>
              <Typography variant="caption">
                {I18n.t("activerecord.models.sensor", { count: 2 })}
                <span className="ml-1">
                  {props.assetTemplateConfig.sensors.length -
                    props.assetTemplateConfig.exclude_keys.length}
                  /{props.assetTemplateConfig.sensors.length}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {showSensors ? <ExpandLess /> : <ExpandMore />}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Collapse in={showSensors}>
              <List dense={true}>
                {map(props.assetTemplateConfig.sensors, (sensor, key) => (
                  <ListItem key={key}>
                    <ListItemIcon>
                      <Wifi />
                    </ListItemIcon>
                    <ListItemText
                      primary={replaceTemplate(sensor.name, replacements)}
                      secondary={replaceTemplate(sensor.key, replacements)}
                    />
                    <Switch
                      edge="end"
                      onChange={(event, checked) => {
                        let exclude_keys =
                          props.assetTemplateConfig.exclude_keys;
                        if (!checked) {
                          exclude_keys.push(sensor.key);
                        } else {
                          exclude_keys = reject(
                            exclude_keys,
                            (item) => item === sensor.key,
                          );
                        }
                        props.onChange({
                          ...props.assetTemplateConfig,
                          exclude_keys: uniq(exclude_keys),
                        });
                      }}
                      checked={
                        props.assetTemplateConfig.exclude_keys.indexOf(
                          sensor.key,
                        ) === -1
                      }
                      inputProps={{
                        "aria-labelledby": "switch-list-label-bluetooth",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Grid>
        </>
      )}
    </Grid>
  );
};
