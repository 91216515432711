import { Box } from "@mui/material";
import { defaultTo, isNil, noop, omit } from "lodash";
import * as React from "react";

type PrettyCheckboxColor =
  | "p-primary"
  | "p-success"
  | "p-info"
  | "p-warning"
  | "p-danger"
  | "p-primary-o"
  | "p-success-o"
  | "p-info-o"
  | "p-warning-o"
  | "p-danger-o";

type PrettyCheckboxStyle =
  | "p-icon"
  | "p-curve"
  | "p-round"
  | "p-default"
  | "p-switch";
export interface PrettyCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  checkboxStyle?: PrettyCheckboxStyle;
  checkboxClassName?: string;

  icon?: string;
  color?: PrettyCheckboxColor;
  label?: string;
  labelClassName?: string;
  type?: "checkbox" | "radio";
  checkboxMargin?: "m-0" | "mr-1" | "mr-2" | "mr-3";
  plain?: boolean;
  locked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

/**
 * A styled checkbox with configurable border, color and icon.
 */
export class PrettyCheckbox extends React.Component<PrettyCheckboxProps> {
  /**
   * Default properties for checkbox
   */
  static defaultProps = {
    checkboxStyle: "p-default",
    onChange: noop,
    icon: "check",
    checkboxClassName: "",
  };

  render(): React.ReactNode {
    const props = this.props;
    return (
      <div
        className={`pretty p-icon ${
          props.checkboxStyle !== "p-icon" ? props.checkboxStyle : ""
        }${!isNil(props.checkboxMargin) ? " " + props.checkboxMargin : ""}${
          this.props.plain ? "p-plain " : " "
        }${this.props.locked ? "p-locked " : " "}${props.checkboxClassName}`}
      >
        <input
          {...omit(props, [
            "checkboxStyle",
            "plain",
            "locked",
            "icon",
            "color",
            "label",
            "labelClassName",
            "checkboxMargin",
            "checkboxClassName",
          ])}
          type={isNil(props.type) ? "checkbox" : props.type}
          onChange={props.onChange}
        />
        <div className={`state ${props.color}`}>
          <i className={`icon fa fa-${props.icon}`} />
          <label className={props.labelClassName}>{props.label}</label>
        </div>
      </div>
    );
  }
}

export interface PrettyCheckboxSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  checkboxStyle?: string;
  icon?: string;
  color?: "p-primary" | "p-success" | "p-info" | "p-warning" | "p-danger";
  label?: string;
  labelClassName?: string;
  margin?: "mr-1" | "mr-2" | "mr-3";
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const PrettyCheckboxSwitch: React.FunctionComponent<PrettyCheckboxSwitchProps> =
  function ({
    checkboxStyle = "p-default",
    onChange = noop,
    icon = "check",
    margin = null,
    checked,
    color,
    labelClassName,
    label,
  }) {
    return (
      <div className={`pretty p-switch ${defaultTo(margin, "")}`}>
        <input type="checkbox" onChange={onChange} checked={checked} />
        <Box className={`state ${color}`}>
          {isNil(icon) ? null : <i className={`icon fa fa-${icon}`} />}
          <label className={labelClassName}>{label}</label>
        </Box>
      </div>
    );
  };
