import { Skeleton, Typography } from "@mui/material";
import {
  compact,
  defaultTo,
  each,
  forEach,
  has,
  isEmpty,
  isEqual,
  isNil,
  map,
  merge,
  orderBy,
  sortBy,
  sortedUniq,
  toInteger,
  toString,
  uniq,
  values,
} from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { Sensor, SensorValueType } from "../../models/sensor";
import { WidgetBox } from "./widget_box";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { SensorLoader } from "../../json_api/sensor_loader";
import { time_series_api_sensor_path } from "../../routes";
// import { SensorDataAttribute } from "../../models/sensor";
import { Box } from "@mui/system";
import Bluebird, { CancellationError } from "bluebird";
import { ChartDataLoader } from "../../charting/chart_data/chart_data_loader";
import { BinaryChartDataLoadResult } from "../../charting/chart_data/chart_data_loader.types";
import { PlainTimeSeriesLoadResult } from "../../fetchers/time_series_data_fetcher";
import { roundDateSeconds } from "../../utils/date";
import { logger } from "../../utils/logger";
import { unitDisplayString } from "../../utils/unit_conversion";
import { IDType } from "../../utils/urls/url_utils";
import { getValueString } from "../../utils/value_format";
import { SensorValueTableWidgetConfigSerialized } from "../../widgets/sensor_value_table_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import {
  GroupedData,
  SensorValueTableWidgetProps,
  SensorValueTableWidgetState,
} from "./sensor_value_table_widget.types";
import { DateRange } from "moment-range";

type GroupedDataHash = Record<string, GroupedData>;

type SensorsByAssetsHash = {
  [assetId: string]: { [sensorId: string]: Sensor };
};

const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];

export class SensorValueTableWidget
  extends React.Component<
    SensorValueTableWidgetProps,
    SensorValueTableWidgetState
  >
  implements SensorEventSubscriber
{
  static defaultProps: Partial<SensorValueTableWidgetProps> = {
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,
    allowFullscreen: true,
    pageSize: 20,
    maxEntries: 500,
    tableHeight: 200,
    timeRange: new DateRange(moment().startOf("day"), moment().endOf("day")),
    groupByAsset: false,
    assetSearchMode: "subtree",
    sensorIds: [],

    assetInfo: [],
  };

  static serializedConfigToProps(
    config: SensorValueTableWidgetConfigSerialized,
  ): SensorValueTableWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      pageSize: config?.page_size,
      maxEntries: config?.max_entries,
      tableHeight: config.table_height,
      assetSearchMode: config.asset_search_mode,
      groupByAsset: config.group_by_asset,
      sensorIds: uniq(config.sensor_ids),
      assetInfo: config.asset_info,

      sensorColumnSorting: config.sensor_sort,
      dataUpdateEnabled: !config.disable_update,
    });
  }

  private sensorsLoadingPromise: Bluebird<Sensor[]>;
  private binaryDataLoadingPromise: Bluebird<
    BinaryChartDataLoadResult<any, string | number>[]
  >;
  private sensorIdsByAssetId?: Record<string, Record<string, Sensor>>;

  private assetNameByAssetId: {
    [assetId: string]: string;
  };

  private assetsByAttributeKeyId?: {
    [attributeKeyId: string]: { assetId: number };
  };
  private dataLoader: ChartDataLoader;

  constructor(props: SensorValueTableWidgetProps) {
    super(props);

    this.sensorIdsByAssetId = {};

    this.assetsByAttributeKeyId = {};

    this.assetNameByAssetId = {};
    each(this.props.assetInfo, (info) => {
      if (!has(this.assetNameByAssetId, info.id)) {
        this.assetNameByAssetId[info.id] = info.name;
      }
    });

    this.dataLoader = new ChartDataLoader();

    const maxEntries = [1, this.props.maxEntries, 5000].sort(
      (a, b) => a - b,
    )[1];

    this.state = {
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: props.title as string,
      titleLinkUrl: props.titleLinkUrl,
      contentLinkUrl: props.contentLinkUrl,
      tableData: [],

      // LoadableState
      loading: true,
      // PageableState
      pageSize: this.props.pageSize,
      pageSizes: sortedUniq(
        [this.props.pageSize, ...DEFAULT_PAGE_SIZES]
          .sort((a, b) => a - b)
          .filter((value) => value < maxEntries),
      ),
      currentPage: 1, //isNil(assetData.assets) ? null : 1,
      loadingPage: null,
      totalPages: null, //assetData.totalPages,

      maxEntries: maxEntries,

      testValue: 999,
      lastTimestamp: moment(),

      groupByAsset: defaultTo(this.props.groupByAsset, false),
      assetSearchMode: defaultTo(this.props.assetSearchMode, "subtree"),
      sensorIds: defaultTo(this.props.sensorIds, []),
      sensors: null,
      columns: null,

      assetInfo: defaultTo(this.props.assetInfo, []),
    };
    this.sensorsLoadingPromise = null;
    this.binaryDataLoadingPromise = null;
  }

  componentDidMount(): void {
    this.fetchAndPrepareData();
  }

  componentDidUpdate(oldProps: SensorValueTableWidgetProps): void {
    if (
      !this.props.timeRange?.start?.isSame(oldProps.timeRange.start) ||
      !this.props.timeRange?.end?.isSame(oldProps.timeRange.end) ||
      !isEqual(this.props.sensorIds, oldProps.sensorIds)
    ) {
      this.fetchAndPrepareData();
    }
  }
  protected buildBinaryDataUrls(
    sensors: Sensor[],
  ): Array<{ baseUrl: string; dataType: "text" | "number" }> {
    return sensors.map((s) => ({
      baseUrl: time_series_api_sensor_path(null as number, {
        id: s.id,
        format: "bin",
        // set secret parameter _options to mark this object  as routing options
        _options: true,
        min_time: this.props.timeRange.start.toISOString(),
        max_time: this.props.timeRange.end.toISOString(),
        limit: this.state.maxEntries,
        order: "desc",
      }),
      dataType: s.value_type == "text" ? "text" : "number",
    }));
  }

  fetchAndPrepareData(): void {
    // wait until state has been set to continue loading
    this.setState(
      {
        ...this.state,
        loading: true,
      },
      () => {
        try {
          // load sensor information
          this.sensorsLoadingPromise = this.loadSensors()
            .then((sensors) => {
              const binaryDataSensorIds = [];
              const plainDataSensors: Sensor[] = [];

              // fetch binary sensor data
              const binaryDataUrls = this.buildBinaryDataUrls(sensors);
              this.binaryDataLoadingPromise =
                this.dataLoader.loadBinaryChartData(binaryDataUrls, null);

              return Bluebird.all([this.binaryDataLoadingPromise, sensors]);
            })
            .then(([binaryChartData, sensors]) => {
              // Merge and group all data objects into a single object
              const groupedDataHash =
                this.groupAndMergeDataFromBinaryData(binaryChartData);
              /*groupedDataHash = this.groupAndMergeDataFromPlainTimeSeries(
                plainLoadResults,
                groupedDataHash,
              );*/
              let tableData = values(groupedDataHash);
              tableData = orderBy(tableData, ["timestamp"], ["desc"]);

              // Save tableData
              this.setState({
                columns: this.gridColDef(sensors),
                sensors: sensors,
                tableData: tableData,
              });
              return sensors;
            })
            .catch(CancellationError, (e) => {
              logger.info("Sensor Fetching Cancelled");
              return [];
            })
            .catch((error) => {
              logger.error("Error fetching data: ", error);
              this.setState({ loading: false });
              return [] as Sensor[];
            })
            .finally(() => {
              this.setState({
                loading: false,
              });
            });
        } catch (error) {
          logger.error("Error fetching data: ", error);
        }
      },
    );
  }

  groupAndMergeDataFromPlainTimeSeries(
    loadedResults: PlainTimeSeriesLoadResult[],
    groupedDataHash: GroupedDataHash = {},
  ): GroupedDataHash {
    // first group the data by time (and asset)
    //
    // INPUT from API:
    // [ { key_id: attributeKeyId1,
    //     x: [t1, t2, t3, ...],
    //     y: [v1, v2, v3, ...],
    //        ...
    //   }, ... ]
    //
    // RESULT after group and merge:
    // {
    //   "timeGroup1": {
    //     timestamp: t1,
    //     measures: {
    //       "attributeKeyId1": v1,
    //       "attributeKeyId2": v2,
    //       ...
    //     },
    //     id: "timeGroup1"
    //   }, ...
    //  ]
    each(loadedResults, (loadedData) => {
      loadedData?.data.forEach((data) => {
        this.addOrCreateValueTimeGroup(
          new Date(data.t),
          data.v,
          toString(loadedData.sensor.attribute_key_id),
          groupedDataHash,
        );
      });
    });
    return groupedDataHash;
  }
  groupAndMergeDataFromBinaryData(
    timesAndValuesPerSensor: BinaryChartDataLoadResult<any, string | number>[],
    groupedDataHash: GroupedDataHash = {},
  ): GroupedDataHash {
    // first group the data by time (and asset)
    //
    // INPUT from API:
    // [ { key_id: attributeKeyId1,
    //     x: [t1, t2, t3, ...],
    //     y: [v1, v2, v3, ...],
    //        ...
    //   }, ... ]
    //
    // RESULT after group and merge:
    // {
    //   "timeGroup1": {
    //     timestamp: t1,
    //     measures: {
    //       "attributeKeyId1": v1,
    //       "attributeKeyId2": v2,
    //       ...
    //     },
    //     id: "timeGroup1"
    //   }, ...
    //  ]

    each(timesAndValuesPerSensor, (loadedData) => {
      const timesAndValues = loadedData.data;
      each(timesAndValues.x, (x, index) => {
        this.addOrCreateValueTimeGroup(
          new Date(x),
          timesAndValues.y[index],
          toString(timesAndValues.key_id),
          groupedDataHash,
        );
      });
    });
    return groupedDataHash;
  }

  /**
   *
   *
   * @param {Date} time
   * @param {SensorValueType} value
   * @param {IDType} key_id
   * @param {GroupedDataHash} groupedDataHash
   * @memberof SensorValueTableWidget
   */
  addOrCreateValueTimeGroup(
    time: Date,
    value: SensorValueType,
    key_id: IDType,
    groupedDataHash: GroupedDataHash,
  ) {
    const timeGroup = moment(time).format("L LTS");

    const assetId: string = defaultTo(
      this.getAssetIdByAttributeKey(key_id),
      "",
    );

    const groupId = this.state.groupByAsset
      ? `${timeGroup}_${assetId}`
      : timeGroup;

    if (isNil(groupedDataHash[groupId])) {
      groupedDataHash[groupId] = {
        timestamp: roundDateSeconds(time),
        measures: {
          [key_id]: value,
        },
        id: groupId,
        assetId: assetId,
      };
    } else {
      groupedDataHash[groupId].measures = {
        ...groupedDataHash[groupId].measures,
        [key_id]: value,
      };
    }
  }

  getAssetIdByAttributeKey(attributeKeyId: string | number): string {
    let foundAssetId = null;

    each(this.sensorIdsByAssetId, (sensorById) => {
      each(sensorById, (sensorInfo) => {
        if (
          sensorInfo.asset_id &&
          toString(sensorInfo.attribute_key_id) == toString(attributeKeyId)
        ) {
          foundAssetId = sensorInfo.asset_id;
        }
      });
    });
    return defaultTo(toString(foundAssetId), "");
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // register listeners to widget controller
      // for sensors :
      /// WidgetController.getInstance().sensorDataChannel.removeEventListener(this, this.props.sensorId);
      forEach(this.state.sensorIds, (sensorId: string) => {
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          toInteger(sensorId),
        );
      });
    }
    if (!isNil(this.sensorsLoadingPromise)) {
      this.sensorsLoadingPromise.cancel();
      this.sensorsLoadingPromise = null;
    }
    if (!isNil(this.binaryDataLoadingPromise)) {
      this.binaryDataLoadingPromise.cancel();
      this.binaryDataLoadingPromise = null;
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  gridColDef(sensors: Sensor[]): GridColDef[] {
    const assetIds = defaultTo(uniq(map(sensors, "asset_id")), []);
    const sensorsById: { [sensorId: string]: Sensor } = {};

    forEach(assetIds, (assetId: string) => {
      const assetSensors = defaultTo(this.sensorIdsByAssetId[assetId], {});
      forEach(assetSensors, (value, key) => {
        sensorsById[key] = value;
      });
    });

    // const defaultWidth = 130;
    // const largeWidth = 200;
    const columns: GridColDef<GroupedData>[] = [
      {
        field: "timestamp",
        type: "dateTime",

        headerName: I18n.t(
          "frontend.widgets.sensor_value_table_widget.timestamp",
        ),
        valueGetter: (value, row) => {
          return row.timestamp;
        },
        valueFormatter: (value, row) => moment(value).format("L LTS"),

        flex: 1,
      },
    ];
    if (this.state.groupByAsset) {
      columns.push({
        field: "assetId",
        headerName: I18n.t("activerecord.models.asset.one"),
        flex: 1,

        valueGetter: (value, row) => {
          return defaultTo(
            this.assetNameByAssetId[row.assetId],
            row.assetId,
          );
        },
      });
    }
    let sensorsToUse = sensors;
    if (!isEmpty(this.props.sensorColumnSorting)) {
      const criteria = compact(
        map(this.props.sensorColumnSorting, (sortCriterion) => {
          if (sortCriterion == "asset") return "asset_id";
          if (sortCriterion == "sensor_type") return "sensor_type_id";
          if (sortCriterion == "context") return "sensor_context";
          if (sortCriterion == "context2") return "sensor_context2";
          if (sortCriterion == "attribute_key") return "key";
          if (sortCriterion == "name") return "name";

          return null;
        }),
      );
      if (!isEmpty(criteria)) sensorsToUse = sortBy(sensors, criteria);
    }
    each(sensorsToUse, (sensor) => {
      const key_id = sensor.attribute_key_id;
      columns.push({
        field: `measures.${key_id}`,
        type: "number",
        valueGetter: (value, row) => row.measures[key_id],
        valueFormatter: (value, row) => {
          this.displayValue(value, sensor);
        },
        headerName: this.columnNameForSensor(sensor),

        //width: defaultWidth,
        flex: 1,
      } as GridColDef);
    });

    return columns;
  }

  displayValue(value: number, sensor: Sensor): string {
    return `${getValueString(
      value,
      defaultTo(sensor?.precision, defaultTo(sensor?.import_precision, 3)),
    )} ${unitDisplayString(
      defaultTo(sensor?.display_unit, sensor?.attribute_key_unit),
    )}`;
  }

  columnNameForSensor(sensor: Sensor): string {
    return `${sensor.name}`;
  }

  tableContent(): React.ReactNode {
    let showPagination = true;
    const rowCount = defaultTo(this.state.tableData?.length, null);
    if (!isNil(this.state.pageSize) && !isNil(rowCount)) {
      showPagination = this.state.pageSize <= rowCount;
    }
    if (isEmpty(this.state.sensors) || isEmpty(this.state.columns))
      return (
        <Typography>
          {I18n.t(
            "frontend.widgets.sensor_value_table_widget.no_sensors_to_display",
          )}
        </Typography>
      );
    return (
      <Box height={this.props.tableHeight} width="100%">
        <DataGrid
          autoPageSize={false}
          initialState={{
            density: defaultTo(this.props.density, "compact"),
          }}
          pagination
          paginationMode={"client"}
          paginationModel={{
            pageSize: this.state.pageSize,
            page: this.state.currentPage,
          }}
          hideFooterPagination={!showPagination}
          pageSizeOptions={this.state.pageSizes}
          //rowCount={this.state.tableData.length}
          rows={defaultTo(this.state.tableData, [])}
          columns={this.state.columns}
          loading={this.state.loading}
          onPaginationModelChange={(newPaginationModel) => {
            if (this.state.pageSize != newPaginationModel.pageSize) {
              this.setPageSize(newPaginationModel.pageSize);
            }
            if (this.state.currentPage !== newPaginationModel.page) {
              this.setCurrentPage(newPaginationModel.page);
            }
          }}
        />
      </Box>
    );
  }

  setCurrentPage(newPage: number): void {
    //void this.loadAssets(page, pageSize);
    this.setState({
      ...this.state,
      currentPage: newPage,
    });
  }

  setPageSize(newPageSize: number) {
    // Todo: change also state.currentPage to ensure that the first row of the current pagination
    // is visible after updating state.pageSize.
    this.setState({
      ...this.state,
      pageSize: newPageSize,
    });
  }

  render(): React.ReactNode {
    const content = this.state.loading ? (
      <Skeleton variant="rounded" height={300} />
    ) : (
      this.tableContent()
    );

    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          content
        ) : (
          <WidgetBox
            {...this.props}
            title={this.state.title}
            titleLinkUrl={this.state.titleLinkUrl}
            contentLinkUrl={this.state.contentLinkUrl}
          >
            {content}
          </WidgetBox>
        )}
      </>
    );
  }

  /** Loads sensor information from API. Stores the result in this.sensorIdsByAssetId.
   *
   *
   * @private
   * @return {Promise<Sensor[]>}
   * @memberof SensorValueTableWidget
   */
  private loadSensors(): Bluebird<Sensor[]> {
    const sensor_ids = defaultTo(this.state?.sensorIds, []);

    return SensorLoader.getInstance()
      .getSensors(sensor_ids)
      .then((sensors) => {
        const sensorIdsByAssetId = {} as SensorsByAssetsHash;
        forEach(sensors, (sensor: Sensor) => {
          if (!isNil(sensor)) {
            const assetIdString = `${sensor.asset_id}`;
            const sensorIdString = `${sensor.id}`;
            if (isNil(sensorIdsByAssetId[assetIdString])) {
              sensorIdsByAssetId[assetIdString] = {};
            }
            sensorIdsByAssetId[assetIdString][sensorIdString] = sensor;
          }
        });
        this.sensorIdsByAssetId = sensorIdsByAssetId;
        return sensors;
      })
      .catch(CancellationError, (e) => {
        logger.info("Sensor loading cancelled");
        return [];
      })
      .catch((e) => {
        logger.error(`Error: ${(e as Error).toString()}`);
        return [] as Sensor[];
      });
  }

  protected toggleSensorUpdates(props: SensorValueTableWidgetProps): void {
    forEach(this.state.sensorIds, (sensorId) => {
      if (WidgetController.getInstance()) {
        if (props.dataUpdateEnabled) {
          WidgetController.getInstance().sensorDataChannel.subscribe(
            toInteger(sensorId),
          );
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            this,
            toInteger(sensorId),
          );
        } else {
          WidgetController.getInstance().sensorDataChannel.removeEventListener(
            this,
            toInteger(sensorId),
          );
        }
      }
    });
  }
}
