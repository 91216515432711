import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Switch,
  Typography,
} from "@mui/material";

import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";

import PieIcon from "@mui/icons-material/PieChart";

import { isEmpty } from "lodash";
import moment from "moment";
import * as React from "react";
import { MeasurementPlanJSONApiAttributes } from "../../../json_api/measurement_plan";
import { MeasurementPlan } from "../../../models/measurement_plan";
import { rruleToText } from "../../../utils/rotation_description";
import {
  assetMeasurementPlanMeasurementPath,
  assetMeasurementPlansMeasurementsPath,
  editAssetMeasurementPlanPath,
  newAssetMeasurementPath,
} from "../../../utils/urls";
import { IBox, IBoxContent } from "../../common/ibox";

import { EmailNotificationIcon } from "../../common/email_notification_icon";

interface MeasurementPlanItemProperties {
  measurementPlan: MeasurementPlanJSONApiAttributes;
  onSubscribe?: (plan: MeasurementPlan) => void;
  onUnsubscribe?: (plan: MeasurementPlan) => void;
}

export const MeasurementPlanItem: React.FunctionComponent<
  MeasurementPlanItemProperties
> = (props) => {
  const handleSubscriptionSwitch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.measurementPlan.subscribed) {
        props.onUnsubscribe(props.measurementPlan);
      } else {
        props.onSubscribe(props.measurementPlan);
      }
    },
    [props.measurementPlan.subscribed],
  );

  return (
    <IBox>
      <IBoxContent>
        <Grid
          container
          className="align-items-center w-100"
          spacing={2}
          justifyContent="space-between"
        >
          <Grid item xs={12} lg={3}>
            <Typography variant="h6" className="auto-hyphen">
              <Box component="span" marginRight={2}>
                {props.measurementPlan.measurement_type_type ===
                "distribution_measurement_type" ? (
                  <PieIcon />
                ) : (
                  <AssignmentIcon />
                )}
              </Box>
              <Link
                href={assetMeasurementPlansMeasurementsPath(
                  props.measurementPlan.asset_id,
                  props.measurementPlan.id,
                  "html",
                )}
                title={I18n.t(
                  "frontend.measurement_plan_item.show_measurement_list",
                )}
              >
                {props.measurementPlan.measurement_type_title}
              </Link>
            </Typography>
          </Grid>
          <Grid item container xs={12} lg="auto" spacing={2}>
            <Grid item xs={4}>
              <Typography component="div" variant="caption">
                {I18n.t("activerecord.attributes.measurement_plan.rrule", {
                  count: 1,
                })}
              </Typography>

              <Typography variant="subtitle2">
                {rruleToText(props.measurementPlan.rrule)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {isEmpty(props.measurementPlan.next_measurement_at) ? null : (
                <>
                  <Typography component="div" variant="caption">
                    {I18n.t(
                      "activerecord.attributes.measurement_plan.next_scheduled_at",
                    )}
                  </Typography>

                  <Typography variant="subtitle2">
                    {moment(props.measurementPlan.next_measurement_at).format(
                      "L LT",
                    )}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography component="div" variant="caption">
                {I18n.t("frontend.measurement_plan_item.last_measurement")}
              </Typography>

              <Typography>
                {isEmpty(props.measurementPlan.last_measurement_at) ? (
                  I18n.t("frontend.measurement_plan_item.no_last_measurement")
                ) : (
                  <Link
                    href={assetMeasurementPlanMeasurementPath(
                      props.measurementPlan.asset_id,
                      props.measurementPlan.id,
                      props.measurementPlan.last_measurement_id,
                      "html",
                    )}
                    title={I18n.t(
                      "frontend.measurement_plan_item.show_last_measurement",
                    )}
                  >
                    {moment(props.measurementPlan.last_measurement_at).format(
                      "L LT",
                    )}
                  </Link>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg="auto">
            <FormControlLabel
              className="float-right"
              control={
                <Switch
                  size="small"
                  checked={props.measurementPlan.subscribed}
                  onChange={(e) => handleSubscriptionSwitch(e)}
                />
              }
              title={
                props.measurementPlan.subscribed
                  ? I18n.t("frontend.subscribed")
                  : I18n.t("frontend.unsubscribed")
              }
              label={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  <EmailNotificationIcon
                    enabled={props.measurementPlan.subscribed}
                  />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <IconButton
              aria-label={I18n.t("frontend.edit")}
              title={I18n.t("frontend.edit")}
              href={editAssetMeasurementPlanPath(
                props.measurementPlan.asset_id,
                props.measurementPlan.id,
              )}
              size="large"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label={I18n.t(
                "frontend.measurement_plan_item.new_measurement",
              )}
              title={I18n.t("frontend.measurement_plan_item.new_measurement")}
              color="primary"
              href={newAssetMeasurementPath(
                props.measurementPlan.asset_id,
                props.measurementPlan.id,
              )}
              size="large"
            >
              <AddCircleOutline />
            </IconButton>
          </Grid>
        </Grid>
      </IBoxContent>
    </IBox>
  );
};
