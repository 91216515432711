/// <reference types="../../../definitions/index" />;
import { ConstructionOutlined } from "@mui/icons-material";
import {
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { getTranslatedProp } from "../../../utils/globalize";
import * as url_helper from "../../../utils/urls";
import { DropdownButton } from "../../notifications_dropdown/views/dropdown_button";
import { MaintenancePlan } from "../data/models";
import { MaintenanceListEntry } from "./maintenance_list_entry";

interface MaintenanceDueDropdownProps {
  dueMaintenances: MaintenancePlan[];
}

export const MaintenanceDueDropdown: React.FunctionComponent<
  MaintenanceDueDropdownProps
> = ({ dueMaintenances = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <DropdownButton
        icon={<ConstructionOutlined />}
        iconClassName="fa-flip-horizontal"
        title={I18n.t("frontend.maintenance_due_dropdown.heading")}
        aria-haspopup="true"
        aria-controls={open ? "maintenance-due-dropdown" : undefined}
        aria-expanded={open ? "true" : undefined}
        id="maintenance-due-dropdown-btn"
        unnoticedEvents={dueMaintenances.length}
        onClick={handleClick}
      />
      <Menu
        id="maintenance-due-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{ paper: { style: { width: "350px" } } }}
        MenuListProps={{
          "aria-labelledby": "maintenance-due-dropdown-btn",
        }}
      >
        <MenuItem>
          <ListItemText>
            <Typography variant="h5">
              {I18n.t("frontend.maintenance_due_dropdown.heading")}
            </Typography>
          </ListItemText>
        </MenuItem>

        <Divider />
        {isEmpty(dueMaintenances) ? (
          <MenuItem>
            {I18n.t("frontend.maintenance_due_dropdown.no_maintenances_due")}
          </MenuItem>
        ) : (
          dueMaintenances.map((maintenancePlan) => {
            return (
              <MaintenanceListEntry
                key={maintenancePlan.id}
                name={getTranslatedProp(maintenancePlan, "name")}
                assetName={getTranslatedProp(maintenancePlan.asset, "name")}
                url={url_helper.assetMaintenancePlansPath(
                  maintenancePlan.root_asset_id,
                  maintenancePlan.id,
                  "html",
                )}
                icon="wrench"
                dueAt={maintenancePlan.due_date.format("DD.MM.YYYY, HH:mm:ss")}
              />
            );
          })
        )}
      </Menu>
    </div>
  );
};
