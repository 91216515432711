import { isNil, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { SensorAggregationWidgetConfigSerialized } from "../../widgets/sensor_aggregation_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ValueDisplay } from "../common/value_display";
import {
  SensorAggregationWidgetProps,
  SensorAggregationWidgetState,
} from "./sensor_aggregation_widget.types";
import { WidgetBox } from "./widget_box";

export class SensorAggregationWidget
  extends React.Component<
    SensorAggregationWidgetProps,
    SensorAggregationWidgetState
  >
  implements SensorEventSubscriber
{
  static defaultProps: Partial<SensorAggregationWidgetProps> = {
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,
  };

  static serializedConfigToProps(
    config: SensorAggregationWidgetConfigSerialized,
  ): SensorAggregationWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      dataUpdateEnabled: !config.disable_update,
      value: config?.value,
      sensorIds: config.sensor_ids,
      timestamp: config?.timestamp,
      iconName: config?.icon_name,
      iconSize: config?.icon_size,
      vertical: config?.vertical,
      precision: config?.precision,
      shadowText: config.text_shadow,
    });
  }
  constructor(props: SensorAggregationWidgetProps) {
    super(props);
    this.state = {
      value: props.value,
      timestamp: isNil(props.timestamp) ? null : moment(props.timestamp),
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: props.title as string,
      titleLinkUrl: props.titleLinkUrl,
      contentLinkUrl: props.contentLinkUrl,
    };
  }

  componentDidMount(): void {
    // initialize component, e.g. some loading from API
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // register listeners to widget controller
      // for sensors :
      /// WidgetController.getInstance().sensorDataChannel.removeEventListener(this, this.props.sensorId);
    }
  }

  componentDidUpdate(oldProps: SensorAggregationWidgetProps): void {
    if (this.props.dataUpdateEnabled !== oldProps.dataUpdateEnabled) {
      //this.toggleSensorUpdates(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  render(): React.ReactNode {
    // implement display content here ...

    const valueDisplay = (
      <ValueDisplay
        value={this.state.value}
        unit={this.props.unit}
        iconName={this.props.iconName}
        iconSize={this.props.iconSize}
        precision={this.props.precision}
        timestamp={this.state.timestamp?.format("L LT")}
        vertical={this.props.vertical}
        shadowText={this.props.shadowText}
      />
    );

    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          { valueDisplay }
        ) : (
          <WidgetBox
            {...this.props}
            title={this.state.title}
            titleLinkUrl={this.state.titleLinkUrl}
            contentLinkUrl={this.state.contentLinkUrl}
          >
            {valueDisplay}
          </WidgetBox>
        )}
      </>
    );
  }
}
