import { Box, Grid, Typography } from "@mui/material";

import {
  cloneDeep,
  compact,
  defaultTo,
  each,
  isEmpty,
  isNil,
  map,
  merge,
  toInteger,
  toNumber,
  values,
} from "lodash";
import { EvalFunction, compile } from "mathjs";
import moment, { Moment } from "moment";
import * as React from "react";
import SVG from "react-inlinesvg";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { BaseMapping } from "../../charting/svg/mapping_base";
import { createMappingFromConfig } from "../../charting/svg/mapping_factory";
import { WidgetController } from "../../controller/widget_controller";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { SensorValueType } from "../../models/sensor";
import { logger } from "../../utils/logger";
import { getTimeString } from "../../utils/time_strings";
import { sensorUrl } from "../../utils/urls";
import { WidgetBox } from "./widget_box";

import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { SvgAnimationWidgetConfigSerialized } from "../../widgets/svg_animation_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { LoadingIcon } from "../common/icon";
import {
  MappedSensorConfig,
  SvgAnimationWidgetProps,
  SvgAnimationWidgetState,
} from "./svg_animation_widget.types";
import {
  SensorAttributesForSvgMapping,
  SensorMapping,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { WidgetTimestampGridItem } from "./widget_timestamp";

export class SvgAnimationWidget
  extends React.Component<SvgAnimationWidgetProps, SvgAnimationWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<SvgAnimationWidgetProps> = {
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,
    encloseInIBox: false,
  };

  static serializedConfigToProps(
    config: SvgAnimationWidgetConfigSerialized,
  ): SvgAnimationWidgetProps {
    const sensorMappings = config.mappings?.map(
      (sensorMappingWithJsonApiDoc) => {
        // process and store the sensor information for the i-th sensor
        // if sensor is not available, return null - sensor for mappings has not been found in backend
        if (isNil(sensorMappingWithJsonApiDoc)) return null;
        const sensorJsonApiData = sensorMappingWithJsonApiDoc.sensor?.sensor;
        // no sensor, no changes
        if (isNil(sensorJsonApiData)) return sensorMappingWithJsonApiDoc;

        // convert the json api data to a flat object
        const sensor = !isNil(sensorJsonApiData)
          ? jsonApiSingleResourceToFlatObject(sensorJsonApiData)
          : null;

        // merge the sensor data with the sensor mapping data
        const d = {
          ...sensorMappingWithJsonApiDoc,
          sensor: sensorJsonApiData
            ? { ...sensorMappingWithJsonApiDoc.sensor, sensor }
            : null,
        };
        return d;
      },
    );

    return merge(widgetBoxPropsFromSerializedConfig(config), {
      svgCode: config.svg_code,
      svgUrl: config.svg_url,
      contentLinkTarget: config.title_link_target,
      svgMaxWidth: config.max_svg_width,
      svgMaxHeight: config.max_svg_height,
      dataUdateEnabled: isNil(config.disable_update)
        ? false
        : !config.disable_update,
      svgImageElementSelector: config.image_placeholder_selector,

      svgImageUrl: config.image_url,
      sensorMappings: sensorMappings,
    } as SvgAnimationWidgetProps);
  }
  svgElement: SVGSVGElement = null;

  sensorBySensorId: { [key: string]: MappedSensorConfig } = {};
  mappingsBySensorId: { [key: string]: BaseMapping[] } = {};

  invalidSensorMappings?: BaseMapping[] = [];
  constructor(props: SvgAnimationWidgetProps) {
    super(props);

    const mostRecentTime: Moment = this.buildMappings(props);

    // Todo: Make the link configurable?
    // const sensorLink: string = sensorUrl(this.oldConfig.sensor, "html");

    let sensorLink: string;
    const sensor_1 = defaultTo(values(this.sensorBySensorId)[0]?.sensor, null);
    if (!isNil(sensor_1)) {
      sensorLink = sensorUrl(sensor_1, "html");
    }

    this.state = {
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: defaultTo(props.title as string, sensor_1?.name),
      titleLinkUrl: defaultTo(props.titleLinkUrl, sensorLink),
      titleLinkTarget: defaultTo(props.linkTarget, "_self"),
      contentLinkUrl: defaultTo(props.contentLinkUrl, props.titleLinkUrl),
      contentLinkTarget: defaultTo(
        props.contentLinkTarget,
        defaultTo(props.linkTarget, "_self"),
      ),
      lastTimestamp: mostRecentTime,
    };
  }
  handleElementClick(
    event: MouseEvent,
    element: SVGElement,
    mappingConfig: SensorMappingAttributes,
    sensor: SensorAttributesForSvgMapping,
  ): void {
    if (sensor?.sensor) {
      const sensorLink = sensorUrl(sensor.sensor, "html");
      if (!isNil(sensorLink)) {
        window.open(sensorLink, "_blank");
      }
    }
  }

  buildMappings(props: SvgAnimationWidgetProps): Moment {
    let mostRecentTime: Moment;

    const mappingsToBeApplied = props.sensorMappings;
    this.invalidSensorMappings = [];

    each(mappingsToBeApplied, (sensorMapping, mappingIndex) => {
      // process and store the mapping for i-th sensor
      // Mappings are generated but need to be SetFromSvgElement later
      const theSensorsMappings: BaseMapping[] = compact(
        map(sensorMapping.mappings, (mappingConfig) =>
          createMappingFromConfig(
            mappingConfig,
            (event: MouseEvent, element: SVGElement) => {
              this.handleElementClick(
                event,
                element,
                mappingConfig,
                sensorMapping.sensor,
              );
            },
          ),
        ),
      );

      // process and store the sensor information for the i-th sensor
      const sensorConfig = sensorMapping.sensor;
      const sensor = sensorConfig?.sensor;
      let sensorId: number;
      let compiledValueFormula: EvalFunction = null;
      let time: Moment;
      if (sensorConfig || sensor) {
        // formula to be applied for each new sensor value (e.g., "value * 10.0")

        if (!isEmpty(sensorConfig?.formula)) {
          try {
            compiledValueFormula = compile(sensorConfig.formula);
          } catch (e) {
            logger.warn(e);
          }
        }
        sensorId = this.getSensorIdFromConfig(sensorConfig);
        const time = isNil(sensorConfig?.time)
          ? isNil(sensor?.last_value?.timestamp)
            ? null
            : moment(sensor?.last_value?.timestamp)
          : moment(sensorConfig.time);

        if (!isNil(time)) {
          if (isNil(mostRecentTime) || mostRecentTime?.isBefore(time)) {
            mostRecentTime = time;
          }
        }
      }
      if (!isNil(sensorId)) {
        const mappedSensorConfig: MappedSensorConfig = {
          sensor,
          sensorId,
          sensorType: sensorConfig.sensor_type,
          value: sensorConfig.value,
          valueFormula: sensorConfig.formula,
          compiledValueFormula: compiledValueFormula,
          range: defaultTo(
            sensorConfig.range,
            defaultTo(sensor?.total_value_range, null),
          ),
          time,
          unit: defaultTo(sensor?.attribute_key_unit, ""),
        };
        this.sensorBySensorId[sensorId.toString()] = mappedSensorConfig;
        this.mappingsBySensorId[sensorId.toString()] = theSensorsMappings;
      } else {
        this.invalidSensorMappings.push(...theSensorsMappings);
      }
    });
    return mostRecentTime;
  }

  calculateValue(
    value: number,
    compiledValueFormula: EvalFunction = null,
    sensor: SensorJSONAPIAttributes = null,
  ): number {
    if (isNil(compiledValueFormula) || isNil(value)) {
      return value;
    } else {
      return compiledValueFormula.evaluate({
        value: value,
        min: sensor?.total_value_range?.min,
        max: sensor?.total_value_range?.min,
      }) as number;
    }
  }

  componentDidMount(): void {
    this.toggleSensorUpdates();
  }

  getSensorIdFromConfig(config: MappedSensorConfig): number {
    if (isNil(config)) return null;

    return isNil(config.sensor)
      ? toNumber(config.sensorId)
      : toNumber(config.sensor.id);
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // unregister listeners to widget controller
      // for sensors :
      Object.keys(this.sensorBySensorId).forEach((sensorId) => {
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          toInteger(sensorId),
        );
      });
    }
  }

  initializeSvgElement(element: SVGSVGElement) {
    // set Svg Element Ref
    const svgRef = this.svgElement;
    this.svgElement = element;

    // process only for initialization or if svgElement has changed
    if (!isNil(element) && svgRef !== element) {
      this.initializeMappingSvgElements();
      // retrieve element info from SVG
      this.setElementInfoFromSvg();

      if (isNil(svgRef)) {
        // set the background image
        this.setImageReference();
        each(this.invalidSensorMappings, (mapping) => {
          if (mapping.config.hide_on_missing) {
            mapping.hide(element);
          }
        });
        // initial application of transformation
        each(this.sensorBySensorId, (sensorConfig) => {
          const sensorId = sensorConfig.sensorId;
          each(this.mappingsBySensorId[sensorId], (mapping) => {
            try {
              // be sure that
              mapping.applyValueToSvg(sensorConfig, this.svgElement);
            } catch (e) {
              logger.log(e);
            }
          });
        });
      }
    }
  }

  setImageReference() {
    if (
      !isEmpty(this.props.svgImageElementSelector) &&
      !isEmpty(this.props.svgImageUrl)
    ) {
      const imageSelector = defaultTo(
        $(this.svgElement).data("image-selector") as string,
        this.props.svgImageElementSelector,
      );
      const element = $(this.svgElement).find(imageSelector);
      if (element.length > 0) {
        (element[0] as any as SVGImageElement).setAttribute(
          "href",
          this.props.svgImageUrl,
        );
      }
    }
  }

  initializeMappingSvgElements() {
    each(this.mappingsBySensorId, (mappings: BaseMapping[]) => {
      each(mappings, (mapping: BaseMapping) => {
        mapping.initElementBinding(this.svgElement);
      });
    });
  }

  setElementInfoFromSvg() {
    each(this.mappingsBySensorId, (mappings: BaseMapping[]) => {
      each(mappings, (mapping: BaseMapping) => {
        mapping.setElementInfoFromSvg(this.svgElement);
      });
    });
  }

  componentDidUpdate(oldProps: SvgAnimationWidgetProps): void {
    if (this.props.dataUpdateEnabled !== oldProps.dataUpdateEnabled) {
      this.toggleSensorUpdates();
    }
    if (this.props.sensorMappings !== oldProps.sensorMappings) {
      this.buildMappings(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    const sensorConfig = cloneDeep(this.sensorBySensorId[sensorId]);
    // update the sensor value to the most recent value
    sensorConfig.value = this.calculateValue(
      value as number,
      sensorConfig.compiledValueFormula,
      sensorConfig.sensor,
    );
    sensorConfig.time = time;
    this.sensorBySensorId[sensorId] = sensorConfig;

    // process all the mapping affected by the sensor update
    values(this.mappingsBySensorId[sensorId]).forEach((mapping) => {
      mapping.applyValueToSvg(sensorConfig, this.svgElement);
    });
    if (
      isNil(this.state.lastTimestamp) ||
      time.isAfter(this.state.lastTimestamp)
    ) {
      this.setState({ lastTimestamp: time });
    }
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  protected toggleSensorUpdates(): void {
    Object.keys(this.mappingsBySensorId).forEach((sensorId) => {
      if (WidgetController.getInstance()) {
        if (
          this.state.dataUpdateEnabled //&& // if data update is enabled
          //this.props.timeRange?.contains(moment()) // and the current time is within the time range
        ) {
          WidgetController.getInstance().sensorDataChannel.subscribe(
            toInteger(sensorId),
          );
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            this,
            toInteger(sensorId),
          );
        } else {
          WidgetController.getInstance().sensorDataChannel.removeEventListener(
            this,
            toInteger(sensorId),
          );
        }
      }
    });
  }

  render(): React.ReactNode {
    // implement display content here ...
    const content = (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box m="auto" width="90%">
            <SVG
              loader={<LoadingIcon size="4x" />}
              style={{
                maxWidth: defaultTo(this.props.svgMaxWidth, "100%"),
                maxHeight: this.state.fullscreen
                  ? "80vh"
                  : this.props.svgMaxHeight || "90vh",
              }}
              src={defaultTo(this.props.svgCode, this.props.svgUrl)}
              width="100%"
              height={this.state.fullscreen ? "75vh" : null}
              innerRef={(element) =>
                this.initializeSvgElement(element as SVGSVGElement)
              }
            />
          </Box>
        </Grid>
        <WidgetTimestampGridItem
          timestamp={getTimeString(null, this.state.lastTimestamp)}
          align="center"
        />
      </Grid>
    );

    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          content
        ) : (
          <WidgetBox
            {...this.props}
            title={this.state.title}
            titleLinkUrl={this.state.titleLinkUrl}
            contentLinkUrl={this.state.contentLinkUrl}
            onFullscreen={(fullscreen) => {
              this.setState({ fullscreen });
            }}
            allowFullscreen
          >
            {content}
          </WidgetBox>
        )}
      </>
    );
  }
}
