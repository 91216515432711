import { AccountTree, KeyboardArrowLeft, PlusOne } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { SingleResourceDoc } from "jsonapi-typescript";
import * as React from "react";
import {
  ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetTypeJSONObject,
} from "../../json_api/asset_type";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import {
  asset_type_path,
  asset_type_product_model_product_model_asset_template_settings_path,
  new_asset_asset_template_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { AssetTemplateList } from "../asset_templates/asset_template_list";
import { AssetTypesSensorTypesList } from "../asset_types_sensor_types/asset_types_sensor_types_list";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { ProductModelList } from "../product_models";

interface AssetTypeHomeProps {
  assetTypeId: number;
  canEdit: boolean;
}

export const AssetTypeHome: React.FC<AssetTypeHomeProps> = ({
  assetTypeId,
  canEdit,
}) => {
  const assetTypeQuery = useQuery({
    queryKey: [ASSET_TYPE_JSONAPI_RESOURCE_TYPE, assetTypeId],
    queryFn: async () => {
      const result = await loadDataFromUrl<
        SingleResourceDoc<string, AssetTypeJSONObject>
      >(asset_type_path(assetTypeId));
      return jsonApiSingleResourceToFlatObject(result);
    },
  });

  return (
    <Grid container spacing={4}>
      {assetTypeQuery.isLoading ? (
        <Grid item xs={12}>
          <Skeleton height={500} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h4">{assetTypeQuery.data.name}</Typography>

          <ProductModelList
            assetType={assetTypeQuery.data}
            canEdit={canEdit}
            itemActions={
              canEdit
                ? (productModel) => {
                    return [
                      <GridActionsCellItem
                        showInMenu
                        label={I18n.t(
                          "activerecord.models.product_model_asset_template_setting.other",
                        )}
                        icon={<AccountTree />}
                        onClick={() => {
                          redirectTo(
                            asset_type_product_model_product_model_asset_template_settings_path(
                              assetTypeQuery.data.id,
                              productModel.id,
                            ),
                          );
                        }}
                        key="template_settings"
                      />,
                    ];
                  }
                : undefined
            }
            tableHeight={500}
          />
        </Grid>
      )}
      {assetTypeQuery.isLoading ? (
        <Grid item xs={12}>
          <Skeleton height={500} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={I18n.t("activerecord.models.asset_template", {
                count: 2,
              })}
            />
            <CardContent>
              <AssetTemplateList
                assetType={assetTypeQuery.data}
                canEdit={canEdit}
                tableHeight={500}
                rowActions={
                  canEdit
                    ? (template) => {
                        return [
                          <GridActionsCellItem
                            showInMenu
                            label={`${I18n.t("frontend.create")} ${I18n.t("activerecord.models.asset.one")}`}
                            icon={<PlusOne />}
                            onClick={() => {
                              redirectTo(
                                new_asset_asset_template_path(template.id),
                              );
                            }}
                            title="New Asset"
                            key="new_asset"
                          />,
                        ];
                      }
                    : undefined
                }
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      {assetTypeQuery.isLoading ? (
        <Grid item xs={12}>
          <Skeleton height={800} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={I18n.t("activerecord.models.asset_types_sensor_type", {
                count: 2,
              })}
            />
            <CardContent>
              <AssetTypesSensorTypesList
                assetType={assetTypeQuery.data}
                canEdit={canEdit}
                tableHeight={800}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn
          onCancel={() => {
            redirectTo("back");
          }}
          cancelIcon={<KeyboardArrowLeft />}
        />
      </FixedBottomArea>
    </Grid>
  );
};
