import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { defaultTo, isEmpty, isNil, isNumber, merge } from "lodash";
import * as React from "react";

import { ChartAnnotationOptions } from "../../charting/chart_data/line_diagram_annotation_builder";
import { LineChartStatistics } from "../../charting/plotly_line_chart";
import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";
import { EventPatternJSONAPIAttributes } from "../../json_api/event_pattern";
import { ModelErrors } from "../../json_api/jsonapi_tools";
import { StateJSONObject } from "../../json_api/state";
import { StateContextJSONObject } from "../../json_api/state_contexts";
import { EventPatternAttributes } from "../../models/event_pattern";
import { time_serie_sensor_path } from "../../routes";
import { IDType } from "../../utils/urls/url_utils";
import { TimeDurationInput } from "../common/time_duration_input";
import { AssetStateSelectionDisplay } from "../states/asset_state_selection_display";
import { LineDiagramWidget } from "../widgets/line_diagram_widget";
import { EventPatternCommonFormFields } from "./event_pattern_common_form_fields";
import { EventPatternConditionFields } from "./event_pattern_condition_fields";
import { EventPatternStateConditionFields } from "./event_pattern_state_condition_fields";
interface SensorEventPatternTypeSelectProps {
  eventPattern: EventPatternJSONAPIAttributes;
  errors?: ModelErrors<EventPatternAttributes>;
  onPatternUpdate(newType: EventPatternJSONAPIAttributes): void;
  readonly?: boolean;
}

export const SensorEventPatternForm: React.FunctionComponent<
  SensorEventPatternTypeSelectProps
> = (props) => {
  const [selectedState, setSelectedState] = React.useState<{
    assetId: IDType;
    csm: ContextStateMachineJSONObject;
    context: StateContextJSONObject;
    state: StateJSONObject;
  }>(null);

  const [chartAnnotationOptions, setChartAnnotationOptions] =
    React.useState<ChartAnnotationOptions>(null);
  const [chartStatistics, setChartStatistics] =
    React.useState<LineChartStatistics>(null);
  React.useEffect(() => {
    if (isNil(props.eventPattern)) return;
    const showMinMax =
      (props.eventPattern.pattern_type == "EventPatterns::SensorEventPattern" &&
        isNumber(props.eventPattern.min) &&
        !isNaN(props.eventPattern.min) &&
        isNumber(props.eventPattern.max) &&
        !isNaN(props.eventPattern.max)) ||
      (isNumber(props.eventPattern.value) && !isNaN(props.eventPattern.value));

    const newAnnotationOptions: ChartAnnotationOptions = {
      ...(chartAnnotationOptions || {}),
      showMinMax: showMinMax,
      min: props.eventPattern.min,
      max: props.eventPattern.max,
      value: props.eventPattern.value,

      annotationMode: props.eventPattern.comparator,
    };
    setChartAnnotationOptions(newAnnotationOptions);
  }, [props.eventPattern]);

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <TextField
          size="small"
          required
          fullWidth
          helperText={
            isEmpty(props.errors?.name)
              ? I18n.t("frontend.event_patterns.form.name_help")
              : props.errors?.name
          }
          title={I18n.t(
            "activerecord.attributes.event_patterns/event_pattern.name",
          )}
          error={!isEmpty(props.errors.name)}
          InputProps={{ readOnly: props.readonly }}
          value={props.eventPattern.name}
          label={I18n.t(
            "activerecord.attributes.event_patterns/event_pattern.name",
          )}
          onChange={(e) =>
            props.onPatternUpdate({
              ...props.eventPattern,
              name: e.currentTarget.value,
            })
          }
        />
      </Grid>

      <Grid item container xs={12} lg={4} spacing={2}>
        <EventPatternCommonFormFields
          eventPattern={props.eventPattern}
          errors={props.errors}
          onPatternUpdate={props.onPatternUpdate}
          readonly={props.readonly}
        />
        <Grid item xs={12}>
          <Paper>
            <Box m={1} p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {I18n.t(
                      "frontend.event_patterns.form.trigger_conditions_header",
                    )}
                  </Typography>

                  <Typography variant="subtitle2">
                    {I18n.t("frontend.event_patterns.form.value_trigger")}
                  </Typography>
                  <Box marginY={{ xs: 2 }}>
                    <EventPatternConditionFields
                      readonly={props.readonly}
                      eventPattern={props.eventPattern}
                      errors={props.errors}
                      onComparatorSelect={(comparator, changes) => {
                        const newPattern = merge(
                          { ...props.eventPattern, comparator },
                          changes,
                        );
                        props.onPatternUpdate(newPattern);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {I18n.t("frontend.event_patterns.form.state_trigger")}
                  </Typography>
                  {props.readonly ? (
                    <AssetStateSelectionDisplay
                      selectedContextStateMachine={null}
                      selectedContextStateMachineId={
                        props.eventPattern.context_state_machine_id
                      }
                      selectedStateId={props.eventPattern.state_id}
                    />
                  ) : null}
                  {props.readonly ? null : (
                    <EventPatternStateConditionFields
                      selectedContextStateMachingeId={
                        isNil(selectedState?.csm)
                          ? props.eventPattern.context_state_machine_id
                          : selectedState.csm.id
                      }
                      selectedStateId={
                        isNil(selectedState?.state)
                          ? props.eventPattern.state_id
                          : selectedState.state.id
                      }
                      errors={props.errors}
                      assetId={props.eventPattern.asset_id}
                      onStateSelect={(state, context, csm) => {
                        setSelectedState({
                          assetId: csm?.stateful_item_id,
                          state,
                          context,
                          csm,
                        });
                        props.onPatternUpdate({
                          ...props.eventPattern,
                          state_id: state?.id,
                          context_state_machine_id: csm?.id,
                        });
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid item container xs={12} lg={8} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box marginTop={{ xs: 1, lg: 0 }} padding={{ xs: 2 }}>
              <LineDiagramWidget
                dataUrls={[
                  time_serie_sensor_path(props.eventPattern.sensor_id, {
                    id: props.eventPattern.sensor_id,
                    format: "bin",
                    _options: true,
                  }),
                ]}
                sensorIds={[props.eventPattern.sensor_id]}
                chartAnnotationOptions={chartAnnotationOptions}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box m={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {I18n.t("frontend.event_patterns.form.timing_header")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TimeDurationInput
                  readonly={props.readonly}
                  value={props.eventPattern.time_threshold}
                  title={I18n.t(
                    "activerecord.attributes.event_patterns/event_pattern.time_threshold",
                  )}
                  onValueUpdate={(newDuration) =>
                    props.onPatternUpdate({
                      ...props.eventPattern,
                      time_threshold: newDuration?.toISOString(),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TimeDurationInput
                  readonly={props.readonly}
                  title={I18n.t(
                    "activerecord.attributes.event_patterns/event_pattern.cool_down_time",
                  )}
                  value={props.eventPattern.cool_down_time}
                  onValueUpdate={(newDuration) =>
                    props.onPatternUpdate({
                      ...props.eventPattern,
                      cool_down_time: newDuration?.toISOString(),
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1">
                  {I18n.t(
                    "frontend.event_patterns.form.notification_settings_header",
                  )}
                </Typography>
                <FormControl fullWidth size="small">
                  <FormControlLabel
                    label={I18n.t(
                      "activerecord.attributes.event_patterns/event_pattern.enabled_repeated_notification",
                    )}
                    control={
                      <Checkbox
                        size="small"
                        disabled={props.readonly}
                        readOnly={props.readonly}
                        name="enable_repeated"
                        checked={defaultTo(
                          props.eventPattern.enabled_repeated_notification,
                          false,
                        )}
                        onChange={(event) => {
                          props.onPatternUpdate({
                            ...props.eventPattern,
                            enabled_repeated_notification: event.target.checked,
                          });
                        }}
                      />
                    }
                  />
                  <FormHelperText>
                    {I18n.t(
                      "frontend.event_patterns.form.help_repeated_notification",
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {props.eventPattern.enabled_repeated_notification ? (
                <Grid item xs={12}>
                  <TimeDurationInput
                    title={I18n.t(
                      "activerecord.attributes.event_patterns/event_pattern.repeated_notification_after",
                    )}
                    readonly={props.readonly}
                    value={props.eventPattern.repeated_notification_after}
                    onValueUpdate={(newDuration) =>
                      props.onPatternUpdate({
                        ...props.eventPattern,
                        repeated_notification_after: newDuration?.toISOString(),
                      })
                    }
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
