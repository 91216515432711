import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputLabel,
  Link,
} from "@mui/material";
import { findIndex } from "lodash";
import * as React from "react";

import { PrettyCheckbox } from "../../common/pretty_checkbox";
import { SensorGroup, SensorWithSelection } from "../data/model";

export interface SensorListProps {
  sensorGroup: SensorGroup;
  onChange(sensorGroup: SensorGroup): void;
  onDelete(sensor: SensorWithSelection): void;
}

export class SensorList extends React.Component<SensorListProps> {
  render(): React.ReactNode {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                this.props.sensorGroup.title_link ? (
                  <Link href={this.props.sensorGroup.title_link}></Link>
                ) : (
                  this.props.sensorGroup.title
                )
              }
            />

            <CardContent>
              <Grid container>
                <Grid item xs={1}>
                  <InputLabel shrink>{I18n.t("frontend.selected")}</InputLabel>
                </Grid>
                <Grid xs={10}>
                  <InputLabel shrink>
                    {I18n.t("activerecord.models.sensor", { count: 1 })}
                  </InputLabel>
                </Grid>
                <Grid xs={1}>
                  <InputLabel shrink>{I18n.t("frontend.actions")}</InputLabel>
                </Grid>
                {this.props.sensorGroup.sensors.map((sensor) => {
                  return this.renderSensorListEntry(sensor);
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  renderSensorListEntry(sensor: SensorWithSelection) {
    return (
      <Grid item container xs={12} key={`sensor-${sensor.id}`}>
        <Grid item xs={1}>
          <PrettyCheckbox
            type="checkbox"
            checkboxStyle="p-round"
            checked={sensor.selected ?? false}
            value="true"
            onChange={(event) => this.onSelectSensor(sensor, event)}
          />
        </Grid>
        <Grid item xs={10}>
          {sensor.name}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="small"
            aria-label={I18n.t("frontend.delete")}
            title={I18n.t("frontend.delete")}
            onClick={() => this.props.onDelete(sensor)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  private onSelectSensor(
    sensor: SensorWithSelection,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    // Create new sensor group with updated sensor
    const sensorGroup: SensorGroup = {
      ...this.props.sensorGroup,
      sensors: [...this.props.sensorGroup.sensors],
    };
    const newSensor: SensorWithSelection = {
      ...sensor,
      selected: event.target.checked,
    };

    const sensorIndex = findIndex(
      sensorGroup.sensors,
      (s) => s.id === sensor.id,
    );
    if (sensorIndex >= 0) {
      sensorGroup.sensors[sensorIndex] = newSensor;
    }

    this.props.onChange(sensorGroup);
  }
}
