import { each, map } from "lodash";
import { Moment } from "moment";
import * as React from "react";

import * as url_helper from "../utils/urls";
import Widget from "./widget";

import { ChartAnnotationOptions } from "../charting/chart_data/line_diagram_annotation_builder";
import { AppRoot } from "../components/common/app_root";
import { LineDiagramWidget as LineDiagramWidgetComponent } from "../components/widgets/line_diagram_widget";
import { SensorSamplingRateUnit } from "../models/sensor";
import { LineDiagramWidgetConfigSerialized } from "./line_diagram_widget.types";

/**
 * Widget class for updating line diagram widgets.
 * Reads the following data attributes from the root element id provided in the constructor:
 * data-sensor-ids: comma separated list of sensor ids to be displayed
 * data-asset-ids: ids of the referenced asset. Needed for url compositio. must match with the sensor id array
 * data-kpi-ids: comma separated list of kpi ids to be displayed
 * data-min-time: minimum of time range to show in the diagram as ISO 8601 String
 * data-max-time: maximum of time range to show in the diagram as ISO 8601 String
 * data-sampling-rate-unit: unit of the sampling rate to request from min, hour, day, week, month
 * data-sampling-rate-value: integer value of the sampling rate to request from server
 * data-unify-unit-axes: if === 'true' if multiple axis share the same unit, the values will share one axis.
 * data-offset-y-axis: if === 'true' the y axis will start a 0 no matter what the values are, defauts to false
 * data-show-trend if 'true' trendlines will be fetched
 * @class LineDiagramWidget
 * @extends {Widget}
 */
export default class LineDiagramWidget extends Widget {
  config: LineDiagramWidgetConfigSerialized;
  private chartAnnotationOptions?: Partial<ChartAnnotationOptions>;

  static getDomClassName(): string {
    return "line-diagram-widget";
  }

  protected constructor(widgetElement: JQuery) {
    super(widgetElement);
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);
    const props = LineDiagramWidgetComponent.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <LineDiagramWidgetComponent
          {...props}
          encloseInIBox={true}
          chartAnnotationOptions={this.chartAnnotationOptions}
        />
      </AppRoot>,
    );
  }

  protected postInitialize(): void {
    super.postInitialize();
  }
  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    const config = element.data("config") as LineDiagramWidgetConfigSerialized;
    this.config = config;
  }
  /**
   * @override
   */
  getSensorIdsForUpdate(): number[] {
    return [];
  }

  /**
   * @override
   */
  setTimeScope(start: Moment, end: Moment): Promise<void> {
    this.config.time_range = {
      start: start.toISOString(),
      end: end.toISOString(),
    };
    this.initComponent(this.rootElement);
    return;
  }

  /**
   * @override
   */
  setSamplingRate(value: number, unit: SensorSamplingRateUnit): Promise<void> {
    this.config.sampling_rate.value = value;
    this.config.sampling_rate.unit = unit;
    this.initComponent(this.rootElement);
    return;
  }

  /**
   * @override
   */
  setBeginAtZero(beginAtZero: boolean): Promise<any> {
    this.config.offset_y_axis = !beginAtZero;
    this.initComponent(this.rootElement);

    return;
  }

  /**
   * Set options for chart annotations (min, max and trend lines) and updates chart.
   * @param option The chart options to set
   */
  setChartAnnotationOptions(
    options: Partial<ChartAnnotationOptions>,
  ): Promise<any> {
    this.chartAnnotationOptions = options;
    this.initComponent(this.rootElement);
    return;
  }

  /**
   * @override
   */
  cleanup(): void {
    super.cleanup();
  }
}

export function buildLineDiagramDataUrls(
  sensorIds: number[],
  assetIds: number[],
  kpiIds: string[],
): string[] {
  const dataUrls: string[] = [];

  each(sensorIds, (sensorId, index) => {
    dataUrls.push(
      url_helper.sensorDataUrl(
        { id: sensorId, assetId: assetIds[index] },
        "bin",
      ),
    );
  });

  each(kpiIds, (kpiId) => {
    dataUrls.push(url_helper.kpiDataUrl({ id: kpiId }, "bin"));
  });
  return dataUrls;
}

export function buildLineDiagramTrendUrls(
  sensorIds: number[],
  assetIds: number[],
): string[] {
  return map(sensorIds, (id, index) => {
    return url_helper.sensorTrendUrl({
      id: id,
      assetId: assetIds[index],
    });
  });
}

export function buildLineDiagramAnnotationUrls(
  sensorIds: number[],
  assetIds: number[],
): string[] {
  return map(sensorIds, (id, index) => {
    return url_helper.sensorAnnotationsUrl({
      id: id,
      assetId: assetIds[index],
    });
  });
}
