import { Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { defaultTo, isEmpty, isNil, map } from "lodash";
import * as React from "react";

import { AssetJSONObject } from "../../json_api/asset";
import { EventSeverityLevel } from "../../models/event_notification";
import { IDType } from "../../utils/urls/url_utils";
import { AssetEventTypeSelect } from "../asset_event_types/asset_event_type_select";
import { AssetsAutocomplete } from "../assets/assets_autocomplete";
import { MaterialUiDatePicker } from "../common/date_picker";
import { EventSeveritySelect } from "./event_severity_selection";

export interface AssetEventFilter {
  typeId: IDType;
  assetIds: IDType[];
  from: Date;
  to: Date;
  search?: string;
  severity_level?: EventSeverityLevel;
}
export interface AssetEventFilterProps {
  assetId: IDType;
  filter: AssetEventFilter;
  selectableAssets: AssetJSONObject[];
  useAssetAutocomplete?: boolean;
  onFilterChange: (filter: AssetEventFilter) => void;
}

export const AssetEventFilter: React.FunctionComponent<
  AssetEventFilterProps
> = (props) => {
  const [searchText, setSearchText] = React.useState<string>(
    props.filter.search,
  );

  const [assetFromAutocomplete, setAssetFromAutocomplete] =
    React.useState(null);

  React.useEffect(() => {
    props.onFilterChange({
      ...props.filter,
      assetIds: isNil(assetFromAutocomplete)
        ? null
        : [assetFromAutocomplete.id],
    });
  }, [assetFromAutocomplete]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={3} xl={3}>
        {isNil(props.selectableAssets) && !props.useAssetAutocomplete ? (
          <Skeleton variant="rectangular" height={50} />
        ) : isEmpty(props.selectableAssets) ? (
          props.useAssetAutocomplete ? (
            <AssetsAutocomplete
              size="small"
              width="100%"
              label={I18n.t(
                "frontend.asset_events.asset_event_filter.asset_autocomplete_label",
              )}
              onSelect={(a) => {
                setAssetFromAutocomplete(a);
              }}
              helperText={I18n.t(
                "frontend.asset_events.asset_event_filter.asset_filter_helper_text",
              )}
            />
          ) : null
        ) : (
          <Autocomplete<AssetJSONObject | null, true>
            options={props.selectableAssets}
            multiple
            getOptionLabel={(o) => o.name}
            getOptionKey={(o) => o.id}
            size="small"
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                label={I18n.t(
                  "frontend.asset_events.asset_event_filter.assets",
                  {
                    count: 2,
                  },
                )}
                helperText={I18n.t(
                  "frontend.asset_events.asset_event_filter.asset_filter_helper_text",
                )}
              />
            )}
            onChange={(event, assets) => {
              if (isEmpty(assets)) {
                props.onFilterChange({
                  ...props.filter,
                  assetIds: map(props.selectableAssets, (a) => a.id),
                });
              } else {
                props.onFilterChange({
                  ...props.filter,
                  assetIds: map(assets as AssetJSONObject[], (a) => a.id),
                });
              }
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={3} xl={2}>
        <AssetEventTypeSelect
          selectedTypeId={props.filter.typeId}
          assetId={defaultTo(props.assetId, assetFromAutocomplete?.id)}
          includeSystemEventsOnLoad
          helperText={I18n.t(
            "frontend.asset_events.asset_event_filter.asset_event_type_filter_helper_text",
          )}
          onTypeSelect={(type) => {
            props.onFilterChange({ ...props.filter, typeId: type?.id });
          }}
        />
      </Grid>

      <Grid item xs={12} lg={3} xl={2}>
        <EventSeveritySelect
          required={false}
          severity={props.filter.severity_level}
          onSeveritySelect={(es) =>
            props.onFilterChange({ ...props.filter, severity_level: es })
          }
        />
      </Grid>
      <Grid item xs={12} lg={3} xl={2}>
        <TextField
          fullWidth
          size="small"
          label={I18n.t("frontend.asset_events.asset_event_list.search_text")}
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key == "Enter") {
              e.stopPropagation();
              props.onFilterChange({
                ...props.filter,
                search: searchText,
              });
            }
          }}
          helperText={I18n.t(
            "frontend.asset_events.asset_event_filter.search_text_filter_helper_text",
          )}
          value={defaultTo(searchText, "")}
          InputProps={{
            endAdornment: isEmpty(searchText) ? null : (
              <>
                <Tooltip title={I18n.t("frontend.search")}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onFilterChange({
                        ...props.filter,
                        search: searchText,
                      });
                    }}
                  >
                    <Search fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={I18n.t("frontend.reset_search")}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchText(null);
                      props.onFilterChange({
                        ...props.filter,
                        search: null,
                      });
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
        />
      </Grid>
      <Grid item container xs={12} xl={3} spacing={2}>
        <Grid item xs={6}>
          <MaterialUiDatePicker
            type="datetime"
            noValueLabel={"---"}
            value={isNil(props.filter.from) ? null : moment(props.filter.from)}
            dateFormat="L LT"
            autoApply
            onChange={(date) => {
              props.onFilterChange({ ...props.filter, from: date?.toDate() });
            }}
            maxDate={isNil(props.filter.to) ? null : moment(props.filter.to)}
            label={I18n.t("frontend.from")}
            helperText={I18n.t(
              "frontend.asset_events.asset_event_filter.from_helper_text",
            )}
            endAdornment={
              <IconButton
                size="small"
                onClick={() => {
                  props.onFilterChange({ ...props.filter, from: null });
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <MaterialUiDatePicker
            dateFormat="L LT"
            noValueLabel={"---"}
            type="datetime"
            value={isNil(props.filter.to) ? null : moment(props.filter.to)}
            label={I18n.t("frontend.to")}
            helperText={I18n.t(
              "frontend.asset_events.asset_event_filter.to_helper_text",
            )}
            minDate={
              isNil(props.filter.from) ? null : moment(props.filter.from)
            }
            onChange={(date) => {
              props.onFilterChange({ ...props.filter, to: date?.toDate() });
            }}
            endAdornment={
              <IconButton
                size="small"
                onClick={() => {
                  props.onFilterChange({ ...props.filter, to: null });
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            size="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
