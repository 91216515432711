/********
 * SENSORS
 *******/

import { defaultTo, isArray, isEmpty, isNil, omit, toNumber } from "lodash";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";

import {
  api_asset_sensor_path,
  api_asset_sensors_path,
  api_sensor_path,
  api_sensors_path,
  asset_sensor_path,
  asset_sensors_path,
  last_value_api_sensor_path,
  search_api_asset_sensors_path,
  search_api_sensors_path,
} from "../../routes";
import { logger } from "../logger";
import {
  IDType,
  ParamsType,
  RequestFormat,
  applyParamsToBaseUrl,
  formatString,
  localizedBasePath,
  objectToParams,
} from "./url_utils";
import { Moment } from "moment";

export type SensorIncludes =
  | "asset"
  | "attribute_key"
  | "value_ranges"
  | "event_patterns"
  | "sensor_type";

export function sensorApiUrl(sensorIds: IDType | Array<IDType>): string {
  if (isNil(sensorIds) || (!isArray(sensorIds) && isNaN(toNumber(sensorIds)))) {
    throw new Error("Invalid sensor id requested");
  }
  const sensorIdArray = isArray(sensorIds) ? sensorIds : [sensorIds];
  if (isEmpty(sensorIdArray)) {
    throw new Error("Empty sensor id array provided for sensorApiUrl");
  }

  return api_sensors_path({
    _options: true,
    locale: I18n.locale,
    format: "json",
    filter: {
      id: sensorIdArray.join(","),
    },
  });
}

export function sensorsJsonApiSearchPath(
  searchTerm: string,
  assetId: IDType = null,
  pageNumber: number,
  pageSize: number,
  include: SensorIncludes[] = [],
  params: ParamsType = [],
): string {
  const url = isEmpty(assetId)
    ? search_api_sensors_path({
        _options: true,
        locale: I18n.locale,
        format: "json",
      })
    : search_api_asset_sensors_path(assetId, {
        _options: true,
        locale: I18n.locale,
        format: "json",
      });
  return applyParamsToBaseUrl(
    url,
    pageNumber,
    pageSize,
    include,
    params.concat([["search", searchTerm]]),
  );
}

export interface SensorAttributes {
  id?: number | string;
  assetId?: number | string;
}

export function sensorDataUrl(
  sensor: SensorAttributes,
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/assets/${sensor.assetId}/sensors/${
    sensor.id
  }/time_series${formatString(format)}`;
}

export function sensorTrendUrl(sensor: SensorAttributes): string {
  return `${localizedBasePath()}/assets/${sensor.assetId}/sensors/${
    sensor.id
  }/value_trend`;
}

export function sensorAnnotationsUrl(sensor: SensorAttributes): string {
  return `${localizedBasePath()}/assets/${sensor.assetId}/sensors/${
    sensor.id
  }/annotations`;
}

export function sensorUrl(
  sensor: SensorAttributes,
  format: RequestFormat = "json",
): string {
  if (isNil(sensor)) return null;

  const assetId = defaultTo(
    sensor?.assetId,
    (sensor as SensorJSONAPIAttributes)?.asset_id,
  );
  if (assetId) {
    return asset_sensor_path(assetId, sensor.id, {
      _options: true,
      format,
      asset_id: assetId,
      id: sensor.id,
    });
  } else {
    if (format == "json") {
      return api_sensor_path(sensor.id);
    } else {
      logger.warn("requested sensor path without asset for non api url");
    }
  }
}

export function assetSensorsUrl(
  assetId: number,
  format: RequestFormat = "html",
) {
  if (format === "json") {
    return api_asset_sensors_path(assetId);
  } else {
    return asset_sensors_path(assetId);
  }
}

export function assetSensorUrl(
  assetId: IDType,
  sensorId: IDType,
  format: RequestFormat = "json",
): string {
  if (assetId) {
    return `${localizedBasePath(
      format === "json",
    )}/assets/${assetId}/sensors/${sensorId}${formatString(format)}`;
  } else {
    return `${localizedBasePath(
      format === "json",
    )}/sensors/${sensorId}${formatString(format)}`;
  }
}

export interface SensorLastValueWithinParams {
  id: IDType;
  min_time?: Moment;
  max_time?: Moment;
  fallback_to_last?: boolean;
}

export function jsonApiSensorsPath(assetId?: IDType): string {
  if (assetId) {
    return `${localizedBasePath(true)}/assets/${assetId}/sensors`;
  } else {
    return `${localizedBasePath(true)}/sensors`;
  }
}

export function jsonApiSensorPath(
  opts: {
    assetId?: IDType;
    sensorId: IDType;
  },
  includes?: string[],
): string {
  let url = null;
  if (opts.assetId) {
    url = api_asset_sensor_path(opts.assetId, opts.sensorId);
  } else {
    url = api_sensor_path(opts.sensorId);
  }
  return applyParamsToBaseUrl(url, null, null, includes);
}

export function manageSensorDataUrl(assetId: IDType): string {
  return `${localizedBasePath()}/assets/${assetId}/admin/manage_sensor_data`;
}

export function manageSensorRestoreDataUrl(assetId: IDType): string {
  return `${localizedBasePath()}/assets/${assetId}/admin/manage_sensor_data/restore`;
}

export function assignAssetSensorJsonApiPath(
  sensorId: IDType,
  assetId: IDType,
) {
  return `${localizedBasePath(
    true,
  )}/sensors/${sensorId}/assign_asset/${assetId}`;
}
