import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isNil, toInteger } from "lodash";

import * as React from "react";
import { Root, createRoot } from "react-dom/client";
import { AssetJSONObject } from "../../json_api/asset";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { AppRoot } from "../common/app_root";
import { AssetForm, AssetFormMode, AssetPermissions } from "./asset_form";
import { AssetsAutocomplete } from "./assets_autocomplete";
import { AssetHome } from "./asset_home";

const assetAutocompleteRoots: Root[] = [];
/**
 * Initialize react component AssetsAutocomplete within all elements with data-toggle="assets-autocomplete".
 *
 */
export function initializeAssetsAutocomplete(): void {
  $('[data-toggle="assets-autocomplete"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const assetJsonApi = $(element).data("asset") as SingleResourceDoc<
      string,
      AssetJSONObject
    >;

    const asset =
      jsonApiSingleResourceToFlatObject<AssetJSONObject>(assetJsonApi);
    const assetsJsonApi = $(element).data("assets") as CollectionResourceDoc<
      string,
      AssetJSONObject
    >;
    const assets = isNil(assetsJsonApi)
      ? null
      : jsonApiResourceCollectionToFlatObjects<AssetJSONObject>(assetsJsonApi);
    const url = $(element).data("assets-base-url") as string;
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <AssetsAutocomplete assetsBaseUrl={url} assets={assets} asset={asset} />
      </AppRoot>,
    );
    assetAutocompleteRoots.push(root);
  });
}

/**
 * Remove react component from all elements
 */
export function destroyAssetsAutocomplete(): void {
  assetAutocompleteRoots.forEach((root) => root.unmount());
  assetAutocompleteRoots.length = 0;
}

const assetFormRoots: Root[] = [];
/****************** Sensor Form */

export function initializeAssetForm(): void {
  $('[data-toggle="asset-form"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const jqElement = $(element);
    const assetId = jqElement.data("asset-id") as string;
    const parentId = jqElement.data("parent-asset-id") as string;
    const parentAssetTypeId = jqElement.data("asset-type-id") as string;
    const assetTypeId = jqElement.data("asset-type-id") as string;
    const permissions = jqElement.data("permissions") as AssetPermissions;

    const mode = jqElement.data("mode") as AssetFormMode;
    const root = createRoot(element);
    assetFormRoots.push(root);
    root.render(
      <AppRoot>
        <AssetForm
          assetId={isNil(assetId) ? null : toInteger(assetId)}
          parentAssetId={isNil(parentId) ? null : toInteger(parentId)}
          assetTypeId={isNil(assetTypeId) ? null : toInteger(assetTypeId)}
          parentAssetTypeId={
            isNil(parentAssetTypeId) ? null : toInteger(parentAssetTypeId)
          }
          permssions={permissions}
          mode={mode}
          buttonPosition={"bottom"}
        />
        ,
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyAssetForm(): void {
  assetFormRoots.forEach((root) => root.unmount());
  assetFormRoots.length = 0;
}

const assetHomeRoots: Root[] = [];
/****************** Sensor Form */

export function initializeAssetHome(): void {
  $('[data-toggle="asset-home"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const jqElement = $(element);
    const assetId = jqElement.data("asset-id") as string;
    const mapUrl = jqElement.data("map-url") as string;
    const mapAttribution = jqElement.data("map-attribution") as string;
    const root = createRoot(element);
    assetHomeRoots.push(root);
    root.render(
      <AppRoot>
        <AssetHome
          assetId={isNil(assetId) ? null : toInteger(assetId)}
          map={{ url: mapUrl, attribution: mapAttribution }}
        />
        ,
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyAssetHome(): void {
  assetHomeRoots.forEach((root) => root.unmount());
  assetHomeRoots.length = 0;
}
