import { isEmpty } from "lodash";
import {
  asset_asset_event_path,
  asset_asset_events_path,
  asset_events_path,
} from "../../routes";
import {
  IDType,
  RequestFormat,
  formatString,
  localizedBasePath,
} from "./url_utils";

export function assetEventsPath(assetId?: IDType): string {
  if (assetId) {
    return asset_asset_events_path(assetId);
  } else {
    return asset_events_path();
  }
}

export function assetEventPath(assetId: IDType, eventId: IDType): string {
  return asset_asset_event_path(assetId, eventId);
}

export function assetEventsNotificationsPath(
  assetId: IDType,
  format: RequestFormat = "json",
): string {
  if (!isEmpty(assetId)) {
    return `${localizedBasePath()}/assets/${assetId}/asset_events/notifications${formatString(
      format,
    )}`;
  } else {
    return `${localizedBasePath()}/asset_events/notifications${formatString(
      format,
    )}`;
  }
}

export function assetEventsConfirmReadPath(
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/asset_events/confirm_read${formatString(
    format,
  )}`;
}
