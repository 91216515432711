import { Box, Grid, TextField } from "@mui/material";
import * as React from "react";
import { Location } from "../../models/location";
import { LocationPicker } from "../location_picker";

import { defaultTo, toNumber } from "lodash";
interface LocationFormProps {
  location: Location;
  disabled?: boolean;
  onLocationChange(newLocation: Location): void;
}

export const LocationForm: React.FunctionComponent<LocationFormProps> = (
  props,
) => {
  return (
    <Grid container spacing={2}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs spacing={2}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth={true}
                    autoComplete="off"
                    disabled={props.disabled}
                    label={I18n.t("activerecord.attributes.location.lat")}
                    value={defaultTo(props.location?.lat, "")}
                    inputProps={{ type: "number", min: -180, max: 180 }}
                    onChange={(el) => {
                      el.stopPropagation();
                      props.onLocationChange({
                        ...defaultTo(props.location, {}),
                        lat: toNumber(el.target.value),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.lon")}
                    value={defaultTo(props.location?.lon, "")}
                    inputProps={{ type: "number", min: -180, max: 180 }}
                    onChange={(el) => {
                      el.stopPropagation();
                      props.onLocationChange({
                        ...defaultTo(props.location, {}),
                        lon: toNumber(el.target.value),
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.zip")}
                    value={defaultTo(props.location?.zip, "")}
                    onChange={(el) => {
                      el.stopPropagation();
                      props.onLocationChange({
                        ...defaultTo(props.location, {}),
                        zip: el.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.city")}
                    value={defaultTo(props.location?.city, "")}
                    onChange={(el) => {
                      props.onLocationChange({
                        ...defaultTo(props.location, {}),
                        city: el.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  disabled={props.disabled}
                  autoComplete="off"
                  label={I18n.t("activerecord.attributes.location.street")}
                  value={defaultTo(props.location?.street, "")}
                  onChange={(el) => {
                    el.stopPropagation();
                    props.onLocationChange({
                      ...defaultTo(props.location, {}),
                      street: el.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.level")}
                    value={defaultTo(props.location?.level, "")}
                    onChange={(el) => {
                      el.stopPropagation();
                      props.onLocationChange({
                        ...defaultTo(props.location, {}),
                        level: el.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.room")}
                    value={defaultTo(props.location?.room, "")}
                    onChange={(el) => {
                      el.stopPropagation();
                      props.onLocationChange({
                        ...defaultTo(props.location, {}),
                        room: el.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocationPicker
            saveLocationOnChange={false}
            location={props.location}
            onLocationSelected={(newLocation) => {
              props.onLocationChange(newLocation);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
