import { merge } from "lodash";
import * as React from "react";
import { CommentWidgetConfigSerialized } from "../../widgets/comment_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { CommentThread } from "../comments/comment_thread";
import { CommentWidgetProps } from "./comment_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";

export const CommentWidget: React.FunctionComponent<CommentWidgetProps> = (
  props,
) => {
  const content = <CommentThread {...props} />;

  return <WidgetBox {...props}>{content}</WidgetBox>;
};

function serializedConfigToProps(
  config: CommentWidgetConfigSerialized,
): CommentWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    itemType: config.item_type,
    itemId: config.item_id,
    tags: config.tags,
    sort: config.sort,
    showFilter: config.show_filter,
    limit: config.limit,
  });
}

export const CommentWidgetDefinition: SialogicWidgetDefinition = {
  component: CommentWidget,
  serializedConfigToProps: serializedConfigToProps,
};
