import { Add, Close, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { defaultTo, isEmpty, isNil, merge, times } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { useTheme } from "@mui/material/styles";

import { useQuery } from "@tanstack/react-query";
import { Moment } from "moment";
import { WidgetController } from "../../controller/widget_controller";
import { AssetJSONObject, loadAsset } from "../../json_api/asset";
import {
  AssetEventJSONObject,
  AssetEventJsonApiFilter,
} from "../../json_api/asset_event";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiFilterParamsArgumentsFromFilterObject,
  metaDataFromJsonApiCollectionResourceDoc,
} from "../../json_api/jsonapi_tools";
import { EventNotification } from "../../models/event_notification";
import { api_asset_events_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { ExtensiblePageSettings } from "../common/page_size";
import { PageSizeSelect } from "../common/page_size_select";
import { AssetEventDetails } from "./asset_event_details";
import { AssetEventFilter } from "./asset_event_filter";
import { AssetEventFormDialog } from "./asset_event_form_dialog";
import { AssetEventItem } from "./asset_event_item";
export interface AssetEventListProps {
  assetId: IDType;
  asset?: AssetJSONObject;
  withCard?: boolean;

  onBack?: () => void;
}

export const AssetEventList: FunctionComponent<AssetEventListProps> = (
  props,
) => {
  const [asset, setAsset] = React.useState(props.asset);
  const [selectableAssets, setSelectableAssets] =
    React.useState<Array<AssetJSONObject | null>>(null);
  const [pageSettings, setPageSettings] = React.useState<
    ExtensiblePageSettings & { totalPages: number; totalRecords: number }
  >({
    number: 1,
    size: 10,
    totalPages: null,
    totalRecords: null,
  });

  const [filter, setFilter] = React.useState<AssetEventFilter>({
    typeId: null,
    assetIds: [props.assetId],
    from: null,
    to: null,
    search: null,
  });

  const [showDetailEvent, setShowDetailEvent] =
    React.useState<AssetEventJSONObject>(null);
  const [loading, setLoading] = React.useState(false);

  const [reloadData, setReloadData] = React.useState(0);

  // an effect to reload the event list when a new event for the asset was created
  React.useEffect(() => {
    const listener = {
      handleNewEvent: (
        event: EventNotification,
        time: Moment,
        assetId: number,
        eventId: number,
      ) => {
        if (pageSettings.number == 1) {
          // if first page is visible reload to make the event appear
          setReloadData(reloadData + 1);
        }
      },
    };
    WidgetController.getInstance().assetNotifitcationChannel.addEventListener(
      listener,
      props.asset?.id as number,
    );
    return () => {
      WidgetController.getInstance().assetNotifitcationChannel.removeEventListener(
        listener,
      );
    };
  }, []);

  React.useEffect(() => {
    if (asset?.subtree) {
      filter.assetIds = asset.subtree_ids;
      // we have to create new object since there are circular dependencies in the result object from json api
      setSelectableAssets(
        asset.subtree.map((a) => ({
          id: a.id,
          root: {
            id: asset.id,
            root_id: asset.id,
            name: asset?.name,
            asset_type_name: asset.asset_type_name,
            asset_type_id: asset.asset_type_id,
          },
          name: a.name,
          asset_type_name: a.asset_type_name,
        })),
      );
    }
  }, [asset]);

  const assetQuery = useQuery({
    queryKey: ["assetSubtree", props.assetId],
    queryFn: () => loadAsset(props.assetId, ["subtree"]),
    enabled: !isNil(props.assetId),
  });

  React.useEffect(() => {
    if (assetQuery.data) {
      setAsset(assetQuery.data);
    }
  }, [assetQuery.data]);

  const [eventsUrl, setEventsUrl] = React.useState<string>(null);

  const eventsQuery = useQuery({
    queryKey: [eventsUrl],
    queryFn: () =>
      loadDataFromUrl<CollectionResourceDoc<string, AssetEventJSONObject>>(
        eventsUrl,
      ).then((loadedEvents) => {
        const metaInfo = metaDataFromJsonApiCollectionResourceDoc(loadedEvents);
        setPageSettings({
          ...pageSettings,
          totalPages: metaInfo.page_count,
          totalRecords: metaInfo.record_count,
        });
        return {
          events: jsonApiResourceCollectionToFlatObjects(loadedEvents),
          totalPages: metaInfo.page_count,
          totalRecords: metaInfo.record_count,
        };
      }),
    enabled: !isNil(eventsUrl),
  });

  React.useEffect(() => {
    const ps = { ...pageSettings };
    if (eventsQuery.data?.totalPages) {
      ps.totalPages = eventsQuery.data?.totalPages;
    }
    if (eventsQuery.data?.totalRecords) {
      ps.totalRecords = eventsQuery.data?.totalRecords;
    }

    setPageSettings(ps);
  }, [eventsQuery.data?.totalPages, eventsQuery.data?.totalRecords]);
  React.useEffect(() => {
    setLoading(eventsQuery.isLoading || assetQuery.isLoading);
  }, [eventsQuery.isLoading, assetQuery.isLoading]);

  React.useEffect(() => {
    const theFilter = filter;
    if (props.assetId && isEmpty(filter.assetIds)) {
      // avoid loading all assets when an asset scope is requested and no asset filter is set (yet)
      return;
    }

    const url = api_asset_events_path(
      requestOptionsForFilter(filter, pageSettings),
    );
    setEventsUrl(url);
  }, [
    filter.typeId,
    filter.assetIds,
    filter.search,
    filter.from,
    filter.to,
    filter.severity_level,
    pageSettings.number,
    pageSettings.size,
  ]);

  const [assetEventFormOpen, setAssetEventFormOpen] = React.useState(false);
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={I18n.t("activerecord.models.asset_event", { count: 2 })}
            subheader={isNil(asset) ? null : asset.name}
            action={
              isNil(props.assetId) ? null : (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setAssetEventFormOpen(true);
                  }}
                  title={I18n.t(
                    "frontend.asset_events.asset_event_list.add_event",
                  )}
                >
                  <Add fontSize="inherit" />
                </IconButton>
              )
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AssetEventFilter
                  useAssetAutocomplete={isNil(props.assetId)}
                  selectableAssets={selectableAssets}
                  filter={filter}
                  onFilterChange={(f) => setFilter(f)}
                  assetId={asset?.id || props.assetId}
                />
              </Grid>

              {loading ? (
                <>
                  {times(pageSettings.size, (i) => (
                    <Grid item xs={12} key={i}>
                      <Skeleton variant="rectangular" height={60} />
                    </Grid>
                  ))}
                </>
              ) : isEmpty(eventsQuery.data?.events) ? (
                <Grid item xs={12} p={4}>
                  <Typography textAlign="center">
                    {I18n.t("frontend.asset_events.asset_event_list.no_events")}
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} p={4}>
                    <Typography variant="h6">
                      {I18n.t("activerecord.models.asset_event", {
                        count: 2,
                      })}
                    </Typography>
                  </Grid>

                  {eventsQuery.data?.events.map((e, index) => (
                    <AssetEventItem
                      key={index}
                      event={e}
                      onShowDetails={(e) => setShowDetailEvent(e)}
                    />
                  ))}
                </>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container spacing={4} mx={2} justifyContent="space-between">
              <Grid item xs={2}>
                <PageSizeSelect
                  pageSize={pageSettings.size}
                  onSelectPageSize={(newPageSize) => {
                    setPageSettings({ ...pageSettings, size: newPageSize });
                  }}
                />
              </Grid>
              <Grid item alignContent="center">
                <Pagination
                  size="small"
                  page={pageSettings.number}
                  count={defaultTo(pageSettings.totalPages, undefined)}
                  onChange={(p, newPage) => {
                    setPageSettings({ ...pageSettings, number: newPage });
                  }}
                />
              </Grid>
              {isNil(pageSettings.totalRecords) ? null : (
                <Grid item>
                  <Typography variant="caption">
                    {I18n.t("frontend.total")}: {pageSettings.totalRecords}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardActions>
        </Card>
        {isNil(showDetailEvent) ? null : (
          <Dialog
            open={!isNil(showDetailEvent)}
            fullScreen={dialogFullScreen}
            onClose={() => {
              setShowDetailEvent(null);
            }}
          >
            <DialogTitle>
              {I18n.t(
                "frontend.asset_events.asset_event_list.asset_details_heading",
              )}
              <Box displayPrint="none" position="absolute" right={8} top={8}>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setShowDetailEvent(null);
                  }}
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                  size="large"
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent style={{ display: "flex" }}>
              <AssetEventDetails assetEvent={showDetailEvent} />
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                onClick={() => {
                  setShowDetailEvent(null);
                }}
              >
                {I18n.t("frontend.close")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <AssetEventFormDialog
          assetId={asset?.id ?? props.assetId}
          asset={asset}
          selectableAssets={selectableAssets}
          open={assetEventFormOpen}
          onClose={() => setAssetEventFormOpen(false)}
          onSave={(event) => {
            setReloadData(reloadData + 1);
            setAssetEventFormOpen(false);
          }}
        />
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn
            cancelIcon={<KeyboardArrowLeft />}
            onCancel={() => {
              redirectTo("back");
            }}
            submitBtnIcon={<Add />}
            submitBtnColor="primary"
            onSubmit={() => {
              setAssetEventFormOpen(true);
            }}
            saveTitle={I18n.t(
              "frontend.asset_events.asset_event_list.add_event",
            )}
            disableSave={assetEventFormOpen}
          />
        </FixedBottomArea>
      </Grid>
    </Grid>
  );
};

function requestOptionsForFilter(
  filter: AssetEventFilter,
  pageSettings: ExtensiblePageSettings,
) {
  const theJsonApiFilter: AssetEventJsonApiFilter = {
    asset: filter.assetIds,
    event_type: filter.typeId,
    start_after: filter.from,
    end_before: filter.to,
    severity_level: filter.severity_level,
    search: filter.search,
  };
  const params = jsonApiFilterParamsArgumentsFromFilterObject(theJsonApiFilter);
  const options: Record<string, string | number> = {};
  options["include"] = "root_asset,asset,event_type,user,event_pattern";
  options["page[size]"] = pageSettings.size;
  options["page[number]"] = pageSettings.number;
  options["sort"] = "-from";

  return merge(options, params);
}
