import { includes, isNil } from "lodash";

import * as sourceMap from "sourcemapped-stacktrace";
import { logger } from "./logger";

const browserInfo = require("browser-info");

/**
 * Extract stacktrace from error and convert to execjs format
 * @param error An error
 * @return A stacktrace in execjs format
 */
export function extractStacktrace(error: Error): Promise<string> {
  if (isNil(error?.stack)) {
    return Promise.resolve("No stacktrace due to missing stack.");
  }

  return new Promise<string[]>((resolve, reject) => {
    // fallback for jsdom
    if (navigator.userAgent.indexOf("jsdom") > -1) {
      resolve(error?.stack?.split("\n"));
      return;
    }

    /* istanbul ignore next */
    try {
      sourceMap.mapStackTrace(error.stack, (mappedStack: string[]) => {
        resolve(mappedStack);
      });
    } catch (e) {
      reject(e);
    }
  }).then((mappedStack) => {
    if (includes(mappedStack[0], error.name)) {
      mappedStack = mappedStack.slice(1); // ignore first line if it contains the error name
    }

    return mappedStack.map((frame) => frame.replace("    at ", "")).join("\n");
  });
}

/**
 * Send an error to the airbrake error handler
 * @param error A javascript error
 * @return Promise to the completed request
 */
export function notifyAirbrake(error: Error): Promise<any> {
  try {
    const browser = browserInfo();
    const requestBody = {
      error: {
        error_class: error?.name,
        message: error?.message,
        browser: browser,
        backtrace: null as string,
        location: window.location.href,
      },
    };

    return extractStacktrace(error)
      .then((backtrace) => {
        requestBody.error.backtrace = backtrace;
      })
      .catch((e) => {
        logger.log(e);
      })
      .finally(() => {
        return $.ajax({
          url: "/airbrake/log_error.json",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(requestBody),
        }).catch((e) => {
          console.error("Could not log error to airbrake.");
        });
      });
  } catch (e) {
    return Promise.reject(e);
  }
}

let GlobalErrorHandlerRegistered = false;
export function GlobalErrorHandler(event: ErrorEvent) {
  logger.log(event);
  try {
    notifyAirbrake(event.error);
  } catch (error) {
    logger.error(error);
  }
}

/**
 * Register a global error catcher
 */
export function registerGlobalErrorHandler(): void {
  if (isNil(window) || GlobalErrorHandlerRegistered) {
    return;
  }

  window.addEventListener("error", GlobalErrorHandler);
  GlobalErrorHandlerRegistered = true;
}

/**
 * Unregister global error catcher
 */
export function unregisterGlobalErrorHandler(): void {
  window.removeEventListener("error", GlobalErrorHandler);
  GlobalErrorHandlerRegistered = false;
}
