import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import {
  find,
  includes,
  isArray,
  isEmpty,
  isNil,
  map,
  toInteger,
} from "lodash";

import { Asset } from "../models/asset";
import { Location } from "../models/location";
import { Organization } from "../models/organization";
import { Sensor } from "../models/sensor";
import { api_asset_path } from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";
import { AssetTypeJSONObject } from "./asset_type";
import { jsonApiSingleResourceToFlatObject } from "./jsonapi_tools";
import { LocationJSONObject } from "./location";
import {
  ManufacturerJSONAPIAttributes,
  ManufacturerJSONObject,
} from "./manufacturer";
import {
  ORGANIZATION_JSONAPI_RESOURCE_TYPE,
  OrganizationJSONObject,
} from "./organization";
import { ProductModelJSONObject } from "./product_model";
import { SENSOR_JSONAPI_RESOURCE_TYPE, SensorJSONObject } from "./sensor";
import { ContextStateMachineJSONObject } from "./context_state_machines";

export const ASSET_JSONAPI_RESOURCE_TYPE = "assets";
export interface AssetStateInfo {
  csm_id: number;
  sc_id: number;
  s_id: number;
  state: string;
  name: string;
  icon: string;
  color: string;
  criticality: string;
  criticality_val: number;
}

export interface AssetStateInfoJSONObject extends AssetStateInfo, JSON.Object {}

export interface AssetJsonApiAttributes extends Asset {
  name?: string;
  description?: string;

  asset_properties?: JSON.Object;
  subtree_ids?: number[];

  asset_states?: Record<string, AssetStateInfoJSONObject>;
  asset_type_id?: number;
  asset_type_name?: string;

  product_model_id?: number;
  product_model_name?: string;

  operator_id?: number;
  operator_name?: string;

  logbook_id?: number;

  // attributes added after flattening relations
  root?: AssetJSONAPIAttributes;
  subtree?: AssetJSONAPIAttributes[];
  operator?: OrganizationJSONObject;
  manufacturer?: ManufacturerJSONAPIAttributes;
  sensors?: SensorJSONObject[];
}

export interface AssetJsonApiUpdateAttributes extends AssetJSONAPIAttributes {
  icon_signed_blob_id?: number;
}

export interface AssetJSONObject extends AssetJsonApiAttributes, JSON.Object {
  product_model?: ProductModelJSONObject;
  manufacturer?: ManufacturerJSONObject;
  context_state_machines?: ContextStateMachineJSONObject[];
  asset_type?: AssetTypeJSONObject;
  operator?: OrganizationJSONObject;
  sensors?: SensorJSONObject[];
  location?: LocationJSONObject;
}

export type AssetJSONAPIAttributes = JSONAPI.AttributesObject<AssetJSONObject>;

export interface ExtractedAssetData {
  assets: AssetJsonApiAttributes[];
  locations: Record<number, Location>;
  sensors: Record<number, Sensor>;
  organizations: Record<number, Organization>;
  totalCount: number;
  totalPages: number;

  links: JSONAPI.PaginationLinks;
}

export type AssetIncludes =
  | "asset_type"
  | "sensors"
  | "product_model"
  | "location"
  | "operator"
  | "parent"
  | "root"
  | "root.subtree"
  | "subtree.asset_type"
  | "children"
  | "subtree"
  | "devices"
  | "maintenance_plans"
  | "maintenance_jobs"
  | "manufacturer"
  | "context_state_machines"
  | "report_plans"
  | "reports"
  | "file_attachments"
  | "id_tokens"
  | "external_references";
export async function loadAsset(
  assetId: IDType,
  assetIncludes?: AssetIncludes[],
): Promise<AssetJSONObject> {
  const options = {
    id: assetId,
    include: assetIncludes?.join(","),
    format: "json",
    _options: true,
  };
  const url = api_asset_path(assetId, options);
  const resp =
    await loadDataFromUrl<
      JSONAPI.SingleResourceDoc<string, AssetJSONAPIAttributes>
    >(url);

  let callback = null;
  // handle multi level
  if (includes(assetIncludes, "root.subtree")) {
    callback = (
      plainObject: AssetJSONAPIAttributes,
      apiObject: JSONAPI.SingleResourceDoc<string, AssetJSONAPIAttributes>,
      includesByItemType: Record<string, JSON.Object>,
    ) => {
      const rootPlainObject = plainObject.root as AssetJsonApiAttributes;
      const includedAssets = includesByItemType?.[
        ASSET_JSONAPI_RESOURCE_TYPE
      ] as AssetJSONAPIAttributes;
      if (isEmpty(includedAssets)) return;
      let rootApiObject: JSONAPI.ResourceObject<string, AssetJSONAPIAttributes>;
      if (plainObject.root_id == plainObject.id) {
        rootApiObject = apiObject.data;
      } else {
        rootApiObject = find(
          apiObject.included,
          (item) => item.id == rootPlainObject.id,
        );
      }

      const subtreeItems = (
        rootApiObject.relationships?.[
          "subtree"
        ] as JSONAPI.RelationshipsWithData
      ).data as JSONAPI.ResourceIdentifierObject[];
      const mappedRelatedItems = map(subtreeItems, (si) =>
        si.id == plainObject.id ? plainObject : includedAssets[si.id],
      );
      (rootPlainObject as AssetJSONAPIAttributes).subtree =
        mappedRelatedItems as AssetJSONAPIAttributes[];
    };
  }

  const flatObject = jsonApiSingleResourceToFlatObject(resp, callback);
  return flatObject;
}

export function extractIncludedFromJsonApiListResponse(
  resp:
    | JSONAPI.CollectionResourceDoc<string, AssetJSONAPIAttributes>
    | JSONAPI.SingleResourceDoc<string, AssetJSONAPIAttributes>,
): ExtractedAssetData {
  const data: ExtractedAssetData = {
    assets: [] as AssetJsonApiAttributes[],
    locations: {} as Record<number, Location>,
    sensors: {} as Record<number, Sensor>,
    organizations: {} as Record<number, Organization>,
    totalCount: isNil(resp?.meta?.record_count)
      ? null
      : toInteger(resp?.meta?.record_count),
    totalPages: isNil(resp?.meta?.page_count)
      ? null
      : toInteger(resp?.meta?.page_count),
    links: resp.links as JSONAPI.PaginationLinks,
  };

  if (isArray(resp?.data)) {
    resp?.data?.map((asset) => {
      data.assets.push({ ...asset.attributes, id: toInteger(asset.id) });
    });
  } else {
    const singleResource = resp as JSONAPI.SingleResourceDoc<
      string,
      AssetJSONAPIAttributes
    >;
    data.assets.push({
      ...singleResource.data.attributes,
      id: toInteger(singleResource.data.id),
    });
  }

  resp?.included?.forEach((includedItem) => {
    if (includedItem.type === "locations" || includedItem.type === "location") {
      data.locations[toInteger(includedItem.id)] = {
        ...includedItem.attributes,
        id: toInteger(includedItem.id),
      };
    } else if (
      includedItem.type === SENSOR_JSONAPI_RESOURCE_TYPE ||
      includedItem.type === "sensor"
    ) {
      data.sensors[toInteger(includedItem.id)] = {
        ...includedItem.attributes,
        id: toInteger(includedItem.id),
      } as Sensor;
    } else if (
      includedItem.type === ORGANIZATION_JSONAPI_RESOURCE_TYPE ||
      includedItem.type === "organization"
    ) {
      data.organizations[toInteger(includedItem.id)] = {
        ...includedItem.attributes,
        id: toInteger(includedItem.id),
      };
    } else {
      // no known models
      return;
    }
  });

  return data;
}

export const ASSET_JSONAPI_UPDATEABLE_FIELDS: (keyof AssetJsonApiUpdateAttributes)[] =
  [
    "name",
    "key",
    "short_name",
    "description",
    "serial",
    "mf_date",
    "icon_signed_blob_id",
    "timezone",
  ];

export const ASSET_JSONAPI_CREATABLE_FIELDS: (keyof AssetJsonApiUpdateAttributes)[] =
  [
    "name",
    "key",
    "short_name",
    "description",
    "serial",
    "mf_date",
    "icon_signed_blob_id",
    "timezone",
  ];
export const ASSETS_JSONAPI_RESOURCE_TYPE = "assets";
