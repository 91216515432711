import { Settings } from "@mui/icons-material";
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";

import {
  compact,
  defaultTo,
  each,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isString,
  map,
  merge,
  toInteger,
  uniq,
} from "lodash";
import { DateRange } from "moment-range";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { LineConfig, PlotlyLineChart } from "../../charting/plotly_line_chart";
import { WidgetController } from "../../controller/widget_controller";
import {
  ContextStateMachineJSONObject,
  loadContextStateMachine,
} from "../../json_api/context_state_machines";
import {
  SamplingRate,
  SensorValueType,
  samplingRateFromString,
  samplingRateToString,
} from "../../models/sensor";
import {
  addTimeScopeToAnchor,
  loadPropsFromAnchor,
  loadTimeScopeFromAnchor,
  updatePropInAnchor,
} from "../../utils/anchor_prop_store";
import { createTimeRanges } from "../../utils/time_scopes";
import { LoadingIcon } from "../common/icon";

import Bluebird from "bluebird";
import { Moment } from "moment";
import { time_series_api_sensor_path } from "../../routes";
import {
  buildLineDiagramAnnotationUrls,
  buildLineDiagramDataUrls,
} from "../../widgets/line_diagram_widget";
import { LineDiagramWidgetConfigSerialized } from "../../widgets/line_diagram_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { DiagramSettings } from "../diagram_settings";
import {
  LineDiagramWidgetProps,
  LineDiagramWidgetState,
} from "./line_diagram_widget.types";
import { WidgetBox } from "./widget_box";

export class LineDiagramWidget
  extends React.Component<LineDiagramWidgetProps, LineDiagramWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: LineDiagramWidgetProps = {
    dataUrls: [],
    activeContextStateMachineId: null,
    samplingRate: null,
    samplingMode: "avg",
    sensorIds: [],
    dashboardSettings: {},
    hideSettings: false,
    disableSettings: false,
    storeSettingsInAnchor: true,
    diagramHeight: 350,
    lineOptions: {
      lineMode: "lines+markers",
      lineShape: "linear",
    },
  };

  static serializedConfigToProps(
    config: LineDiagramWidgetConfigSerialized,
  ): LineDiagramWidgetProps {
    let sensorIds = config.sensor_ids;
    if (isNumber(sensorIds) || isString(sensorIds)) {
      sensorIds = [sensorIds as number];
    }

    let samplingRate: SamplingRate;
    if (!isNil(config.sampling_rate?.value)) {
      samplingRate = config.sampling_rate;
    }

    const showStateSelection = defaultTo(
      config.show_state_selection,
      !isEmpty(config.context_state_machine_ids),
    );

    return merge(widgetBoxPropsFromSerializedConfig(config), {
      sensorIds,
      diagramHeight: config.diagram_height,

      contentTitle: config.title,
      dataUrls: buildLineDiagramDataUrls(
        sensorIds,
        config.asset_ids,
        config.kpi_ids,
      ),
      oneYAxisPerUnitOnly: config.unify_unit_axes,
      contextStateMachineIds: config.context_state_machine_ids,
      activeContextStateMachineId: config.active_context_state_machine_id,
      // do not show trends - currently no support in backend
      //trendLineUrls: config.showTrend ? buildLineDiagramTrendUrls(config.sensor_ids, config.asset_ids) : [],
      annotationUrls: config.show_annotations
        ? buildLineDiagramAnnotationUrls(sensorIds, config.asset_ids)
        : [],
      samplingRate,

      title: config.widget_name,
      zoomMode: config.zoom_mode,
      showStatistics: config.show_statistics,
      showStateSelection,
      hideSettings: config.hide_settings,
      disableSettings: config.disable_settings,
      offsetYAxis: config.offset_y_axis,
      lineOptions: {
        lineMode: config.line_mode || "lines+markers",
        lineShape: config.line_shape || "linear",
      },
      storeSettingsInAnchor: config.store_settings_in_anchor,
    } as LineDiagramWidgetProps);
  }

  constructor(props: LineDiagramWidgetProps) {
    super(props);
    let samplingRate = props.samplingRate;

    let startDate = props.timeRange?.start;
    let endDate = props.timeRange?.end;
    let activeContextStateMachineId = props.activeContextStateMachineId;

    if (this.props.storeSettingsInAnchor) {
      const timeRanges = createTimeRanges();
      const anchorTimeRange = loadTimeScopeFromAnchor(timeRanges.ranges);
      if (!isNil(anchorTimeRange)) {
        [startDate, endDate] = anchorTimeRange;
      }

      const anchorProps = loadPropsFromAnchor();
      activeContextStateMachineId = defaultTo(
        anchorProps["active-context-state-machine-id"],
        activeContextStateMachineId,
      ) as string;

      samplingRate = defaultTo(
        samplingRateFromString(anchorProps["sampling-rate"] as string),
        props.samplingRate,
      );
    }

    if (isNil(samplingRate?.unit) || isNil(samplingRate?.value)) {
      samplingRate = null;
    }

    if (isNil(startDate) && isNil(endDate)) {
      startDate = moment().startOf("day");
      endDate = moment().endOf("day");
    }
    this.state = {
      fullscreen: false,
      showSettings: !props.hideSettings,
      startDate,
      endDate,
      samplingRate,
      samplingMode: props.samplingMode,
      showSettingsFullscreen: false,
      lineConfigs: this.buildLineConfigs(),
      contextStateMachines: [],
      activeContextStateMachineId: activeContextStateMachineId,
    };
  }
  diagramContainer: HTMLDivElement;
  statisticsContainer: HTMLDivElement;
  lineDiagram: PlotlyLineChart;

  setDiagramContainer(elem: HTMLDivElement): void {
    if (!isNil(elem) && this.diagramContainer !== elem) {
      this.diagramContainer = elem;
      this.updatePlotlyDiagram();
    }
  }
  setStatisticsElement(elem: HTMLDivElement): void {
    if (!isNil(elem) && this.statisticsContainer !== elem) {
      this.statisticsContainer = elem;
      this.updatePlotlyDiagram();
    }
  }

  componentDidMount(): void {
    this.updatePlotlyDiagram();
    void this.loadStateMachines();
    each(this.props.sensorIds, (id) => {
      WidgetController.getInstance().sensorDataChannel.addEventListener(
        this,
        toInteger(id),
      );
    });
  }

  async loadStateMachines() {
    const csms = await Bluebird.map(
      uniq(compact(this.props.contextStateMachineIds)),
      (id) => loadContextStateMachine(id, ["stateful_item"]),
    );

    this.setState({ contextStateMachines: csms });
  }

  /**
   * @override
   */
  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ) {
    if (!isNumber(value)) {
      return;
    }

    this.lineDiagram.addValue(attributeKeyId, sensorId, time, value, unit);
  }

  /**
   * @override
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @param {*} snapshot
   * @memberof LineDiagramWidget
   */
  componentDidUpdate(prevProps: LineDiagramWidgetProps) {
    if (
      (this.props.timeRange?.start &&
        !this.props.timeRange.start.isSame(prevProps.timeRange?.start)) ||
      (this.props.timeRange?.end &&
        !this.props.timeRange.end.isSame(prevProps.timeRange?.end))
    )
      this.setState(
        (prevState) => ({
          ...prevState,
          startDate: this.props.timeRange?.start,
          endDate: this.props.timeRange?.start,
        }),
        () => this.lineDiagram.setTimeScope(this.props.timeRange),
      );
    if (prevProps.sensorIds !== this.props.sensorIds) {
      this.setState((prevState) => {
        return {
          ...prevState,
          lineConfigs: this.buildLineConfigs(),
        };
      });
    }
    if (this.props.fullscreen != prevProps.fullscreen) {
      this.setState({ fullscreen: this.props.fullscreen });
      void this.lineDiagram?.resize();
    }
    this.updatePlotlyDiagram();
  }

  buildLineConfigs(): LineConfig[] {
    let configs: LineConfig[];
    if (isNil(this.props.dataUrls)) {
      configs = map(this.props.sensorIds, (sid) => {
        const cfg: LineConfig = {
          baseUrl: time_series_api_sensor_path(sid, {
            format: "bin",
            _options: true,
          }),
        };

        return cfg;
      });
    } else {
      configs = this.props.dataUrls.map((url) => ({
        baseUrl: url,
      }));
    }
    return configs;
  }
  updatePlotlyDiagram() {
    if (!isNil(this.diagramContainer)) {
      if (isNil(this.lineDiagram)) {
        this.lineDiagram = new PlotlyLineChart(
          {
            baseElementIdOrElement: this.diagramContainer,
            statisticsElementOrId: this.statisticsContainer,
          },
          {
            lineConfigs: this.state.lineConfigs,
            trendURLs: this.props.trendLineUrls,
            annotationURLs: this.props.annotationUrls,
          },
          {
            activeContextStateMachineId: this.state.activeContextStateMachineId,
            contextStateMachineIds: this.props.contextStateMachineIds,
          },
          {
            yAxesPerUnit: this.props.oneYAxisPerUnitOnly,
            offsetYAxis: this.props.offsetYAxis,
            zoomMode: this.props.zoomMode,
          },
          this.props.onUpdateStatistics || this.props.showStatistics,
        );

        this.lineDiagram.lineMode = this.props.lineOptions?.lineMode;
        this.lineDiagram.lineShape = this.props.lineOptions?.lineShape;
        this.lineDiagram.setSamplingRate(this.state.samplingRate, "avg", false);

        void this.lineDiagram.setChartAnnotationOptions(
          this.props.chartAnnotationOptions,
          false,
        );
        void this.lineDiagram.setTimeScope(
          new DateRange([this.state.startDate, this.state.endDate]),
        );
      } else {
        void this.lineDiagram.setChartAnnotationOptions(
          this.props.chartAnnotationOptions,
          false,
        );

        if (
          !isEqual(this.state.lineConfigs, this.lineDiagram.getLineConfigs())
        ) {
          this.lineDiagram.setLineConfigs(this.state.lineConfigs);
        } else {
          void this.lineDiagram
            .initElements(
              {
                baseElementIdOrElement: this.diagramContainer,
                statisticsElementOrId: this.statisticsContainer,
              },
              false,
            )
            .then(() => {
              this.lineDiagram.updateChart();
              // update chart updates the statistcs itself, so this should not be necesssary
              //this.lineDiagram.statisticsUpdated();
            });
        }
      }
    }
  }
  componentWillUnmount() {
    const instance = WidgetController.getInstance();

    if (!isNil(instance)) {
      each(this.props.sensorIds, (id) => {
        instance.sensorDataChannel.removeEventListener(this, toInteger(id));
      });
    }

    this.lineDiagram?.destroyChart();
  }

  render(): React.ReactNode {
    const content = (
      <Grid container spacing={2}>
        {this.props.disableSettings ? null : (
          <Grid item xs={12}>
            <DiagramSettings
              key={"settings"}
              visible={this.state.showSettings}
              samplingRate={this.state.samplingRate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              samplingMode={this.state.samplingMode}
              contextStateMachines={this.state.contextStateMachines}
              selectedContextStateMachineId={
                this.state.activeContextStateMachineId
              }
              storeSettingsInAnchor={this.props.storeSettingsInAnchor}
              showCsmSelection={this.props.showStateSelection}
              mobileOpen={this.state.showSettingsFullscreen}
              onSelectContextStateMachine={(
                csm: ContextStateMachineJSONObject,
              ) => {
                this.setState({ activeContextStateMachineId: csm?.id });
                void this.lineDiagram?.setActiveContextStateMachineId(csm?.id);
              }}
              onChangeBeginAtZero={(beginAtZero) => {
                if (this.props.storeSettingsInAnchor)
                  updatePropInAnchor("begin-at-zero", beginAtZero);
                void this.lineDiagram?.setBeginAtZero(beginAtZero);
              }}
              onChangeSamplingRate={(samplingRate, samplingMode) => {
                if (isNil(samplingRate?.value) || isNil(samplingRate?.unit)) {
                  if (this.props.storeSettingsInAnchor)
                    updatePropInAnchor("sampling-rate", null);
                } else {
                  if (this.props.storeSettingsInAnchor)
                    updatePropInAnchor(
                      "sampling-rate",
                      samplingRateToString(samplingRate),
                    );
                }
                if (this.props.storeSettingsInAnchor)
                  updatePropInAnchor("sampling-mode", samplingMode);
                this.setState({
                  samplingRate: samplingRate,
                  samplingMode: samplingMode,
                });
                void this.lineDiagram?.setSamplingRate(
                  samplingRate,
                  samplingMode,
                );
              }}
              onChangeTimeRange={(startTime, endTime, label) => {
                if (this.props.storeSettingsInAnchor)
                  addTimeScopeToAnchor(startTime, endTime, label);
                if (this.props.onChangeTimeRange) {
                  this.props.onChangeTimeRange([
                    startTime.toDate(),
                    endTime.toDate(),
                  ]);
                }
                this.setState({ startDate: startTime, endDate: endTime });
                void this.lineDiagram?.setTimeScope(
                  new DateRange(startTime, endTime),
                );
              }}
              onRequestHide={() => {
                this.setState({
                  showSettings: false,
                  showSettingsFullscreen: false,
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            className={`sensor-diagram-container`}
            height={
              this.state.fullscreen == true
                ? "calc(100vh - 250px)"
                : defaultTo(this.props.diagramHeight, 300)
            }
            minHeight={150}
            ref={(node: HTMLDivElement) => this.setDiagramContainer(node)}
            key={"diagram-container"}
          >
            <Box
              className="sensor-diagram-no-data"
              display="none"
              height="100%"
            >
              <Box m={"auto"}>
                <Typography variant="h5">
                  {I18n.t("frontend.no_data")}
                </Typography>
              </Box>
            </Box>
            <Box className="sensor-diagram-error" display="none">
              <Box m={"auto"}>
                <Typography variant="h5">Error while loading data.</Typography>
                <Box className="sensor-diagram-error-message" display="none" />
              </Box>
            </Box>
            <Grid
              container
              justifyContent="center"
              className="loading-overlay text-center"
              display="none"
            >
              <Skeleton variant="rectangular" />
              <Box m={"auto"}>
                <LoadingIcon size="4x" />
              </Box>
            </Grid>
            <Box className="sensor-diagram"></Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          className="sensor-diagram-statistics"
          ref={(node) => this.setStatisticsElement(node)}
        />
      </Grid>
    );

    const boxedContent = this.props.encloseInIBox ? (
      <WidgetBox
        {...this.props}
        allowFullscreen={true}
        minWidthPx={250}
        onFullscreen={(fullscreen) => {
          this.props.onFullscreen?.(fullscreen);
          // apply the fullscreen information to the state in order to keep the state in sync with the widget
          this.setState({ fullscreen });
          //resize regardless which direction we switched
          void this.lineDiagram?.resize();
        }}
        tools={[
          this.props.disableSettings ? null : (
            <IconButton
              key={"diagram-settings"}
              size="small"
              onClick={() => {
                this.setState({
                  showSettings: !this.state.showSettings,
                  showSettingsFullscreen: !this.state.showSettingsFullscreen,
                });
              }}
            >
              <Settings />
            </IconButton>
          ),
        ]}
      >
        {content}
      </WidgetBox>
    ) : (
      content
    );
    return boxedContent;
  }
}
