import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
  flatMap,
} from "lodash";
import * as React from "react";
import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";
import { StateJSONObject } from "../../json_api/state";
import { StateContextJSONObject } from "../../json_api/state_contexts";
import { IDType } from "../../utils/urls/url_utils";
import { AssetStateSelectionDialog } from "../states/asset_state_selection_dialog";
import { AssetStateSelectionDisplay } from "../states/asset_state_selection_display";
import { ModelErrors } from "../../json_api/jsonapi_tools";
import { EventPatternJSONAPIAttributes } from "../../json_api/event_pattern";
interface EventPatternStateConditionFieldsProps {
  assetId: IDType;
  selectedStateId?: IDType;
  selectedContextStateMachingeId?: IDType;

  errors?: ModelErrors<EventPatternJSONAPIAttributes>;
  onStateSelect(
    state: StateJSONObject,
    context: StateContextJSONObject,
    csm: ContextStateMachineJSONObject,
  ): void;
}

export const EventPatternStateConditionFields: React.FunctionComponent<
  EventPatternStateConditionFieldsProps
> = (props) => {
  const [stateSelectEnabled, setStateSelectEnabled] = React.useState(
    !isNil(props.selectedStateId) &&
      !isNil(props.selectedContextStateMachingeId),
  );
  React.useEffect(() => {
    setStateSelectEnabled(
      !isNil(props.selectedStateId) &&
        !isNil(props.selectedContextStateMachingeId),
    );
  }, [props.selectedStateId, props.selectedContextStateMachingeId]);

  const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <FormControlLabel
            label={I18n.t(
              "activerecord.attributes.event_patterns/event_pattern.restrict_to_state",
            )}
            control={
              <Checkbox
                size="small"
                checked={stateSelectEnabled}
                onChange={(e) => {
                  if (!e.target.checked) {
                    // reset selection
                    props.onStateSelect(null, null, null);
                    setStateSelectEnabled(false);
                  } else {
                    setStateSelectEnabled(true);
                  }
                }}
              />
            }
          />
        </FormControl>
      </Grid>

      {!stateSelectEnabled ? null : (
        <Grid item xs={12} container>
          <Grid
            item
            container
            xs={10}
            onClick={() => {
              setSelectionDialogOpen(true);
            }}
          >
            <AssetStateSelectionDisplay
              showTitle={false}
              selectedContextStateMachineId={
                props.selectedContextStateMachingeId
              }
              selectedStateId={props.selectedStateId}
            />
          </Grid>
          {!isEmpty(props.errors) &&
          find(
            [
              "context_state_machine_id",
              "context_state_machine",
              "state",
              "state_id",
            ],
            (attr) => !isEmpty(props.errors[attr]),
          ) ? (
            <Grid item xs={12}>
              <ul>
                {flatMap(
                  [
                    "context_state_machine_id",
                    "context_state_machine",
                    "state",
                    "state_id",
                  ],
                  (attr) =>
                    isEmpty(props.errors[attr])
                      ? null
                      : map(props.errors[attr], (err) => <li>{err}</li>),
                )}
              </ul>
            </Grid>
          ) : null}
          <Grid item xs={2}>
            <Box marginY={{ xs: "auto" }} height={"100%"}>
              <IconButton
                color="primary"
                size="small"
                title={I18n.t(
                  "frontend.event_patterns.event_pattern_state_condition_fields.set_state",
                )}
                onClick={() => {
                  setSelectionDialogOpen(true);
                }}
              >
                <Edit />
              </IconButton>
              <AssetStateSelectionDialog
                open={selectionDialogOpen}
                onClose={() => {
                  setSelectionDialogOpen(false);
                }}
                baseAssetId={props.assetId}
                selectedContextStateMachineId={
                  props.selectedContextStateMachingeId
                }
                selectedStateId={props.selectedStateId}
                onStateSelect={(
                  statefulItemId: IDType,
                  state,
                  stateContext,
                  csm,
                ) => {
                  props.onStateSelect(state, stateContext, csm);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
