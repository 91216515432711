import { Cancel, Check, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DocWithErrors } from "jsonapi-typescript";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { AssetJSONObject } from "../../json_api/asset";
import { AssetEventJSONObject } from "../../json_api/asset_event";
import {
  AssetEventTypeJSONObject,
  saveAssetEventType,
  validateAssetEventType,
} from "../../json_api/asset_event_type";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import {
  ModelErrors,
  extractErrorsFromJsonApi,
} from "../../json_api/jsonapi_tools";
import { HttpError } from "../../utils/jquery_helper";
import { error, success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { LoadingIcon } from "../common/icon";
import { AssetEventTypeForm } from "./asset_event_type_form";

interface AssetEventTypeFormDialogProps {
  assetId?: IDType;
  asset?: AssetJSONObject;
  assetTypeId?: IDType;
  assetType?: AssetTypeJSONObject;
  assetEventType?: AssetEventTypeJSONObject;
  onClose?: () => void;
  onSave?: (event: AssetEventJSONObject) => void;
  open?: boolean;
  fullScreen?: boolean;
}
export const AssetEventTypeFormDialog: React.FunctionComponent<
  AssetEventTypeFormDialogProps
> = (props) => {
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = React.useState(false);
  // asset event currently created/edited in form. Undefined will hide the dialog
  const [assetEventTypeForForm, setAssetEventTypeForForm] =
    React.useState<AssetEventTypeJSONObject>(props.assetEventType);
  const [assetEventTypeForFormErrors, setAssetEventTypeForFormErrors] =
    React.useState<ModelErrors<AssetEventTypeJSONObject>>({});

  const [submitEnabled, setSubmitEnabled] = React.useState(true);

  React.useEffect(() => {
    setSubmitEnabled(isEmpty(assetEventTypeForFormErrors) && !loading);
  }, [assetEventTypeForFormErrors, loading]);

  if (
    isNil(assetEventTypeForForm) &&
    isNil(props.assetId) &&
    isNil(props.assetTypeId)
  )
    return null;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen={props.fullScreen || dialogFullScreen}
    >
      <DialogTitle>
        {I18n.t("frontend.asset_event_types.asset_event_type_form.title_new")}
        <Box displayPrint="none" position="absolute" right={8} top={8}>
          <IconButton aria-label="close" onClick={() => props.onClose()}>
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ display: "flex" }}>
        <Grid container spacing={2} m={2}>
          <AssetEventTypeForm
            assetId={props.asset?.id ?? props.assetId}
            asset={props.asset}
            assetTypeId={props.assetTypeId}
            assetType={props.assetType}
            errors={assetEventTypeForFormErrors}
            assetEventType={assetEventTypeForForm}
            withCard={false}
            withFloatingButtons={false}
            onCancel={() => setAssetEventTypeForForm(undefined)}
            onChangeAssetEventType={(e) => {
              setAssetEventTypeForForm(e);
              setAssetEventTypeForFormErrors(null);
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setAssetEventTypeForForm(undefined);
            props.onClose();
          }}
          startIcon={<Cancel />}
        >
          {I18n.t("frontend.cancel")}
        </Button>
        <Button
          color="primary"
          disabled={!submitEnabled}
          onClick={() => {
            if (!isNil(assetEventTypeForForm)) {
              const errors = validateAssetEventType(assetEventTypeForForm);
              if (!isEmpty(errors)) {
                setAssetEventTypeForFormErrors(errors);
              } else {
                setLoading(true);
                saveAssetEventType(
                  assetEventTypeForForm,
                  props.asset?.id ?? props.assetId,
                  props.assetType?.id ?? props.assetTypeId,
                )
                  .then(() => {
                    void success(
                      I18n.t("frontend.success"),
                      I18n.t("frontend.saved_successfully"),
                    );
                    if (props.onSave) {
                      props.onSave(assetEventTypeForForm);
                    }
                  })
                  .catch((e) => {
                    setAssetEventTypeForFormErrors(
                      extractErrorsFromJsonApi<AssetEventJSONObject>(
                        (e as HttpError).request.responseJSON as DocWithErrors,
                      ),
                    );
                    error(
                      I18n.t("frontend.error"),
                      I18n.t(
                        "frontend.asset_event_types.asset_event_type_list.could_not_save_event_type",
                      ),
                    );
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }
            }
          }}
          startIcon={loading ? <LoadingIcon size="1x" /> : <Check />}
        >
          {I18n.t("frontend.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
