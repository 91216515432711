import { first, isEmpty, merge } from "lodash";
import * as React from "react";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { WidgetBox } from "./widget_box";

import {
  Book,
  CalendarMonthOutlined,
  ConstructionOutlined,
  Sensors,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  asset_asset_events_path,
  asset_component_path,
  asset_maintenance_plans_path,
  asset_path,
  asset_report_plans_path,
  asset_sensors_path,
} from "../../routes";
import { redirectTo } from "../../utils/redirection";
import { AssetPartsWidgetConfigSerialized } from "../../widgets/asset_parts_widget.types";
import { AssetTreeItem, AssetTreeSelect } from "../assets/asset_tree_select";
import { getIconForName } from "../common/icon_for_name";
import { AssetPartsWidgetProps } from "./asset_parts_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";

export const AssetPartsWidget: React.FunctionComponent<
  AssetPartsWidgetProps
> = (props) => {
  const content = (
    <Box m={1} p={1}>
      <AssetTreeSelect
        assetId={props.assetId}
        selectable={false}
        treeMode="subtree"
        itemContent={(item: AssetTreeItem, parent: AssetTreeItem) => (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" fontSize={9} my={1}>
                {item.asset_type?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs="auto">
                  <Avatar
                    variant="rounded"
                    src={item.icon_url}
                    sx={{ width: 24, height: 24 }}
                  >
                    {item.asset_type?.icon
                      ? getIconForName(item.asset_type?.icon, "1x")
                      : first(item.asset_type.name)}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Link
                    href={
                      parent
                        ? asset_component_path(parent.id, item.id)
                        : asset_path(item.id)
                    }
                  >
                    <Typography variant="h5" fontSize={12} my="auto" ml={1}>
                      {item.title}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title={I18n.t("activerecord.models.sensor.other")}>
                    <IconButton
                      color="primary"
                      style={{ fontSize: 14 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        redirectTo(asset_sensors_path(item.id));
                      }}
                    >
                      <Sensors fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={I18n.t("activerecord.models.asset_event.other")}
                  >
                    <IconButton
                      color="primary"
                      style={{ fontSize: 14 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        redirectTo(asset_asset_events_path(item.id));
                      }}
                    >
                      <CalendarMonthOutlined fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={I18n.t("activerecord.models.maintenance_plan.other")}
                  >
                    <IconButton
                      color="primary"
                      style={{ fontSize: 14 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        redirectTo(asset_maintenance_plans_path(item.id));
                      }}
                    >
                      <ConstructionOutlined fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={I18n.t("activerecord.models.report.other")}>
                    <IconButton
                      color="primary"
                      style={{ fontSize: 14 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        redirectTo(asset_report_plans_path(item.id));
                      }}
                    >
                      <Book fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <Divider />
            </Grid>
          </Grid>
        )}
      />
    </Box>
  );

  return (
    <WidgetBox
      {...props}
      title={
        isEmpty(props.title)
          ? I18n.t("frontend.widgets.asset_parts_widget.title")
          : props.title
      }
    >
      {content}
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: AssetPartsWidgetConfigSerialized,
): AssetPartsWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetId: config.asset_id,
  });
}

export const AssetPartsWidgetDefinition: SialogicWidgetDefinition = {
  component: AssetPartsWidget,
  serializedConfigToProps: serializedConfigToProps,
};
