import { Moment } from "moment";
import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import { AssetEventsWidget as AssetEventsWidgetComponent } from "../components/widgets/asset_events_widget";
import { AssetEventsWidgetDisplayMode } from "../components/widgets/asset_events_widget.types";
import {
  EventNotification,
  EventSeverityLevel,
} from "../models/event_notification";
import { AssetEventsWidgetConfigSerialized } from "./asset_events_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the AssetEventsWidget.
 *
 * @class AssetEventsWidget
 * @extends {Widget}
 */
export default class AssetEventsWidget extends Widget {
  onlyEventTypeIds: number[];
  exceptEventTypeIds: number[];
  pageSize: number;

  assetIds: number[];
  displayMode: AssetEventsWidgetDisplayMode;
  severityLevels: EventSeverityLevel[];
  events: EventNotification[];
  config: AssetEventsWidgetConfigSerialized;
  static getDomClassName(): string {
    return "asset-events-widget";
  }

  static widgetClassName(): string {
    return "AssetEventsWidget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data("config") as AssetEventsWidgetConfigSerialized;
    this.config = config;
  }
  protected initComponent(element: JQuery) {
    super.initComponent(element);

    const props = AssetEventsWidgetComponent.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <AssetEventsWidgetComponent {...props} encloseInIBox />
      </AppRoot>,
    );
  }

  handleNewEvent(
    event: EventNotification,
    time: Moment,
    assetId: number,
    eventId: number,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  getAssetIdsForNotificationUpdate(): number[] {
    return this.assetIds;
  }

  cleanup(): void {
    super.cleanup();
  }
}
