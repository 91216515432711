/* eslint-disable @typescript-eslint/no-unused-vars */
import Bluebird from "bluebird";
import { isEmpty, isNil, map } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";

import { AssetEventSubscriber } from "../channels/asset_notification_channel";
import { SensorEventSubscriber } from "../channels/sensor_data_channel";
import { WidgetAdminDropdown } from "../components/widgets/widget_admin_dropdown";
import { SensorLoader } from "../json_api/sensor_loader";
import { State } from "../models/state";
import { StateContext } from "../models/state_context";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../components/common/app_root";
import { WidgetBoxProps } from "../components/widgets/widget_box.types";
import { EventNotification } from "../models/event_notification";
import { SensorValueType } from "../models/sensor";
import { logger } from "../utils/logger";
import { ItemSelection, ItemSelectionHandler } from "./item_selection";
import {
  DOMClassNameQueryable,
  DashboardSettings,
  DashboardSettingsConfigSerialized,
  WidgetConfigSerialized,
} from "./widget.types";
import { DateRange } from "moment-range";

type WidgetConstructorInterface<T extends Widget> = new (
  element: JQuery<HTMLElement>,
) => T;

export function widgetBoxPropsFromSerializedConfig(
  config: WidgetConfigSerialized,
): Partial<WidgetBoxProps> {
  let timeRange: DateRange = undefined;
  if (config.time_range?.start && config.time_range?.end) {
    timeRange = new DateRange(
      moment(config.time_range.start),
      moment(config.time_range.end),
    );
  }
  return {
    widgetId: config.widget_id,
    minWidthPx: config.min_width_px,
    linkTarget: config.title_link,
    timeRange,
    title: config.title,
    titleLinkUrl: config.title_link_target,
  };
}
/** Base class for Widgets.
 * Due to their initialization procedure, widget classes cannot be instanciated directly.
 * Use the static factory method createWidget(element) or call initWidgets to
 *
 * @export
 * @abstract
 * @class Widget
 * @implements {SensorEventSubscriber}
 * @implements {AssetEventSubscriber}
 * @implements {ItemSelectionHandler}
 */
export default abstract class Widget<
    ConfigType extends WidgetConfigSerialized = WidgetConfigSerialized,
  >
  implements SensorEventSubscriber, AssetEventSubscriber, ItemSelectionHandler
{
  static widgetClassName(): string | undefined {
    return undefined;
  }

  /** Initializes all widgets of the given widget class. Elements are queried using the
   * selector return by static getDomClassName function
   *
   *
   * @static
   * @template T
   * @param {DOMClassNameQueryable} clazzObject
   * @param {WidgetConstructorInterface<T>} WidgetConstructable
   * @return {*}  {T[]} Created widgets
   * @memberof Widget
   */
  static initWidgets<T extends Widget>(
    clazzObject: DOMClassNameQueryable,
    WidgetConstructable: WidgetConstructorInterface<T>,
  ): T[] {
    const widgets: T[] = [];
    const sensorIds = map(
      $(`.${clazzObject.getDomClassName()}[data-sensor-id]`),
      (e) => e.getAttribute("data-sensor-id"),
    );
    if (!isEmpty(sensorIds)) {
      SensorLoader.getInstance()
        .getSensors(sensorIds)
        .catch((e) => {
          if (!(e instanceof Bluebird.CancellationError)) {
            logger.error(
              `Error loading sensors for widget class ${clazzObject.getDomClassName()}`,
              e,
            );
          }
        });
    }
    $(`.${clazzObject.getDomClassName()}`).each((index, element) => {
      try {
        const wdgt = Widget.createWidget<T>(WidgetConstructable, $(element));
        widgets.push(wdgt);
      } catch (err) {
        logger.logError(err as Error);
      }
    });
    return widgets;
  }

  static createWidget<T extends Widget>(
    WidgetClazz: { new (element: JQuery<HTMLElement>): T },
    element: JQuery<HTMLElement>,
  ): T {
    const instance = new WidgetClazz(element);

    instance.readMembersFromElement(element);
    instance.initComponent(element);
    instance.postInitialize();
    return instance;
  }

  static getDomClassName(): string {
    return "";
  }

  root?: Root;
  adminLinkRoot?: Root;
  widgetId: string | number;

  dashboardSettings: DashboardSettings;
  minWidthPx?: number;
  widgetName: string;
  titleLink?: string;
  titleLinkTarget?: React.HTMLAttributeAnchorTarget;
  updateEnabled?: boolean;
  rootElement?: JQuery<HTMLElement>;
  fullscreenButton?: JQuery<HTMLElement>;

  config: ConfigType;
  // protected widget contructor. Use factory method #createWidget to create instances.
  protected constructor(element: JQuery<HTMLElement>) {
    this.rootElement = element;
    this.fullscreenButton = element.find<HTMLElement>(".fullscreen-link-sia");
    this.fullscreenButton.on("click", () => this.toggleFullscreen());
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected postInitialize(): void {}
  protected readMembersFromElement(element: JQuery) {
    const config = element.data("config") as ConfigType;
    this.config = config;
    const dashboardConfig = element.data(
      "dashboard",
    ) as DashboardSettingsConfigSerialized;
    if (!isNil(config)) {
      this.widgetId = config.widget_id;
      this.widgetName = config.widget_name;
      this.minWidthPx = config.min_width_px;
      this.titleLink = config.title_link;
      this.titleLinkTarget = config.title_link_target;
      this.updateEnabled = config.disable_update !== true;
    }

    this.readDashboardSettings(element, dashboardConfig);

    if (isNil(this.widgetId) && element.attr("id")) {
      this.widgetId = element.attr("id").replace("widget-", "");
    } else if (isNil(this.widgetId)) {
      this.widgetId = "";
    }
  }

  private readDashboardSettings(
    element: JQuery,
    dashboardSettingsFromAttr: DashboardSettingsConfigSerialized = null,
  ) {
    if (isNil(dashboardSettingsFromAttr)) {
      dashboardSettingsFromAttr = element.data(
        "dashboard",
      ) as DashboardSettingsConfigSerialized;
    }
    this.dashboardSettings = dashboardSettingsFromAttr;
  }

  protected initRoot(element: JQuery<HTMLElement>) {
    this.root = createRoot(element.get(0));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected initComponent(element: JQuery<HTMLElement>): void {
    this.initRoot(element);
    const adminLinks = element.find(".widget_admin_edit_links");
    if (adminLinks.length != 0) {
      this.adminLinkRoot = createRoot(adminLinks.get(0));
      this.adminLinkRoot.render(
        <AppRoot>
          <WidgetAdminDropdown
            dashboardSettings={this.dashboardSettings}
            widgetId={this.widgetId}
          />
        </AppRoot>,
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleNewEvent(
    event: EventNotification,
    time: Moment,
    assetId: number,
    eventId: number,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  /**
   * Returns sensors ids that need to be registred for update
   */
  getSensorIdsForUpdate(): number[] {
    return [];
  }

  /**
   * Returns context state machine ids that need to be registred for update
   */
  getContextStateMachineIdsForUpdate(): number[] {
    return [];
  }

  getAssetIdsForNotificationUpdate(): number[] {
    return [];
  }

  /**
   * Handle an new sensor value.
   * @param attributeKeyId Attribute key id of new value
   * @param sensorId Sensor id of new value
   * @param value New sensor value
   * @param time Timestamp of sensor value
   * @param unit Unit of sensor value
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  /** Handles external selection of items. Called when an item is selected, e.g., in other widgets
   *
   * @param itemSelection Information about the selection
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleItemSelection(itemSelection: ItemSelection<any>): void {}

  /** Handles state update messages. Dummy implementation
   *
   *
   * @param {number} contextStateMachineIdContext ID of context state machine that changed state
   * @param {string} newStateName
   * @param {number} newStateCrititcality
   * @param {number} newStateId
   * @param {string} newStateDescription
   * @param {Moment} time
   * @param {number} stateful_item_id
   * @param {string} stateful_item_type
   * @param {number} stateContextId
   * @param {string} stateContextName
   * @memberof Widget
   */
  handleContextStateMachineUpdate(
    contextStateMachineId: number,
    stateContext: StateContext,
    newState: State,
    time: Moment,
    stateful_item_id: number,
    stateful_item_type: string,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  /**
   * Set time scope of a widget
   *
   * @param start Start of time range
   * @param end End of time range
   * @returns Bluebird Promise
   */
  setTimeScope(start: Moment, end: Moment): Promise<any | void> {
    return;
  }

  /**
   * Set sampling rate of widget
   *
   * @param value Sampling rate value
   * @param unit Sampling rate unit
   */
  setSamplingRate(value: number, unit: string): Promise<any> {
    return;
  }

  /**
   * Start scale of y axes at zero instead of smallest value
   * @param beginAtZero Enable or disable start at zero
   */
  setBeginAtZero(beginAtZero: boolean): Promise<any> {
    return;
  }

  /**
   * Performs necessary cleanup perations (e.g., removing charts)
   */
  cleanup(): void {
    this.adminLinkRoot?.unmount();
    this.root?.unmount();
  }

  /**
   * Toggles the widget between regular size and fullscreen mode
   */
  toggleFullscreen(): void {
    if (this.fullscreenButton.length != 0) {
      const ibox = $(this.fullscreenButton).closest("div.ibox");
      const button = $(this.fullscreenButton).find("i");
      $("body").toggleClass("fullscreen-ibox-mode");
      button.toggleClass("fa-expand").toggleClass("fa-compress");
      ibox.toggleClass("fullscreen");
      setTimeout(function () {
        $(window).trigger("resize");
      }, 100);
    }
  }
}
