import * as React from "react";
import { isEmpty } from "lodash";
import JQuery from "jquery";
import { AssetTypesSensorTypesList } from "./asset_types_sensor_types_list";
import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { Box, Paper, Typography } from "@mui/material";
import { FloatingButtons } from "../common/floating_buttons";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { redirectTo } from "../../utils/redirection";
const assetTypeHomeRoots: Root[] = [];

export function initAssetTypesSensorTypesList(
  itemOrSelector:
    | JQuery
    | string = '[data-toggle="asset-types-sensor-types-list"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, formMountItem) => {
    const assetTypeId = $(formMountItem).data("asset-type-id") as number;
    const canEdit = $(formMountItem).data("can-edit") as boolean;
    const root = createRoot(formMountItem);
    assetTypeHomeRoots.push(root);
    root.render(
      <AppRoot>
        <Paper>
          <Box padding={2}>
            <Typography variant="h5" marginBottom={2}>
              {I18n.t("activerecord.models.asset_types_sensor_type", {
                count: 2,
              })}
            </Typography>
            <AssetTypesSensorTypesList
              assetTypeId={assetTypeId}
              canEdit={canEdit}
            />
          </Box>
          <FixedBottomArea>
            <FloatingButtons
              showScrollToTopBtn
              onCancel={() => redirectTo("back")}
            />
          </FixedBottomArea>
        </Paper>
      </AppRoot>,
    );
  });
}

/**
 *  Destroy Asset Types Sensor Types List in given container
 * @param selector jquery selector to report list container
 */
export function destroyAssetTypesSensorTypesList(selector: JQuery): void {
  if (isEmpty(selector)) return;

  assetTypeHomeRoots.forEach((root) => {
    root.unmount();
  });
  assetTypeHomeRoots.length = 0;
}
