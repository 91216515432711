import { KeyboardArrowLeft } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Grid, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, map, toString } from "lodash";
import * as React from "react";
import { AssetIncludes, loadAsset } from "../../json_api/asset";
import { loadContextStateMachinesForAsset } from "../../json_api/context_state_machines";
import { used_tags_asset_logbook_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { CSMIncludes } from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { ErrorBoundary } from "../common/error_boundary";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { AssetEventsWidget } from "../widgets/asset_events_widget";
import { AssetInfoWidget } from "../widgets/asset_info_widget";
import { AssetMapWidget } from "../widgets/asset_map_widget";
import { AssetPartsWidget } from "../widgets/asset_parts_widget";
import { CommentWidget } from "../widgets/comment_widget";
import { SensorGroupWidget } from "../widgets/sensor_group_widget";
import { StateWidget } from "../widgets/state_widget";
import { AssetHomeProps } from "./asset_home.types";
const CSM_INCLUDES: CSMIncludes[] = [
  "state_context",
  "possible_states",
  "current_state",
];
const ASSET_INCLUDES: AssetIncludes[] = ["asset_type", "location"];
/**
 *
 *
 * @param {*} assetState
 */
export const AssetHome: React.FunctionComponent<AssetHomeProps> = ({
  floatingButtons = true,
  ...props
}) => {
  const assetQuery = useQuery({
    queryKey: ["asset", { id: props.assetId, include: ASSET_INCLUDES }],
    queryFn: (data) => {
      return loadAsset(props.assetId, ASSET_INCLUDES);
    },
  });

  const stateMachinesQuery = useQuery({
    queryKey: [
      "context_state_machines",
      {
        item_id: props.assetId,
        item_type: "Asset",
        include: CSM_INCLUDES,
      },
    ],
    queryFn: (data) =>
      loadContextStateMachinesForAsset(props.assetId, CSM_INCLUDES),
  });

  const logbookTagsQuery = useQuery({
    queryKey: ["logbook_tags", { logbook_id: assetQuery.data?.logbook_id }],
    enabled: !!assetQuery.data?.logbook_id,
    queryFn: (data) => {
      return loadDataFromUrl<string[]>(
        used_tags_asset_logbook_path(props.assetId, {
          format: "json",
        }),
      );
    },
  });

  const appContext = React.useContext(AppContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorBoundary>
              {assetQuery.isLoading ? (
                <Skeleton variant="rectangular" height={200} />
              ) : (
                <AssetEventsWidget
                  title={I18n.t("activerecord.models.asset_event.other")}
                  encloseInIBox={true}
                  assetId={props.assetId}
                  assetIds={assetQuery?.data.subtree_ids}
                  displayMode="detailed"
                  eventListSize={5}
                />
              )}
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SensorGroupWidget
              title={I18n.t("activerecord.models.sensor.other")}
              encloseInIBox
              assetId={props.assetId}
              vertical
              updateEnabled
            />
          </Grid>

          {!stateMachinesQuery.isLoading &&
          isEmpty(stateMachinesQuery.data) ? null : (
            <Grid item xs={12}>
              <ErrorBoundary>
                <Card>
                  <CardHeader
                    title={I18n.t("activerecord.models.state.other")}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      {assetQuery.isLoading || stateMachinesQuery.isLoading ? (
                        <Skeleton variant="rectangular" height={200} />
                      ) : assetQuery.data && stateMachinesQuery.data ? (
                        map(stateMachinesQuery.data, (csm) => {
                          const identifier = csm.state_context?.identifier;
                          const stateInfo =
                            assetQuery.data.asset_states[identifier];
                          return (
                            <Grid
                              item
                              xs={12}
                              sm
                              key={toString(stateInfo?.csm_id || identifier)}
                            >
                              <StateWidget
                                enableStateUpdate={appContext.user.isAdmin}
                                enableEditState={appContext.user.isAdmin}
                                encloseInIBox={true}
                                contextStateMachine={csm}
                                states={csm.possible_states}
                                assetId={props.assetId}
                              />
                            </Grid>
                          );
                        })
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </ErrorBoundary>
            </Grid>
          )}
          {appContext.subscribedModules.logbook &&
          assetQuery.data?.logbook_id ? (
            <Grid item xs={12}>
              <CommentWidget
                title={I18n.t("activerecord.models.logbook.one")}
                itemId={assetQuery.data.logbook_id}
                tags={logbookTagsQuery.data}
                itemType="Logbook"
                assetId={props.assetId}
                encloseInIBox
                limit={5}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AssetInfoWidget assetId={props.assetId} encloseInIBox={true} />
          </Grid>
          <Grid item xs={12}>
            <AssetPartsWidget assetId={props.assetId} encloseInIBox={true} />
          </Grid>
          {props.map.url && assetQuery.data?.location?.lat ? (
            <Grid item xs={12}>
              <AssetMapWidget
                assetIds={[props.assetId]}
                mapUrl={props.map.url}
                attribution={props.map.attribution}
                encloseInIBox={true}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {floatingButtons ? (
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn
            onCancel={() => redirectTo("back")}
            cancelIcon={<KeyboardArrowLeft />}
          />
        </FixedBottomArea>
      ) : null}
    </Grid>
  );
};
