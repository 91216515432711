import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import { SensorGroupWidget as SensorGroupWidgetComponent } from "../components/widgets/sensor_group_widget";
import { IDType } from "../utils/urls/url_utils";
import Widget from "./widget";
import { WidgetConfigSerialized } from "./widget.types";
interface SensorGroupWidgetConfigSerialized extends WidgetConfigSerialized {
  sensor_ids?: IDType[];
}
/**
 * Javascript implementation of the SensorGroupWidget.
 *
 * @class SensorGroupWidget
 * @extends {Widget}
 */
export default class SensorGroupWidget extends Widget<SensorGroupWidgetConfigSerialized> {
  static getDomClassName(): string {
    return "sensor-group-widget";
  }

  static widgetClassName(): string {
    return "SensorGroupWidget";
  }

  readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data("config") as SensorGroupWidgetConfigSerialized;
    this.config = config;
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);
    if (this.config) {
      const props = SensorGroupWidgetComponent.serializedConfigToProps(
        this.config,
      );
      this.root.render(
        <AppRoot>
          <SensorGroupWidgetComponent {...props} encloseInIBox />
        </AppRoot>,
      );
    }
  }
  cleanup(): void {
    super.cleanup();
  }
}
