import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { cloneDeep, set } from "lodash";

import Start from "./Start/index";
import Repeat from "./Repeat/index";
import End from "./End/index";
import computeRRuleToString from "../utils/computeRRule/toString/computeRRule";
import computeRRuleFromString from "../utils/computeRRule/fromString/computeRRule";
import configureInitialState from "../utils/configureInitialState";
import translateLabel from "../utils/translateLabel";
import translations from "../translations";
import "../styles/index.css";
import { DATE_TIME_FORMAT } from "../constants";
import { Box, Divider, Grid } from "@mui/material";

class ReactRRuleGenerator extends PureComponent {
  // compute default view based on user's config
  constructor(props) {
    super(props);

    const state = configureInitialState(
      this.props.config,
      this.props.calendarComponent,
      this.props.id,
      this.props.dateTimeFormat,
    );

    let data = state.data;
    if (this.props.value) {
      // if value is provided to RRuleGenerator, it's used to compute state of component's forms
      data = computeRRuleFromString(
        state.data,
        this.props.value,
        this.props.dateTimeFormat,
      );
    }
    if (this.props.onChange === ReactRRuleGenerator.defaultProps.onChange) {
      // no onChange() was provided
      throw new Error(
        "No onChange() function has been passed to RRuleGenerator. \n" +
          "Please provide one, it's needed to handle generated value.",
      );
    }

    this.state = { ...state, data };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value) {
      const data = computeRRuleFromString(
        prevState.data,
        nextProps.value,
        nextProps.dateTimeFormat,
      );

      return { ...prevState, data };
    }

    return null;
  }

  handleChange = ({ target }) => {
    const newData = cloneDeep(this.state.data);
    set(newData, target.name, target.value);
    const rrule = computeRRuleToString(newData);

    this.setState({ data: newData });
    this.props.onChange(rrule);
  };

  render() {
    const {
      id,
      data: { start, repeat, end, options, error },
    } = this.state;

    return (
      <Box px={3}>
        {!options.hideError && error && (
          <Box className="alert alert-danger">
            {translateLabel(this.props.translations, "invalid_rrule", {
              value: error.value,
            })}
          </Box>
        )}

        <Box px={0} pt={3} border>
          <Grid container spacing={2}>
            {!options.hideStart && (
              <>
                <Start
                  id={`${id}-start`}
                  start={start}
                  handleChange={this.handleChange}
                  translations={this.props.translations}
                />

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Repeat
                id={`${id}-repeat`}
                repeat={repeat}
                handleChange={this.handleChange}
                translations={this.props.translations}
              />
            </Grid>

            {!options.hideEnd && (
              <Grid item xs={12}>
                <Divider />
                <End
                  id={`${id}-end`}
                  end={end}
                  handleChange={this.handleChange}
                  translations={this.props.translations}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    );
  }
}

ReactRRuleGenerator.propTypes = {
  id: PropTypes.string,
  config: PropTypes.shape({
    frequency: PropTypes.arrayOf(
      PropTypes.oneOf(["Yearly", "Monthly", "Weekly", "Daily", "Hourly"]),
    ),
    yearly: PropTypes.oneOf(["on", "on the"]),
    monthly: PropTypes.oneOf(["on", "on the"]),
    end: PropTypes.arrayOf(PropTypes.oneOf(["Never", "After", "On date"])),
    hideStart: PropTypes.bool,
    hideEnd: PropTypes.bool,
    hideError: PropTypes.bool,
    weekStartsOnSunday: PropTypes.bool,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  calendarComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dateTimeFormat: PropTypes.string,
};
ReactRRuleGenerator.defaultProps = {
  id: null,
  value: "",
  config: {},
  onChange() {},
  calendarComponent: null,
  dateTimeFormat: DATE_TIME_FORMAT,

  translations: translations.english,
};

export default ReactRRuleGenerator;
