import { ReduceStore } from "flux/utils";
import { includes, isNil, orderBy } from "lodash";
import { EventNotification } from "../../../models/event_notification";
import {
  AddNewNotification,
  LoadInitialStateAction,
  MarkNotificationsAsNoticed,
  NotificationAction,
  ResetStateAction,
} from "./notification_actions";
import NotificationDispatcher from "./notification_dispatcher";

export interface NotificationsState {
  originalTitle: string;
  newNotifications: EventNotification[];
  previousNotifications: EventNotification[];
}

type ActionHandler = (
  state: NotificationsState,
  action: NotificationAction,
) => NotificationsState;

export class NotificationsStore extends ReduceStore<
  NotificationsState,
  NotificationAction
> {
  originalTitle: string;
  constructor() {
    super(NotificationDispatcher);
    this.originalTitle = document.title;
  }

  getInitialState(): NotificationsState {
    return {
      originalTitle: this.originalTitle,
      newNotifications: [],
      previousNotifications: [],
    };
  }

  reduce(
    state: NotificationsState,
    action: NotificationAction,
  ): NotificationsState {
    const actionHandler = this[action.type] as ActionHandler;

    if (isNil(actionHandler)) {
      // handle unknown actions
      return state;
    }

    return actionHandler.call(this, state, action) as NotificationsState;
  }

  RESET_STATE(
    state: NotificationsState,
    action: ResetStateAction,
  ): NotificationsState {
    if (!isNil(this.originalTitle)) {
      document.title = this.originalTitle;
    }
    return this.getInitialState();
  }

  LOAD_INITIAL_STATE(
    state: NotificationsState,
    action: LoadInitialStateAction,
  ): NotificationsState {
    const newNotifications = orderBy(
      action.newNotifications,
      ["from"],
      ["desc"],
    );
    updateDocumentTitle(newNotifications?.length, this.originalTitle);
    return {
      originalTitle: this.originalTitle,
      newNotifications: newNotifications,
      previousNotifications: orderBy(
        action.previousNotifications,
        ["from"],
        ["desc"],
      ),
    };
  }

  ADD_NEW_NOTIFICATION(
    state: NotificationsState,
    action: AddNewNotification,
  ): NotificationsState {
    const newNotifications = [action.notification, ...state.newNotifications];
    updateDocumentTitle(newNotifications?.length, this.originalTitle);
    return {
      ...state,
      newNotifications: newNotifications,
    };
  }

  MARK_NOTIFICATIONS_AS_NOTICED(
    state: NotificationsState,
    action: MarkNotificationsAsNoticed,
  ): NotificationsState {
    const previousNotifications: EventNotification[] = [];
    const newNotifications: EventNotification[] = [];

    state.newNotifications.forEach((notification) => {
      if (includes(action.eventIds, notification.event_id)) {
        notification.read_by_user = true;
        // move noticed event notifications to the list of previous notifications
        previousNotifications.push(notification);
      } else {
        // event is unnoticed keep it in new notification list
        newNotifications.push(notification);
      }
    });

    // keep events that were already in previous events list
    state.previousNotifications.forEach((notification) =>
      previousNotifications.push(notification),
    );

    updateDocumentTitle(0, this.originalTitle);
    return {
      originalTitle: this.originalTitle,
      newNotifications: orderBy(newNotifications, ["from"], ["desc"]),
      previousNotifications: orderBy(previousNotifications, ["from"], ["desc"]),
    };
  }
}

/**
 * Add number of unoticed events to document title
 */
function updateDocumentTitle(
  notificationCount: number,
  originalTitle: string,
): void {
  if (isNil(notificationCount) || notificationCount == 0) {
    if (!isNil(originalTitle)) {
      document.title = originalTitle;
    }
  } else if (notificationCount < 100) {
    document.title = `(${notificationCount}) ${originalTitle}`;
  } else {
    document.title = `(99+) ${originalTitle}`;
  }
}

export default new NotificationsStore();
