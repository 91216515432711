/// <reference types="../../../definitions/index" />;

import { Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  clone,
  cloneDeep,
  compact,
  defaultTo,
  Dictionary,
  each,
  filter,
  flatten,
  get,
  isEmpty,
  isNil,
  map,
  pick,
  set,
  times,
} from "lodash";
import * as React from "react";
import { Component } from "react";

import { ProductModelJSONObject } from "../../../json_api/product_model";
import { Location } from "../../../models/location";
import { notifyAirbrake } from "../../../utils/airbrake_error_handler";
import { sendData } from "../../../utils/jquery_helper";
import { redirectTo } from "../../../utils/redirection";
import { error, success } from "../../../utils/toasts";
import { assetPath } from "../../../utils/urls";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { LocationForm } from "../../location_form/location_form";
import { AssetTemplateApi } from "../models/api_interface";
import {
  ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP,
  AssetTemplateConfig,
  AssetTemplateConfigsByAssetTemplate,
  AssetTemplateConfigsForTemplate,
  AssetTemplateConfigTree,
  AssetTemplateTree,
  AssetTemplateWithSensorProps,
} from "../models/models";
import { AssetTemplateFormContextProvider } from "./asset_template_form_context_provider";
import { AssetTemplateItemGroup } from "./asset_template_item_group";

export interface AssetTemplateFormProperties {
  assetTemplates: AssetTemplateTree;
  organization_id?: number;
  url: string;
}

export interface AssetTemplateFormState {
  assetTemplateConfigs: AssetTemplateConfigsByAssetTemplate;
  modelsByAssetType: Dictionary<ProductModelJSONObject[]>;
  isProcessing: boolean;
  organizationId: number;
  locationFormOpen: boolean;
}

interface AssetTemplateCreateAssetResponse {
  status: string;
  asset_id: number;
}

function applyAssetCounters(
  assetTemplateConfigs: AssetTemplateConfigsByAssetTemplate,
  assetConfigCountByAssetTemplateId: Record<number, number> = {},
  assetConfigCountByAssetTypeId: Record<number, number> = {},
  assetIndex = 0,
) {
  each(assetTemplateConfigs, (atc) => {
    each(atc.configs, (cfg) => {
      cfg.counters = defaultTo(cfg.counters, {});
      const indexByAssetTemplate = defaultTo(
        assetConfigCountByAssetTemplateId[cfg.asset_template_id],
        0,
      );

      cfg.counters.assetIndexByTemplate = indexByAssetTemplate;
      assetConfigCountByAssetTemplateId[cfg.asset_template_id] =
        indexByAssetTemplate + 1;
      const indexByAssetType = defaultTo(
        assetConfigCountByAssetTypeId[cfg.assetTemplate.asset_type_id],
        0,
      );
      cfg.counters.assetIndexByType = indexByAssetType;
      assetConfigCountByAssetTypeId[cfg.assetTemplate.asset_type_id] =
        indexByAssetType + 1;
      cfg.counters.globalAssetIndex = assetIndex;
      assetIndex++;
      applyAssetCounters(
        cfg.children,
        assetConfigCountByAssetTemplateId,
        assetConfigCountByAssetTypeId,
        assetIndex,
      );
    });
  });
}

function configFromAssetTemplate(
  at: AssetTemplateWithSensorProps,
): AssetTemplateConfig {
  const name = defaultTo(at.name, at.asset_type?.name);
  // count of the current instance
  return {
    name: name,
    asset_type_id: at.asset_type_id,
    asset_template_id: at.id,
    assetTemplate: at,
    location: null,
    serial: null,
    short_name: defaultTo(at.short_name, name),
    exclude_keys: at.exclude_keys,
    key: at.key,
    sensors: at.sensors,
    assetType: at.asset_type,
    product_model_id: null,
    enabled: true,
  };
}
function configFromAssetTemplateTree(
  tree: AssetTemplateTree,
): AssetTemplateConfigsForTemplate {
  const base = configFromAssetTemplate(tree);
  if (isNil(tree.children) || isEmpty(tree.children)) {
    return {
      configs: [base],
      enabled: true,
      assetTemplate: tree,
      minCount: tree.min_count,
      maxCount: tree.max_count,
    };
  }

  return {
    assetTemplate: tree,
    enabled: true,
    minCount: tree.min_count,
    maxCount: tree.max_count,
    configs: [
      {
        ...base, // copy properties to children, e.g., to pass excluded sensor keys

        // override rest of the properties by the children properties
        children: tree.children.map((childTree) =>
          configFromAssetTemplateTree(childTree),
        ),
      },
    ],
  };
}
export class AssetTemplateForm extends Component<
  AssetTemplateFormProperties,
  AssetTemplateFormState
> {
  constructor(props: AssetTemplateFormProperties) {
    super(props);

    const rootTree = {
      ...configFromAssetTemplateTree(props.assetTemplates),
      minCount: 1,
      maxCount: 1,
    };

    applyAssetCounters([rootTree]);
    this.state = {
      assetTemplateConfigs: [rootTree],
      isProcessing: false,
      organizationId: props.organization_id,
      locationFormOpen: false,
      modelsByAssetType: {},
    };
  }

  componentDidMount(): void {}

  handleLocationChange(path: string, newLocation: Location) {
    const atc = cloneDeep(this.state.assetTemplateConfigs);
    // set location of root asset
    set(atc, path, newLocation);
    this.setState({ assetTemplateConfigs: atc });
  }

  handleLocationFormOpen(open: boolean) {
    this.setState({ locationFormOpen: open });
  }

  render(): React.ReactNode {
    const location = this.state.assetTemplateConfigs[0]?.configs[0]?.location;
    /*const mapCenter =
      isEmpty(location) || isNil(location.lat) || isNil(location.lon)
        ? null
        : ([location.lat, location.lon] as [number, number]);*/
    return (
      <AssetTemplateFormContextProvider
        templates={this.props.assetTemplates}
        organizationId={this.props.organization_id}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IBox>
              <IBoxTitle
                tools={
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      this.handleLocationFormOpen(true);
                    }}
                  >
                    <Edit />
                    {I18n.t("frontend.asset_template_form.edit_location")}
                  </Button>
                }
              >
                <Typography variant="h5">
                  {I18n.t("activerecord.models.location", { count: 1 })}
                </Typography>{" "}
              </IBoxTitle>
              <IBoxContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {isNil(location) || isEmpty(location)
                      ? I18n.t("frontend.asset_template_form.no_location_set")
                      : compact(
                          map(
                            ["zip", "city", "street", "level", "room"],
                            (value: keyof Location) => {
                              const locValue = location[value];
                              return isEmpty(locValue) ? null : locValue;
                            },
                          ),
                        ).join(", ")}
                  </Grid>
                </Grid>
              </IBoxContent>
            </IBox>

            <Dialog
              style={{ zIndex: 2000 }}
              onClose={() => {
                this.setState({ locationFormOpen: false });
              }}
              open={this.state.locationFormOpen}
              fullWidth={true}
              maxWidth="lg"
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {I18n.t("activerecord.models.location", { count: 1 })}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {I18n.t("frontend.asset_template_form.select_location")}
                </DialogContentText>
                <LocationForm
                  location={
                    this.state.assetTemplateConfigs[0].configs[0].location
                  }
                  disabled={this.state.isProcessing}
                  onLocationChange={(newLocation) => {
                    const atc = cloneDeep(this.state.assetTemplateConfigs);

                    atc[0].configs[0].location = newLocation;
                    this.setState({ assetTemplateConfigs: atc });
                  }}
                ></LocationForm>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.handleLocationFormOpen(false);
                  }}
                  color="primary"
                >
                  {I18n.t("frontend.close")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <AssetTemplateItemGroup
              readonly={this.state.isProcessing}
              assetTemplateConfigs={this.state.assetTemplateConfigs}
              assetTemplateConfigPath={[]}
              onChangeEnabled={(path, enabled) =>
                this.onChangeEnabled(path, enabled)
              }
              onChangeQuantity={(path, quantity) =>
                this.onChangeQuantity(path, quantity)
              }
              onDeleteAssetTemplateConfig={(path) =>
                this.onDeleteAssetTemplateConfig(path)
              }
              onChangeAssetTemplateConfig={(
                path: Array<number | string>,
                atc: AssetTemplateConfig,
              ) => {
                this.onChangeAssetTemplateConfig(path, atc);
              }}
            />
          </Grid>
          <FixedBottomArea id="fixed-bottom-area">
            <FloatingButtons
              disableCancel={
                this.state.isProcessing || this.state.locationFormOpen
              }
              disableSave={
                this.state.isProcessing || this.state.locationFormOpen
              }
              isProcessing={this.state.isProcessing}
              onSubmit={() => void this.onSubmit()}
              onCancel={() => this.onCancel()}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.asset_template_form.submit_title")}
            />
          </FixedBottomArea>
        </Grid>
      </AssetTemplateFormContextProvider>
    );
  }

  async onSubmit() {
    const submitData = {
      config: this.createSubmitData(
        this.state.assetTemplateConfigs[0].configs[0],
      ),
    };

    try {
      this.setState({ isProcessing: true });
      const resp = await sendData<
        { config: AssetTemplateApi },
        AssetTemplateCreateAssetResponse
      >(this.props.url, submitData, "POST");

      if (resp.status === "ok") {
        void success(
          I18n.t("frontend.asset_template_form.success_title"),
          I18n.t("frontend.asset_template_form.success_body"),
        );
        redirectTo(assetPath(resp.asset_id));
      }
    } catch (err) {
      this.setState({ isProcessing: false });
      void error(
        I18n.t("frontend.asset_template_form.error_creating_asset"),
        I18n.t("frontend.asset_template_form.asset_creation_error"),
      );
      void notifyAirbrake(err as Error);
    }
  }

  createSubmitData(
    assetTemplateConfig: AssetTemplateConfigTree,
  ): AssetTemplateApi {
    const data: AssetTemplateApi = {
      ...pick(assetTemplateConfig, [
        "asset_template_id",
        "asset_type_id",
        "product_model_id",
        "name",
        "key",
        "short_name",
        "exclude_keys",
        "serial",
        "location",
      ]),
    };

    const children = flatten(
      filter(assetTemplateConfig.children, (child) => child.enabled).map(
        (child) =>
          map(flatten(child.configs), (config) =>
            this.createSubmitData(config),
          ),
      ),
    );
    if (!isNil(children) && !isEmpty(children)) {
      data.children = children;
    }

    return data;
  }
  onCancel() {
    redirectTo("back");
  }
  onChangeAssetTemplateConfig(
    path: Array<number | string>,
    atc: AssetTemplateConfig,
  ) {
    const assetTemplateConfigs = clone(this.state.assetTemplateConfigs);
    set(assetTemplateConfigs, path, { ...atc });

    this.setState({ assetTemplateConfigs });
  }

  onDeleteAssetTemplateConfig(path: Array<number | string>) {
    const assetTemplateConfigs = clone(this.state.assetTemplateConfigs);
    const arrayPath = path.slice(0, path.length - 1);
    const atcIndex: number = path[path.length - 1] as number;
    const configArray: Array<any> = get(
      assetTemplateConfigs,
      arrayPath,
    ) as AssetTemplateConfig[];

    configArray.splice(atcIndex, 1);
    set(assetTemplateConfigs, arrayPath, configArray);
    this.setState({ assetTemplateConfigs });
  }

  onChangeQuantity(path: Array<number | string>, quantity: number) {
    const assetTemplateConfigs = cloneDeep(this.state.assetTemplateConfigs);
    const templateConfigsAtPath: AssetTemplateConfigsForTemplate = get(
      assetTemplateConfigs,
      path,
    ) as AssetTemplateConfigsForTemplate;

    const oldLength = templateConfigsAtPath.configs.length;
    if (oldLength === quantity) return;

    if (oldLength < quantity) {
      const noItemsToAdd = quantity - oldLength;
      const startLen = oldLength;
      const at = templateConfigsAtPath.assetTemplate;

      times(noItemsToAdd, (index) => {
        let name = at.name;
        if (isEmpty(at.name)) {
          name = at.asset_type.name;
        }
        // scan for template variables
        if (isEmpty(name.match(ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP))) {
          // add a number to the name for distinguishment as there are no replacements applied later on
          name = `${name} ${startLen + index + 1}`;
        }

        let shortName = at.short_name;
        if (
          !isEmpty(shortName) &&
          isEmpty(name.match(ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP))
        ) {
          // add a number to the name for distinguished as there are no replacements applied later on
          shortName = `${shortName} ${startLen + index + 1}`;
        }

        let key = at.key;
        if (
          !isEmpty(key) &&
          isEmpty(key.match(ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP))
        ) {
          // add a number to the name for distinguished as there are no replacements applied later on
          key = `${key} ${startLen + index + 1}`;
        }

        templateConfigsAtPath.configs.push({
          name: `${defaultTo(at.name, at.asset_type.name)} `,
          serial: null,
          key,
          short_name: shortName,
          asset_template_id: at.id,
          children: map(at.children, (childTree) =>
            configFromAssetTemplateTree(childTree),
          ),
          exclude_keys: at.exclude_keys,
          sensors: at.sensors,
          assetTemplate: at,
          assetType: at.asset_type,
          product_model_id: null,
          enabled: true,
        });
      });
    } else if (templateConfigsAtPath.configs.length > quantity) {
      templateConfigsAtPath.configs.splice(
        quantity,
        templateConfigsAtPath.configs.length - quantity,
      );
    }

    const countByTemplate = {};
    applyAssetCounters(assetTemplateConfigs, countByTemplate);

    this.setState({ assetTemplateConfigs });
  }

  onChangeEnabled(path: Array<string | number>, enabled: boolean) {
    const assetTemplateConfigs = cloneDeep(this.state.assetTemplateConfigs);
    const templateConfigsAtPath: AssetTemplateConfigsForTemplate = get(
      assetTemplateConfigs,
      path,
    ) as AssetTemplateConfigsForTemplate;
    templateConfigsAtPath.enabled = enabled;
    templateConfigsAtPath.configs = templateConfigsAtPath.configs.map(
      (atc) => ({ ...atc, enabled }),
    );
    set(assetTemplateConfigs, path, templateConfigsAtPath);
    this.setState({ assetTemplateConfigs });
  }
}
