import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Bluebird from "bluebird";
import * as JSONAPI from "jsonapi-typescript";
import {
  Dictionary,
  NumericDictionary,
  chain,
  clone,
  each,
  isEmpty,
  isNil,
  map,
  max,
  min,
} from "lodash";
import moment from "moment";
import * as React from "react";

import {
  jsonApiFilterParamsArray,
  jsonApiIncludeParamsArray,
  jsonApiPagingParamsArray,
} from "../../../json_api/jsonapi_tools";
import {
  MeasurementJSONAPIAttributesObject,
  MeasurementJSONApiAttributes,
} from "../../../json_api/measurement";
import { MeasurementPlanJSONApiAttributes } from "../../../json_api/measurement_plan";
import { MeasurementTypeJsonAttributes } from "../../../json_api/measurement_type";
import { MeasurementValueJSONAPIAttributesObject } from "../../../json_api/measurement_value";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import {
  MeasurementAttributes,
  MeasurementValue,
  ValuesForMeasurementIds,
} from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { loadDataFromUrl } from "../../../utils/jquery_helper";
import { redirectTo } from "../../../utils/redirection";
import { newAssetMeasurementPath } from "../../../utils/urls";
import { applyParamsDataToBaseUrl } from "../../../utils/urls/url_utils";
import { FilePreviewModal } from "../../common/file_preview_modal";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxFooter, IBoxTitle } from "../../common/ibox";
import { LoadingIcon } from "../../common/icon";
import { PageSizeSelect } from "../../common/page_size_select";
import { handleJsonApiMeasurements } from "../utils/handle_json_api_measurements";
import { MeasurementItem } from "./measurement_item";
import { MeasurementValueDiagram } from "./measurement_value_diagram";
import { Compress, Expand } from "@mui/icons-material";

interface MeasurementListProperties {
  assetId?: string | number;
  measurementsUrl?: string;
  measurements?: MeasurementJSONApiAttributes[];
  measurementTypeById?: Dictionary<MeasurementTypeJsonAttributes>;
  measurementValueDefinitionsByMeasurementId?: Dictionary<
    MeasurementValueDefinition[]
  >;
  measurementValueByMeasurementId?: ValuesForMeasurementIds;
  measurementCategoriesById?: Dictionary<MeasurementCategory>;
  measurementPlan?: MeasurementPlanJSONApiAttributes;
  currentPage?: number;
  totalPages?: number;
  totalItems?: number;
  pageSize?: number;
}

interface CollapsibleMeasurementItem extends MeasurementJSONApiAttributes {
  collapsed: boolean;
}

interface MeasurementListState {
  isFetching: boolean;
  measurements?: CollapsibleMeasurementItem[];
  measurementValueDefinitionsByMeasurementId?: Dictionary<
    MeasurementValueDefinition[]
  >;
  measurementValueByMeasurementId?: ValuesForMeasurementIds;
  measurementValues: MeasurementValue[];
  measurementTypeById?: Dictionary<MeasurementTypeJsonAttributes>;
  measurementCategoriesById?: Dictionary<MeasurementCategory>;
  measurementCategories: MeasurementCategory[];
  measurementPlan?: MeasurementPlanJSONApiAttributes;
  measurementValueDefinitions: MeasurementValueDefinition[];
  fileToPreview?: ActiveStorageStoredFile;
  loadedPage?: number;
  loadedPageSize?: number;
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  groupMeasurementValues?: boolean;

  totalItems?: number;
}

/**
 * Extract list of uniq values from ID to values dictionary
 * @param dictionary
 */
function getUniqueValues<T extends { id?: string | number }>(
  dictionary: Dictionary<T | T[]> | NumericDictionary<T | T[]>,
): T[] {
  if (isEmpty(dictionary)) {
    return [];
  }

  return chain(dictionary)
    .values()
    .flatten()
    .compact()
    .uniqBy("id")
    .value() as T[];
}

export class MeasurementList extends React.Component<
  MeasurementListProperties,
  MeasurementListState
> {
  static getMeasurementValues(
    measurements: MeasurementAttributes[],
    valuesForMeasurementIds?: ValuesForMeasurementIds,
  ): MeasurementValue[] {
    const measurementValue: MeasurementValue[] = [];

    if (isEmpty(measurements) || isEmpty(valuesForMeasurementIds)) {
      return [];
    }
    measurements.forEach((measurement) => {
      const values = valuesForMeasurementIds[measurement.id as number];
      if (isEmpty(values)) {
        return;
      }

      values.forEach((value) => {
        measurementValue.push({
          timestamp: measurement.created_at,
          ...value,
        });
      });
    });

    return measurementValue.sort((a, b) =>
      moment(a.timestamp).diff(b.timestamp),
    );
  }

  constructor(props: MeasurementListProperties) {
    super(props);
    this.state = {
      isFetching: false,
      measurements: map(props.measurements, (measurement) => {
        return { ...measurement, collapsed: true };
      }),
      measurementValueByMeasurementId:
        props.measurementValueByMeasurementId ?? {},
      measurementValueDefinitionsByMeasurementId:
        props.measurementValueDefinitionsByMeasurementId ?? {},
      measurementCategoriesById: props.measurementCategoriesById ?? {},
      measurementTypeById: props.measurementTypeById,
      loadedPage: !isNil(props.measurements) ? props.currentPage : null,
      loadedPageSize: props.pageSize,
      measurementPlan: props.measurementPlan,
      currentPage: props.currentPage,
      totalPages: props.totalPages,
      groupMeasurementValues: false,
      pageSize: props.pageSize,
      totalItems: props.totalItems,
      measurementValueDefinitions: getUniqueValues<MeasurementValueDefinition>(
        props.measurementValueDefinitionsByMeasurementId,
      ),
      measurementCategories: getUniqueValues(props.measurementCategoriesById),
      measurementValues: MeasurementList.getMeasurementValues(
        props.measurements,
        props.measurementValueByMeasurementId,
      ),
    };
  }

  componentDidMount(): void {
    if (!isNil(this.props.measurementsUrl) && isNil(this.state.measurements)) {
      void this.fetchMeasurements();
    }
    if (
      !isNil(this.state.measurements) &&
      isNil(this.state.measurementValueByMeasurementId)
    ) {
      void this.fetchMeasurementValues();
    }
  }

  componentDidUpdate(prevProps: MeasurementListProperties): void {
    if (
      this.props.measurementValueDefinitionsByMeasurementId !==
      prevProps.measurementValueDefinitionsByMeasurementId
    ) {
      void this.fetchMeasurementValues();
    }
  }

  render(): React.ReactNode {
    if (isEmpty(this.props.measurements)) {
      return (
        <IBox>
          <IBoxContent>
            <div className="text-center">
              {I18n.t("frontend.measurement_list.no_measurements")}
            </div>
          </IBoxContent>
          {this.renderFixedBottomArea()}
        </IBox>
      );
    }
    if (this.state.isFetching) {
      return (
        <IBox>
          <IBoxContent>
            <LoadingIcon size="6x"></LoadingIcon>
          </IBoxContent>
        </IBox>
      );
    }

    return (
      <>
        <IBox>
          <IBoxTitle
            tools={
              this.state.measurementPlan.measurement_type_type !==
              "distribution_measurement_type" ? null : (
                <>
                  <Box displayPrint="none">
                    <ButtonGroup size="small">
                      <Button
                        disabled={this.state.groupMeasurementValues !== true}
                        onClick={() =>
                          this.setState({ groupMeasurementValues: false })
                        }
                      >
                        {I18n.t("frontend.measurement_list.show_ungrouped")}
                      </Button>
                      <Button
                        disabled={this.state.groupMeasurementValues === true}
                        onClick={() =>
                          this.setState({ groupMeasurementValues: true })
                        }
                      >
                        {I18n.t(
                          "frontend.measurement_list.show_grouped_by_category",
                        )}
                      </Button>
                    </ButtonGroup>
                  </Box>
                </>
              )
            }
          >
            <Typography variant="h5">
              {this.state.measurementPlan?.measurement_type_title}
            </Typography>
          </IBoxTitle>
          <IBoxContent>
            <MeasurementValueDiagram
              measurementValueDefinitions={
                this.state.measurementValueDefinitions
              }
              measurementCategories={this.state.measurementCategories}
              measurementValues={this.state.measurementValues}
              mvdIntervalUnit={
                this.state.measurementTypeById[
                  this.state.measurementPlan.measurement_type_id
                ]?.interval_unit
              }
              measurementTypesById={this.state.measurementTypeById}
              groupMeasurementValues={this.state.groupMeasurementValues}
            />
          </IBoxContent>
        </IBox>
        <IBox>
          <IBoxTitle
            tools={
              <Box displayPrint="none">
                <ButtonGroup size="small">
                  <IconButton
                    size="small"
                    onClick={() => {
                      const newMeasurements = map(
                        this.state.measurements,
                        (m) => ({ ...m, collapsed: true }),
                      );
                      this.setState({ measurements: newMeasurements });
                    }}
                  >
                    <Compress />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      const newMeasurements = map(
                        this.state.measurements,
                        (m) => ({ ...m, collapsed: false }),
                      );
                      this.setState({ measurements: newMeasurements });
                    }}
                  >
                    <Expand />
                  </IconButton>
                </ButtonGroup>
              </Box>
            }
          />
          <IBoxContent>
            {map(this.state.measurements, (measurement, index) => {
              return (
                <MeasurementItem
                  measurement={measurement}
                  measurementValues={
                    this.state?.measurementValueByMeasurementId[measurement.id]
                  }
                  measurementPlan={this.state.measurementPlan}
                  measurementType={
                    this.state.measurementTypeById[
                      this.state.measurementPlan.measurement_type_id
                    ]
                  }
                  orderedMeasurementValueDefinitions={
                    this.state.measurementValueDefinitionsByMeasurementId[
                      measurement.id
                    ]
                  }
                  collapsed={measurement.collapsed}
                  measurementCategoryById={this.state.measurementCategoriesById}
                  onToggle={(collapsed) => {
                    const newMeasurements = clone(this.state.measurements);
                    newMeasurements[index] = {
                      ...newMeasurements[index],
                      collapsed: collapsed,
                    };
                    this.setState({ measurements: newMeasurements });
                  }}
                  key={`measurement-${measurement.id}`}
                  onShowFilePreview={(file) => this.onShowFilePreview(file)}
                />
              );
            })}
          </IBoxContent>

          <IBoxFooter>
            <Grid container>
              <Grid item container xs={12} justifyContent="space-between">
                <Grid item>
                  <Box height="100%" display="flex" alignItems="center">
                    {I18n.t("frontend.total")}: {this.state.totalItems}
                  </Box>
                </Grid>
                <Grid item xs="auto">
                  {this.state.totalPages && this.state.totalPages > 1 ? (
                    <Pagination
                      page={this.state.currentPage}
                      count={this.state.totalPages}
                      onChange={(event, pageNumber) => {
                        this.onSelectPage(pageNumber);
                      }}
                    />
                  ) : null}
                </Grid>
                <Grid item>
                  <PageSizeSelect
                    pageSize={this.state.pageSize}
                    onSelectPageSize={(newSize) => {
                      this.onSelectPage(this.state.currentPage, newSize);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </IBoxFooter>

          {this.renderFixedBottomArea()}
        </IBox>

        <FilePreviewModal
          url={this.state.fileToPreview?.url}
          contentType={this.state.fileToPreview?.content_type}
          fileName={this.state.fileToPreview?.filename}
          isOpen={!isNil(this.state.fileToPreview)}
          onClose={() => this.closeFilePreview()}
        />
      </>
    );
  }

  renderFixedBottomArea(): React.ReactNode {
    return (
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn={true}
          isProcessing={false}
          submitBtnIcon={<AddIcon />}
          saveTitle={I18n.t("frontend.measurement_list.add_new_measurement")}
          onSubmit={
            !isNil(this.props.measurementPlan?.asset_id)
              ? () => {
                  redirectTo(
                    newAssetMeasurementPath(
                      this.props.measurementPlan.asset_id,
                      this.props.measurementPlan.id,
                    ),
                  );
                }
              : null
          }
        >
          <Fab
            aria-label={I18n.t("frontend.print")}
            title={I18n.t("frontend.print")}
            color="default"
            size="medium"
            onClick={() => window.print()}
          >
            <PrintIcon />
          </Fab>
        </FloatingButtons>
      </FixedBottomArea>
    );
  }

  onSelectPage(
    pageNumber: number,
    pageSize: number = this.state.loadedPageSize,
  ): void {
    if (
      this.state.loadedPage != pageNumber ||
      this.state.pageSize != pageSize
    ) {
      this.setState(
        (prevState) => {
          return {
            loadedPage: prevState.currentPage,
            currentPage: pageNumber,
            pageSize: pageSize,
            isFetching: true,
            measurements: null,
          };
        },
        () => {
          void this.fetchMeasurements();
        },
      );
    }
  }

  closeFilePreview(): void {
    this.setState({ fileToPreview: null });
  }

  onShowFilePreview(file: ActiveStorageStoredFile): void {
    this.setState({ fileToPreview: file });
  }

  fetchMeasurements(force = false): void {
    if (
      this.state.currentPage != this.state.loadedPage ||
      this.state.pageSize != this.state.loadedPageSize
    ) {
      let pageToLoad = this.state.currentPage;
      if (this.state.pageSize != this.state.loadedPageSize) {
        // page size changed, fall back to first page for now
        pageToLoad = 1;
      }

      void loadDataFromUrl<
        JSONAPI.CollectionResourceDoc<
          string,
          MeasurementJSONAPIAttributesObject
        >
      >(
        applyParamsDataToBaseUrl(
          `${this.props.measurementsUrl}.json`,
          jsonApiIncludeParamsArray([
            "measurement_plan.measurement_type",
            "measurement_plan.measurement_value_definitions.measurement_category",
          ]).concat(jsonApiPagingParamsArray(pageToLoad, this.state.pageSize)),
        ),
      )
        .then((fetchedMeasurements) => {
          const measurementData =
            handleJsonApiMeasurements(fetchedMeasurements);

          this.setState((prevState) => {
            const measurements = measurementData.measurements.map((msrmnt) => {
              return { ...msrmnt, collapsed: true };
            });
            return {
              measurementPlan: measurementData.measurementPlan,
              measurements,
              measurementValueDefinitionById:
                measurementData.measurementValueDefinitions,
              measurementValueByMeasurementId:
                measurementData.measurementValueByMeasurementId,
              measurementValueDefinitionsByMeasurementId:
                measurementData.measurementValueDefinitionsByMeasurementId,
              measurementCategoriesById:
                measurementData.measurementCategoryById,
              measurementValueDefinitions:
                getUniqueValues<MeasurementValueDefinition>(
                  measurementData.measurementValueDefinitionsByMeasurementId,
                ),
              measurementCategories: getUniqueValues(
                measurementData.measurementCategoryById,
              ),

              measurementValues: MeasurementList.getMeasurementValues(
                measurements,
                measurementData.measurementValueByMeasurementId,
              ),

              measurementTypeById: measurementData.measurementTypeById,
              currentPage: pageToLoad,
              loadedPage: pageToLoad,
              loadedPageSize: prevState.pageSize,
              totalPages: !isNil(fetchedMeasurements?.meta?.page_count)
                ? (fetchedMeasurements.meta.page_count as number)
                : prevState.totalPages,
              totalItems: !isNil(fetchedMeasurements?.meta?.record_count)
                ? (fetchedMeasurements.meta.record_count as number)
                : prevState.totalItems,
              isFetching: false,
            };
          });
        })
        .catch((err) => {
          this.setState({
            measurements: null,
            loadedPage: null,
            totalItems: null,
            currentPage: null,
            isFetching: false,
          });
        });
    }
  }

  fetchMeasurementValues(): Bluebird<void> {
    this.setState({
      isFetching: true,
    });
    const timestamps = this.state.measurements?.map((m) =>
      moment(m.created_at),
    );
    const startTime = min(timestamps);
    const endTime = max(timestamps).add(1, "millisecond");
    const timeFilter = `${startTime.toISOString()}..${endTime.toISOString()}`;
    const measurementValueDefinitionIds = chain(
      this.state.measurementValueDefinitionsByMeasurementId,
    )
      .values()
      .flatten()
      .compact()
      .map("id")
      .uniq()
      .value();

    return loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<
        string,
        MeasurementValueJSONAPIAttributesObject
      >
    >(
      applyParamsDataToBaseUrl(
        "/api/measurement_values.json" as string,
        jsonApiFilterParamsArray(
          "measurement_value_definition_id",
          measurementValueDefinitionIds,
        ).concat(jsonApiFilterParamsArray("timestamp", timeFilter)),
      ).toString(),
    ).then((measurementValues) => {
      const measurementValueByMeasurementId: ValuesForMeasurementIds = {};

      measurementValues?.data?.forEach((measurementValue) => {
        let measurementValuesForId =
          measurementValueByMeasurementId[
            measurementValue.attributes.measurement_id
          ];
        if (isNil(measurementValuesForId)) {
          measurementValuesForId = [];
          measurementValueByMeasurementId[
            measurementValue.attributes.measurement_id
          ] = measurementValuesForId;
        }

        measurementValuesForId.push({
          ...measurementValue.attributes,
        });
      });

      this.setState({
        measurementValueByMeasurementId,
        measurementValues: MeasurementList.getMeasurementValues(
          this.state.measurements,
          measurementValueByMeasurementId,
        ),

        isFetching: false,
      });
    });
  }
}
