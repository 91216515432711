import { Close } from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CollectionResourceDoc } from "jsonapi-typescript";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { SubscriptionJSONAPIAttributes } from "../../json_api/subscription";
import { api_organization_subscriptions_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { LoadingIcon } from "../common/icon";
import { ExtensiblePageSettings } from "../common/page_size";
import { PageSizeSelect } from "../common/page_size_select";
import { SubscriptionPeriodList } from "../subscription_periods/subscription_period_list";
import { SubscriptionCard } from "./subscrition_card";
import { AppContext } from "../common/app_context/app_context_provider";
interface SubscriptionCardsProps {
  organizationId?: IDType;
  pageSize?: number;
}

export const SubscriptionCards: React.FunctionComponent<
  SubscriptionCardsProps
> = (props) => {
  const context = React.useContext(AppContext);
  const [subscriptions, setSubscriptions] =
    React.useState<SubscriptionJSONAPIAttributes[]>();
  const [selectedSubscription, setSelectedSubscription] = React.useState(null);

  const [pageSettings, setPageSettings] =
    React.useState<ExtensiblePageSettings>({
      number: 1,
      size: defaultTo(props.pageSize, 20),
    });

  const [loading, setLoading] = React.useState(false);
  const orgId = defaultTo(props.organizationId, context.currentOrganizationId);
  const loadSubscriptions = React.useEffect(() => {
    setLoading(true);

    loadDataFromUrl<
      CollectionResourceDoc<string, SubscriptionJSONAPIAttributes>
    >(
      api_organization_subscriptions_path(orgId, {
        organization_id: orgId,
        locale: I18n.locale,
        format: "json",
        page: pageSettings,
        include: "subscription_plan",
        _options: true,
      }),
    )
      .then((loadedPlans) => {
        setSubscriptions(jsonApiResourceCollectionToFlatObjects(loadedPlans));
      })
      .catch((e) => {
        void error(I18n.t("base.error", e.message));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSettings]);

  return (
    <Grid container spacing={4}>
      {loading ? (
        <Grid item xs="auto">
          <LoadingIcon size="4x" />
        </Grid>
      ) : (
        map(subscriptions, (subscription, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <SubscriptionCard
              onRequestPeriodsDisplay={(s) => setSelectedSubscription(s)}
              subscription={subscription}
            />
          </Grid>
        ))
      )}

      <Grid item xs={12}>
        <PageSizeSelect
          pageSize={pageSettings.size}
          onSelectPageSize={(size) => {
            setPageSettings({ ...pageSettings, size });
          }}
        />
      </Grid>
      {isNil(selectedSubscription) ? null : (
        <Dialog
          open={!isNil(selectedSubscription)}
          fullWidth
          maxWidth="lg"
          onClose={() => {
            setSelectedSubscription(null);
          }}
        >
          <DialogTitle>
            <Box displayPrint="none" position="absolute" right={8} top={8}>
              <ButtonGroup>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setSelectedSubscription(null);
                  }}
                  size="large"
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </ButtonGroup>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SubscriptionPeriodList
                  subscriptionId={selectedSubscription.id}
                  organizationId={orgId}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
};
