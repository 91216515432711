import { snakeCase } from "lodash";
import { Asset as AssetAttributes } from "../../../models/asset";
import { ErrorMap } from "../../../utils/error_map";
export type MaintenanceScheduleType =
  | "planned_after_runtime"
  | "planned_after_runtime_or_time"
  | "planned_after_time"
  | "unplanned";

export type MaintenanceTypeType = "MaintenanceType" | "InspectionType";

/**
 * JSON equivalent of the ActiveRecord MaintenanceType model
 */
export interface MaintenanceType {
  id: string | number;
  type: MaintenanceTypeType;
  name_de: string;
  name_en: string;
  asset_type_id: string | number;
  maintenance_type: MaintenanceScheduleType;
  critical_value?: number;
  rrule?: string;
  notification_setting?: string;
  [prop: string]: any;
}

export type MaintenancePlanType = "MaintenancePlan" | "InspectionPlan";

export const AllMaintenancePlanTypes: MaintenancePlanType[] = [
  "MaintenancePlan",
  "InspectionPlan",
];

export const AllMaintenancePlanTypesUnderscore =
  AllMaintenancePlanTypes.map(snakeCase);
/**
 * JSON equivalent of the ActiveRecord MaintenancePlan model
 */
export interface MaintenancePlan {
  id: string | number;
  type: MaintenancePlanType;
  maintenance_type_id: string | number;
  asset_id: string | number;
  status_measurement_id: string | number;
  name_de: string;
  name_en: string;
  maintenance_plan_type?: MaintenanceScheduleType;
  critical_value?: number;
  rrule?: string;
  notification_setting?: string;
  position?: number;
  errors?: ErrorMap;
  [prop: string]: any;
}

/**
 * JSON equivalent of the ActiveRecord Asset model
 */
export interface Asset extends AssetAttributes {
  open?: boolean;
  maintenance_plans: MaintenancePlan[];
  [prop: string]: any;
}

export interface AssetGroup {
  title: string;
  title_link: string;
  assets: Asset[];
}

export type AssetGroups = Array<AssetGroup>;

export interface MaintenancePlanErrorResponse {
  errors: string[];
  maintenance_plans: Array<{ errors: ErrorMap }>;
}

export interface MaintenancePlanFilterSelection
  extends MaintenancePlanTypeFilterSelection {
  assetIds: number[];
}

export interface MaintenancePlanTypeFilterSelection {
  maintenancePlanTypes: MaintenancePlanType[];
}
