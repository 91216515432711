import { each, isEmpty, replace } from "lodash";
import { ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP } from "../components/asset_template_form/models/models";

const MAX_REPLACEMENTS = 4;
export function replaceTemplate(
  string: string,
  replacements: Record<string, string>,
): string {
  if (isEmpty(string) || isEmpty(replacements)) {
    return string;
  }
  let replacementCount = 0;
  while (!isEmpty(string.match(ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP))) {
    each(replacements, (replacement, template) => {
      string = replace(string, template, replacement);
    });
    replacementCount += 1;
  }

  return string;
}
