import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE,
  ProductModelJSONObject,
} from "../../json_api/product_model";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
  JSONApiFormRequestMode,
} from "../../utils/jsonapi_form_tools";

import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { toInteger } from "lodash";
import { ASSET_TYPE_JSONAPI_RESOURCE_TYPE } from "../../json_api/asset_type";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import {
  MANUFACTURER_JSONAPI_RESOURCE_TYPE,
  ManufacturerJSONObject,
} from "../../json_api/manufacturer";
import { ProductModelModelFor } from "../../models/product_model";
import { api_product_model_path, api_product_models_path } from "../../routes";
import { loadDataFromUrl, sendJsonApiData } from "../../utils/jquery_helper";
import { apiProductModelsUrl } from "../../utils/urls/product_model_urls";
import { IDType, ParamsType } from "../../utils/urls/url_utils";

function buildSubmitData(
  productModel: ProductModelJSONObject,
  modelFor: ProductModelModelFor,
  selectedManufacturer: ManufacturerJSONObject,
): {
  mode: JSONApiFormRequestMode;
  submitData?: SingleResourceDoc<string, ProductModelJSONObject>;
} {
  const submitData = buildJsonApiSubmitData<ProductModelJSONObject>(
    productModel,
    "product_models",
    ["name", "identifier", "description", "model_for"],
  );
  const { submitData: submitDataDoc, mode } = submitData;
  // set the model_for attribute to the default value if it is not set - defaults to asset
  submitDataDoc.data.attributes.model_for =
    submitDataDoc.data.attributes.model_for || modelFor || "asset";

  if (mode == "create") {
    addHasOneRelationToJsonApiSubmitData(
      submitDataDoc,
      "asset_type",
      ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
      productModel.asset_type_id!,
      false,
    );
  }

  addHasOneRelationToJsonApiSubmitData(
    submitDataDoc,
    "manufacturer",
    MANUFACTURER_JSONAPI_RESOURCE_TYPE,
    // prefer the passed object, but fallback to the one in the product model
    selectedManufacturer?.id || productModel.manufacturer_id,
    true,
  );

  return { submitData: submitDataDoc, mode };
}

function getUrl(
  pageSize: number,
  pageNumber: number,
  assetTypeId?: IDType,
  modelFor?: ProductModelModelFor,
  searchTerm?: string,
) {
  const params: ParamsType = [];
  if (searchTerm) {
    params.push(["filter[search]", searchTerm]);
  }
  if (modelFor) {
    params.push([
      "filter[model_for]",
      modelFor === "asset" ? "asset," : "device",
    ]);
  }

  return apiProductModelsUrl(
    assetTypeId,
    pageNumber + 1,
    pageSize,
    ["manufacturer"],
    params,
  );
}

async function loadProductModels(
  pageNumber: number,
  pageSize: number,
  assetTypeId: IDType,
  modelFor: ProductModelModelFor,
  searchTerm: string,
) {
  const path = getUrl(pageSize, pageNumber, assetTypeId, modelFor, searchTerm);
  const jsonApiResponse =
    await loadDataFromUrl<
      CollectionResourceDoc<string, ProductModelJSONObject>
    >(path);
  const productModels =
    jsonApiResourceCollectionToFlatObjects<ProductModelJSONObject>(
      jsonApiResponse,
    );
  return {
    productModels,
    totalItems: toInteger(jsonApiResponse?.meta?.record_count),
    totalPages: toInteger(jsonApiResponse?.meta?.page_count),
  };
}

export const useFetchProductModel = (
  enabled: boolean,
  page: number,
  pageSize: number,
  modelFor: ProductModelModelFor,
  searchTerm: string,
  assetTypeId?: IDType,
) => {
  return useQuery({
    queryKey: [
      PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE,
      {
        assetTypeId,
        page: page,
        pageSize: pageSize,
        searchTerm,
        modelFor: modelFor,
      },
    ] as [
      string,
      {
        assetTypeId: IDType;
        page: number;
        pageSize: number;
        searchTerm: string;
        modelFor: ProductModelModelFor;
      },
    ],
    // only enabled if there are asset templates for the selected root, a selected
    enabled,
    // init with destructurable object
    initialData: { productModels: [], totalItems: 0, totalPages: 0 },
    queryFn: async ({ queryKey }) => {
      const result = await loadProductModels(
        queryKey[1].page,
        queryKey[1].pageSize,
        queryKey[1].assetTypeId,
        queryKey[1].modelFor,
        queryKey[1].searchTerm,
      );

      return result;
    },
  });
};

interface CreateProductModelOptions {
  productModel: ProductModelJSONObject;
  modelFor?: ProductModelModelFor;
  selectedManufacturer?: ManufacturerJSONObject;
  assetTpyeId?: IDType;
}

export const useCreateProductModel = (onError?: (eerData: unknown) => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      productModel,
      modelFor,
      selectedManufacturer,
    }: CreateProductModelOptions) => {
      const jsonAPISubmitData = buildSubmitData(
        productModel,
        modelFor,
        selectedManufacturer,
      );
      const { submitData, mode } = jsonAPISubmitData;

      if (mode !== "create") {
        throw new Error("Invalid mode - expected create");
      }

      addHasOneRelationToJsonApiSubmitData(
        submitData,
        "asset_type",
        ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
        productModel.asset_type_id,
        false,
      );

      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, ProductModelJSONObject>
      >(api_product_models_path(), submitData, "POST");
    },
    onError,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useUpdateProductModel = (onError?: (eerData: unknown) => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      productModel,
      modelFor,
      selectedManufacturer,
    }: CreateProductModelOptions) => {
      const { submitData, mode } = buildSubmitData(
        productModel,
        modelFor,
        selectedManufacturer,
      );

      if (mode !== "update") {
        throw new Error("Invalid mode - expected update");
      }

      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, ProductModelJSONObject>
      >(api_product_model_path(productModel.id), submitData, "PATCH");
    },
    onError,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useDeleteProductModel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: IDType) => {
      return sendJsonApiData(api_product_model_path(id), null, "DELETE");
    },
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE],
      });
      return data;
    },
  });
};
