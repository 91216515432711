import { defaultTo, isEmpty, isNil, kebabCase, map } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";

import ExpandMore from "@mui/icons-material/ExpandMore";
import { SingleResourceDoc } from "jsonapi-typescript";
import { AssetJSONAPIAttributes, loadAsset } from "../../json_api/asset";
import {
  AssetEventTypeJSONAPIAttributes,
  AssetEventTypeJSONObject,
  validateAssetEventType,
} from "../../json_api/asset_event_type";
import { AssetTypeJSONAPIAttributes } from "../../json_api/asset_type";
import {
  ModelErrors,
  jsonApiSingleResourceToFlatObject,
  modelPropertyError,
} from "../../json_api/jsonapi_tools";
import {
  ASSET_EVENT_TYPE_SLUG_REGEXP,
  AssetEventTypeCategories,
  AssetEventTypeCategory,
} from "../../models/asset_event_type";
import { api_asset_event_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { EventSeveritySelect } from "../asset_events/event_severity_selection";
export interface AssetEventTypeFormFieldsProps {
  asset?: AssetJSONAPIAttributes;
  assetType?: AssetTypeJSONAPIAttributes;
  assetId?: IDType;
  assetEventTypeId?: IDType;
  assetEventType?: AssetEventTypeJSONAPIAttributes;
  errors?: ModelErrors<AssetEventTypeJSONAPIAttributes>;
  onChangeAssetEventType: (
    assetEventType: AssetEventTypeJSONAPIAttributes,
  ) => void;
  onRequestValidation?: (
    assetEventType: AssetEventTypeJSONAPIAttributes,
  ) => void;
}

export const AssetEventTypeFormFields: FunctionComponent<
  AssetEventTypeFormFieldsProps
> = (props) => {
  const isNew = React.useCallback(
    () => isNil(props.assetEventTypeId) && isNil(props.assetEventType?.id),
    [props.assetEventType?.id, props.assetEventTypeId],
  );

  const [asset, setAsset] = React.useState(props.asset);
  const [assetEventType, setAssetEventType] = React.useState(
    props.assetEventType,
  );
  const [errors, setErrors] = React.useState<
    ModelErrors<AssetEventTypeJSONObject>
  >({});

  const [loading, setLoading] = React.useState(false);
  const [runningLoads, setRunningLoads] = React.useState(0);

  const requestValidation = React.useCallback(
    (theAssetEventType: AssetEventTypeJSONObject = null) => {
      const eventTypeToValidate = defaultTo(theAssetEventType, assetEventType);
      if (props.onRequestValidation) {
        props.onRequestValidation(eventTypeToValidate);
      } else {
        setErrors(validateAssetEventType(eventTypeToValidate));
      }
    },
    [props.onRequestValidation, assetEventType],
  );

  // determine load state
  React.useEffect(() => {
    setLoading(runningLoads > 0);
  }, [runningLoads]);

  React.useEffect(() => {
    setErrors(defaultTo(props.errors, {}));
  }, [props.errors]);

  // Load asset info
  React.useEffect(() => {
    if (!isNil(asset) && !isNil(props.assetId) && asset.id != props.assetId)
      // everything loaded and up to date
      return;
    if (!isNil(props.asset)) {
      setAsset(props.asset);
    } else {
      setRunningLoads(runningLoads + 1);
      loadAsset(props.assetId)
        .then((asset) => {
          setAsset(asset);
        })
        .catch((error) => {})
        .finally(() => {
          setRunningLoads(runningLoads - 1);
        });
    }
  }, [props.asset, props.assetId, props.assetEventType]);

  React.useEffect(() => {
    if (assetEventType != props.assetEventType) {
      props.onChangeAssetEventType(assetEventType);
    }
    setErrors({});
  }, [assetEventType, props.assetEventType]);

  React.useEffect(() => {
    if (isNil(props.assetEventTypeId) && isNil(props.assetEventType)) {
      const assets = isNil(asset)
        ? isNil(props.assetId)
          ? null
          : [{ id: props.assetId }]
        : [asset];
      const assetType = isNil(props.assetType) ? null : [props.assetType];
      // initialize asset event as none is given and no event to load by id
      setAssetEventType({
        assets,
        asset_types: assetType,
        default_severity_level: "info", //
      });
    } else {
      if (
        !isNil(props.assetEventTypeId) &&
        (isNil(props.assetEventType) ||
          props.assetEventType?.id != props.assetEventTypeId)
      ) {
        setRunningLoads(runningLoads + 1);
        void loadDataFromUrl<
          SingleResourceDoc<string, AssetEventTypeJSONAPIAttributes>
        >(
          api_asset_event_path(props.assetEventTypeId, {
            id: props.assetEventTypeId,
            format: "json",
            include: "asset,root_asset,event_type",
            _options: true,
          }),
        )
          .then((assetEventType) => {
            const assetEventTypeObj =
              jsonApiSingleResourceToFlatObject(assetEventType);
            setAssetEventType(assetEventTypeObj);
          })
          .catch((e) => {
            error(
              I18n.t("frontend.error"),
              I18n.t(
                "frontend.asset_event_types.asset_event_type_form.error_loading_event_type",
              ),
            );
          })
          .finally(() => {
            setRunningLoads(runningLoads - 1);
          });
      }
    }
  }, [props.assetEventTypeId, props.assetEventType?.id]);

  const [editDisabled, setEditDisabled] = React.useState(false);

  const title = isNew()
    ? I18n.t("frontend.asset_event_types.asset_event_type_form.title_new")
    : I18n.t("frontend.asset_event_types.asset_event_type_form.title_edit");

  return loading ? (
    <Grid item xs={12}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rectangular" height={50} />
      </Stack>
    </Grid>
  ) : (
    <>
      <Grid item xs={12}>
        <TextField
          onBlur={() => requestValidation()}
          fullWidth
          size="small"
          required
          disabled={editDisabled}
          label={I18n.t("activerecord.attributes.asset_event_type.slug")}
          onChange={(e) => {
            const slug = e.target.value;
            if (ASSET_EVENT_TYPE_SLUG_REGEXP.test(slug) || isEmpty(slug)) {
              setAssetEventType({
                ...assetEventType,
                slug: e.target.value,
              });
            }
          }}
          value={assetEventType?.slug ?? kebabCase(assetEventType?.name ?? "")}
          error={!isEmpty(errors.slug)}
          helperText={defaultTo(
            modelPropertyError(errors, "slug"),
            I18n.t(
              "frontend.asset_event_types.asset_event_type_form_fields.slug_helper_text",
            ),
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onBlur={() => requestValidation()}
          fullWidth
          size="small"
          required
          disabled={editDisabled}
          label={I18n.t("activerecord.attributes.asset_event_type.name")}
          onChange={(e) =>
            setAssetEventType({ ...assetEventType, name: e.target.value })
          }
          value={assetEventType?.name ?? ""}
          error={!isEmpty(errors.name)}
          helperText={modelPropertyError(errors, "name")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onBlur={() => requestValidation()}
          fullWidth
          type="text"
          multiline
          minRows={3}
          disabled={editDisabled}
          size="small"
          label={I18n.t("activerecord.attributes.asset_event_type.description")}
          onChange={(e) =>
            setAssetEventType({
              ...assetEventType,
              description: e.target.value,
            })
          }
          value={assetEventType?.description ?? ""}
          error={!isEmpty(errors.description)}
          helperText={isEmpty(errors.description) ? null : errors.description}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onBlur={() => requestValidation()}
          fullWidth
          type="text"
          disabled={editDisabled}
          size="small"
          select
          label={I18n.t("activerecord.attributes.asset_event_type.category")}
          onChange={(e) =>
            setAssetEventType({
              ...assetEventType,
              category: e.target.value as AssetEventTypeCategory,
            })
          }
          required
          value={assetEventType?.category ?? "none"}
          error={!isEmpty(errors.description)}
          helperText={isEmpty(errors.description) ? null : errors.description}
        >
          <MenuItem key={"none"} value={"none"}>
            {I18n.t("frontend.none")}
          </MenuItem>
          {map(AssetEventTypeCategories, (c) => (
            <MenuItem key={c} value={c}>
              {I18n.t(
                `activerecord.attributes.asset_event_type.categories.${c}`,
              )}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <EventSeveritySelect
          onBlur={() => {
            requestValidation();
          }}
          disabled={editDisabled}
          required
          severity={assetEventType?.default_severity_level ?? "info"}
          onSeveritySelect={(sl) => {
            setAssetEventType({
              ...assetEventType,
              default_severity_level: sl,
            });
          }}
          error={modelPropertyError(errors, "default_severity_level")}
        />
      </Grid>
      <Grid item xs={12}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2-content"
            id="with-additional-options"
          >
            {I18n.t(
              "frontend.asset_event_types.asset_event_type_form_fields.event_type_details",
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onBlur={() => {
                    requestValidation();
                  }}
                  disabled={editDisabled}
                  label={I18n.t(
                    "activerecord.attributes.asset_event_type.code",
                  )}
                  size="small"
                  fullWidth
                  value={assetEventType?.code ?? ""}
                  onChange={(e) =>
                    setAssetEventType({
                      ...assetEventType,
                      code: e.target.value,
                    })
                  }
                  error={!isEmpty(errors.code)}
                  helperText={
                    isEmpty(errors.code)
                      ? I18n.t(
                          "frontend.asset_event_types.asset_event_type_form.code_helper_text",
                        )
                      : modelPropertyError(errors, "code")
                  }
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onBlur={() => requestValidation()}
                  fullWidth
                  label={I18n.t(
                    "activerecord.attributes.asset_event_type.message",
                  )}
                  disabled={editDisabled}
                  size="small"
                  type="text"
                  minRows={3}
                  value={assetEventType?.message ?? ""}
                  onChange={(e) =>
                    setAssetEventType({
                      ...assetEventType,
                      message: e.target.value,
                    })
                  }
                  multiline
                  error={!isEmpty(errors.message)}
                  helperText={
                    isEmpty(errors.message)
                      ? I18n.t(
                          "frontend.asset_event_types.asset_event_type_form.message_helper_text",
                        )
                      : modelPropertyError(errors, "code")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onBlur={() => requestValidation()}
                  label={I18n.t(
                    "activerecord.attributes.asset_event_type.action",
                  )}
                  fullWidth
                  size="small"
                  type="text"
                  multiline
                  minRows={3}
                  value={assetEventType?.action ?? ""}
                  onChange={(e) =>
                    setAssetEventType({
                      ...assetEventType,
                      action: e.target.value,
                    })
                  }
                  error={!isEmpty(errors.action)}
                  helperText={
                    isEmpty(errors.message)
                      ? I18n.t(
                          "frontend.asset_event_types.asset_event_type_form.action_helper_text",
                        )
                      : errors.message
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
