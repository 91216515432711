import { isNil } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";

import { DocWithErrors, SingleResourceDoc } from "jsonapi-typescript";

import { Delete } from "@mui/icons-material";
import { AssetJSONAPIAttributes, AssetJSONObject } from "../../json_api/asset";
import {
  AssetEventTypeJSONAPIAttributes,
  AssetEventTypeJSONObject,
  deleteAssetEventType,
  saveAssetEventType,
} from "../../json_api/asset_event_type";

import { AssetTypeJSONAPIAttributes } from "../../json_api/asset_type";
import {
  ModelErrors,
  extractErrorsFromJsonApi,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { ResourcePermission } from "../../models/resource_permission";
import { api_asset_type_path, asset_events_path } from "../../routes";
import { HttpError, loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { AssetEventTypeFormFields } from "./asset_event_type_form_fields";

export interface AssetEventTypeFormProps {
  assetId?: IDType;
  asset?: AssetJSONAPIAttributes;
  assetType?: AssetTypeJSONAPIAttributes;
  assetTypeId?: IDType;

  assetEventTypeId?: IDType;
  assetEventType?: AssetEventTypeJSONAPIAttributes;

  permissions?: ResourcePermission;

  // Provide a list of assets when assets should be selectable. Otherwise only the provided assetId wil be used.
  selectableAssets?: AssetJSONObject[];
  selectFromAssetTreeWithAssetId?: IDType;
  withCard?: boolean;
  withFloatingButtons?: boolean;
  errors?: ModelErrors<AssetEventTypeJSONObject>;
  onCancel?: () => void;
  onDeleted?: (event: AssetEventTypeJSONObject) => void;
  onSaved?: (assetEvent: AssetEventTypeJSONAPIAttributes) => void;
  onChangeAssetEventType?: (assetEvent: AssetEventTypeJSONObject) => void;
}

export const AssetEventTypeForm: FunctionComponent<AssetEventTypeFormProps> = ({
  withCard = false,
  ...props
}) => {
  const isNew = React.useCallback(
    () => isNil(props.assetEventTypeId) && isNil(props.assetEventType?.id),
    [props.assetEventTypeId, props.assetEventType?.id],
  );

  const [assetEventType, setAssetEventType] = React.useState(
    props.assetEventType,
  );

  const [assetTypeForNew, setAssetTypeForNew] = React.useState(props.assetType);
  const [assetForNew, setAssetForNew] = React.useState(props.asset);

  React.useEffect(() => {
    if (!isNil(assetEventType?.id) && !isNil(props.assetEventTypeId)) {
      // not a new asset. Do not load asset type
      return;
    }

    if (isNil(props.assetTypeId)) {
      // no asset type id given
      if (isNil(assetTypeForNew)) {
        // no asset type loaded
        if (!isNil(props.assetType))
          // asset type provided by props
          setAssetTypeForNew(props.assetType);
      } else if (assetTypeForNew.id != props.assetType?.id) {
        if (!isNil(props.assetType)) setAssetTypeForNew(props.assetType);
      }
    } else {
      if (
        (!isNil(assetTypeForNew) && props.assetTypeId != assetTypeForNew.id) ||
        isNil(assetTypeForNew)
      ) {
        setLoading(true);
        loadDataFromUrl<SingleResourceDoc<string, AssetTypeJSONAPIAttributes>>(
          api_asset_type_path(props.assetTypeId),
        )
          .then((assetType) =>
            setAssetTypeForNew(jsonApiSingleResourceToFlatObject(assetType)),
          )
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [props.assetType?.id, props.assetTypeId]);

  const [errors, setErrors] = React.useState<
    ModelErrors<AssetEventTypeJSONObject>
  >({});
  const [loading, setLoading] = React.useState(false);
  const [runningLoads, setRunningLoads] = React.useState(0);

  React.useEffect(() => {
    if (
      props.onChangeAssetEventType &&
      assetEventType != props.assetEventType
    ) {
      props.onChangeAssetEventType(assetEventType);
    }
  }, [assetEventType]);

  React.useEffect(() => {
    if (assetEventType !== props.assetEventType) {
      setAssetEventType(props.assetEventType);
    }
  }, [props.assetEventType]);

  React.useEffect(() => {
    if (errors !== props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);
  // determine load state
  React.useEffect(() => {
    setLoading(runningLoads > 0);
  }, [runningLoads]);

  const title = isNew()
    ? I18n.t("frontend.asset_event_types.asset_event_type_form.title_new")
    : I18n.t("frontend.asset_event_types.asset_event_type_form.title_edit");

  const saveEventType = React.useCallback(() => {
    const theAssetEventType = assetEventType;
    setRunningLoads(runningLoads + 1);

    saveAssetEventType(theAssetEventType, assetForNew?.id, assetTypeForNew?.id)
      .then((data) => {
        void success(
          I18n.t("frontend.success"),
          I18n.t("frontend.saved_successfully"),
        ).then(() => {
          if (props.onSaved) {
            props.onSaved(data);
          } else {
            redirectTo();
          }
        });
      })
      .catch((e) => {
        setErrors(
          extractErrorsFromJsonApi<AssetEventTypeJSONObject>(
            (e as HttpError).request.responseJSON as DocWithErrors,
          ),
        );
      })
      .finally(() => {
        setRunningLoads(runningLoads - 1);
      });
  }, [runningLoads, assetTypeForNew, assetForNew, assetEventType]);

  let form = (
    <>
      {loading ? (
        <>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="rectangular" height={100} />
          </Stack>
        </>
      ) : (
        <AssetEventTypeFormFields
          errors={errors}
          assetEventTypeId={props.assetEventTypeId}
          assetType={assetTypeForNew}
          assetEventType={assetEventType}
          onChangeAssetEventType={(e) => setAssetEventType(e)}
        />
      )}
    </>
  );

  if (withCard) {
    form = (
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={4}>
            {form}
          </Grid>
        </CardContent>
        {props.withFloatingButtons ? null : (
          <CardActions>
            <Button
              onClick={() => {
                props.onCancel ? props.onCancel() : redirectTo();
              }}
            >
              {I18n.t("frontend.cancel")}
            </Button>
            <Button
              onClick={() => {
                void saveEventType();
              }}
            >
              {I18n.t("frontend.save")}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
  if (props.withFloatingButtons) {
    form = (
      <>
        {form}
        <FixedBottomArea>
          <FloatingButtons
            isProcessing={loading}
            onCancel={() => {
              props.onCancel ? props.onCancel() : redirectTo();
            }}
            onSubmit={async () => {
              saveEventType();
            }}
          >
            {props.permissions?.destroy && !isNil(assetEventType) ? (
              <Tooltip title={I18n.t("frontend.delete")}>
                <>
                  <div />
                  <Fab
                    size="medium"
                    color="error"
                    onClick={() => {
                      setLoading(true);
                      void deleteAssetEventType(assetEventType?.id)
                        .finally(() => {
                          setLoading(false);
                        })
                        .then(() => {
                          if (props.onDeleted) {
                            props.onDeleted(assetEventType);
                          } else {
                            redirectTo(asset_events_path());
                          }
                        });
                    }}
                  >
                    <Delete />
                  </Fab>
                </>
              </Tooltip>
            ) : null}
          </FloatingButtons>
        </FixedBottomArea>
      </>
    );
  }
  return form;
};
