import { isNil, merge, toInteger } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { OrganizationJSONAPIAttributes } from "../../json_api/organization";
import { SensorValueType } from "../../models/sensor";
import { OrganizationsWidgetConfigSerialized } from "../../widgets/organizations_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { OrganizationsList } from "../organizations/organization_list";
import {
  OrganizationsWidgetProps,
  OrganizationsWidgetState,
} from "./organizations_widget.types";
import { WidgetBox } from "./widget_box";

export class OrganizationsWidget
  extends React.Component<OrganizationsWidgetProps, OrganizationsWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<OrganizationsWidgetProps> = {
    allowFullscreen: true,
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,
    tableHeight: 300,
    pageSize: 20,
    enableSearch: true,
  };

  static serializedConfigToProps(
    config: OrganizationsWidgetConfigSerialized,
  ): OrganizationsWidgetProps {
    let totalItems: number = null;
    let totalPages: number = null;
    let orgs = null;
    if (!isNil(config.organizations)) {
      orgs =
        jsonApiResourceCollectionToFlatObjects<OrganizationJSONAPIAttributes>(
          config.organizations,
        );
      totalItems = toInteger(config.organizations?.meta?.record_count);
      totalPages = toInteger(config.organizations?.meta?.page_count);
    }

    return merge(widgetBoxPropsFromSerializedConfig(config), {
      enableSearch: config.enable_search,
      tableDensity: config.density,
      pageSize: config.page_size,
      pageNumber: config.page_number,
      tableHeight: config.table_height,
      organizationsBaseUrl: config.base_url,
      totalItems,
      totalPages,
      organizations: orgs,
      organizationActions: config.actions,
    } as OrganizationsWidgetProps);
  }
  constructor(props: OrganizationsWidgetProps) {
    super(props);
    this.state = {
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: props.title,
      titleLinkUrl: props.titleLinkUrl,
      contentLinkUrl: props.contentLinkUrl,
      totalItems: props.totalItems,
      totalPages: props.totalPages,
      pageSize: props.pageSize,
      organizations: props.organizations,
      loading: false,
    };
  }

  componentDidMount(): void {
    // initialize component, e.g. some loading from API
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // register listeners to widget controller
      // for sensors :
      /// WidgetController.getInstance().sensorDataChannel.removeEventListener(this, this.props.sensorId);
    }
  }

  componentDidUpdate(oldProps: OrganizationsWidgetProps): void {
    if (this.props.dataUpdateEnabled !== oldProps.dataUpdateEnabled) {
      //this.toggleSensorUpdates(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  render(): React.ReactNode {
    const content = (
      <OrganizationsList
        organizations={this.state.organizations}
        pageNumber={this.state.pageNumber}
        enableSearch={this.props.enableSearch}
        pageSize={this.state.pageSize}
        density={this.props.tableDensity}
        totalPages={this.state.totalPages}
        totalItems={this.state.totalItems}
        tableHeight={this.props.tableHeight}
        organizationActions={this.props.organizationActions}
        organizationsBaseUrl={this.props.organizationsBaseUrl}
      />
    );
    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          content
        ) : (
          <WidgetBox
            {...this.props}
            title={this.state.title}
            titleLinkUrl={this.state.titleLinkUrl}
            contentLinkUrl={this.state.contentLinkUrl}
          >
            {content}
          </WidgetBox>
        )}
      </>
    );
  }
}
