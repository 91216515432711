import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { AssetTypeJSONObject } from "./asset_type";
import { SensorTypeJSONObject } from "./sensor_type";
import { AssetTypesSensorType } from "../models/asset_types_sensor_type";

export const ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE =
  "asset_types_sensor_types";
export interface AssetTypesSensorTypeJSONObject
  extends AssetTypesSensorType,
    JSON.Object {
  asset_type?: AssetTypeJSONObject;
  sensor_type?: SensorTypeJSONObject;
}

export type AssetTypesSensorTypeJSONAPIAttributes =
  JSONAPI.AttributesObject<AssetTypesSensorTypeJSONObject>;
