import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import { ValueDifferenceWidget as ValueDifferenceWidgetComponent } from "../components/widgets/value_difference_widget";
import SensorValueWidget from "./sensor_value_widget";
import { ValueDifferenceWidgetConfigSerialized } from "./value_difference_widget.types";

/** Javascript part for the value difference widget. Requires additional parameters
 *
 *
 * @class ValueDifferenceWidget
 * @extends {SensorValueWidget}
 */
export default class ValueDifferenceWidget extends SensorValueWidget {
  config: ValueDifferenceWidgetConfigSerialized;

  static getDomClassName(): string {
    return "value-difference-widget";
  }

  protected constructor(element: JQuery) {
    super(element);
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data(
      "config",
    ) as ValueDifferenceWidgetConfigSerialized;
    this.config = config;
  }

  protected initComponent(element: JQuery, render = true) {
    super.initComponent(element, false);

    if (render) {
      const props = ValueDifferenceWidgetComponent.serializedConfigToProps(
        this.config,
      );

      this.root.render(
        <AppRoot>
          <ValueDifferenceWidgetComponent
            {...props}
            dashboardSettings={this.dashboardSettings}
          />
        </AppRoot>,
      );
    }
  }
  cleanup(): void {
    super.cleanup();
  }
}
