import { isEmpty, isNil } from "lodash";
import * as React from "react";

import { Delete, Search, Upload } from "@mui/icons-material";
import {
  Avatar,
  Button,
  ButtonGroup,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import { iconForContentType } from "../../../utils/icon_for_content_type";
import { UploadedFile } from "./file_upload_input";

interface PreviewableFile extends File {
  preview_url?: string;
}
interface FileUploadListItemProps {
  id?: string;
  file: PreviewableFile | UploadedFile;
  onShow?: () => void;
  onChange?: (file: File) => void;
  onRemove?: () => void;
}

export const FileUploadListItem: React.FunctionComponent<
  FileUploadListItemProps
> = (props: FileUploadListItemProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const onReplace = () => {
    if (isNil(fileInputRef.current)) {
      return;
    }
    fileInputRef.current.click();
  };

  return (
    <ListItem id={props.id}>
      <Grid container className="align-items-center">
        <Grid item xs={12} sm={3} lg={2}>
          {isEmpty(props.file.preview_url) ? (
            iconForContentType(props.file.type)
          ) : (
            <Avatar
              sx={{ marginX: "auto" }}
              variant="square"
              src={props.file.preview_url}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3} lg={5}>
          <Typography variant="h5" className="auto-hyphen">
            {props.file.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={5}>
          <ButtonGroup className="float-right">
            {isNil(props.onShow) ? null : (
              <Button
                className="btn-primary auto-hyphen align-items-center"
                size="small"
                startIcon={<Search />}
                title={I18n.t("frontend.file_upload_input.show")}
                onClick={props.onShow}
              >
                <span className="d-none d-xl-inline">
                  {I18n.t("frontend.file_upload_input.show")}
                </span>
              </Button>
            )}
            {isNil(props.onRemove) ? null : (
              <Button
                className="btn-default auto-hyphen align-items-center"
                size="small"
                startIcon={<Upload />}
                title={I18n.t("frontend.file_upload_input.replace")}
                onClick={onReplace}
              >
                <span className="d-none d-xl-inline">
                  {I18n.t("frontend.file_upload_input.replace")}
                </span>
              </Button>
            )}
            {isNil(props.onRemove) ? null : (
              <Button
                color="error"
                className="auto-hyphen align-items-center"
                size="small"
                startIcon={<Delete />}
                title={I18n.t("frontend.file_upload_input.delete")}
                onClick={props.onRemove}
              >
                <span className="d-none d-xl-inline">
                  {I18n.t("frontend.file_upload_input.delete")}
                </span>
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        <input
          title="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          onChange={(event) => props.onChange(event.target.files[0])}
        />
      </Grid>
    </ListItem>
  );
};
