import * as React from "react";

import { isEmpty, isNil } from "lodash";
import { BarChartData, BarChartDataset } from "../charting/plotly_bar_chart";
import { Plotly } from "../charting/plotly_package";
import { AppRoot } from "../components/common/app_root";
import {
  BarChartWidget as BarChartWidgetComponent,
  BarChartWidgetDefinition,
} from "../components/widgets/bar_chart_widget";
import {
  BarChartWidgetConfigSerialized,
  BarWidgetChartData,
} from "./bar_chart_widget.types";
import Widget from "./widget";

/**
 * Widget class for updating bar chart widgets.
 * Reads the following data attributes from the root element id provided in the constructor:
 * data-chart-data: json encoded chart data
 * @class LineDiagramWidget
 * @extends {Widget}
 */
export default class BarChartWidget extends Widget {
  static getDomClassName(): string {
    return "bar-chart-widget";
  }

  barChartWrapper: JQuery<HTMLElement>;

  orientation: "v" | "h";

  config: BarChartWidgetConfigSerialized;
  protected initComponent(element: JQuery) {
    super.initComponent(element);
    const props = BarChartWidgetDefinition.serializedConfigToProps(this.config);
    this.root.render(
      <AppRoot>
        <BarChartWidgetComponent {...props} />
      </AppRoot>,
    );
  }
  protected readMembersFromElement(widgetElement: JQuery<HTMLElement>): void {
    super.readMembersFromElement(widgetElement);
    const config = widgetElement.data(
      "config",
    ) as BarChartWidgetConfigSerialized;

    this.config = config;
    if (isNil(this.orientation) || isEmpty(this.orientation)) {
      this.orientation = "v";
    }

    this.barChartWrapper = widgetElement.find<HTMLElement>(
      `#widget-${this.widgetId}-bar-chart`,
    );
  }
  cleanup(): void {
    super.cleanup();
  }

  resize(): void {
    const barChart = this.barChartWrapper.find<HTMLElement>(
      ".sensor-diagram-container",
    );
    Plotly.Plots.resize(barChart[0]);
  }

  toggleFullscreen(): void {
    if (this.fullscreenButton.length != 0) {
      const ibox = $(this.fullscreenButton).closest("div.ibox");
      const button = $(this.fullscreenButton).find("i");
      $("body").toggleClass("fullscreen-ibox-mode");
      button.toggleClass("fa-expand").toggleClass("fa-compress");
      ibox.toggleClass("fullscreen");

      this.resize();
    }
  }
}

export function convertBarChartData(
  chartData: BarWidgetChartData,
): BarChartData {
  if (isEmpty(chartData)) {
    return {
      datasets: [],
    };
  }

  try {
    // parse timestamps
    chartData.datasets.forEach((dataset) => {
      (dataset as any as BarChartDataset).timestamps = dataset.timestamps.map(
        (timestamp) => new Date(timestamp),
      );
      (dataset as any as BarChartDataset).text = dataset.y.map(
        (value: number) => {
          if (isNil(value)) return "";
          return value.toLocaleString(I18n.locale, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          });
        },
      );
    });

    return chartData as any as BarChartData;
  } catch (error) {
    throw new Error("Invalid bar chart data.");
  }
}
