import { Close, Fullscreen, FullscreenExit } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import * as React from "react";

interface SialogicDialogProps extends React.PropsWithChildren {
  title?: string;
  open?: boolean;
  onClose?: () => void;
  allowFullscreen?: boolean;
  buttons?: React.ReactNode | React.ReactNode[];
}

export const SialogicDialog: React.FC<SialogicDialogProps> = ({
  title,
  open,
  onClose,
  children,
  allowFullscreen = true,
  buttons,
}) => {
  const [fullScreen, setFullScreen] = React.useState(false);
  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>
        <Box marginRight={allowFullscreen ? "80px" : "40px"}>{title}</Box>

        <Box displayPrint="none" position="absolute" right={8} top={8}>
          {allowFullscreen ? (
            <IconButton
              aria-label="fullscreen"
              onClick={() => {
                setFullScreen(!fullScreen);
              }}
            >
              {fullScreen ? (
                <FullscreenExit fontSize="inherit" />
              ) : (
                <Fullscreen fontSize="inherit" />
              )}
            </IconButton>
          ) : null}
          <IconButton aria-label="close" onClick={() => onClose()}>
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box padding={2}>{children}</Box>
      </DialogContent>
      {buttons ? <DialogActions>{buttons}</DialogActions> : null}
    </Dialog>
  );
};
