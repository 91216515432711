import { defaultTo, isEmpty, isNil, map, toString, unionBy } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { SingleResourceDoc } from "jsonapi-typescript";

import { AssetEventTypeJSONAPIAttributes } from "../../json_api/asset_event_type";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import {
  api_asset_event_type_path,
  edit_asset_event_type_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { assetPath } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { Icon } from "../common/icon";
import { SeverityLevelChip } from "../common/severity_level";

import { Edit, KeyboardArrowLeft } from "@mui/icons-material";

import { ResourcePermission } from "../../models/resource_permission";

export interface AssetEventTypeDetailsProps {
  assetEventType?: AssetEventTypeJSONAPIAttributes;
  assetEventTypeId?: IDType;
  permission?: ResourcePermission;

  withFabButtons?: boolean;
  wrapInCard?: boolean;
  onCancel?: () => void;
}

function stringsForAssetEventType(
  assetEventType: AssetEventTypeJSONAPIAttributes,
) {
  if (isNil(assetEventType))
    return { name: "---", description: "---", message: "---", code: "" };

  return {
    name: defaultTo(assetEventType.name, "---"),
    description: defaultTo(assetEventType.description, "---"),
    action: defaultTo(assetEventType.action, "---"),
    message: defaultTo<string>(assetEventType.message, "---"),
    code: defaultTo(assetEventType.code, "---"),
    slug: defaultTo(assetEventType.slug, "---"),
  };
}
export const AssetEventTypeDetails = ({
  withFabButtons = false,
  wrapInCard = false,
  ...props
}: AssetEventTypeDetailsProps) => {
  const [assetEventType, setAssetEventType] =
    React.useState<AssetEventTypeJSONAPIAttributes>(props.assetEventType);
  const [strings, setStrings] = React.useState(
    stringsForAssetEventType(assetEventType),
  );

  const [loading, setLoading] = React.useState(isNil(props.assetEventType));

  React.useEffect(() => {
    if (
      isNil(assetEventType) ||
      (!isNil(props.assetEventTypeId) &&
        assetEventType?.id != props.assetEventTypeId)
    ) {
      setLoading(true);
      void loadDataFromUrl<
        SingleResourceDoc<string, AssetEventTypeJSONAPIAttributes>
      >(
        api_asset_event_type_path(props.assetEventTypeId, {
          id: props.assetEventTypeId,
          format: "json",
          include: "assets,asset_type",
          _options: true,
        }),
      )
        .then((assetEvent) => {
          setAssetEventType(jsonApiSingleResourceToFlatObject(assetEvent));
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (
        props.assetEventType &&
        props.assetEventType?.id != assetEventType.id
      ) {
        setAssetEventType(props.assetEventType);
      }
    }
  }, [props.assetEventTypeId, props.assetEventType]);

  React.useEffect(() => {
    if (assetEventType) {
      setStrings(stringsForAssetEventType(assetEventType));
    }
  }, [assetEventType]);

  let details = (
    <Grid container spacing={2}>
      {loading ? (
        <Skeleton height={500} />
      ) : (
        <>
          <Grid item xs={12} container className="border-bottom mb-2">
            <Grid item xs={12} sm={3}>
              <Typography my="auto">
                {I18n.t(
                  "activerecord.attributes.asset_event_type.default_severity_level",
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} mb={1}>
              <SeverityLevelChip
                severityLevel={assetEventType.default_severity_level}
              />
            </Grid>
          </Grid>
          {isNil(assetEventType.event_type) ? null : (
            <Grid
              item
              xs={12}
              container
              marginBottom={2}
              className="border-bottom"
            >
              <Grid item xs={12} sm={3}>
                <Typography my="auto">
                  {I18n.t("activerecord.attributes.asset_event_type.category")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {isEmpty(assetEventType.category) ? (
                  "---"
                ) : (
                  <>
                    <Box mr={1} component="span">
                      {!isEmpty(assetEventType.icon) ? (
                        <Icon
                          icon={assetEventType.icon}
                          color={assetEventType.color}
                        />
                      ) : null}
                    </Box>
                    <Typography component="span" variant="body2">
                      {I18n.t(
                        `activerecord.asset_event_types.categories.${toString(assetEventType.category)}`,
                      )}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {["name", "slug", "description", "action", "message", "code"].map(
            (attr: keyof typeof strings) => (
              <Grid
                item
                container
                xs={12}
                className="border-bottom mb-2"
                key={attr}
              >
                <Grid item xs={12} sm={3}>
                  <Typography className="auto-hyphen">
                    {I18n.t(`activerecord.attributes.asset_event_type.${attr}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="body2">{strings[attr]}</Typography>
                </Grid>
              </Grid>
            ),
          )}
          <Grid item xs={12} container className="border-bottom  mb-2">
            <Grid item xs={12} sm={3}>
              <Typography>
                {I18n.t("activerecord.attributes.asset_event_type.assets")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {map(assetEventType.assets, (asset) => (
                <Link href={assetPath(asset.id)}>
                  {defaultTo(asset.name, "---")}
                </Link>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} container className="border-bottom  mb-2">
            <Grid item xs={12} sm={3}>
              <Typography>
                {I18n.t("activerecord.attributes.asset_event_type.asset_types")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {map(
                unionBy(assetEventType.asset_types, (at) => at.id),
                (assetType) => defaultTo(assetType.name, "---"),
              ).join(", ")}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  if (withFabButtons) {
    details = (
      <>
        {details}
        <FixedBottomArea>
          <FloatingButtons
            submitBtnIcon={<Edit />}
            onSubmit={() => {
              redirectTo(edit_asset_event_type_path(assetEventType?.id));
            }}
            cancelIcon={<KeyboardArrowLeft />}
            onCancel={() => {
              if (isNil(props?.onCancel)) {
                redirectTo("back");
              } else {
                props.onCancel();
              }
            }}
          />
        </FixedBottomArea>
      </>
    );
  }
  if (!wrapInCard) {
    return details;
  }

  return (
    <Card>
      <CardHeader>
        <Typography variant="h4">
          {I18n.t("activerecord.models.asset_event_type.one")}{" "}
          {assetEventType?.name}
        </Typography>
      </CardHeader>
      <CardContent>
        <Box display={"flex"} m={2}>
          {details}
        </Box>
      </CardContent>
    </Card>
  );
};
