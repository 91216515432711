import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { AssetJSONObject } from "../../json_api/asset";

import {
  ContextStateMachineJSONObject,
  loadContextStateMachine,
} from "../../json_api/context_state_machines";
import { StateJSONObject } from "../../json_api/state";
import { StateContextJSONObject } from "../../json_api/state_contexts";

import { Cancel, Check } from "@mui/icons-material";
import { logger } from "../../utils/logger";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { AssetStateSelect } from "./asset_state_selection";

export interface AssetStateSelectionDialogProps {
  open: boolean;
  baseAssetId: IDType;
  selectedContextStateMachineId: IDType;
  selectedStateId: IDType;
  onClose: () => void;
  onStateSelect: (
    statefulItemId: IDType,
    state: StateJSONObject,
    stateContext: StateContextJSONObject,
    csm: ContextStateMachineJSONObject,
  ) => void;
}

export const AssetStateSelectionDialog: React.FunctionComponent<
  AssetStateSelectionDialogProps
> = (props) => {
  if (!props.open) {
    return null;
  }

  const [contextStateMachine, setContextStateMachine] =
    React.useState<ContextStateMachineJSONObject<AssetJSONObject>>(null);

  const [state, setState] = React.useState<StateJSONObject>(null);
  const [stateContext, setStateContext] =
    React.useState<StateContextJSONObject>(null);

  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    // effect for inital load with no stateful item id selected because it is initially not kown
    if (
      !isNil(props.selectedContextStateMachineId) &&
      isNil(contextStateMachine)
    ) {
      setLoading(true);
      loadContextStateMachine(props.selectedContextStateMachineId, [
        "stateful_item",
        "possible_states",
        "state_context",
      ])
        .then((csm) => {
          setContextStateMachine(csm);
          setStateContext(csm.state_context);
          const theState = find(
            csm.possible_states,
            (s) => s.id == props.selectedStateId,
          );
          setState(theState);
        })
        .catch((err) => {
          void error(I18n.t("base.error"));
          logger.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.selectedContextStateMachineId, props.selectedStateId]);

  return (
    <Dialog onClose={props.onClose} open={props.open} maxWidth="md" fullWidth>
      <DialogTitle>
        {I18n.t("frontend.states.state_selection_dialog.select_state_title")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AssetStateSelect
              assetSelectionMode="root-subtree"
              selectedStatefulItemId={contextStateMachine?.stateful_item_id}
              selectedContextStateMachineId={defaultTo(
                contextStateMachine?.id,
                props.selectedContextStateMachineId,
              )}
              baseAssetId={props.baseAssetId}
              selectedStateId={defaultTo(state?.id, props.selectedStateId)}
              onStateSelected={(asset, state, stateContext, csm) => {
                setContextStateMachine(csm);
                setState(state);
                setStateContext(stateContext);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button endIcon={<Cancel />} onClick={() => props.onClose()}>
            {I18n.t("frontend.cancel")}
          </Button>

          <Button
            endIcon={<Check />}
            color="primary"
            disabled={!(contextStateMachine && state && stateContext)}
            onClick={() => {
              if (contextStateMachine && state && stateContext) {
                props.onStateSelect(
                  contextStateMachine?.stateful_item_id,
                  state,
                  stateContext,
                  contextStateMachine,
                );
              }
              props.onClose();
            }}
          >
            {I18n.t("frontend.apply")}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
