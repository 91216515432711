import * as React from "react";
import Widget from "./widget";

import { AppRoot } from "../components/common/app_root";
import { SensorValueTableWidget as SensorValueTableWidgetComponent } from "../components/widgets/sensor_value_table_widget";
import { SensorValueTableWidgetConfigSerialized } from "./sensor_value_table_widget.types";

/**
 * Javascript implementation of the SensorValueTableWidget.
 *
 * @class SensorValueTableWidget
 * @extends {Widget}
 */
export default class SensorValueTableWidget extends Widget<SensorValueTableWidgetConfigSerialized> {
  static getDomClassName(): string {
    return "sensor-value-table-widget";
  }

  static widgetClassName(): string {
    return "SensorValueTableWidget";
  }

  constructor(element: JQuery<HTMLElement>) {
    super(element);
    // init the members in the context of the current this, should only be called in leaf classes
    // otherwise the properties will not be attached to the correct 'this', pass "false" to super call if this is not a leaf class
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);
    const props = SensorValueTableWidgetComponent.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <SensorValueTableWidgetComponent
          {...props}
          dashboardSettings={this.dashboardSettings}
        />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    const config = element.data(
      "config",
    ) as SensorValueTableWidgetConfigSerialized;

    this.config = config;
  }

  cleanup(): void {
    super.cleanup();
  }
}
