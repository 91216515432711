import { ReduceStore } from "flux/utils";
import { clone, cloneDeep, each, isNil, map } from "lodash";
import { TreeItem } from "../../../models/tree_item";
import {
  AddMaintenancePlanAction,
  ChangeMaintenancePlanOrderAction,
  CopyMaintenancePlanAction,
  LoadInitialStateAction,
  MaintenancePlanAction,
  MoveMaintenancePlanAction,
  RemoveMaintenancePlanAction,
  ResetErrorsAction,
  ResetStateAction,
  SetErrorsAction,
  SetProcessingAction,
  ToggleAllMaintenancePlanListsAction,
  ToggleMaintenancePlanListAction,
  UpdateMaintenancePlanAction,
} from "./maintenance_plan_actions";
import MaintenancePlanDispatcher from "./maintenance_plan_dispatcher";
import {
  AssetGroup,
  AssetGroups,
  MaintenancePlan,
  MaintenanceType,
} from "./models";

export interface MaintenancePlanState {
  assetTree: TreeItem;
  rootAssetId: string | number;
  assetGroups: AssetGroups;
  maintenanceTypes: MaintenanceType[];
  maintenancePlansToDelete: MaintenancePlan[];
  isProcessing: boolean;
  hasChanges: boolean;
}

type ActionHandler = (
  state: MaintenancePlanState,
  action: MaintenancePlanAction,
) => MaintenancePlanState;

/**
 * The Flux store for the maintenance plan form.
 * Contains the actual business logic in the reducer methods.
 * Each reducer method is named after the action type.
 */
export class MaintenancePlanStore extends ReduceStore<
  MaintenancePlanState,
  MaintenancePlanAction
> {
  constructor() {
    super(MaintenancePlanDispatcher);
  }

  getInitialState(): MaintenancePlanState {
    return {
      rootAssetId: null,
      assetGroups: [],
      maintenanceTypes: [],
      maintenancePlansToDelete: [],
      isProcessing: false,
      hasChanges: false,
      assetTree: null,
    };
  }

  reduce(
    state: MaintenancePlanState,
    action: MaintenancePlanAction,
  ): MaintenancePlanState {
    const actionHandler = this[action.type] as ActionHandler;

    if (isNil(actionHandler)) {
      // handle unknown actions
      return state;
    }

    return actionHandler.call(this, state, action);
  }

  RESET_STATE(
    state: MaintenancePlan,
    action: ResetStateAction,
  ): MaintenancePlanState {
    return this.getInitialState();
  }

  LOAD_INITIAL_STATE(
    state: MaintenancePlanState,
    action: LoadInitialStateAction,
  ): MaintenancePlanState {
    // open all asset groups by default
    const assetGroups = cloneDeep(action.assetGroups);
    each(assetGroups, (group) => {
      each(group.assets, (asset) => {
        asset.open = true;
      });
    });
    return {
      ...this.getInitialState(),

      assetTree: action.assetTree,
      rootAssetId: action.rootAssetId,
      assetGroups: assetGroups,
      maintenanceTypes: cloneDeep(action.maintenanceTypes),
      hasChanges: false,
    };
  }

  ADD_MAINTENANCE_PLAN(
    state: MaintenancePlanState,
    action: AddMaintenancePlanAction,
  ): MaintenancePlanState {
    const newAssetGroups = MaintenancePlanStore.modifyAssetGroups(
      state.assetGroups,
      action.assetGroupIndex,
      action.assetIndex,
    );
    const newAsset =
      newAssetGroups[action.assetGroupIndex].assets[action.assetIndex];

    newAsset.maintenance_plans = clone(newAsset.maintenance_plans);
    newAsset.maintenance_plans.unshift({
      id: null,
      type: "MaintenancePlan",
      asset_id: newAsset.id,
      maintenance_type_id: null,
      status_measurement_id: null,
      maintenance_plan_type: "unplanned",
      name_de: "",
      name_en: "",
    });

    return {
      ...state,
      assetGroups: newAssetGroups,
      hasChanges: true,
    };
  }

  ADD_INSPECTION_PLAN(
    state: MaintenancePlanState,
    action: AddMaintenancePlanAction,
  ): MaintenancePlanState {
    const newAssetGroups = MaintenancePlanStore.modifyAssetGroups(
      state.assetGroups,
      action.assetGroupIndex,
      action.assetIndex,
    );
    const newAsset =
      newAssetGroups[action.assetGroupIndex].assets[action.assetIndex];

    newAsset.maintenance_plans = clone(newAsset.maintenance_plans);
    newAsset.maintenance_plans.unshift({
      id: null,
      type: "InspectionPlan",
      asset_id: newAsset.id,
      maintenance_type_id: null,
      status_measurement_id: null,
      maintenance_plan_type: "unplanned",
      name_de: "",
      name_en: "",
    });

    return {
      ...state,
      assetGroups: newAssetGroups,
      hasChanges: true,
    };
  }

  REMOVE_MAINTENANCE_PLAN(
    state: MaintenancePlanState,
    action: RemoveMaintenancePlanAction,
  ): MaintenancePlanState {
    const newAssetGroups = MaintenancePlanStore.modifyAssetGroups(
      state.assetGroups,
      action.assetGroupIndex,
      action.assetIndex,
    );
    const newAsset =
      newAssetGroups[action.assetGroupIndex].assets[action.assetIndex];
    newAsset.maintenance_plans = clone(newAsset.maintenance_plans);
    const maintenancePlansToDelete = newAsset.maintenance_plans.splice(
      action.maintenancePlanIndex,
      1,
    );

    const newMaintenancePlansToDelete = clone(state.maintenancePlansToDelete);
    newMaintenancePlansToDelete.splice(0, 0, ...maintenancePlansToDelete);

    return {
      ...state,
      assetGroups: newAssetGroups,
      maintenancePlansToDelete: newMaintenancePlansToDelete,
      hasChanges: true,
    };
  }

  UPDATE_MAINTENANCE_PLAN(
    state: MaintenancePlanState,
    action: UpdateMaintenancePlanAction,
  ): MaintenancePlanState {
    const newAssetGroups = MaintenancePlanStore.modifyAssetGroups(
      state.assetGroups,
      action.assetGroupIndex,
      action.assetIndex,
    );
    const newAsset =
      newAssetGroups[action.assetGroupIndex].assets[action.assetIndex];
    newAsset.maintenance_plans = clone(newAsset.maintenance_plans);
    newAsset.maintenance_plans[action.maintenancePlanIndex] =
      action.maintenancePlan;

    return {
      ...state,
      assetGroups: newAssetGroups,
      hasChanges: true,
    };
  }

  CHANGE_MAINTENANCE_PLAN_ORDER(
    state: MaintenancePlanState,
    action: ChangeMaintenancePlanOrderAction,
  ): MaintenancePlanState {
    const newAssetGroups = MaintenancePlanStore.modifyAssetGroups(
      state.assetGroups,
      action.assetGroupIndex,
      action.assetIndex,
    );
    const newAsset =
      newAssetGroups[action.assetGroupIndex].assets[action.assetIndex];
    const newMaintenancePlanIndex =
      action.maintenancePlanIndex + action.direction;

    // can't change order if element is already first or last
    if (
      newMaintenancePlanIndex < 0 ||
      newMaintenancePlanIndex >= newAsset.maintenance_plans.length
    ) {
      return state;
    }

    // swap places with previous or next element
    const temp = newAsset.maintenance_plans[action.maintenancePlanIndex];
    newAsset.maintenance_plans = clone(newAsset.maintenance_plans);
    newAsset.maintenance_plans[action.maintenancePlanIndex] =
      newAsset.maintenance_plans[
        action.maintenancePlanIndex + action.direction
      ];
    newAsset.maintenance_plans[action.maintenancePlanIndex + action.direction] =
      temp;

    return {
      ...state,
      assetGroups: newAssetGroups,
      hasChanges: true,
    };
  }

  TOGGLE_MAINTENANCE_PLAN_LIST(
    state: MaintenancePlanState,
    action: ToggleMaintenancePlanListAction,
  ): MaintenancePlanState {
    const newAssetGroups = clone(state.assetGroups);
    const newAssetList = clone(newAssetGroups[action.assetGroupIndex]);
    const newAsset = newAssetList.assets[action.assetIndex];

    if (!isNil(action.open)) {
      newAsset.open = action.open;
    } else {
      newAsset.open = !newAsset.open;
    }
    newAssetList.assets[action.assetIndex] = newAsset;
    newAssetGroups[action.assetGroupIndex] = newAssetList;
    return { ...state, assetGroups: newAssetGroups };
  }

  TOGGLE_ALL_MAINTENANCE_PLAN_LISTS(
    state: MaintenancePlanState,
    action: ToggleAllMaintenancePlanListsAction,
  ): MaintenancePlanState {
    const newAssetGroups = map(state.assetGroups, (assetGroup) => {
      return {
        ...assetGroup,
        assets: map(assetGroup.assets, (asset) => {
          return { ...asset, open: action.open };
        }),
      };
    });
    return { ...state, assetGroups: newAssetGroups };
  }

  COPY_MAINTENANCE_PLAN(
    state: MaintenancePlanState,
    action: CopyMaintenancePlanAction,
  ): MaintenancePlanState {
    const newAssetGroups = clone(state.assetGroups);
    action.assetIndices.forEach((index) => {
      if (
        index.assetGroupIndex < 0 ||
        index.assetGroupIndex >= newAssetGroups.length
      ) {
        return;
      }
      if (
        index.assetIndex < 0 ||
        index.assetIndex >= newAssetGroups[index.assetGroupIndex].assets.length
      ) {
        return;
      }

      // clone asset group
      const newAssetGroup = clone(newAssetGroups[index.assetGroupIndex]);
      newAssetGroups[index.assetGroupIndex] = newAssetGroup;
      newAssetGroup.assets = clone(newAssetGroup.assets);

      // clone asset
      const newAsset = clone(newAssetGroup.assets[index.assetIndex]);
      newAssetGroup.assets[index.assetIndex] = newAsset;
      newAsset.maintenance_plans = clone(newAsset.maintenance_plans);

      // insert new maintenance plan
      const newMaintenancePlan = cloneDeep(action.maintenancePlan);
      newMaintenancePlan.id = null;
      newMaintenancePlan.asset_id = newAsset.id;
      newAsset.maintenance_plans.unshift(newMaintenancePlan);
    });

    return {
      ...state,
      assetGroups: newAssetGroups,
      hasChanges: true,
    };
  }

  MOVE_MAINTENANCE_PLAN(
    state: MaintenancePlanState,
    action: MoveMaintenancePlanAction,
  ): MaintenancePlanState {
    if (
      isNil(action.destAsset) ||
      (action.assetGroupIndex == action.destAsset.assetGroupIndex &&
        action.assetIndex === action.destAsset.assetIndex)
    ) {
      return state;
    }

    // clone asset groups list, source group and destination asset group
    const newAssetGroups = clone(state.assetGroups);
    const sourceAssetGroup = clone(newAssetGroups[action.assetGroupIndex]);
    newAssetGroups[action.assetGroupIndex] = sourceAssetGroup;
    sourceAssetGroup.assets = clone(sourceAssetGroup.assets);
    let destAssetGroup: AssetGroup;
    if (action.assetGroupIndex === action.destAsset.assetGroupIndex) {
      destAssetGroup = sourceAssetGroup;
    } else {
      destAssetGroup = clone(newAssetGroups[action.destAsset.assetGroupIndex]);
      newAssetGroups[action.destAsset.assetGroupIndex] = destAssetGroup;
      destAssetGroup.assets = clone(destAssetGroup.assets);
    }

    // clone source asset
    const sourceAsset = clone(sourceAssetGroup.assets[action.assetIndex]);
    sourceAssetGroup.assets[action.assetIndex] = sourceAsset;
    sourceAsset.maintenance_plans = clone(sourceAsset.maintenance_plans);

    // clone destination asset
    const destAsset = clone(destAssetGroup.assets[action.destAsset.assetIndex]);
    destAssetGroup.assets[action.destAsset.assetIndex] = destAsset;
    destAsset.maintenance_plans = clone(destAsset.maintenance_plans);

    // change asset id of maintenance plan
    const newMaintenancePlan = clone(
      sourceAsset.maintenance_plans[action.maintenancePlanIndex],
    );
    newMaintenancePlan.asset_id = destAsset.id;

    // remove maintenance plan from old asset and add it to new
    sourceAsset.maintenance_plans.splice(action.maintenancePlanIndex, 1);
    destAsset.maintenance_plans.push(newMaintenancePlan);

    return {
      ...state,
      assetGroups: newAssetGroups,
      hasChanges: true,
    };
  }

  /** Modifies the state and sets processing flag to indicate that something is going on.
   *
   *
   * @param {MaintenancePlanState} state
   * @param {SetProcessingAction} action
   * @returns {MaintenancePlanState}
   * @memberof MaintenancePlanStore
   */
  SET_PROCESSING(
    state: MaintenancePlanState,
    action: SetProcessingAction,
  ): MaintenancePlanState {
    return {
      ...state,
      isProcessing: action.isProcessing,
    };
  }

  SET_ERRORS(
    state: MaintenancePlanState,
    action: SetErrorsAction,
  ): MaintenancePlanState {
    const response = action.errorResponse;
    const newAssetGroups = cloneDeep(state.assetGroups);

    let planIndex = 0;
    newAssetGroups.forEach((assetGroup) => {
      assetGroup.assets.forEach((asset) => {
        asset.maintenance_plans.forEach((maintenancePlan) => {
          if (planIndex >= response.maintenance_plans.length) {
            return;
          }

          maintenancePlan.errors = response.maintenance_plans[planIndex].errors;
          ++planIndex;
        });
      });
    });

    return {
      ...state,
      assetGroups: newAssetGroups,
    };
  }

  /** Resets validation errors on the dataset
   *
   *
   * @param {MaintenancePlanState} state Current state
   * @param {ResetErrorsAction} action
   * @returns {MaintenancePlanState} State without error objects
   * @memberof MaintenancePlanStore
   */
  RESET_ERRORS(
    state: MaintenancePlanState,
    action: ResetErrorsAction,
  ): MaintenancePlanState {
    const newAssetGroups = cloneDeep(state.assetGroups);

    newAssetGroups.forEach((assetGroup) => {
      assetGroup.assets.forEach((asset) => {
        asset.maintenance_plans.forEach((maintenancePlan) => {
          delete maintenancePlan.errors;
        });
      });
    });

    return {
      ...state,
      assetGroups: newAssetGroups,
    };
  }

  /** Creates a new asset groups
   *
   *
   * @static
   * @param {AssetGroups} assetGroups
   * @param {number} assetGroupIndex
   * @param {number} assetIndex
   * @returns {AssetGroups}
   * @memberof MaintenancePlanStore
   */
  static modifyAssetGroups(
    assetGroups: AssetGroups,
    assetGroupIndex: number,
    assetIndex: number,
  ): AssetGroups {
    const newAssetGroups = clone(assetGroups);
    newAssetGroups[assetGroupIndex] = clone(assetGroups[assetGroupIndex]);
    newAssetGroups[assetGroupIndex].assets = clone(
      assetGroups[assetGroupIndex].assets,
    );
    newAssetGroups[assetGroupIndex].assets[assetIndex] = clone(
      assetGroups[assetGroupIndex].assets[assetIndex],
    );

    return newAssetGroups;
  }
}

export default new MaintenancePlanStore();
