import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import { SensorAggregationWidget as SensorAggregationWidgetComponent } from "../components/widgets/sensor_aggregation_widget";
import { SensorAggregationWidgetConfigSerialized } from "./sensor_aggregation_widget.types";
import SensorValueWidget from "./sensor_value_widget";
import Widget from "./widget";

/**
 * Javascript implementation of the SensorAggregationWidget.
 *
 * @class SensorAggregationWidget
 * @extends {Widget}
 */
export default class SensorAggregationWidget extends SensorValueWidget {
  config: SensorAggregationWidgetConfigSerialized;
  sensorIds: number[];

  static getDomClassName(): string {
    return "sensor-aggregation-widget";
  }

  static widgetClassName(): string {
    return "SensorAggregationWidget";
  }

  constructor(element: JQuery<HTMLElement>) {
    super(element);

    // init the members in the context of the current this, should only be called in leaf classes
    // otherwise the properties will not be attached to the correct 'this', pass "false" to super call if this is not a leaf class
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element, false);
    const props = SensorAggregationWidgetComponent.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <SensorAggregationWidgetComponent
          {...props}
          dashboardSettings={this.dashboardSettings}
        />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    this.config = element.data(
      "config",
    ) as SensorAggregationWidgetConfigSerialized;
  }

  cleanup(): void {
    super.cleanup();
  }
}
