import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isEqual } from "lodash";
import * as React from "react";
import {
  ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetTypeJSONObject,
} from "../../json_api/asset_type";
import {
  ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE,
  AssetTypesSensorTypeJSONObject,
} from "../../json_api/asset_types_sensor_type";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { AttributeKeyDataTypes } from "../../models/attribute_key";
import {
  api_asset_types_sensor_type_path,
  api_asset_types_sensor_types_path,
} from "../../routes";
import { dialog } from "../../utils/dialog";
import {
  HttpError,
  loadDataFromUrl,
  sendJsonApiData,
} from "../../utils/jquery_helper";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../../utils/jsonapi_form_tools";
import { logger } from "../../utils/logger";
import * as toasts from "../../utils/toasts";

interface AssetTypesSensorTypesListProps {
  assetType?: AssetTypeJSONObject;
  assetTypeId?: number;
  canEdit: boolean;
  tableHeight?: number;
  maxTableHeight?: number | string;
}

export const AssetTypesSensorTypesList: React.FC<
  AssetTypesSensorTypesListProps
> = ({
  assetType,
  assetTypeId,
  tableHeight = 600,
  maxTableHeight = "75vh",
  canEdit = false,
}) => {
  const [reloadCount, setReloadCount] = React.useState(0);
  const [pageSettings, setPageSettings] = React.useState({
    pageSize: 20,
    page: 0,
  });

  const theAssetTypeID = assetType ? assetType.id : assetTypeId;
  const assetTypesSensorTypesQuery = useQuery({
    queryKey: [
      ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE,
      {
        assetType: theAssetTypeID,
        page: pageSettings.page,
        pageSize: pageSettings.pageSize,
        reloadCount,
      },
    ],
    queryFn: async () => {
      const options = {
        filter: { asset_type: theAssetTypeID },
      };
      const path = api_asset_types_sensor_types_path(options);
      const assetTemplateDoc =
        await loadDataFromUrl<
          CollectionResourceDoc<string, AssetTypesSensorTypeJSONObject>
        >(path);
      return jsonApiResourceCollectionToFlatObjects(assetTemplateDoc);
    },
  });

  const [rows, setRows] =
    React.useState<
      GridRowModel<AssetTypesSensorTypeJSONObject & { isNew?: boolean }>[]
    >(null);

  React.useEffect(() => {
    if (assetTypesSensorTypesQuery.isSuccess) {
      setRows([...assetTypesSensorTypesQuery.data]);
    }
  }, [assetTypesSensorTypesQuery.isSuccess, assetTypesSensorTypesQuery.data]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    void dialog
      .fire({
        title: I18n.t("frontend.confirm_title"),
        validationMessage: I18n.t(
          "frontend.asset_types_sensor_types_list.confirm_delete_text",
        ),
        confirmButtonText: I18n.t("frontend.delete"),
        showCloseButton: true,
        cancelButtonText: I18n.t("frontend.cancel"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          void sendJsonApiData(
            api_asset_types_sensor_type_path(id),
            undefined,
            "DELETE",
          )
            .then(() => {
              void toasts.success("Sensor template deleted", "Success");
              setReloadCount(reloadCount + 1);
            })
            .catch((error) => {
              logger.error(error);

              void toasts.error(
                I18n.t(
                  "frontend.asset_types_sensor_types_list.error_delete_title",
                ),
                I18n.t(
                  "frontend.asset_types_sensor_types_list.error_delete_text",
                ),
              );
            });
        }
      });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (
    newRow: GridRowModel<AssetTypesSensorTypeJSONObject>,
    oldRow: GridRowModel<AssetTypesSensorTypeJSONObject>,
  ) => {
    if (isEqual(newRow, oldRow)) {
      return;
    }
    let sendData = buildJsonApiSubmitData<AssetTypesSensorTypeJSONObject>(
      newRow,
      ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE,
      [
        "default_attribute_key_type",
        "creation_scope",
        "default_derived",
        "default_sampling_rate_unit",
        "default_context",
        "default_context2",
        "default_import_formula",
        "default_import_formula_target_key",
        "default_name",
        "default_short_name",
        "default_description",
        "default_unit",
        "default_display_unit",
        "default_attribute_key",
        "default_min",
        "default_max",
        "default_precision",
        "default_sampling_rate_value",
        "initial_value",
        "default_exp_data_period",
        "position",
      ],
    );
    if (!newRow.isNew) {
      return sendJsonApiData<
        SingleResourceDoc<string, Partial<AssetTypesSensorTypeJSONObject>>,
        SingleResourceDoc<string, AssetTypesSensorTypeJSONObject>
      >(
        api_asset_types_sensor_type_path(newRow.id),
        sendData.submitData,
        "PATCH",
      ).then((result) => {
        void toasts.success("Sensor template created", "Success");
        return jsonApiSingleResourceToFlatObject(result);
      });
    } else {
      addHasOneRelationToJsonApiSubmitData(
        sendData.submitData,
        "asset_type",
        ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
        assetTypeId,
        false,
      );
    }
  };

  const gridColDef: readonly GridColDef<AssetTypesSensorTypeJSONObject>[] = [
    {
      field: "id",
      headerName: I18n.t("activerecord.attributes.asset_types_sensor_type.id"),
    },
    {
      field: "default_name",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_name",
      ),
      editable: true,
      flex: 1,
    },
    {
      field: "default_short_name",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_short_name",
      ),
      editable: true,
      flex: 1,
    },
    {
      field: "default_attribute_key",
      editable: true,
      flex: 1,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_attribute_key",
      ),
    },
    {
      field: "default_attribute_key_type",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_attribute_key_type",
      ),
      type: "singleSelect",
      editable: true,

      valueGetter: (value) =>
        value == null || value == undefined ? "" : value,
      valueSetter: (value, row) => {
        return {
          ...row,
          default_attribute_key_type: value == "" ? null : (value as string),
        };
      },
      valueOptions: () => {
        const data = AttributeKeyDataTypes.map((type) => ({
          value: type as string,
          label: type as string,
        }));
        data.push({ value: "", label: "None" });
        return data;
      },
    },
    {
      field: "default_unit",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_unit",
      ),
    },
    {
      field: "default_display_unit",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_display_unit",
      ),
    },
    {
      field: "default_min",
      type: "number",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_min",
      ),
    },
    {
      field: "default_max",
      type: "number",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_max",
      ),
    },
    {
      field: "default_precision",
      type: "number",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_precision",
      ),
    },
    {
      field: "default_description",
      type: "string",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_description",
      ),
    },
    {
      field: "creation_scope",
      type: "singleSelect",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.creation_scope",
      ),
      editable: true,
      valueGetter: (v) => (v == null || v == undefined ? "" : v),
      valueSetter: (v, row) => ({
        ...row,
        creation_scope: v == "" ? null : (v as "optional" | "autocreate"),
      }),

      valueOptions: [
        { value: "autocreate", label: "autocreate" },
        { label: "optional", value: "optional" },
        { label: "None", value: "" },
      ],
    },
    {
      field: "default_import_formula",
      type: "string",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_import_formula",
      ),
    },
    {
      field: "default_import_formula_target_key",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_import_formula_target_key",
      ),
      type: "string",
      editable: true,
    },
    {
      field: "default_sampling_rate_value",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_sampling_rate_value",
      ),
      type: "number",
      editable: true,
    },
    {
      field: "default_sampling_rate_unit",
      type: "singleSelect",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_sampling_rate_unit",
      ),
      editable: true,
      valueGetter: (v) => (v == null || v == undefined ? "" : v),
      valueSetter: (v, row) => ({
        ...row,
        default_sampling_rate_unit: v == "" ? null : (v as string),
      }),
      valueOptions: [
        { label: "minutes", value: "Minutes" },
        { value: "seconds", label: "Seconds" },
        { value: "hours", label: "Hours" },
        { value: "days", label: "Days" },
        { value: "weeks", label: "Weeks" },
        { label: "None", value: "" },
      ],
    },
    {
      field: "initial_value",
      type: "number",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.initial_value",
      ),
    },
    {
      field: "default_exp_data_period",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_exp_data_period",
      ),
      type: "number",
      editable: true,
    },
    {
      field: "default_derived",
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_derived",
      ),
      type: "boolean",
      editable: true,
    },
    {
      field: "default_context",
      type: "string",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_context",
      ),
    },
    {
      field: "default_context2",
      type: "string",
      editable: true,
      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.default_context2",
      ),
    },
    {
      field: "position",
      type: "number",
      editable: true,

      headerName: I18n.t(
        "activerecord.attributes.asset_types_sensor_type.position",
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: I18n.t("frontend.actions"),
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        if (!canEdit) {
          return null;
        }
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              key="save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              key="cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            key="edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            key="delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box height={tableHeight} width="100%" maxHeight={maxTableHeight}>
      <DataGrid
        columns={gridColDef}
        rows={rows}
        density="comfortable"
        onPaginationModelChange={(newModel) => {
          setPageSettings({ ...newModel });
        }}
        rowModesModel={rowModesModel}
        editMode="row"
        onRowModesModelChange={handleRowModesModelChange}
        onProcessRowUpdateError={(er) => {
          if (er instanceof HttpError) {
            void toasts.error(I18n.t("frontend.error"), er.response);
          } else {
            void toasts.error(
              I18n.t("frontend.error"),
              (er as Error).message ||
                I18n.t(
                  "frontend.asset_types_sensor_types_list.error_updating_sensor_template",
                ),
            );
          }
          logger.info(er);
        }}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
};
