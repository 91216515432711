/// <reference types="./../definitions/index" />;
/// <reference types="./../definitions/bootstrap-daterangepicker" />;
import { each, includes, isEmpty, isNil } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../components/common/app_root";
import { LocationPicker } from "../components/location_picker";

export class AssetFormController {
  root: Root;
  form: JQuery<HTMLElement>;
  addSensorButton: JQuery<HTMLElement>;
  sensorTable: JQuery<HTMLElement>;
  sensorFormTemplate: JQuery<HTMLElement>;
  sensorCount: number;

  sensorTemplateVariables: {
    [name: string]: {
      original: string;
      value: string;
      textField: JQuery<HTMLInputElement>;
    };
  };
  addMaintenancePlanButton: JQuery<HTMLElement>;
  maintenancePlanTable: JQuery<HTMLElement>;
  maintenancePlanFormTemplate: JQuery<HTMLElement>;
  maintenancePlanCount: number;

  newSensorCount: number;

  static initAssetForm(id = "#new_asset") {
    if ($(id).length !== 0) {
      const assetFormController = new AssetFormController(id);
    }
  }

  constructor(id = "#new_asset") {
    this.form = $(id);
    this.sensorTable = this.form.find<HTMLElement>("#sensor-table-body");
    this.addSensorButton = this.form.find<HTMLElement>("#add-sensor");
    this.sensorFormTemplate = this.form.find<HTMLElement>(
      "#sensor-form-template",
    );
    this.sensorCount = Math.max(
      this.form.find<HTMLElement>("#sensor-table-body tr").length - 1,
      0,
    );
    this.maintenancePlanTable = this.form.find<HTMLElement>(
      "#maintenance-plan-table-body",
    );
    this.addMaintenancePlanButton = this.form.find<HTMLElement>(
      "#add-maintenance-plan",
    );
    this.maintenancePlanFormTemplate = this.form.find<HTMLElement>(
      "#maintenance-plan-form-template",
    );
    this.maintenancePlanCount = Math.max(
      this.form.find<HTMLElement>("#maintenance-plan-table-body tr").length - 1,
      0,
    );
    this.sensorTemplateVariables = {};
    // disable submit on enter
    this.form.on("keyup keypress", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        return false;
      }
    });
    // register events
    this.addSensorButton.on("click", () => this.addSensorForm());
    this.addMaintenancePlanButton.on("click", () =>
      this.addMaintenancePlanForm(),
    );

    this.bindAllRemoveButton(this.sensorTable, ".remove-sensor");
    this.bindAllRemoveOtherButton(this.sensorTable, ".remove-other-sensors");
    this.bindAllRemoveButton(
      this.maintenancePlanTable,
      ".remove-maintenance-plan",
    );

    this.bindTemplateReplacement(this.sensorTable);
    const locationPickerField = this.form.find<HTMLElement>("#location-picker");
    const locationFormLat = this.form.find<HTMLInputElement>(
      "#asset_location_attributes_lat",
    );
    const locationFormLon = this.form.find<HTMLInputElement>(
      "#asset_location_attributes_lon",
    );

    if (!isEmpty(locationPickerField)) {
      this.root = createRoot(locationPickerField[0]);
      this.root.render(
        <AppRoot>
          <LocationPicker
            saveLocationOnChange={false}
            onLocationSelected={(location) => {
              locationFormLat?.val(location.lat);
              locationFormLon?.val(location.lon);
            }}
          />
        </AppRoot>,
      );
    }
    // replace default date time picker
    this.form
      .find('input[type="datetime-local"]')
      .attr("type", "text")
      .daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        showDropdown: true,
        timePicker24Hour: true,
        locale: {
          format: "L LT",
        },
      });
  }

  destroy(): void {
    this.root?.unmount();
    // unbind events
    this.addSensorButton.off("click");
    this.addMaintenancePlanButton.off("click");
    this.sensorTable.find(".remove-sensor").off("click");
    this.sensorTable.find(".remove-other-sensors").off("click");
    this.maintenancePlanTable.find(".remove-maintenance-plan").off("click");
  }

  addSensorForm(): void {
    const newIndex = this.sensorCount++;
    this.newSensorCount++;
    const newRow = this.sensorFormTemplate.clone();
    newRow.html(newRow.html().replace(/:sensor_index/g, newIndex.toString()));

    newRow.removeAttr("id").removeAttr("style");
    $(newRow).attr("id", `new-sensor-row-${this.newSensorCount}`);
    this.bindRemoveButton(newRow, ".remove-sensor");
    this.bindRemoveOtherButton(
      this.sensorTable,
      newRow,
      ".remove-other-sensors",
    );
    this.sensorTable.append(newRow);
  }

  addMaintenancePlanForm(): void {
    const newIndex = this.maintenancePlanCount++;
    const newRow = this.maintenancePlanFormTemplate.clone();
    newRow.html(
      newRow.html().replace(/:maintenance_plan_index/g, newIndex.toString()),
    );

    newRow.removeAttr("id").removeAttr("style");
    this.bindRemoveButton(newRow, ".remove-maintenance-plan");

    this.maintenancePlanTable.append(newRow);
  }

  protected bindAllRemoveButton(
    table: JQuery,
    removeButtonClassOrId: string,
  ): void {
    table.find("tr").each((index, element) => {
      const row = $(element);
      this.bindRemoveButton(row, removeButtonClassOrId);
    });
  }

  protected bindAllRemoveOtherButton(
    table: JQuery,
    removeButtonClassOrId: string,
  ): void {
    table.find("tr").each((index, element) => {
      const row = $(element);
      this.bindRemoveOtherButton(table, row, removeButtonClassOrId);
    });
  }

  protected bindRemoveButton(row: JQuery, removeButtonClassOrId: string): void {
    row.find(removeButtonClassOrId).on("click", () => {
      if (
        includes(
          ["sensor-form-template", "maintenance-plan-form-template"],
          row.attr("id"),
        )
      ) {
        return;
      }
      row.remove();
    });
  }

  protected bindRemoveOtherButton(
    table: JQuery,
    row: JQuery,
    removeButtonClassOrId: string,
  ): void {
    row.find(removeButtonClassOrId).on("click", () => {
      const rows = table.find("tr").not(row);
      rows.each((index, otherRow) => {
        if (
          includes(
            ["sensor-form-template", "maintenance-plan-form-template"],
            otherRow.id,
          )
        ) {
          return;
        }
        otherRow.remove();
      });
    });
  }

  protected bindTemplateReplacement(table: JQuery) {
    $.each(table.find("tr"), (index, row) => {
      $(row)
        .find("td input")
        .each((index, input) => {
          const i = $(input);
          i.data("originalValue", i.val());
        });
    });
    $("#sensor-template-form .sensor-template-input-group").each(
      (index, inputGroup) => {
        const input = $(inputGroup).find("input");
        const resetBtn = $(inputGroup).find("button.resetBtn");
        const variableName = input.data("var-name") as string;
        const value = this.sensorTemplateVariables[variableName];
        if (isNil(value)) {
          this.sensorTemplateVariables[variableName] = {
            original: `%${variableName}%`,
            value: `%${variableName}%`,
            textField: input,
          };
        }
        resetBtn.on("click", () => {
          this.resetSensorVariableTemplate(variableName);
        });

        input.on("input", () => {
          const newValue = input.val();
          this.replaceSensorVariable(variableName, newValue);
        });
        input.on("keydown", (event) => {
          if (event.key == "Enter") {
            event.stopPropagation();
          }
        });
      },
    );
  }

  protected replaceSensorVariable(varName: string, value: string) {
    const info = this.sensorTemplateVariables[varName];
    info.value = value;
    this.applySensorTemplateVariables();
  }

  protected applySensorTemplateVariables() {
    each(
      this.form.find<HTMLInputElement>("#sensor-table-body tr td input"),
      (input) => {
        let replacement = $(input).data("originalValue") as string;
        if (isNil(replacement)) return;
        each(this.sensorTemplateVariables, (variableInfo) => {
          const searchString = variableInfo.original;
          if (!includes(replacement, searchString)) return;
          replacement = replacement.replace(searchString, variableInfo.value);
        });
        input.value = replacement;
      },
    );
  }

  protected resetSensorVariableTemplate(varName: string) {
    this.sensorTemplateVariables[varName].value =
      this.sensorTemplateVariables[varName].original;
    this.sensorTemplateVariables[varName].textField.val(
      this.sensorTemplateVariables[varName].original,
    );
    this.applySensorTemplateVariables();
  }
}
