import { Grid, Typography } from "@mui/material";
import Bluebird from "bluebird";
import { isEmpty, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import { DateRange } from "moment-range";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { PlotlyGaugeChart } from "../../charting/plotly_gauge_chart";
import { WidgetController } from "../../controller/widget_controller";
import { SensorLoader } from "../../json_api/sensor_loader";
import { Sensor, SensorValueType } from "../../models/sensor";
import { ValueRangeStatus } from "../../models/sensor_value_range";
import { logger } from "../../utils/logger";
import { getValueRangeForValue } from "../../utils/status_helper";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { sensorUrl } from "../../utils/urls";
import { LevelIndicatorWidgetConfigSerialized } from "../../widgets/level_indicator_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { PercentageBar } from "../common/percentage_bar";
import {
  LevelIndicatorWidgetProps,
  LevelIndicatorWidgetState,
} from "./level_indicator_widget.types";
import { WidgetBox } from "./widget_box";
import { WidgetTimestampGridItem } from "./widget_timestamp";

export class LevelIndicatorWidget
  extends React.Component<LevelIndicatorWidgetProps, LevelIndicatorWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: LevelIndicatorWidgetProps = {
    minRange: 0,
    dashboardSettings: {},
    maxRange: 100,
    displayMode: "bar",
    useRange: true,
    noStepIntervals: false,
    fallbackToLastValue: false,
    ignoreTimeScope: false,
  };

  static serializedConfigToProps(
    config: LevelIndicatorWidgetConfigSerialized,
  ): LevelIndicatorWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      updateEnabled: !config.disable_update,
      iconName: config.icon_name,
      iconSize: config.icon_size,
      vertical: config.vertical,
      value: config.value,
      valueRanges: config.value_ranges,
      displayMode: config.display_mode ?? "bar",
      unit: config.unit,
      displayUnit: config.display_unit,
      minRange: config.range_min ?? 0,
      maxRange: config.range_max ?? 100,
      noStepIntervals: config.no_step_intervals,
      timestamp: config.timestamp ? moment(config.timestamp) : null,
      measurementType: config.measurement_type,
      sensorType: config.sensor_type,
      sensorId: config.sensor_id,
      ignoreTimeScope: config.ignore_time_scope,
      fallbackToLastValue: config.fallback_to_last_value,
      gaugeHeight: config.gauge_height,
    } as LevelIndicatorWidgetProps);
  }
  private loadSensorPromise: Bluebird<void>;

  constructor(props: LevelIndicatorWidgetProps) {
    super(props);

    const link =
      !isNil(props.sensorId) && !isNil(props.assetId)
        ? sensorUrl(
            {
              id: props.sensorId,
              assetId: props.assetId,
            },
            "html",
          )
        : null;
    this.state = {
      title: props.title as string,
      titleLinkUrl: props.titleLinkUrl ?? link,
      contentLinkUrl: props.contentLinkUrl ?? link,
      value: props.value,
      timestamp: props.timestamp,
      valueRanges: props.valueRanges,
      status: props.status,
      displayUnit: props.displayUnit || props.unit,
      currentRange: getValueRangeForValue(props.value, props.valueRanges),
    };
  }

  componentDidMount(): void {
    if (this.props.sensorId) {
      this.loadSensorData(this.props);
    }
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      instance.sensorDataChannel.removeEventListener(this, this.props.sensorId);
    }

    if (!isNil(this.loadSensorPromise)) {
      this.loadSensorPromise.cancel();
      this.loadSensorPromise = null;
    }
  }
  componentDidUpdate(oldProps: LevelIndicatorWidgetProps): void {
    if (
      !this.props.timeRange?.start?.isSame(oldProps.timeRange.start) ||
      !this.props.timeRange?.end?.isSame(oldProps.timeRange.end) ||
      this.props.sensorId !== oldProps.sensorId
    ) {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        oldProps.sensorId,
      );
      this.loadSensorData(this.props);
      return;
    }

    if (this.props.title !== oldProps.title) {
      this.setState({
        title: this.titleFromProps(this.props, this.state.sensorName),
      });
    }

    if (this.props.updateEnabled !== oldProps.updateEnabled) {
      this.toggleSensorUpdates(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    if (
      !isNumber(value) ||
      !this.props.updateEnabled ||
      sensorId !== this.props.sensorId ||
      (!isNil(this.props.timeRange?.start) &&
        time.isBefore(this.props.timeRange?.start)) ||
      (!isNil(this.props.timeRange?.end) &&
        time.isAfter(this.props.timeRange?.end))
    ) {
      return;
    }

    if (!isNil(unit) && !isNil(this.props.unit)) {
      value = convertToUnit(value, unit, this.props.unit);
    }
    let status = this.state.status;
    let currentRange = this.state.currentRange;
    if (!isNil(this.state.valueRanges)) {
      currentRange = getValueRangeForValue(value, this.state.valueRanges);
      if (!isNil(currentRange)) {
        status = currentRange.status;
      }
    }
    this.setState({
      value,
      currentRange,
      timestamp: time,
      status,
    });
  }

  render(): React.ReactNode {
    return (
      <WidgetBox
        {...this.props}
        title={this.state.title}
        titleLinkUrl={this.state.titleLinkUrl}
        contentTitle={this.state.sensorName}
        contentLinkUrl={this.state.contentLinkUrl}
        onFullscreen={(fullscreen) => this.setState({ fullscreen })}
      >
        {this.getDisplayComponent()}
      </WidgetBox>
    );
  }

  private getDisplayComponent() {
    const valRange = this.props.useRange ? this.state.currentRange : null;
    if (this.props.displayMode === "gauge") {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <PlotlyGaugeChart
              value={this.state.value}
              divId={`widget-${this.props.widgetId}-diagram-container`}
              unit={this.state?.displayUnit || this.props.unit}
              range={[this.props.minRange, this.props.maxRange]}
              valueRanges={this.props.valueRanges}
              height={this.state.fullscreen ? 600 : this.props.gaugeHeight}
              maxWidth={this.state.fullscreen ? "80%" : null}
              noStepIntervals={this.props.noStepIntervals}
            />
          </Grid>
          {isNil(this.state.timestamp) ? null : (
            <WidgetTimestampGridItem
              timestamp={getTimeString(
                this.props.timeScopeName,
                this.state.timestamp,
              )}
              align="center"
            />
          )}
        </Grid>
      );
    } else {
      return (
        <PercentageBar
          value={this.state.value}
          max={this.props.maxRange}
          unit={this.props.unit}
          status={this.state.status}
          timestamp={getTimeString(
            this.props.timeScopeName,
            this.state.timestamp,
          )}
        />
      );
    }
  }

  titleFromProps(
    props: LevelIndicatorWidgetProps,
    fallback: string = "",
  ): string {
    return isEmpty(props.title) ? fallback : (props.title as string);
  }

  private loadSensorData(props: LevelIndicatorWidgetProps): void {
    let sensor: Sensor = null;
    if (isNil(props.sensorId) || isNaN(props.sensorId)) {
      logger.warn("No sensor id provided for level indicator widget");
      return;
    }

    this.loadSensorPromise = SensorLoader.getInstance()
      .getSensors([props.sensorId])
      .then((sensors) => {
        if (isNil(sensors) || isEmpty(sensors)) {
          throw new Error(`Error loading sensor ${props.sensorId}`);
        }

        let timeRange = null;
        if (!isNil(props.timeRange?.start) && !isNil(props.timeRange?.end)) {
          timeRange = props.timeRange;
        } else if (!isNil(props.timeRange?.start)) {
          timeRange = new DateRange(
            props.timeRange.start,
            props.timeRange.start.endOf("day"),
          );
        } else if (!isNil(props.timeRange?.end)) {
          timeRange = new DateRange(props.timeRange.end, moment());
        }

        sensor = sensors[0];
        // Write sensor information to state.
        this.setState({
          // apply heading from sensor name only if not heading is provided
          title: this.titleFromProps(props, sensor.name),
          valueRanges: sensor.value_ranges,
          displayUnit: sensor.display_unit || props.unit,
          titleLinkUrl: sensorUrl(
            {
              id: sensor.id,
              assetId: sensor.asset_id,
            },
            "html",
          ),
        });

        return SensorLoader.getInstance().getLastValueWithin(
          sensor.id,
          timeRange,
          this.props.fallbackToLastValue,
        );
      })
      .then((sensorValue) => {
        let value: number = sensorValue.value as number;

        if (!isNil(sensorValue.unit) && !isNil(props.unit)) {
          value = convertToUnit(
            value,
            sensorValue.unit,
            this.state.displayUnit,
          );
        }

        const currentRange = getValueRangeForValue(value, sensor.value_ranges);
        let newStatus: ValueRangeStatus;
        if (!isNil(currentRange)) {
          newStatus = currentRange.status;
        }

        // write sensor valuie information to state
        this.setState({
          value: value,
          timestamp: moment(sensorValue.timestamp),
          // apply heading from sensor name only if not heading is provided
          status: newStatus,
        });

        this.toggleSensorUpdates(props);
      })
      .catch((e) => {
        logger.error(
          "Error during sensor data loading in Level Indicator Widget",
          e,
        );
      });
  }

  protected toggleSensorUpdates(props: LevelIndicatorWidgetProps): void {
    if (WidgetController.getInstance()) {
      if (props.updateEnabled) {
        WidgetController.getInstance().sensorDataChannel.subscribe(
          props.sensorId,
        );
        WidgetController.getInstance().sensorDataChannel.addEventListener(
          this,
          props.sensorId,
        );
      } else {
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          props.sensorId,
        );
      }
    }
  }
}
