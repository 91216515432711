import DeleteIcon from "@mui/icons-material/Delete";
import { cloneDeep, findIndex } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";

import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { dialog } from "../../../utils/dialog";
import { sendData } from "../../../utils/jquery_helper";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import { MaterialUiDateRangePicker } from "../../common/data_range_picker";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { InfluxJournal, SensorGroup } from "../data/model";
import { SensorList } from "./sensor_list";

export interface DeleteSensorDataFormProps {
  assetId: string | number;
  sensorGroups: SensorGroup[];
  journalEntriesAdded?(journalEntries: InfluxJournal[]): void;

  onCancel(): void;
}

export interface DeleteSensorDataFormState {
  sensorGroups: SensorGroup[];
  timeRange: [Moment, Moment];
  selectAll: boolean;
  processing: boolean;
}

export interface SensorDataDeleteResponse {
  success?: string;
  error?: string;

  journal_entries?: InfluxJournal[];
}

export interface SensorDataDeleteBody {
  sensor_ids: Array<number | string>;
  time_range_start: string;
  time_range_end: string;
}
export class DeleteSensorDataForm extends React.Component<
  DeleteSensorDataFormProps,
  DeleteSensorDataFormState
> {
  constructor(props: DeleteSensorDataFormProps) {
    super(props);

    this.state = {
      sensorGroups: cloneDeep(props.sensorGroups),
      timeRange: [moment().startOf("day"), moment().endOf("day")],
      selectAll: false,
      processing: false,
    };
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <IBox>
              <IBoxTitle>
                <h4>
                  {I18n.t(
                    "frontend.manage_sensor_data_form.delete_sensor_data",
                  )}
                </h4>
              </IBoxTitle>
              <IBoxContent>
                <Grid container>
                  <Grid item xs={12}>
                    <MaterialUiDateRangePicker
                      type="datetime"
                      dateFormat="L LTS"
                      pickSeconds={true}
                      name="time-range"
                      label={I18n.t(
                        "frontend.manage_sensor_data_form.delete_time_range",
                      )}
                      value={this.state.timeRange}
                      onChange={(event) => {
                        this.setState({
                          timeRange: event.dateRange,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} marginTop={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.selectAll ?? false}
                          value="true"
                          onChange={(event) => this.onSelectAll(event)}
                        />
                      }
                      label={I18n.t(
                        "frontend.manage_sensor_data_form.select_all",
                      )}
                    />
                  </Grid>
                </Grid>
              </IBoxContent>
            </IBox>
          </Grid>
        </Grid>
        {this.state.sensorGroups.map((sensorGroup, index) => {
          return (
            <SensorList
              key={index}
              sensorGroup={sensorGroup}
              onChange={(sensorGroup) => this.onChangeSensorGroup(sensorGroup)}
              onDelete={(sensor) => void this.onDelete([sensor.id])}
            />
          );
        })}
        <FixedBottomArea key="bot-buttons">
          <FloatingButtons
            showScrollToTopBtn
            isProcessing={this.state.processing}
            onSubmit={() => void this.onDelete(this.getSelectedSensorIds())}
            onCancel={() => this.props.onCancel()}
            submitBtnIcon={<DeleteIcon />}
            submitBtnColor="secondary"
            saveTitle={I18n.t("frontend.delete")}
          />
        </FixedBottomArea>
      </React.Fragment>
    );
  }

  private onDelete(sensorIds: Array<number | string>) {
    return dialog
      .fire({
        title: I18n.t("frontend.manage_sensor_data_form.delete_sensor_data"),
        text: I18n.t("frontend.manage_sensor_data_form.delete_dialog_text"),
        showConfirmButton: true,
        showCancelButton: true,
        icon: "question",
        cancelButtonText: I18n.t("frontend.cancel"),
        confirmButtonText: I18n.t("frontend.delete"),
        confirmButtonColor: "#ff0000",
      })
      .then((result) => {
        if (result.isDismissed) {
          return;
        }
        this.setState({ processing: true });

        return sendData<SensorDataDeleteBody, SensorDataDeleteResponse>(
          url_helper.manageSensorDataUrl(this.props.assetId),
          {
            sensor_ids: sensorIds,
            time_range_start: this.state.timeRange[0].toISOString(),
            time_range_end: this.state.timeRange[1].toISOString(),
          },
          "DELETE",
        ).then((ret) => {
          void toast
            .success(I18n.t("frontend.manage_sensor_data_form.delete_success"))
            .then(() => {
              // reload the page to have the data reloaded
            });

          this.props.journalEntriesAdded?.(ret.journal_entries);
        });
      })
      .catch(() => {
        void toast.error(
          I18n.t("frontend.manage_sensor_data_form.error_on_delete"),
          I18n.t("frontend.measurement_form.error_title"),
          true,
        );
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  }

  private getSelectedSensorIds(): Array<string | number> {
    const sensorIds: Array<string | number> = [];

    this.state.sensorGroups.forEach((sensorGroup) => {
      sensorGroup.sensors.forEach((sensor) => {
        if (sensor.selected) {
          sensorIds.push(sensor.id);
        }
      });
    });

    return sensorIds;
  }

  private onChangeSensorGroup(sensorGroup: SensorGroup) {
    this.setState((prevState) => {
      const sensorGroups = [...prevState.sensorGroups];
      const sensorGroupIndex = findIndex(
        sensorGroups,
        (sg) => sg.title_link === sensorGroup.title_link,
      );
      if (sensorGroupIndex >= 0) {
        sensorGroups[sensorGroupIndex] = sensorGroup;
      }

      let selectAll = true;
      sensorGroups.forEach((sensorGroup) => {
        sensorGroup.sensors.forEach((sensor) => {
          selectAll = selectAll && sensor.selected;
        });
      });

      return {
        sensorGroups,
        selectAll,
      };
    });
  }

  private onSelectAll(event: React.ChangeEvent<HTMLInputElement>): void {
    event.persist();

    this.setState((prevState) => {
      const sensorGroups = cloneDeep(prevState.sensorGroups);

      sensorGroups.forEach((sensorGroup) => {
        sensorGroup.sensors.forEach((sensor) => {
          sensor.selected = event.target.checked;
        });
      });

      return {
        sensorGroups,
        selectAll: event.target.checked,
      };
    });
  }
}
