/// <reference types="../../../definitions/index" />;
import { Avatar, ListItemAvatar, ListItemText, MenuItem } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { redirectTo } from "../../../utils/redirection";
import { Icon } from "../../common/icon";

interface MaintenanceListEntryProps {
  /**
   * Name of the asset
   */
  assetName?: string;
  /**
   * Name of the maintenance
   */
  name?: string;
  /**
   * Icon for the maintenance
   */
  icon?: string;
  /**
   * Url to the maintenance page
   */
  url?: string;
  /**
   * Maintenance due in
   */
  dueAt?: string;
}

export function MaintenanceListEntry(props: MaintenanceListEntryProps) {
  return (
    <MenuItem onClick={(event) => handleClick(props.url, event)}>
      <ListItemAvatar>
        <Avatar>
          <Icon icon={props.icon}></Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <strong className="event-list-entry-heading">{props.name}</strong>
        <br />
        <span className="event-list-entry-description">{props.assetName}</span>
        <br />
        <small className="text-muted">
          {I18n.t("frontend.maintenance_due_dropdown.due_at")} {props.dueAt}
        </small>
      </ListItemText>
    </MenuItem>
  );
}

function handleClick(url: string, event: React.MouseEvent<HTMLElement>) {
  if (isEmpty(url)) {
    return;
  }

  event.preventDefault();
  redirectTo(url);
}
