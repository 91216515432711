import Bluebird from "bluebird";
import { Container } from "flux/utils";
import { has, isEmpty, isNil } from "lodash";
import * as React from "react";
import { SweetAlertResult } from "sweetalert2";
import {
  setConfirmLeaveMessage,
  showBeforeUnloadDialog,
} from "../../../utils/before_unload_dialog";
import { dialog } from "../../../utils/dialog";
import "../../../utils/flux_container_adapter";
import { sendData } from "../../../utils/jquery_helper";
import { redirectTo } from "../../../utils/redirection";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import * as Actions from "../data/maintenance_plan_actions";
import MaintenancePlanStore, {
  MaintenancePlanState,
} from "../data/maintenance_plan_store";
import { MaintenancePlan } from "../data/models";
import { MaintenancePlanForm } from "../views/maintenance_plan_form";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";

/**
 * Base class of maintenance plan container.
 * This is never used directly but extended with the Flux.Container mixin.
 */
class MaintenancePlanContainerBase extends React.Component<
  {},
  MaintenancePlanState
> {
  static getStores() {
    return [MaintenancePlanStore];
  }

  static calculateState(prevState: MaintenancePlanState) {
    return MaintenancePlanStore.getState();
  }

  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;

  render(): React.ReactNode {
    const state = this.state;

    if (state.hasChanges) {
      // enable before unload dialog if changes are applied
      showBeforeUnloadDialog(true);
      setConfirmLeaveMessage(
        I18n.t("frontend.maintenance_plan_form.cancel_confirm_heading"),
        I18n.t("frontend.maintenance_plan_form.cancel_confirm_message"),
      );
    }

    return (
      <MaintenancePlanForm
        assetTree={state.assetTree}
        assetGroups={state.assetGroups}
        maintenanceTypes={state.maintenanceTypes}
        maintenancePlansToDelete={state.maintenancePlansToDelete}
        isProcessing={state.isProcessing}
        onSubmit={() => this.onSaveMaintenancePlans()}
        onCancel={() => this.onCancel()}
        onAddMaintenancePlan={Actions.addMaintenancePlan}
        onAddInspectionPlan={Actions.addInspectionPlan}
        onRemoveMaintenancePlan={Actions.removeMaintenancePlan}
        onUpdateMaintenancePlan={Actions.updateMaintenacePlan}
        onChangeMaintenancePlanOrder={Actions.changeMaintenancePlanOrder}
        onCopyMaintenancePlan={Actions.copyMaintenancePlan}
        onMoveMaintenancePlan={Actions.moveMaintenancePlan}
        onToggle={Actions.toggleMaintenancePlanList}
        onToggleAll={Actions.toggleAllMaintenancePlanLists}
      />
    );
  }

  private onSaveMaintenancePlans(): Bluebird<void> {
    Actions.setProcessing(true);
    Actions.resetErrors();
    const state = this.state;

    const maintenancePlans: MaintenancePlan[] = [];
    state.assetGroups.forEach((assetGroup) => {
      assetGroup.assets.forEach((asset) => {
        asset.maintenance_plans.forEach((maintenancePlan, index) => {
          maintenancePlans.push({
            ...maintenancePlan,
            position: index,
          });
        });
      });
    });

    const maintenancePlansToDelete = state.maintenancePlansToDelete
      .filter((maintenancePlan) => !isNil(maintenancePlan.id))
      .map((maintenancePlan) => ({ id: maintenancePlan.id }));

    return sendData(url_helper.createOrUpdateMaintenancePlanPath(), {
      maintenance_plans: maintenancePlans,
      maintenance_plans_to_delete: maintenancePlansToDelete,
    })
      .then(() => {
        showBeforeUnloadDialog(false);
        this.redirect();
      })
      .catch((error) => {
        Actions.setProcessing(false);

        // load errors from response if present
        if (has(error, "request.responseJSON")) {
          Actions.setErrors(error.request.responseJSON);
        }

        void toast.error(
          I18n.t("frontend.maintenance_plan_form.error"),
          I18n.t("frontend.maintenance_plan_form.error_title"),
          true,
        );
      });
  }

  private onCancel(): Bluebird<any | void> {
    Actions.setProcessing(false);
    const closeAndRedirect = (result: SweetAlertResult<boolean>) => {
      if (result.value) {
        showBeforeUnloadDialog(false);
        this.redirect();
      }

      return Bluebird.resolve();
    };

    if (!this.state.hasChanges) {
      closeAndRedirect({
        value: true,
        isDismissed: true,
        isConfirmed: true,
        isDenied: false,
      });
      return Bluebird.resolve();
    }

    return Bluebird.cast(
      dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          icon: "question",
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          title: I18n.t(
            "frontend.maintenance_plan_form.cancel_confirm_heading",
          ),
          html: `<p>${I18n.t(
            "frontend.maintenance_plan_form.cancel_confirm_message",
          )}</p>`,
        })
        .then(closeAndRedirect),
    );
  }

  private redirect(url: string = null): void {
    const state = this.state;

    if (!isEmpty(url)) {
      redirectTo(url);
      return;
    }
    if (!isNil(state.rootAssetId) && !isEmpty(state.rootAssetId.toString())) {
      // redirect to maintenance plans overview
      this.redirect(
        url_helper.assetMaintenancePlansPath(state.rootAssetId, null, "html"),
      );
    } else if (
      !isNil(this.context.referrer) &&
      !isEmpty(this.context.referrer)
    ) {
      // redirect to referrer
      this.redirect(this.context.referrer);
    }
  }
}

/**
 * A Flux container component that connects the maintenance plan form to the store and actions.
 */
export const MaintenancePlanContainer = Container.create(
  MaintenancePlanContainerBase,
);
