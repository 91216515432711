import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";
import { DashboardProps } from "./dashboard.types";
import { DashboardWidgetRow } from "./dashboard_widget_row";

const sidebarWidth = { xs: 12, lg: 3, xl: 3 };

export const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const hasRightSidebar = !isEmpty(
    props.dashboardConfig?.columns?.right_sidebar,
  );
  const hasLeftSidebar = !isEmpty(props.dashboardConfig?.columns?.left_sidebar);

  let numSidebars = hasLeftSidebar ? 1 : 0;
  numSidebars += hasRightSidebar ? 1 : 0;

  return (
    <Grid container spacing={2}>
      {hasLeftSidebar ? (
        <Grid
          item
          xs={sidebarWidth.xs}
          lg={sidebarWidth.lg}
          xl={sidebarWidth.xl}
        >
          <Grid container spacing={1}>
            {props.dashboardConfig?.columns?.left_sidebar?.map(
              (widgets, index) => (
                <DashboardWidgetRow widgets={widgets} key={index} />
              ),
            )}
          </Grid>
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        lg={12 - sidebarWidth.lg * numSidebars}
        xl={12 - sidebarWidth.xl * numSidebars}
      >
        <Grid container spacing={1}>
          {props.dashboardConfig?.columns?.center?.map((widgets, index) => (
            <DashboardWidgetRow widgets={widgets} key={index} />
          ))}
        </Grid>
      </Grid>
      {hasRightSidebar ? (
        <Grid
          item
          xs={sidebarWidth.xs}
          lg={sidebarWidth.lg}
          xl={sidebarWidth.xl}
        >
          <Grid container spacing={1}>
            {props.dashboardConfig?.columns?.right_sidebar?.map(
              (widgets, index) => (
                <DashboardWidgetRow widgets={widgets} key={index} />
              ),
            )}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
