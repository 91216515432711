import * as React from "react";

import { defaultTo, isEmpty } from "lodash";

import { Grid, IconButton } from "@mui/material";
import { Root, createRoot } from "react-dom/client";

import { Edit } from "@mui/icons-material";
import { sendData } from "../../utils/jquery_helper";
import { measurementValuesUrl } from "../../utils/urls";
import { AppRoot } from "./app_root";
import { LoadingWrapper } from "./loading_wrapper";
import { MiniButtons } from "./mini_buttons";

export interface Measurement {
  id: string | number;
  name: string;
  lastValue: number;
}

interface MeasurementFormProps {
  measurement: Measurement;
}

interface MeasurementFormState {
  isProcessing: boolean;
  editMode: boolean;
  value: number;
  oldValue: number;
  note: string;
}

const measurementFormRoots: Root[] = [];
/**
 * Initialize measurement forms
 */
export function initializeMeasurementForms(): void {
  $('[data-toggle="measurement-form"]').each((index, element) => {
    const data = $(element).attr("data-measurement");
    if (isEmpty(data)) {
      return;
    }

    const measurement = JSON.parse(data) as Measurement;
    const root = createRoot(element);
    measurementFormRoots.push(root);
    root.render(
      <AppRoot>
        <MeasurementForm measurement={measurement}></MeasurementForm>{" "}
      </AppRoot>,
    );
  });
}

/**
 * Destroy measurement forms
 */
export function destroyMeasurementForms(): void {
  measurementFormRoots.forEach((root) => root.unmount());
  measurementFormRoots.length = 0;
}

interface ToggleableInputProps {
  editMode: boolean;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

/**
 * A toggleable number input field. Displays value when edit is disabled or shows a number input.
 * @param props
 */
const ToggleableInput: React.FunctionComponent<ToggleableInputProps> = (
  props: ToggleableInputProps,
) => {
  return !props.editMode ? (
    <b>{props.value ? `${props.value}%` : "---"}</b>
  ) : (
    <div className="input-group">
      <input
        className="form-control input-sm"
        type="number"
        value={defaultTo(props.value, "")}
        onChange={props.onChange}
        disabled={props.disabled}
      ></input>
      <span className="input-group-addon">%</span>
    </div>
  );
};

/**
 * A component used to show the latest measurement value and add new values
 */
export class MeasurementForm extends React.Component<
  MeasurementFormProps,
  MeasurementFormState
> {
  constructor(props: MeasurementFormProps) {
    super(props);

    this.state = {
      isProcessing: false,
      value: props.measurement.lastValue,
      oldValue: props.measurement.lastValue,
      note: "",
      editMode: false,
    };
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <small>{this.props.measurement.name}</small>
          <br />
          <ToggleableInput
            editMode={this.state.editMode}
            value={this.state.value}
            onChange={(event) => this.handleValueChange(event)}
            disabled={this.state.isProcessing}
          />
        </Grid>
        <Grid item xs={2} p={1}>
          <LoadingWrapper size="1x" loading={this.state.isProcessing}>
            {this.state.editMode ? null : (
              <IconButton
                className="text-success"
                title={I18n.t("frontend.measurement_values.edit")}
                onClick={() => this.toggleEditMode()}
                size="small"
              >
                <Edit />
              </IconButton>
            )}
            {this.state.isProcessing ? null : (
              <MiniButtons
                onOk={() => {
                  void this.saveChanges();
                }}
                onCancel={() => {
                  void this.abortChanges();
                }}
              />
            )}
          </LoadingWrapper>
        </Grid>
      </Grid>
    );
  }

  protected toggleEditMode(): void {
    this.setState({
      editMode: true,
    });
  }

  protected handleValueChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    this.setState({
      value: parseFloat(event.target.value),
    });
  }

  protected async saveChanges() {
    this.setState({ isProcessing: true });

    try {
      const { value, note } = this.state;
      await sendData(measurementValuesUrl(this.props.measurement.id), {
        measurement_value: { value, note },
      });

      // disable edit mode and update "old value"
      this.setState({
        editMode: false,
        oldValue: value,
        isProcessing: false,
      });
    } catch (error) {
      this.setState({ isProcessing: false });
      void toasts.error(I18n.t("frontend.measurement_values.error"));
    }
  }

  protected abortChanges(): void {
    this.setState((previousState) => {
      return {
        editMode: false,
        value: previousState.oldValue,
      };
    });
  }
}
