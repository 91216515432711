import { isNil } from "lodash";
import { SensorEventSubscriber } from "../channels/sensor_data_channel";
import { WidgetController } from "../controller/widget_controller";
export function registerSensorUpdates(
  caller: SensorEventSubscriber,
  sensorIds: number[],
): void {
  const instance = WidgetController.getInstance();
  if (isNil(instance)) {
    return;
  }

  sensorIds.forEach((sensorId) => {
    instance.sensorDataChannel.subscribe(sensorId);
    instance.sensorDataChannel.addEventListener(caller, sensorId);
  });
}

export function unregisterSensorUpdates(
  caller: SensorEventSubscriber,
  sensorIds: number[],
): void {
  const instance = WidgetController.getInstance();
  if (isNil(instance)) {
    return;
  }

  sensorIds.forEach((sensorId) => {
    instance.sensorDataChannel.removeEventListener(caller, sensorId);
  });
}
