/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear, Search } from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";

import {
  defaultTo,
  each,
  findIndex,
  isEmpty,
  isNil,
  map,
  merge,
  sortedUniq,
  toInteger,
  toString,
  uniqBy,
  upperFirst,
} from "lodash";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as JSONAPI from "jsonapi-typescript";

import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import {
  AssetIncludes,
  AssetJSONAPIAttributes,
  AssetJSONObject,
  AssetStateInfo,
  ExtractedAssetData,
  extractIncludedFromJsonApiListResponse,
} from "../../json_api/asset";
import { Asset } from "../../models/asset";
import {
  ItemAction,
  generateLinkForItemActionRoute,
} from "../../models/item_action";
import { SensorValueType } from "../../models/sensor";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import {
  assetPath,
  assetTypeAssetsApiPath,
  assetsJsonApiPath,
} from "../../utils/urls";
import {
  IDType,
  ParamsType,
  applyParamsToBaseUrl,
} from "../../utils/urls/url_utils";
import { AssetListWidgetConfigSerialized } from "../../widgets/asset_list_widget.types";
import { ItemSelection } from "../../widgets/item_selection";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";

import { ItemActionButton } from "../common/item_action_button";
import { SialogicQueryClient } from "../common/sialogic_query_client";
import {
  AssetListWidgetProps,
  AssetListWidgetState,
} from "./asset_list_widget.types";
import { StateWidget } from "./state_widget";
import { WidgetBox } from "./widget_box";

export class AssetListWidget
  extends React.Component<AssetListWidgetProps, AssetListWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<AssetListWidgetProps> = {
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,

    pageSize: 10,
    tableHeight: 500,
    enableSearch: true,
    allowFullscreen: true,
  };

  static serializedConfigToProps(
    config: AssetListWidgetConfigSerialized,
  ): AssetListWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      dataUpdateEnabled: !config.disable_update,
      assets: config.assets,
      actions: config.actions,

      assetUrl: config.asset_url,
      assetSearchUrl: config.asset_search_url,
      density: config.table_density,
      // read table height
      tableHeight: defaultTo(
        config.table_height,
        AssetListWidget.defaultProps.tableHeight,
      ),
      assetTypeId: config.asset_type_id as number,
      pageSize: defaultTo(config.page_size, 15),
      stateContexts: config.state_contexts,
    } as AssetListWidgetProps);
  }

  constructor(props: AssetListWidgetProps) {
    super(props);
    let assetData: Partial<ExtractedAssetData>;
    if (!isNil(props.assets)) {
      assetData = extractIncludedFromJsonApiListResponse(props.assets);
    } else {
      assetData = {
        assets: null,
        locations: null,
        sensors: null,
        organizations: null,
      };
    }
    this.state = {
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: props.title as string,
      titleLinkUrl: props.titleLinkUrl,
      contentLinkUrl: props.contentLinkUrl,
      assets: assetData.assets,
      locationsById: assetData.locations,
      sensorsById: assetData.sensors,

      loading: false,
      pageSize: this.props.pageSize,
      pageSizes: sortedUniq(
        [10, this.props.pageSize, 20, 50, 100].sort((a, b) => a - b),
      ),

      currentPage: isNil(props.assets) ? null : 1,
      loadingPage: null,

      stateContexts: uniqBy(props.stateContexts, (sc) => sc.identifier),
      totalPages: assetData.totalPages,
      assetCount: assetData.totalCount,
      searchTerm: null,
    };
  }

  componentDidMount(): void {
    // initialize component, e.g. some loading from API
    if (isNil(this.state.assets) && !isEmpty(this.props.assetUrl)) {
      this.loadAssets(this.state.currentPage || 1, this.state.pageSize);
    }
  }

  componentWillUnmount(): void {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // register listeners to widget controller
      // for sensors :
      /// WidgetController.getInstance().sensorDataChannel.removeEventListener(this, this.props.sensorId);
    }
  }

  componentDidUpdate(oldProps: AssetListWidgetProps): void {
    if (this.props.dataUpdateEnabled !== oldProps.dataUpdateEnabled) {
      //this.toggleSensorUpdates(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  render(): React.ReactNode {
    // implement display content here ...
    const content = this.tableContent();

    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          content
        ) : (
          <WidgetBox {...this.props}>
            <Grid container>
              {this.props.enableSearch ? (
                <Grid item xs={12} className="mb-1">
                  <TextField
                    size="small"
                    className="float-right"
                    value={toString(this.state.searchTerm)}
                    label={I18n.t("frontend.search")}
                    onChange={(event) => {
                      this.setState({
                        searchTerm: event.currentTarget.value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              void this.loadAssets(
                                this.state.currentPage,
                                this.state.pageSize,
                                true,
                              );
                            }}
                          >
                            <Search />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="default"
                            onClick={() => {
                              this.setState({ searchTerm: null }, () => {
                                void this.loadAssets(
                                  this.state.currentPage,
                                  this.state.pageSize,
                                  true,
                                );
                              });
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyUp={(event) => {
                      if (event.key == "Enter") {
                        void this.loadAssets(
                          this.state.currentPage,
                          this.state.pageSize,
                          true,
                        );
                        event.stopPropagation();
                      }
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                {content}
              </Grid>
            </Grid>
          </WidgetBox>
        )}
      </>
    );
  }
  tableToolbar(): React.ReactNode {
    return <></>;
    /*(<GridToolbarContainer>
        <GridDensitySelector />
      </GridToolbarContainer>);*/
  }
  tableContent(): React.ReactElement {
    let showPagination = true;
    if (!isNil(this.state.pageSize) && !isNil(this.state.assetCount)) {
      showPagination = this.state.pageSize <= this.state.assetCount;
    }

    const columns = this.gridColDef();
    return (
      <Box style={{ height: this.props.tableHeight, width: "100%" }}>
        <DataGrid
          paginationMode="server"
          pagination
          hideFooterPagination={!showPagination}
          initialState={{
            density: defaultTo(this.props.density, "standard"),
            columns: {
              columnVisibilityModel: {
                id: false,
                created_at: false,
                updated_at: false,
                description: false,
              },
            },
          }}
          paginationModel={{
            pageSize: this.state.pageSize,
            page: (this.state.currentPage || 1) - 1,
          }}
          pageSizeOptions={this.state.pageSizes}
          rowCount={this.state.assetCount || 0}
          rows={defaultTo(this.state.assets, [])}
          columns={columns}
          loading={this.state.loading}
          onRowClick={(row) => this.handleRowSelected(row)}
          onPaginationModelChange={(model, datails) => {
            this.handlePageChange(model.page + 1, model.pageSize);
          }}
        />
      </Box>
    );
  }

  handlePageChange(page: number, pageSize: number): void {
    void this.loadAssets(page, pageSize);
  }

  buttonForAssetAction(
    action: ItemAction,
    assetId: IDType,
    key: string | number,
  ): React.ReactElement {
    const link = this.linkForAssetAction(action, assetId);
    const title = defaultTo(
      action.title,
      I18n.t(`frontend.organizations.list.actions.${action.action}`),
    );
    if (!isNil(link)) {
      return (
        <ItemActionButton
          key={key}
          action={action}
          link={link}
          title={title}
          onComplete={() => {
            if (action.reload_on_success) {
              window.location.reload();
            } else {
              this.loadAssets(
                this.state.currentPage || 1,
                this.state.pageSize,
                true,
              );
            }
          }}
        />
      );
    } else {
      return null;
    }
  }

  linkForAssetAction(action: ItemAction, assetId: IDType): string {
    if (action.route) {
      return generateLinkForItemActionRoute(
        action.route,
        action.params,
        assetId,
      );
    }
  }

  gridColDef(): GridColDef<AssetJSONObject>[] {
    const defaultWidth = 130;
    const largeWidth = 200;
    const columns: GridColDef<AssetJSONObject>[] = [
      { field: "id", headerName: "#", type: "number" },
      {
        field: "name",
        headerName: I18n.t("activerecord.attributes.asset.name"),
        type: "string",
        width: largeWidth,
        renderCell: (params) => (
          <Link href={assetPath(params.row.id)}>{params.row.name}</Link>
        ),
      },
      {
        field: "asset_type_name",
        headerName: I18n.t("activerecord.attributes.asset.asset_type"),
        type: "string",
        width: defaultWidth,
      },
      {
        field: "description",
        headerName: I18n.t("activerecord.attributes.asset.description"),
        width: defaultWidth,
        type: "string",
      },
      {
        field: "serial",
        headerName: I18n.t("activerecord.attributes.asset.serial"),
        type: "string",
        width: defaultWidth,
      },
      {
        field: "operator",
        headerName: I18n.t("activerecord.attributes.asset.operator"),
        width: largeWidth,
        type: "string",
        valueGetter: (value, row) => {
          const orgID = row.operator_id;
          if (isNil(orgID)) return null;
          return row.operator_name;
        },
      },
      {
        field: "created_at",
        headerName: I18n.t("activerecord.attributes.base.created_at"),
        minWidth: defaultWidth,
        type: "dateTime",

        valueGetter: (value, row) => new Date(row.created_at),
        valueFormatter: (value, row) => moment(value).format("L LT"),
      },
      {
        field: "updated_at",
        headerName: I18n.t("activerecord.attributes.base.updated_at"),
        minWidth: defaultWidth,
        type: "dateTime",

        valueGetter: (value, row) => new Date(row.created_at),
        valueFormatter: (value, row) => moment(value as Date).format("L LT"),
      },
    ];

    if (!isNil(this.props.actions) && !isEmpty(this.props.actions)) {
      columns.push({
        field: "actions",
        headerName: I18n.t("base.actions"),

        width: 400,
        renderCell: (params) => {
          return (
            <ButtonGroup>
              {map(this.props.actions, (action, index) =>
                this.buttonForAssetAction(action, params.row.id, index),
              )}
            </ButtonGroup>
          );
        },
      });
    }

    return this.addStateContextColumns(columns, largeWidth);
  }

  addStateContextColumns(
    columns: GridColDef<AssetJSONObject>[],
    width: number,
  ): GridColDef[] {
    if (isEmpty(this.state.stateContexts)) {
      return columns;
    } else {
      each(
        uniqBy(this.state.stateContexts, (s) => s.id),
        (ctx) => {
          columns.push({
            field: `state_${ctx.id}`,
            width,
            headerName: upperFirst(ctx.name),
            valueGetter: (value, row) => {
              const assetStates = row.asset_states as Record<
                string,
                AssetStateInfo
              >;
              if (isNil(assetStates)) return null;
              const stateInfo: AssetStateInfo = assetStates[ctx.identifier];
              return stateInfo?.name;
            },
            renderCell: (params) => {
              const assetStates = params.row.asset_states as Record<
                string,
                AssetStateInfo
              >;
              if (isNil(assetStates)) return null;
              const stateInfo: AssetStateInfo = assetStates[ctx.identifier];
              if (isNil(stateInfo)) return null;

              return (
                <StateWidget
                  inline={true}
                  widgetBox={false}
                  contextStateMachine={{
                    id: stateInfo.csm_id,
                    current_state: {
                      id: stateInfo.s_id,
                      name: stateInfo.name,
                      icon: stateInfo.icon,
                      color: stateInfo.color,
                      identifier: stateInfo.state,
                      criticality: stateInfo.criticality_val,
                    },
                    state_context: {
                      id: stateInfo.sc_id,
                      identifier: ctx.identifier,
                      name: stateInfo.name,
                    },
                    stateful_item_id: toInteger(params.row.id),
                    stateful_item_type: "Asset",
                  }}
                />
              );
            },
          });
        },
      );
    }
    return columns;
  }
  /** Handles selection of assets from user interaction with the component itself
   *
   *
   * @param {Asset} asset
   * @memberof AssetListWidget
   */

  handleRowSelected(asset: Asset): void {
    const assetId = toInteger(asset.id);
    this.props?.onAssetSelect(assetId, asset);
  }

  /** Handles selections of items from extrernal sources
   *
   *
   * @param {ItemSelection<any>} itemSelection
   * @memberof AssetListWidget
   */
  handleItemSelection(itemSelection: ItemSelection<any>): void {
    if (itemSelection.itemType === "Asset" && itemSelection.source !== this) {
      const index = findIndex(
        this.state.assets,
        (a) => a.id === itemSelection.itemId,
      );
      // TODO apply the selection to the data grid
    }
  }

  loadAssets(pageNumber: number, pageSize: number, force = false): void {
    if (
      force ||
      this.state.currentPage != pageNumber ||
      this.state.pageSize != pageSize
    ) {
      this.setState(
        { loadingPage: pageNumber, loading: true, pageSize },
        () => {
          const includes: AssetIncludes[] = ["location"];
          const params: ParamsType = [];
          if (this.props.enableSearch && !isEmpty(this.state.searchTerm)) {
            params.push(["filter[search]", this.state.searchTerm]);
          }
          let path = null;

          if (!isNil(this.props.assetUrl) && !isEmpty(this.props.assetUrl)) {
            path = applyParamsToBaseUrl(
              this.props.assetUrl,
              pageNumber,
              pageSize,
              includes,
              params,
            );
          } else if (
            isNil(this.props.assetTypeId) ||
            isEmpty(this.props.assetTypeId)
          ) {
            path = assetsJsonApiPath(pageNumber, pageSize, includes, params);
          } else {
            path = assetTypeAssetsApiPath(
              this.props.assetTypeId,
              pageNumber,
              pageSize,
              includes,
              params,
            );
          }

          SialogicQueryClient.fetchQuery({
            queryKey: [path],
            queryFn: () => {
              return loadDataFromUrl<
                JSONAPI.CollectionResourceDoc<string, AssetJSONAPIAttributes>
              >(path);
            },
          })
            .then((jsonApiResponse) => {
              const extractedAssetData =
                extractIncludedFromJsonApiListResponse(jsonApiResponse);
              this.handleAssetsJsonAPIResponse(extractedAssetData);
            })
            .catch((e) => {
              this.setState({ loadingPage: null, loading: false });
              logger.error(e);
            });
        },
      );
    }
  }

  handleAssetsJsonAPIResponse(extractedAssetData: ExtractedAssetData): void {
    this.setState({
      assets: extractedAssetData.assets,
      locationsById: extractedAssetData.locations,
      sensorsById: extractedAssetData.sensors,

      currentPage: defaultTo(this.state.loadingPage, 1),
      loadingPage: null,
      totalPages: extractedAssetData.totalPages,
      assetCount: extractedAssetData.totalCount,
      loading: false,
    });
  }
}
