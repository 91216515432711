import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  defaultTo,
  each,
  includes,
  isEmpty,
  isEqual,
  isNil,
  merge,
  sortBy,
} from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { AssetEventSubscriber } from "../../channels/asset_notification_channel";
import { WidgetController } from "../../controller/widget_controller";
import {
  EventNotification,
  EventSeverityLevel,
} from "../../models/event_notification";
import { assetEventPath, assetEventsPath } from "../../utils/urls";
import { WidgetBox } from "./widget_box";

import { Close } from "@mui/icons-material";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import {
  AssetEventJsonApiFilter,
  AssetEventJSONObject,
} from "../../json_api/asset_event";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { api_asset_event_path, api_asset_events_path } from "../../routes";
import { notifyAirbrake } from "../../utils/airbrake_error_handler";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { AssetEventsWidgetConfigSerialized } from "../../widgets/asset_events_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { AssetEventDetails } from "../asset_events/asset_event_details";
import { AssetEventFilter } from "../asset_events/asset_event_filter";
import { AssetEventWidgetItem } from "../asset_events/asset_event_widget_item";
import { AssetEventWidgetItemCompact } from "../asset_events/asset_event_widget_item_compact";

import { ExtensiblePageSettings } from "../common/page_size";
import {
  AssetEventsWidgetProps,
  AssetEventsWidgetState,
} from "./asset_events_widget.types";
import { SialogicQueryClient } from "../common/sialogic_query_client";

const EVENT_INCLUDES = [
  "event_type",
  "asset",
  "root_asset",
  "user",
  "event_pattern",
];
function requestOptionsForFilter(
  filter: AssetEventFilter,
  pageSettings: ExtensiblePageSettings,
  sort = "-from",
) {
  const theJsonApiFilter: AssetEventJsonApiFilter = {
    asset: filter.assetIds,
    event_type: filter.typeId,
    start_after: filter.from,
    end_before: filter.to,
    severity_level: filter.severity_level,
    search: filter.search,
  };
  const params = jsonApiFilterParamsArgumentsFromFilterObject(theJsonApiFilter);
  const options: Record<string, string | number> = {};
  options["include"] = EVENT_INCLUDES.join(",");
  options["page[size]"] = pageSettings.size;
  options["page[number]"] = pageSettings.number;
  options["sort"] = sort;

  return merge(options, params);
}

export class AssetEventsWidget
  extends React.Component<AssetEventsWidgetProps, AssetEventsWidgetState>
  implements AssetEventSubscriber
{
  static defaultProps: Partial<AssetEventsWidgetProps> = {
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,
    allowFullscreen: true,
    eventListSize: 5,
    displayMode: "detailed",
    events: null,
  };

  // TODO implement
  static serializedConfigToProps(
    config: AssetEventsWidgetConfigSerialized,
  ): AssetEventsWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      assetIds: config.asset_ids,
      displayMode: config.display_mode,
      exceptEventTypeIds: config.except,
      onlyEventTypeIds: config.only,
      severityLevels: config.severity_levels,
      eventListSize: config.limit,
      events: config.events,
    } as AssetEventsWidgetProps);
  }

  constructor(props: AssetEventsWidgetProps) {
    super(props);
    this.state = {
      detailsOpen: false,
      detailsEvent: null,
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: defaultTo(
        props.title as string,
        I18n.t("activerecord.models.asset_event.other"),
      ),
      loading: isNil(props.events) ? false : true,
      events: props.events
        ? sortBy(props.events, (e) => new Date(e.from))?.reverse()
        : null,
      titleLinkUrl: defaultTo(
        props.titleLinkUrl,
        assetEventsPath(this.props.assetIds[0]),
      ),
      contentLinkUrl: props.contentLinkUrl,
    };
  }

  toggleEventUpdates() {
    if (this.props.dataUpdateEnabled) {
      each(this.props.assetIds, (id) => {
        WidgetController.getInstance().assetNotifitcationChannel.addEventListener(
          this,
          id,
        );
        WidgetController.getInstance().assetNotifitcationChannel.subscribe(id);
      });
    } else {
      each(this.props.assetIds, (id) => {
        WidgetController.getInstance().assetNotifitcationChannel.removeEventListener(
          this,
          id,
        );
        WidgetController.getInstance().assetNotifitcationChannel.unsubscribe(
          id,
        );
      });
    }
  }

  componentDidMount(): void {
    if (isNil(this.state.events)) {
      void this.loadEvents();
    }
    // initialize component, e.g. some loading from API
    this.toggleEventUpdates();
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      each(this.props.assetIds, (id) => {
        WidgetController.getInstance().assetNotifitcationChannel.removeEventListener(
          this,
          id,
        );
      });
    }
  }

  componentDidUpdate(oldProps: AssetEventsWidgetProps): void {
    if (
      (this.props.timeRange?.start &&
        !this.props.timeRange?.start?.isSame(oldProps.timeRange?.start)) ||
      (this.props.timeRange?.end &&
        !this.props.timeRange?.end?.isSame(oldProps.timeRange?.end)) ||
      !isEqual(this.props.assetIds, oldProps.assetIds) ||
      !isEqual(this.props.severityLevels, oldProps.severityLevels) ||
      !isEqual(this.props.onlyEventTypeIds, oldProps.onlyEventTypeIds) ||
      !isEqual(this.props.exceptEventTypeIds, oldProps.exceptEventTypeIds) ||
      this.props.eventListSize !== oldProps.eventListSize
    ) {
      void this.loadEvents();
    }
    if (this.props.dataUpdateEnabled !== oldProps.dataUpdateEnabled) {
      this.toggleEventUpdates();
    }
  }

  async loadEvents() {
    const queryKey = [
      "assetEvents",
      this.props.assetIds,
      this.props.timeRange?.start?.toISOString(),
      this.props.timeRange?.end?.toISOString(),
      this.props.severityLevels,
      this.props.onlyEventTypeIds,
      this.props.eventListSize,
      { include: ["event_type", "asset", "root_asset"] },
    ];

    try {
      const events = await SialogicQueryClient.fetchQuery({
        queryKey,
        queryFn: async () => {
          this.setState({ loading: true });
          const filterOptions = requestOptionsForFilter(
            {
              assetIds: this.props.assetIds,
              from: this.props.timeRange?.start?.toDate(),
              to: this.props.timeRange?.end?.toDate(),
              severity_level: this.props.severityLevels
                ? (this.props.severityLevels?.join(",") as EventSeverityLevel)
                : undefined,
              typeId: this.props.onlyEventTypeIds?.join(","),
            },
            { size: this.props.eventListSize, number: 1 },
          );
          const data = await loadDataFromUrl<
            CollectionResourceDoc<string, AssetEventJSONObject>
          >(
            api_asset_events_path({
              ...filterOptions,
              _options: true,
              format: "json",
            }),
          );

          return jsonApiResourceCollectionToFlatObjects(data);
        },
      });
      this.setState((prev) => ({ ...prev, events }));
    } catch (e) {
      console.error(e);
      void notifyAirbrake(e as Error);
    } finally {
      this.setState({ loading: false });
    }
  }
  handleNewEvent(
    event: EventNotification,
    time: Moment,
    assetId: number,
    eventId: number,
  ): void {
    if (
      !isNil(this.props.onlyEventTypeIds) &&
      !isEmpty(this.props.onlyEventTypeIds) &&
      !includes(this.props.onlyEventTypeIds, event.event_type_id)
    ) {
      return;
    }

    if (
      !isNil(this.props.exceptEventTypeIds) &&
      !isEmpty(this.props.exceptEventTypeIds) &&
      includes(this.props.exceptEventTypeIds, event.event_type_id)
    ) {
      return;
    }

    if (
      !isNil(this.props.severityLevels) &&
      !isEmpty(this.props.severityLevels) &&
      !includes(this.props.severityLevels, event.severity_level)
    ) {
      return;
    }
    if (
      this.props.timeRange &&
      (time.isBefore(this.props.timeRange.start) ||
        time.isAfter(this.props.timeRange.end))
    ) {
      return;
    }

    void SialogicQueryClient.fetchQuery({
      queryKey: [
        "assetEvent",
        event.asset.id,
        event.event_id,
        { include: EVENT_INCLUDES },
      ],
      queryFn: async () => {
        const data = await loadDataFromUrl<
          SingleResourceDoc<string, AssetEventJSONObject>
        >(
          api_asset_event_path(event.event_id, {
            _options: true,
            include: EVENT_INCLUDES.join(","),
          }),
        );
        return jsonApiSingleResourceToFlatObject(data);
      },
    }).then((event) => {
      this.setState((prev) => {
        const events = defaultTo(prev.events, []);
        const newLength = events.unshift(event);
        if (newLength > this.props.eventListSize) {
          events.pop();
        }
        this.setState(() => ({
          events,
        }));
      });
    });
  }

  openDetails(eventNotification: AssetEventJSONObject) {
    this.setState({ detailsEvent: eventNotification, detailsOpen: true });
  }

  render(): React.ReactNode {
    const compact = this.props.displayMode === "compact";
    let content;
    if (isEmpty(this.state.events)) {
      if (this.state.loading) {
        content = <Skeleton variant="rectangular" height={200} />;
      } else {
        content = (
          <Grid container spacing={2}>
            <Grid item xs="auto" className="text-center">
              <Typography p={3}>
                {I18n.t("frontend.asset_events.no_events")}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    } else {
      content = this.state.events.map((event) => {
        return compact ? (
          <AssetEventWidgetItemCompact
            event={event}
            key={event.id}
            onShowDetails={(event) => {
              this.openDetails(event);
            }}
          />
        ) : (
          <AssetEventWidgetItem
            event={event}
            key={event.id}
            onShowDetails={(event) => {
              this.openDetails(event);
            }}
          />
        );
      });
    }

    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          content
        ) : (
          <WidgetBox {...this.props}>
            <Grid container spacing={2} display="flex">
              {content}
              <Grid item xs={12} textAlign="end">
                <a
                  href={
                    this.props.assetId
                      ? assetEventsPath(this.props.assetId)
                      : assetEventsPath()
                  }
                >
                  {I18n.t("frontend.all")}
                </a>
              </Grid>
            </Grid>
            {this.eventDetailsDialog()}
          </WidgetBox>
        )}
      </>
    );
  }

  eventDetailsDialog() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          this.setState({ detailsOpen: false });
        }}
        open={this.state.detailsOpen}
      >
        <DialogTitle>
          {isNil(this.state.detailsEvent) ? (
            I18n.t("activerecord.models.asset_event", { count: 1 })
          ) : (
            <Link
              href={assetEventPath(
                this.state.detailsEvent?.asset_id,
                this.state.detailsEvent?.id as number,
              )}
            >
              {I18n.t("activerecord.models.asset_event", { count: 1 })}
            </Link>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <AssetEventDetails assetEvent={this.state.detailsEvent} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              this.setState({ detailsOpen: false });
            }}
            color="primary"
          >
            <Close className="mr-2" />
            {I18n.t("frontend.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
