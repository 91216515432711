import { Settings } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import {
  compact,
  defaultTo,
  find,
  get,
  isEmpty,
  isNil,
  isNumber,
  isString,
  last,
  merge,
  pick,
  toInteger,
} from "lodash";
import * as React from "react";
import { AssetMapWidgetConfigSerialized } from "../../widgets/asset_map_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { DiagramSettings } from "../diagram_settings";
import {
  AssetMapWidgetProps,
  AssetMapWidgetState,
} from "./asset_map_widget.types";
import { WidgetBox } from "./widget_box";

import Bluebird from "bluebird";
import geojson from "geojson";
import * as L from "leaflet";
import { Moment } from "moment";
import { DateRange } from "moment-range";
import { ChartDataLoader } from "../../charting/chart_data/chart_data_loader";
import { WidgetController } from "../../controller/widget_controller";
import { getAssetMarker } from "../../map/asset";
import { getMarkerIconForFeature } from "../../map/feature_icons";
import {
  addFeaturePopup,
  getFeaturePopupContent,
} from "../../map/feature_popup";
import {
  createWaypointData,
  getFeatureIdFromSensorFeature,
} from "../../map/geojson_tools";
import { addLocationDataFeaturePopup } from "../../map/location_data_popup";
import {
  AssetFeatureType,
  LocationFeatureProperties,
  SensorFeatureProperties,
  SensorPointFeatureType,
  ZoomSettings,
} from "../../map/map.types";
import {
  LocationValue,
  SamplingRate,
  SensorSamplingRateUnit,
  SensorValueType,
} from "../../models/sensor";
import { State } from "../../models/state";
import { StateContext } from "../../models/state_context";
import { ColorUtils } from "../../utils/colors";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { getValueRangeForValue } from "../../utils/status_helper";
import { assetTypeLocationsPath, assetsLocationsPath } from "../../utils/urls";
import { widgetMinHeight } from "../../utils/widget_height_class";
import { ItemSelection } from "../../widgets/item_selection";

import { DashboardContextType } from "../dashboard/dashboard_context.types";
import { DashboardContext } from "../dashboard/dashboard_context";
import { SialogicQueryClient } from "../common/sialogic_query_client";
import { IDType } from "../../utils/urls/url_utils";

export class AssetMapWidget extends React.Component<
  AssetMapWidgetProps,
  AssetMapWidgetState
> {
  static defaultProps: Partial<AssetMapWidgetProps> = {
    allowFullscreen: true,
    renderDiagramSettings: false,
    zoom: { min: null, max: null },
    tileZoom: { min: null, max: null },
  };

  static serializedConfigToProps(
    config: AssetMapWidgetConfigSerialized,
  ): AssetMapWidgetProps {
    // load asset ids from data attribute
    const assetIds = config.asset_ids;
    const assetTypeId = config.asset_type_id;
    const sensorIds = config.sensor_ids;
    const renderDiagramSettings = defaultTo(
      config.render_diagram_settings,
      false,
    );

    const enableAssetClustering = defaultTo(
      config.enable_asset_clustering,
      true,
    );

    // load map options from data attributions
    const mapUrl = config.map_url;

    const loadSensorWithTypes = compact(config.load_sensors_with_types);
    //this.loadSensorWithTypes = ['resource_level', 'operating_time_count'];//

    const loadAssetStates = defaultTo(config.load_asset_states, false);
    //this.loadAssetStates = false;
    const markerMappingSensorKey = config.marker_mapping_sensor_key;

    const markerMappingStateContextIdentifier =
      config.marker_mapping_state_context_identifier;
    //this.markerMappingSensorKey = 'ActFill';
    const markerMappingMode = config.marker_mapping_mode;
    //this.markerMappingMode = 'sensor';

    const attribution = config.map_attribution;
    const startPosition = config.start_position;
    const startZoom = config.start_zoom;

    const zoom: ZoomSettings = { min: null, max: null };
    if (!isNil(config.min_zoom)) {
      zoom.min = config.min_zoom;
    }
    if (!isNil(config.max_zoom)) {
      zoom.max = config.max_zoom;
    }
    const tileZoom: ZoomSettings = { min: null, max: null };

    if (!isNil(config.tile_max_zoom)) {
      tileZoom.max = config.tile_max_zoom;
    }
    if (!isNil(config.tile_min_zoom)) {
      tileZoom.min = config.tile_min_zoom;
    }
    // sampling rate
    const samplingRateUnit = config.sampling_rate_unit;
    const samplingRateVal = config.sampling_rate_value;

    let samplingRate: SamplingRate;
    if (!isNil(samplingRateVal)) {
      samplingRate = {
        value: samplingRateVal,
        unit: samplingRateUnit as SensorSamplingRateUnit,
      };
    } else {
      samplingRate = {
        value: null,
        unit: samplingRateUnit as SensorSamplingRateUnit,
      };
    }

    return merge(widgetBoxPropsFromSerializedConfig(config), {
      assetIds,
      sensorIds,
      assetTypeId,
      zoom,
      tileZoom,
      mapUrl,
      samplingRate,

      attribution,
      startPosition,
      mapHeight: config.height,
      startZoom,
      markerMappingMode,
      markerMappingStateContextIdentifier,
      markerMappingSensorKey,
      loadAssetStates,
      loadSensorWithTypes,
      enableAssetClustering,
      renderDiagramSettings,
    } as AssetMapWidgetProps);
  }

  mapNode: HTMLDivElement;
  map: L.Map;

  sensorsLayer: L.Realtime;
  assetLayer: L.FeatureGroup;
  mapLayer: L.TileLayer;

  mapDataLoaded: Bluebird<any>;

  // maps asset id to markers on the map
  assetIdMarkerMap: Map<number, L.Marker>;

  lineFeatures: {
    [sensorId: string]: geojson.FeatureCollection;
  };

  pointFeatures: {
    [sensorId: string]: geojson.FeatureCollection<
      geojson.Point,
      SensorFeatureProperties
    >;
  };

  assetSensorIdMarkerMap: Map<number, L.Marker>;
  assetContextStateMachineIdMarkerMap: Map<number, L.Marker>;

  samplingRate: SamplingRate;

  static contextType?: React.Context<DashboardContextType> = DashboardContext;

  constructor(props: AssetMapWidgetProps) {
    super(props);
    this.mapNode = null;
    this.samplingRate = props.samplingRate;

    this.state = {
      timeRange: props.timeRange,
      sensorIds: props.sensorIds,
      startPosition: props.startPosition,
      renderDiagramSettings: props.renderDiagramSettings,
    };
  }

  componentDidMount(): void {
    //this.initMap();
  }

  componentWillUnmount(): void {
    if (isNil(this.map)) {
      return;
    }

    if (!isNil(this.mapDataLoaded)) {
      this.mapDataLoaded.cancel();
      this.mapDataLoaded = null;
    }

    this.map.remove();
    this.map = null;
  }
  initMap(node: HTMLDivElement) {
    if (!node || node == this.mapNode) return;

    this.mapNode = node;
    if (this.map) {
      console.log("removing map");
      this.map.remove();
    }

    this.sensorsLayer = null;
    this.assetIdMarkerMap = new Map<number, L.Marker>();
    this.assetSensorIdMarkerMap = new Map<number, L.Marker>();
    this.assetContextStateMachineIdMarkerMap = new Map<number, L.Marker>();
    const map = L.map(node, {
      maxZoom: defaultTo(this.props.zoom.max, 19),
      minZoom: defaultTo(this.props.zoom.min, 4),
      preferCanvas: true,
    });
    this.map = map;
    const resizeObserver = new ResizeObserver(() => {
      if (map == this.map) {
        // only if the map is the mounted one
        try {
          map.invalidateSize();
        } catch (e) {
          // swallow errors
        }
      }
    });
    resizeObserver.observe(node);

    const mapLayer = L.tileLayer(this.props.mapUrl, {
      maxZoom: defaultTo(this.props.tileZoom.max, 19),
      minZoom: defaultTo(this.props.tileZoom.min, 0),
      //      detectRetina: true,
      attribution: this.props.attribution,
    });
    mapLayer.addTo(this.map);

    if (!isNil(this.state.startPosition)) {
      this.map.setView(
        [this.state.startPosition[0], this.state.startPosition[1]],
        this.props.startZoom ?? this.state.startPosition[2],
      ); // [51.029672, 10.120477], 6]
    }
  }

  onMapRefChange = (node: HTMLDivElement) => {
    // same as Hooks example, re-render on changes
    this.initMap(node);
  };

  render(): React.ReactNode {
    return (
      <WidgetBox
        {...this.props}
        title={
          this.props.title ?? I18n.t("frontend.widgets.asset_map_widget.title")
        }
        onFullscreen={(fullscreen) => {
          this.setState({ fullscreen });
        }}
        tools={[
          <IconButton
            key="dset"
            onClick={() => {
              this.setState({
                renderDiagramSettings: !this.state.renderDiagramSettings,
              });
            }}
          >
            <Settings />
          </IconButton>,
        ]}
      >
        <Grid container>
          <Grid item xs={12} p={2}>
            <DiagramSettings
              showBeginAtZero={false}
              startDate={defaultTo(this.state.timeRange?.start, null)}
              endDate={defaultTo(this.state.timeRange?.end, null)}
              visible={this.state.renderDiagramSettings}
              showTimeRange={!isNil(this.state.timeRange)}
              showSamplingRate={isEmpty(this.state.sensorIds)}
              onChangeTimeRange={(startTime, endTime) =>
                this.updateTimeRange(startTime, endTime)
              }
              onChangeSamplingRate={(samplingRate, mode) =>
                this.updateSamplingRate(samplingRate)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box height={this.state.fullscreen ? "75vh" : this.props.mapHeight}>
              <Box
                ref={(div: HTMLDivElement) => this.onMapRefChange(div)}
                height="100%"
                width="100%"
                minHeight={widgetMinHeight(
                  this.props.dashboardSettings?.height,
                  300,
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </WidgetBox>
    );
  }

  handleContextStateMachineUpdate(
    contextStateMachineId: number,
    stateContext: StateContext,
    newState: State,
    time: Moment,
    stateful_item_id: number,
    stateful_item_type: string,
  ): void {
    const marker = this.assetContextStateMachineIdMarkerMap.get(
      contextStateMachineId,
    );
    if (!isNil(marker)) {
      const feature = marker.feature as AssetFeatureType;
      const stateInfo = feature.properties.states[stateContext.identifier];
      stateInfo.criticality = newState.criticality;
      stateInfo.name = newState.name;
      stateInfo.icon = newState.icon;
      stateInfo.color = newState.color;
      stateInfo.identifier = newState.identifier;
      stateInfo.state_id = toInteger(newState.id);
      marker.setPopupContent(getFeaturePopupContent(feature));
      marker.setIcon(
        getMarkerIconForFeature(
          feature,
          this.props.markerMappingMode,
          this.props.markerMappingStateContextIdentifier,
          this.props.markerMappingSensorKey,
        ),
      );
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    // skip if value is outside of time range
    if (!isNil(this.state.timeRange) && !this.state.timeRange.contains(time)) {
      return;
    }

    if (isNumber(value) || isString(value)) {
      this.handleNonLocationSensorValueUpdate(
        attributeKeyId,
        sensorId,
        value,
        time,
      );
    } else {
      this.handleLocationUpdate(attributeKeyId, sensorId, value, time);
    }
  }

  handleNonLocationSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: number | string,
    time: Moment,
    unit?: string,
  ): void {
    const marker = this.assetSensorIdMarkerMap.get(sensorId);
    if (!isNil(marker)) {
      const feature = marker.feature as AssetFeatureType;

      const sensorInfo = find(
        feature.properties.sensors,
        (sensorData, keyId) => sensorData.sensor_id === sensorId,
      );
      if (!isNil(sensorInfo)) {
        sensorInfo.value = value;
        sensorInfo.timestamp = time.toISOString();
        if (sensorInfo.value_ranges) {
          sensorInfo.range = getValueRangeForValue(
            value as number,
            sensorInfo.value_ranges,
          );
        }

        marker.setPopupContent(getFeaturePopupContent(feature));
        marker.setIcon(
          getMarkerIconForFeature(
            feature,
            this.props.markerMappingMode,
            this.props.markerMappingStateContextIdentifier,
            this.props.markerMappingSensorKey,
          ),
        );
      }
    }
  }

  handleLocationUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: LocationValue,
    time: Moment,
  ): void {
    const prevPoints = get(this, [
      "pointFeatures",
      sensorId,
    ]) as geojson.FeatureCollection;
    const line = get(this, [
      "lineFeatures",
      sensorId,
      "features",
      0,
    ]) as geojson.Feature;

    if (isNil(line) || isNil(prevPoints)) {
      return;
    }

    const pointFeature: SensorPointFeatureType = {
      type: "Feature",
      properties: {
        isLastFeature: true,
        sensor_id: sensorId,
        attribute_key_id: attributeKeyId,
        timestamp: time.valueOf(),
        ...pick(line.properties, [
          "sensor_name",
          "asset_name",
          "asset_id",
          "feature_id",
        ]),
      },
      geometry: {
        type: "Point",
        coordinates: [value.x, value.y, value.z],
      },
    };

    const lastPoint: geojson.Feature = last(prevPoints.features);
    lastPoint.properties.isLastFeature = false;
    prevPoints.features.push(pointFeature);

    if (line.geometry.type === "LineString") {
      (line.properties.timestamps as number[]).push(time.valueOf());
      line.geometry.coordinates.push([value.x, value.y, value.z]);
    }

    this.sensorsLayer.remove(lastPoint);
    this.sensorsLayer.update([pointFeature, lastPoint]);
    this.sensorsLayer.update(line);
  }

  protected updateTimeRange(start: Moment, end: Moment): void {
    if (!isNil(start) || !isNil(end)) {
      this.setState({ timeRange: new DateRange(start, end) });

      this.loadMapData();
    } else {
      this.setState({ timeRange: this.props.timeRange });
    }
  }

  protected setStartPosition(): void {
    if (isNil(this.state.startPosition)) {
      const assetBounds = !isNil(this.assetLayer)
        ? this.assetLayer.getBounds()
        : null;
      const sensorBounds = !isNil(this.sensorsLayer)
        ? this.sensorsLayer.getBounds()
        : null;

      if (!isNil(assetBounds) && assetBounds.isValid()) {
        // focus asset layer
        this.map.fitBounds(assetBounds, {
          maxZoom: defaultTo(this.props.startZoom, 7),
        });
      } else if (!isNil(sensorBounds) && sensorBounds.isValid()) {
        // focus sensor layer
        this.map.fitBounds(sensorBounds, { maxZoom: this.props.startZoom });
      } else {
        this.map.setView([51.029672, 10.120477], 6);
      }

      this.setState({
        startPosition: [
          this.map.getCenter().lat,
          this.map.getCenter().lng,
          this.map.getZoom(),
        ],
      });
    }
  }
  protected updateSamplingRate(samplingRate: SamplingRate): void {
    this.samplingRate = samplingRate;

    this.loadMapData();
  }

  protected handleMarkerClick(
    marker: L.Marker,
    feature: AssetFeatureType,
  ): void {
    void WidgetController.getInstance().handleItemSelection({
      itemType: "Asset",
      itemId: toInteger(feature.id),
      item: feature,
      source: this,
    });
  }

  protected loadMapData(): void {
    if (!isNil(this.mapDataLoaded)) {
      this.mapDataLoaded.cancel();
    }

    this.mapDataLoaded = Bluebird.all([
      this.loadAssetLocations(),
      this.loadLocationSensorData(),
    ]).then(() => {
      this.setStartPosition();
    });
  }

  protected loadAssetLocations(): Promise<void> {
    if (isEmpty(this.props.assetIds)) {
      return;
    }
    let queryKey: [
      "assetTypeLocations" | "assetLocations",
      {
        assetIds?: IDType[];
        assetTypeId?: IDType;

        sensorTypes?: string[];
        assetStates?: boolean;
      },
    ];
    if (!isNil(this.props.assetTypeId) && !isEmpty(this.props.assetTypeId)) {
      queryKey = [
        "assetTypeLocations",
        {
          assetTypeId: this.props.assetTypeId,
          sensorTypes: this.props.loadSensorWithTypes,
          assetStates: this.props.loadAssetStates,
        },
      ];
    } else if (!isEmpty(this.props.assetIds)) {
      queryKey = [
        "assetLocations",
        {
          assetIds: this.props.assetIds,
          sensorTypes: this.props.loadSensorWithTypes,
          assetStates: this.props.loadAssetStates,
        },
      ];
    }
    this.assetIdMarkerMap.clear();
    this.assetSensorIdMarkerMap.forEach((marker, sensorId) => {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        sensorId,
      );
    });

    this.assetContextStateMachineIdMarkerMap.forEach((marker, csmId) => {
      WidgetController.getInstance().contextStateMachineChannel.removeEventListener(
        this,
        csmId,
      );
    });

    this.assetSensorIdMarkerMap.clear();
    this.assetContextStateMachineIdMarkerMap.clear();
    return SialogicQueryClient.fetchQuery({
      queryKey,
      queryFn: ({ queryKey }) => {
        const url =
          queryKey[0] == "assetLocations"
            ? assetsLocationsPath(
                queryKey[1].assetIds,
                queryKey[1].sensorTypes,
                queryKey[1].assetStates,
              )
            : assetTypeLocationsPath(
                queryKey[1].assetTypeId,
                queryKey[1].sensorTypes,
                queryKey[1].assetStates,
              );
        return loadDataFromUrl<
          geojson.FeatureCollection<geojson.Point, LocationFeatureProperties>
        >(url);
      },
    }).then((data) => {
      if (isNil(this.map)) return;

      if (!isNil(this.assetLayer)) {
        this.assetLayer.remove();
      }

      const assetLayer: L.FeatureGroup = this.props.enableAssetClustering
        ? L.markerClusterGroup()
        : L.featureGroup();
      const assetMarkers = L.geoJSON(data, {
        pointToLayer: (feature) => {
          const marker = getAssetMarker(
            feature,
            this.props.markerMappingMode,
            this.props.markerMappingStateContextIdentifier,
            this.props.markerMappingSensorKey,
            () => this.handleMarkerClick(marker, feature),
          );
          this.assetIdMarkerMap.set(toInteger(feature.id), marker);
          const sensorId = this.getSensorIdToUpdateForFeature(feature);
          const contextStateMachineId =
            this.getContextStateMachineIdToUpdateForFeature(feature);
          if (contextStateMachineId) {
            this.assetContextStateMachineIdMarkerMap.set(
              contextStateMachineId,
              marker,
            );
          }
          if (sensorId) {
            this.assetSensorIdMarkerMap.set(sensorId, marker);
          }

          return marker;
        },
        onEachFeature: addFeaturePopup,
      });

      assetLayer.addLayer(assetMarkers);
      this.assetLayer = assetLayer;
      this.assetLayer.addTo(this.map);
      this.assetSensorIdMarkerMap.forEach((marker, sensorId) => {
        WidgetController.getInstance().sensorDataChannel.subscribe(sensorId);
        WidgetController.getInstance().sensorDataChannel.addEventListener(
          this,
          sensorId,
        );
      });

      this.assetContextStateMachineIdMarkerMap.forEach((marker, csmId) => {
        WidgetController.getInstance().contextStateMachineChannel.subscribe(
          csmId,
        );
        WidgetController.getInstance().contextStateMachineChannel.addEventListener(
          this,
          csmId,
        );
      });
    });
  }

  protected getContextStateMachineIdToUpdateForFeature(
    feature: AssetFeatureType,
  ): number {
    if (
      this.props.markerMappingMode === "state" &&
      !isEmpty(this.props.markerMappingStateContextIdentifier)
    ) {
      const contextStateMachineId =
        feature.properties.states?.[
          this.props.markerMappingStateContextIdentifier
        ].csm_id;
      if (!isNil(contextStateMachineId)) {
        return contextStateMachineId;
      }
      return null;
    } else {
      return null;
    }
  }

  protected getSensorIdToUpdateForFeature(feature: AssetFeatureType): number {
    if (this.props.markerMappingMode === "sensor") {
      const sensorId =
        feature.properties?.sensors?.[this.props.markerMappingSensorKey]
          ?.sensor_id;
      if (!isNil(sensorId)) {
        return sensorId;
      }
      return null;
    } else {
      return null;
    }
  }

  // as geojson uses lng lat order we have to create a new coordinate

  protected loadLocationSensorData(): Promise<any> {
    if (isEmpty(this.props.sensorIds)) {
      return;
    }
    const lineColors: string[] = ColorUtils.getColorsRgba(1.0);
    const markerColors: string[] = ColorUtils.getColorsRgba(0.8);

    // configure styling
    const sensorIdToColors: {
      [sensorId: string]: { lineColor: string; markerColor: string };
    } = {};
    this.props.sensorIds.forEach((sensorId, index) => {
      sensorIdToColors[sensorId] = {
        lineColor: lineColors[index % lineColors.length],
        markerColor: markerColors[index % markerColors.length],
      };
    });

    if (this.sensorsLayer) {
      this.sensorsLayer.remove();
    }
    this.sensorsLayer = L.realtime("", {
      start: false,
      getFeatureId: getFeatureIdFromSensorFeature,
      onEachFeature: addLocationDataFeaturePopup,
      style: function (feature: GeoJSON.Feature) {
        return {
          color:
            sensorIdToColors[feature.properties.sensor_id as string].lineColor,
          weight: 2,
        };
      },
      pointToLayer: function (feature: GeoJSON.Feature, latlng) {
        const color =
          sensorIdToColors[feature.properties.sensor_id as string].markerColor;
        if (feature.properties.isLastFeature) {
          return L.marker(latlng);
        } else {
          return L.circleMarker(latlng, {
            radius: 4,
            fillColor: color,
            color: color,
            weight: 1,
            fillOpacity: 0.8,
            opacity: 0.8,
          });
        }
      },
    });
    this.sensorsLayer.addTo(this.map);

    this.lineFeatures = {};
    this.pointFeatures = {};
    return Bluebird.each(this.props.sensorIds, (sensorId) => {
      const key: ["sensorGeometry", IDType, DateRange, SamplingRate] = [
        "sensorGeometry",
        sensorId,
        this.state.timeRange,
        this.state.samplingRate,
      ];
      return SialogicQueryClient.fetchQuery({
        queryKey: key,
        queryFn: ({ queryKey }) => {
          const query = ChartDataLoader.getUrlQuery({
            timeRange: queryKey[2],
            samplingRate: queryKey[3],
          });
          const url = `/sensors/${queryKey[1]}/geometry.geojson${query}`;
          return loadDataFromUrl<geojson.FeatureCollection<geojson.LineString>>(
            url,
          );
        },
      }).then((data) => {
        if (isNil(this.map)) return;

        const [pointFeatures, lineFeatures] = createWaypointData(data);
        this.lineFeatures[sensorId] = lineFeatures;
        this.pointFeatures[sensorId] = pointFeatures;
        this.sensorsLayer.update(lineFeatures.features);
        this.sensorsLayer.update(pointFeatures.features);
      });
    });
  }

  handleItemSelection(itemSelection: ItemSelection<any>): void {
    if (itemSelection.itemType === "Asset" && itemSelection.source !== this) {
      const marker = this.assetIdMarkerMap.get(itemSelection.itemId);
      if (!isEmpty(marker)) {
        this.map.setView(marker.getLatLng(), 13);
        marker.openPopup();
      }
    }
  }
}
