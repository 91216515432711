import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { CopyValueIcon } from "./copy_value";
export interface AttributeRowProperties {
  attributeName: string;
  value?: number | string;

  copyValue?: string;
  copyable?: boolean;
  title?: string;
  divider?: boolean;
  tools?: React.ReactElement;
}

export const AttributeRow: React.FunctionComponent<AttributeRowProperties> = (
  props,
) => {
  const cols = (
    <>
      <Grid item container xs={12} lg={4} key="1">
        <Grid item xs={12} alignContent="start">
          <Typography variant="caption" fontSize={11} fontWeight={400}>
            {props.attributeName}
          </Typography>
        </Grid>
        <Grid item xs={12} alignContent="start">
          {props.tools}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8} key="2s" justifyContent="space-between">
        <Typography variant="body1" textAlign="right">
          {isEmpty(props.value) ? "---" : props.value}
          {!props.copyable ||
          (isNil(props.value) && isNil(props.copyValue)) ? null : (
            <Box component="span" ml={1}>
              <CopyValueIcon value={props.copyValue ?? props.value} />
            </Box>
          )}
        </Typography>
      </Grid>
    </>
  );

  const content = (
    <Grid container item xs={12} title={props.title} padding={2}>
      {cols}
      {props.divider ? (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      ) : null}
    </Grid>
  );

  if (props.title) {
    return <Tooltip title={props.title}>{content}</Tooltip>;
  } else {
    return content;
  }
};
