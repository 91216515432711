import { clamp, defaultTo, isNil, toInteger, toNumber } from "lodash";
import {
  MappedSensor,
  SensorMapping,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { BaseMapping } from "./mapping_base";
import { percentageValue } from "./utils";
import { on } from "events";

export class ScaleMapping extends BaseMapping<
  SVGGraphicsElement | SVGRectElement
> {
  direction: "bottom-top" | "top-bottom" | "left-right" | "right-left";
  x: number;
  y: number;
  width: number;
  height: number;

  constructor(
    config: SensorMappingAttributes,
    onClick?: (
      event: MouseEvent,
      element: SVGGraphicsElement | SVGRectElement,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config, onClick);

    this.direction = defaultTo(config?.direction, "bottom-top");
    this.x = 0;
    this.y = 0;
    this.width = 0;
    this.height = 0;
  }

  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    super.setElementInfoFromSvg(svgElement);

    if (isNil(this.element)) return;
    const box = this.element.getBBox();

    this.width = toNumber(defaultTo(box.width, this.width));
    this.height = toNumber(defaultTo(box.height, this.height));
    this.x = toNumber(box.x);

    this.y = toNumber(box.y);
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    if (isNil(this.element) || isNil(sensorConfig?.value)) {
      return;
    }
    const value = toInteger(defaultTo(sensorConfig?.value, 0.0));
    const min = defaultTo(sensorConfig?.range?.min, 0.0);
    const max = defaultTo(sensorConfig?.range?.max, 100.0);
    // Apply svg transform

    this.element.setAttribute(
      "transform",
      this.getTransformForValue(value, min, max),
    );
  }
  protected getTransformForValue(
    value: number,
    min: number,
    max: number,
  ): string {
    return `translate(${this.x} ${this.height + this.y})  scale(1 ${clamp(
      percentageValue(value, min, max) / 100.0,
      0,
      1,
    )}) translate(${-this.x} ${-this.y - this.height})`;
  }
}
