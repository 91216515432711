import * as React from "react";

import {
  ArrowDownward,
  ArrowUpward,
  ContentCopy,
  Delete,
  FastForward,
} from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";

import { cloneDeep, defaults, get, has, isArray, isEmpty, isNil } from "lodash";
import { SweetAlertResult } from "sweetalert2";
import { TreeItem } from "../../../models/tree_item";
import { dialog } from "../../../utils/dialog";
import { getTranslatedProp } from "../../../utils/globalize";
import { getMaintenancePlanStyle } from "../../../utils/maintenance_plan_utils";
import { IBox, IBoxContent } from "../../common/ibox";
import {
  AssetWithIndex,
  SelectAssetModal,
} from "../../common/select_asset_modal";
import { AssetIdentifier } from "../data/maintenance_plan_actions";
import { MaintenancePlan, MaintenanceType } from "../data/models";
import { MaintenacePlanNameInput } from "./maintenance_plan_name_input";
import { NotificationSettingsInput } from "./notification_settings_input";
import { RotationInput } from "./rotation_input";

interface ListEntryProperties {
  assetIndex: number;
  index: number;
  assets: AssetWithIndex[];
  assetTree: TreeItem;
  maintenanceTypes: MaintenanceType[];
  maintenancePlan: MaintenancePlan;
  isFirst?: boolean;
  isLast?: boolean;
  onRemoveMaintenancePlan: () => void;
  onUpdateMaintenancePlan: (maintenancePlan: MaintenancePlan) => void;
  onChangeMaintenancePlanOrder: (direction: number) => void;
  onCopyMaintenancePlan: (
    assetIndices: AssetIdentifier[],
    maintenancePlan: MaintenancePlan,
  ) => void;
  onMoveMaintenancePlan: (destAsset: AssetIdentifier) => void;
  visible?: boolean;
}

interface ListEntryState {
  copyModalOpen?: boolean;
  moveModalOpen?: boolean;
}

/**
 * A list entry to configure a maintenance plan.
 */
export class MaintenancePlanListEntry extends React.Component<
  ListEntryProperties,
  ListEntryState
> {
  constructor(props: ListEntryProperties) {
    super(props);

    this.state = {
      copyModalOpen: false,
      moveModalOpen: false,
    };
  }

  shouldComponentUpdate(
    nextProps: ListEntryProperties,
    nextState: ListEntryState,
  ): boolean {
    return (
      this.props.assetIndex !== nextProps.assetIndex ||
      this.props.index !== nextProps.index ||
      this.props.maintenanceTypes !== nextProps.maintenanceTypes ||
      this.props.maintenancePlan !== nextProps.maintenancePlan ||
      this.props.isFirst !== nextProps.isFirst ||
      this.props.isLast !== nextProps.isLast ||
      this.state.copyModalOpen !== nextState.copyModalOpen ||
      this.state.moveModalOpen !== nextState.moveModalOpen
    );
  }

  confirmDeletion(): Promise<void> {
    const theName = getTranslatedProp(this.props.maintenancePlan, "name");
    return new Promise((resolve, reject) => {
      try {
        dialog
          .fire({
            showConfirmButton: true,
            showCancelButton: true,
            icon: "question",
            cancelButtonText: I18n.t("frontend.cancel"),
            confirmButtonText: I18n.t("frontend.delete"),
            confirmButtonColor: "#ff0000",
            title: I18n.t(
              "frontend.maintenance_plan_form.delete_confirm_heading",
            ),
            html: `<p>${I18n.t(
              "frontend.maintenance_plan_form.delete_confirm_message",
              { maintenance_activity_name: theName },
            )}</p><small>${I18n.t(
              "frontend.maintenance_plan_form.delete_confirm_message_subtitle",
            )}</small>`,
          })
          .then((result: SweetAlertResult) => {
            if (result.value === true) {
              this.props.onRemoveMaintenancePlan();
              if (!isNil(document.activeElement)) {
                // remove focus as we don't know where to set it !
                $(document.activeElement).blur();
              }
            }
          })
          .catch(reject);
      } catch (error) {
        reject(error);
      }
    });
  }

  render(): React.ReactNode {
    const otherAssets = this.props.assets.filter(
      (asset) => asset.id !== this.props.maintenancePlan.asset_id,
    );
    return (
      <Box display={this.props.visible === false ? "none" : null}>
        <IBox
          className={`maintenance-list-entry mb-3 ${getMaintenancePlanStyle(
            this.props.maintenancePlan.type,
          )}`}
        >
          <IBoxContent className="pb-1">
            <Grid container spacing={2}>
              <Grid item sm={8}>
                <MaintenacePlanNameInput
                  maintenancePlan={this.props.maintenancePlan}
                  maintenanceTypes={this.props.maintenanceTypes}
                  onChange={(name, maintenanceType) =>
                    this.onChangeMaintenanceName(name, maintenanceType)
                  }
                />
              </Grid>
              <Grid item sm={4} alignContent="flex-end">
                <ButtonGroup className="float-right">
                  <Tooltip
                    title={I18n.t("frontend.maintenance_plan_form.delete")}
                  >
                    <IconButton
                      size="small"
                      className="remove"
                      color="primary"
                      onClick={() => {
                        void this.confirmDeletion();
                      }}
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={I18n.t("frontend.maintenance_plan_form.copy")}
                  >
                    <IconButton
                      size="small"
                      className="copy"
                      color="primary"
                      onClick={() => this.setState({ copyModalOpen: true })}
                    >
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {isEmpty(otherAssets) ? null : (
                    <Tooltip
                      title={I18n.t("frontend.maintenance_plan_form.move")}
                    >
                      <IconButton
                        size="small"
                        className="move"
                        color="primary"
                        onClick={() => this.setState({ moveModalOpen: true })}
                      >
                        <FastForward fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={I18n.t(
                      "frontend.maintenance_plan_form.change_order_down",
                    )}
                  >
                    <span>
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={this.props.isLast}
                        onClick={() =>
                          this.props.onChangeMaintenancePlanOrder(1)
                        }
                      >
                        <ArrowDownward fontSize="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={I18n.t(
                      "frontend.maintenance_plan_form.change_order_up",
                    )}
                  >
                    <span>
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={this.props.isFirst}
                        onClick={() =>
                          this.props.onChangeMaintenancePlanOrder(-1)
                        }
                      >
                        <ArrowUpward fontSize="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ButtonGroup>
                <div className="clearfix" />
              </Grid>

              <SelectAssetModal
                multiselect
                title={I18n.t("frontend.maintenance_plan_form.copy")}
                selectTitle={I18n.t(
                  "frontend.maintenance_plan_form.copy_to_asset",
                )}
                submitButtonText={I18n.t("frontend.maintenance_plan_form.copy")}
                assets={this.props.assets}
                assetTree={[this.props.assetTree]}
                isOpen={this.state.copyModalOpen}
                onClosed={() => this.setState({ copyModalOpen: false })}
                onApply={(selectedAssets) => this.onCopy(selectedAssets)}
              />
              <SelectAssetModal
                title={I18n.t("frontend.maintenance_plan_form.move")}
                selectTitle={I18n.t(
                  "frontend.maintenance_plan_form.move_to_asset",
                )}
                submitButtonText={I18n.t("frontend.maintenance_plan_form.move")}
                assets={otherAssets}
                assetTree={[this.props.assetTree]}
                isOpen={this.state.moveModalOpen}
                onClosed={() => this.setState({ moveModalOpen: false })}
                onApply={(selectedAssets) => this.onMove(selectedAssets)}
              />
              <Grid item container xs={12} spacing={2}>
                <Grid item sm={6}>
                  <RotationInput
                    invalid={this.hasRotationErrors()}
                    maintenancePlan={this.props.maintenancePlan}
                    onChange={this.props.onUpdateMaintenancePlan}
                  />
                  <FormHelperText
                    error={!isEmpty(this.getRotationErrorMessage())}
                  >
                    {this.getRotationErrorMessage()}
                  </FormHelperText>
                </Grid>
                <Grid item sm={6}>
                  <NotificationSettingsInput
                    value={this.props.maintenancePlan.notification_setting}
                    onChange={(notificationSetting) =>
                      this.onChangeNotificationSetting(notificationSetting)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </IBoxContent>
        </IBox>
      </Box>
    );
  }

  private onChangeNotificationSetting(notificationSetting: string): void {
    const newMaintenancePlan = cloneDeep(this.props.maintenancePlan);
    newMaintenancePlan.notification_setting = notificationSetting;

    this.props.onUpdateMaintenancePlan(newMaintenancePlan);
  }

  private onChangeMaintenanceName(
    name: string,
    maintenanceType: MaintenanceType,
  ): void {
    const newMaintenancePlan = cloneDeep(this.props.maintenancePlan);
    newMaintenancePlan[`name_${I18n.locale}`] = name;

    if (!isNil(maintenanceType)) {
      // set maintenance type and load default values if nothing was set
      newMaintenancePlan.maintenance_type_id = maintenanceType.id;
      defaults(newMaintenancePlan, {
        maintenance_plan_type: maintenanceType.maintenance_type,
        critical_value: maintenanceType.critical_value,
        rrule: maintenanceType.rrule,
        notification_setting: maintenanceType.notification_setting,
      });
    }

    this.props.onUpdateMaintenancePlan(newMaintenancePlan);
  }

  private onCopy(selectedAssets: AssetIdentifier | AssetIdentifier[]): void {
    if (isNil(selectedAssets)) return;

    if (!isArray(selectedAssets)) {
      selectedAssets = [selectedAssets];
    }

    this.props.onCopyMaintenancePlan(
      selectedAssets,
      this.props.maintenancePlan,
    );
    this.setState({
      copyModalOpen: false,
    });
  }

  private onMove(selectedAsset: AssetIdentifier | AssetIdentifier[]): void {
    if (isNil(selectedAsset)) return;
    let singleAsset;
    if (isArray(selectedAsset) && !isEmpty(selectedAsset)) {
      singleAsset = selectedAsset[0];
    }

    this.props.onMoveMaintenancePlan(singleAsset);
    this.setState({
      moveModalOpen: false,
    });
  }

  private hasRotationErrors(): boolean {
    return (
      has(this.props.maintenancePlan.errors, "maintenance_plan_type") ||
      has(this.props.maintenancePlan.errors, "critical_value") ||
      has(this.props.maintenancePlan.errors, "rrule")
    );
  }

  private getRotationErrorMessage(): string {
    return [
      get(this.props.maintenancePlan.errors, "maintenance_plan_type"),
      get(this.props.maintenancePlan.errors, "critical_value"),
      get(this.props.maintenancePlan.errors, "rrule"),
    ].join("\n");
  }
}
