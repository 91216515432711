import { includes, noop } from "lodash";
import * as React from "react";

// import PdfViewer from '@phuocng/react-pdf-viewer';
// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import { Close, CloudDownload } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { openInNewWindow } from "../../utils/redirection";
import { DefaultTheme } from "../styling/theme";
import { Icon } from "./icon";
import { AttributeRow } from "./attribute_row";

export interface FilePreviewModalProps {
  url: string;
  contentType?: string;
  additionalInfo?: { title: string; value: string }[];
  isOpen: boolean;
  title?: string;

  fileName?: string;
  onClose?: () => void;
}

export function FilePreviewModal({
  onClose = noop,
  ...props
}: FilePreviewModalProps) {
  const fullScreen = useMediaQuery(DefaultTheme.breakpoints.down("xl"));

  return (
    <Dialog
      open={props.isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogTitle>
        {props.fileName}
        <Box displayPrint="none" position="absolute" right={8} top={8}>
          <IconButton aria-label="close" onClick={onClose} size="large">
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {props.contentType === "application/pdf" ? (
              <iframe
                src={props.url}
                title={props.fileName}
                style={{
                  border: "none",
                  width: "100%",
                  height: "600px",
                }}
              />
            ) : null}
            {isImage(props.contentType) ? (
              <Box textAlign="center" maxHeight="100%" maxWidth="100%">
                <Link href={props.url} target="_blank" rel="noreferrer">
                  <img
                    style={{ minHeight: "300px" }}
                    src={props.url}
                    className="img-fluid"
                    alt={props.fileName}
                  />
                </Link>
              </Box>
            ) : null}
            {!isImage(props.contentType) &&
            props.contentType !== "application/pdf" ? (
              <Box textAlign="center">
                <Icon icon="file-o" className="mr-lg-2" size="5x" />
                <h4>{props.fileName}</h4>
                <Button
                  startIcon={<CloudDownload />}
                  color="primary"
                  size="large"
                  onClick={() => openInNewWindow(props.url)}
                >
                  {I18n.t("frontend.file_preview_modal.download")}
                </Button>
              </Box>
            ) : null}
          </Grid>
          <Grid item container xs={12} mx={4}>
            <Typography variant="h6" gutterBottom>
              {I18n.t("frontend.additional_information")}
            </Typography>
            {props.additionalInfo?.map((i, index) => {
              return i ? (
                <AttributeRow
                  key={index}
                  attributeName={i.title}
                  value={i.value}
                  copyable
                />
              ) : null;
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CloudDownload />}
          color="primary"
          onClick={() => openInNewWindow(props.url)}
        >
          {I18n.t("frontend.download")}
        </Button>
        <Button startIcon={<Close />} onClick={onClose}>
          {I18n.t("frontend.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function isImage(contentType: string) {
  return includes(
    ["image/gif", "image/png", "image/jpeg", "image/svg+xml"],
    contentType,
  );
}
