import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
} from "@mui/material";
import * as React from "react";

import {
  EventPatternErrors,
  EventPatternJSONAPIAttributes,
  EventPatternJSONObject,
  buildEventPatternUpdateSubmitData,
  eventPatternJsonApiUrl,
  loadEventPattern,
} from "../../json_api/event_pattern";

import { isNil } from "lodash";
import { SensorJSONAPIAttributes, loadSensor } from "../../json_api/sensor";
import { error, success } from "../../utils/toasts";
import { LoadingWrapper } from "../common/loading_wrapper";
import { EventPatternTypeSelect } from "./event_pattern_type_select";
import { SensorEventPatternForm } from "./sensor_event_pattern_form";

import { Cancel, Check, Delete } from "@mui/icons-material";
import { DocWithErrors, SingleResourceDoc } from "jsonapi-typescript";
import {
  extractErrorsFromJsonApi,
  jsonApiSingleResourceToFlatObject,
  modelPropertyError,
} from "../../json_api/jsonapi_tools";
import { EventPatternType } from "../../models/event_pattern";
import { ResourcePermission } from "../../models/resource_permission";
import {
  api_sensor_data_transmission_event_pattern_path,
  api_sensor_data_transmission_event_patterns_path,
  api_sensor_event_pattern_path,
  api_sensor_event_patterns_path,
} from "../../routes";
import { dialog } from "../../utils/dialog";
import {
  HttpError,
  sendData,
  sendJsonApiData,
} from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { SensorDataTransmissionEventPatternForm } from "./sensor_data_transmission_event_pattern_form";

interface EventPatternFormProps {
  eventPatternId: IDType;
  eventPatternType?: EventPatternType;
  permissions?: ResourcePermission;
  sensorId: IDType;
  assetId: IDType;
  readonly?: boolean;
  buttonMode?: "card" | "bottom";
  onChangeEventPattern?: (pattern: EventPatternJSONObject) => void;
  onSave?: (pattern: EventPatternJSONObject) => void;
  onCancel?: () => void;
  onDelete?: (deletedEventPattern: EventPatternJSONObject) => void;
}
function confirmAndDelete(
  eventPattern: EventPatternJSONAPIAttributes,
  setLoading: (loading: boolean) => void,
  onDelete: (eventPattern: EventPatternJSONAPIAttributes) => void,
) {
  return dialog
    .fire({
      title: I18n.t("frontend.delete"),
      text: I18n.t("frontend.are_you_sure"),
      showConfirmButton: true,
      showCancelButton: true,
      icon: "question",
      cancelButtonText: I18n.t("frontend.cancel"),
      confirmButtonText: I18n.t("frontend.delete"),
      confirmButtonColor: "#ff0000",
    })
    .then((result) => {
      if (result.isDismissed) {
        return;
      }
      setLoading(true);
      return sendData(
        eventPatternJsonApiUrl(eventPattern.id, eventPattern.pattern_type),
        null,
        "DELETE",
      );
    })
    .then(() => {
      void success(I18n.t("base.successfully_destroyed"));
      if (onDelete) {
        onDelete(eventPattern);
      } else {
        redirectTo();
      }
    })
    .catch((e) => {
      void error(I18n.t("base.error_destroying"));
      logger.error(e);
    })
    .finally(() => {
      setLoading(false);
    });
}

function submitUrl(
  mode: "create" | "update",
  patternType: EventPatternType,
  id: IDType,
): string {
  let url: string;
  if (mode == "create") {
    if (patternType == "EventPatterns::SensorEventPattern") {
      url = api_sensor_event_patterns_path({ format: "json" });
    } else if (
      patternType == "EventPatterns::SensorDataTransmissionEventPattern"
    ) {
      url = api_sensor_data_transmission_event_patterns_path({
        format: "json",
        _options: true,
      });
    }
  } else {
    if (patternType == "EventPatterns::SensorEventPattern") {
      url = api_sensor_event_pattern_path(id, { format: "json" });
    } else if (
      patternType == "EventPatterns::SensorDataTransmissionEventPattern"
    ) {
      url = api_sensor_data_transmission_event_pattern_path(id as string, {
        format: "json",
      });
    }
  }
  return url;
}

export const EventPatternForm: React.FunctionComponent<
  EventPatternFormProps
> = ({ buttonMode = "bottom", ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(props.readonly);
  const [sensor, setSensor] = React.useState<SensorJSONAPIAttributes>(null);
  const [errorString, setErrorString] = React.useState<string>(null);
  const [eventPatternErrors, setEventPatternErrors] =
    React.useState<EventPatternErrors>({});
  const [eventPattern, setEventPattern] =
    React.useState<EventPatternJSONAPIAttributes>(null);

  React.useEffect(() => {
    if (props.eventPatternId) {
      setLoading(true);
      void loadEventPattern(props.eventPatternId, props.eventPatternType, [
        "asset",
        "sensor",
        "asset_event_type",
        "state",
        "context_state_machine",
      ])
        .then((ep) => {
          setEventPattern(ep);
          return null;
        })
        .catch((e) => {
          void error(
            I18n.t("base.error"),
            I18n.t("frontend.event_patterns.form.error_loading_event_pattern"),
          );
          setErrorString(
            I18n.t("frontend.event_patterns.form.error_loading_event_pattern"),
          );
          return null;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!isNil(props.sensorId)) {
        setLoading(true);
        void loadSensor(props.sensorId)
          .then((sensor) => {
            setSensor(sensor);
            // set default values for event pattern
            setEventPattern({
              pattern_type: "EventPatterns::SensorEventPattern",
              comparator: "inside range",
              asset_id: sensor.asset_id,
              sensor_id: sensor.id as number,
              sensor,
            });
          })
          .catch(() => {
            void error(
              I18n.t("base.error"),
              I18n.t("frontend.event_patterns.form.error_loading_sensor"),
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  const submitEventPattern = React.useCallback(
    (eventPattern: EventPatternJSONObject) => {
      setLoading(true);

      const mode = isNil(eventPattern.id) ? "create" : "update";
      const submitData = buildEventPatternUpdateSubmitData(eventPattern);
      const url = submitUrl(mode, eventPattern.pattern_type, eventPattern.id);
      sendJsonApiData(
        url,
        submitData.submitData,
        submitData.mode == "create" ? "POST" : "PATCH",
      )
        .then((res) => {
          void success(
            I18n.t("frontend.success"),
            submitData.mode == "create"
              ? I18n.t("base.successfully_created")
              : I18n.t("base.successfully_updated"),
          );
          eventPattern =
            jsonApiSingleResourceToFlatObject<EventPatternJSONObject>(
              res as SingleResourceDoc<string, EventPatternJSONObject>,
            );
          if (props.onSave) {
            props.onSave(eventPattern);
          } else {
            redirectTo();
          }
        })
        .catch((e) => {
          setEventPatternErrors(
            extractErrorsFromJsonApi(
              (e as HttpError).request.responseJSON as DocWithErrors,
            ),
          );
          logger.warn(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );
  React.useEffect(() => {
    if (props.onChangeEventPattern) {
      props.onChangeEventPattern(eventPattern);
    }
  }, [eventPattern]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={I18n.t("frontend.event_patterns.form.header", {
              sensor: sensor?.name,
            })}
          />

          <CardContent>
            <LoadingWrapper loading={loading || isNil(eventPattern)}>
              {isNil(eventPattern) ? null : (
                <Grid container spacing={4}>
                  <Grid item xs={12} container>
                    <EventPatternTypeSelect
                      readonly={readOnly || !isNil(eventPattern.id)}
                      type={eventPattern.pattern_type}
                      error={modelPropertyError(
                        eventPatternErrors,
                        "pattern_type",
                      )}
                      onTypeSelect={(t) => {
                        const ep = { ...eventPattern, pattern_type: t };

                        setEventPattern(ep);
                      }}
                    />
                  </Grid>

                  {eventPattern.pattern_type ==
                  "EventPatterns::SensorEventPattern" ? (
                    <SensorEventPatternForm
                      readonly={readOnly}
                      eventPattern={eventPattern}
                      errors={eventPatternErrors}
                      onPatternUpdate={(e) => setEventPattern(e)}
                    />
                  ) : null}
                  {eventPattern.pattern_type ==
                  "EventPatterns::SensorDataTransmissionEventPattern" ? (
                    <SensorDataTransmissionEventPatternForm
                      readonly={readOnly}
                      errors={eventPatternErrors}
                      eventPattern={eventPattern}
                      onPatternUpdate={(e) => setEventPattern(e)}
                    />
                  ) : null}
                </Grid>
              )}
            </LoadingWrapper>
          </CardContent>
          {buttonMode != "card" ? null : (
            <CardActions>
              {readOnly ? null : (
                <Button
                  startIcon={<Check />}
                  onClick={
                    readOnly ? null : () => submitEventPattern(eventPattern)
                  }
                ></Button>
              )}

              <Button
                startIcon={<Cancel />}
                onClick={
                  props.onCancel
                    ? props.onCancel
                    : () => {
                        redirectTo();
                      }
                }
              ></Button>
            </CardActions>
          )}
        </Card>
      </Grid>
      {buttonMode != "bottom" ? null : (
        <FixedBottomArea id="fixed-bottom-area">
          <FloatingButtons
            showScrollToTopBtn
            disableCancel={loading}
            disableSave={loading}
            onSubmit={readOnly ? null : () => submitEventPattern(eventPattern)}
            onCancel={
              props.onCancel
                ? props.onCancel
                : () => {
                    redirectTo();
                  }
            }
          >
            {!isNil(eventPattern?.id) && props.permissions?.destroy && (
              <Fab
                size="medium"
                color="secondary"
                disabled={loading}
                onClick={() => {
                  void confirmAndDelete(
                    eventPattern,
                    setLoading,
                    props.onDelete,
                  );
                }}
              >
                <Delete />
              </Fab>
            )}
          </FloatingButtons>
        </FixedBottomArea>
      )}
    </Grid>
  );
};
