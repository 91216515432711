import { Grid, Skeleton, Typography } from "@mui/material";
import { defaultTo, isEmpty, isNil, map, merge } from "lodash";
import * as React from "react";
import { Component } from "react";
import { SensorGroupWidgetConfigSerialized } from "../../widgets/sensor_group_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import {
  SensorGroupWidgetProps,
  SensorGroupWidgetState,
} from "./sensor_group_widget.types";
import { SensorValueWidget } from "./sensor_value_widget";
import { WidgetBox } from "./widget_box";

import { loadSensors } from "../../json_api/sensor";
import { SialogicQueryClient } from "../common/sialogic_query_client";

export class SensorGroupWidget extends Component<
  SensorGroupWidgetProps,
  SensorGroupWidgetState
> {
  constructor(props: SensorGroupWidgetProps) {
    super(props);
    this.state = {
      sensorIds: props.sensorIds ? props.sensorIds : null,
      sensors: null,
      loading: props.sensorIds ? false : !isNil(props.assetId),
    };
  }

  componentDidMount(): void {
    if (this.state.sensorIds === null && isNil(this.state.sensors)) {
      if (this.props.assetId) {
        SialogicQueryClient.fetchQuery({
          queryKey: [
            "sensors",
            {
              asset_id: this.props.assetId,
              include: ["sensor_type"],
            },
          ],
          queryFn: async (data) => {
            this.setState({ loading: true });
            return loadSensors(null, [this.props.assetId], ["sensor_type"]);
          },
        })
          .then((sensors) => {
            this.setState({
              sensors,

              sensorIds: sensors.map((sensor) => sensor.id),
            });
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<SensorGroupWidgetProps>,
    prevState: Readonly<SensorGroupWidgetState>,
    snapshot?: any,
  ): void {
    if (this.props.sensorIds !== prevProps.sensorIds) {
      this.setState({ sensorIds: this.props.sensorIds });
    }
  }

  static serializedConfigToProps(
    config: SensorGroupWidgetConfigSerialized,
  ): SensorGroupWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      sensorIds: config.sensor_ids,

      // load start value and timestamp

      hideValue: defaultTo(config.hide_value, false),

      vertical: defaultTo(config.vertical, false),
      updateEnabled: !defaultTo(config.disable_update, false),

      useValueRange: defaultTo(config.use_value_range, false),
    });
  }
  render() {
    return (
      <WidgetBox {...this.props}>
        <Grid container spacing={4} alignItems="stretch">
          {this.state.loading ? (
            <Grid item xs={12}>
              <Skeleton height={300} />
            </Grid>
          ) : this.state.sensors ? (
            map(this.state.sensors, (sensor, index) => (
              <Grid item key={index} xs={12} sm>
                <SensorValueWidget
                  {...this.props}
                  useValueRange
                  title={null}
                  encloseInIBox
                  minWidthPx={200}
                  sensor={sensor}
                  assetId={this.props.assetId}
                />
              </Grid>
            ))
          ) : (
            map(this.state.sensorIds, (sensorId, index) => (
              <Grid item key={index} xs={12} sm>
                <SensorValueWidget
                  {...this.props}
                  title={null}
                  encloseInIBox
                  minWidthPx={150}
                  sensorId={sensorId as number}
                  assetId={this.props.assetId}
                />
              </Grid>
            ))
          )}
          {isEmpty(this.state.sensorIds) && isEmpty(this.state.sensors) ? (
            <Grid item xs={12} padding={2} textAlign="center">
              <Typography variant="body1">
                {I18n.t("frontend.widgets.sensor_group_widget.no_sensors")}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </WidgetBox>
    );
  }
}
