import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { FileAttachmentAttributes } from "../models/file_attachment";

export interface FileAttachmentJSONObject
  extends FileAttachmentAttributes,
    JSON.Object {}

export type FileAttachmentJSONAPIAttributes =
  JSONAPI.AttributesObject<FileAttachmentJSONObject>;

export const FileAttachmentUpdatableAttributes: (keyof FileAttachmentAttributes)[] =
  ["title", "group", "description", "key", "file", "item_id", "item_type"];

const FileAttachmentCreateableAttributes = FileAttachmentUpdatableAttributes;
