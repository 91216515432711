import Bluebird, { CancellationError, Promise } from "bluebird";
import * as JSONAPI from "jsonapi-typescript";
import {
  clone,
  each,
  filter,
  find,
  flatten,
  isArray,
  isEmpty,
  isNaN,
  isNil,
  isNumber,
  isString,
  reject,
  remove,
  toString,
  uniq,
} from "lodash";
import { DateRange } from "moment-range";

import { SialogicQueryClient } from "../components/common/sialogic_query_client";
import { SensorDataAttribute } from "../models/sensor";
import {
  first_value_within_api_sensor_path,
  last_value_api_sensor_path,
  last_value_within_api_sensor_path,
} from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { logger } from "../utils/logger";
import { sensorApiUrl } from "../utils/urls";
import { IDType } from "../utils/urls/url_utils";
import { jsonApiResourceCollectionToFlatObjects } from "./jsonapi_tools";
import { SensorJSONAPIAttributes } from "./sensor";

export class SensorLoader {
  private cache: Map<string, SensorJSONAPIAttributes>;

  private currentlyFetched: [string, Bluebird<SensorJSONAPIAttributes[]>][];
  private static instance: SensorLoader;
  static getInstance() {
    if (isNil(SensorLoader.instance)) {
      SensorLoader.instance = new SensorLoader();
    }
    return SensorLoader.instance;
  }

  static destroyInstance() {
    if (!isNil(SensorLoader.instance)) {
      delete SensorLoader.instance;
    }
  }

  constructor() {
    this.cache = new Map<string, SensorJSONAPIAttributes>();
    this.currentlyFetched = [];
  }

  clear() {
    this.cache.clear();
    this.currentlyFetched.forEach(([id, promise]) => {
      promise.cancel();
    });
    this.currentlyFetched = [];
  }

  getSensors(
    sensorIds: Array<number | string> | number | string,
    useCache = true,
  ): Bluebird<SensorJSONAPIAttributes[]> {
    if (isNil(sensorIds) || isNaN(sensorIds)) {
      return Promise.resolve([] as SensorJSONAPIAttributes[]);
    }
    if ((isNumber(sensorIds) && !isNaN(sensorIds)) || isString(sensorIds)) {
      sensorIds = [sensorIds];
    }
    if (isEmpty(sensorIds)) {
      return Promise.resolve([] as SensorJSONAPIAttributes[]);
    }

    const {
      sensorDataItems,
      sensorIdArray,
      toDoSensorIds,
      notCurrentlyFetchedIds,
    } = this.initStructuresForLoad(sensorIds, useCache);

    if (sensorDataItems.length === uniq(sensorIdArray).length) {
      // all items fetched
      return Promise.resolve(sensorDataItems);
    }

    let promise: Bluebird<SensorJSONAPIAttributes[]>;
    // capture running promises now, later in the process these might already have been deleted
    const runningPromises = uniq(
      this.getCurrentlyFetchedPromisesForIds(toDoSensorIds),
    );

    if (notCurrentlyFetchedIds.length === 0) {
      // all required requests are running but not yet completed
      promise = Promise.all(runningPromises).reduce(
        (allSensors, fetchedSensors) => allSensors.concat(fetchedSensors),
      );
    } else {
      promise = this.addMissingLoadsToCurrentlyFetched(
        notCurrentlyFetchedIds,
        sensorDataItems,
        toDoSensorIds,
        runningPromises,
      );
    }

    // handle either returned promises or mixture of requests and running promises

    return new Bluebird((resolve, reject, onCancel) => {
      promise
        .then(
          (
            fetchedSensorArrays:
              | SensorJSONAPIAttributes[]
              | SensorJSONAPIAttributes[][],
          ) => {
            const flatArray =
              flatten<SensorJSONAPIAttributes>(fetchedSensorArrays);

            each(flatArray, (sensorAttributes) => {
              if (toDoSensorIds.indexOf(toString(sensorAttributes.id)) !== -1) {
                // remove ids from missing if sensor was fetched
                remove(toDoSensorIds, (id) => id == sensorAttributes.id);
                const existingItem = find(
                  sensorDataItems,
                  (s) => s.id == sensorAttributes.id,
                );
                // avoid double additions
                if (isNil(existingItem)) {
                  // add the data to the data items
                  sensorDataItems.push(sensorAttributes);
                }
              }
              // all are contained
              if (toDoSensorIds.length === 0) return false;
            });
            return sensorDataItems;
          },
        )
        .then(resolve)
        .catch(reject);
      onCancel(() => {
        // avoid the loads to be cancelled as the loads are possibly used by other subscribers.
        // TODO We need to track who is waiting for the load of particular sensors and cancel loading only if there are no other requesters for a particluar sensor
        logger.debug(
          "Sensor loader does not support cancellation of requests as the results are used by multiple subscribers. Falls back to Don't care semantics of bluebird",
        );
      });
    });
  }

  /** Executes loading and combines results to finally return the requested sensors only
   *
   *
   * @private
   * @param {string[]} idsToFetch Sensor IDs that should be loaded
   * @param {SensorJSONAPIAttributes[]} sensorDataItems Collection of sensor items that hold load results
   * @param {string[]} toDoSensorIds Collection of sensor ids that are still due loading
   * @param {Promise<SensorJSONAPIAttributes[]>[]} runningPromises Currently running
   * @return {Bluebird<SensorJSONAPIAttributes[]>[]} Promise that finally returns all of the requested sensors that could be loaded
   * @memberof SensorLoader
   */
  private addMissingLoadsToCurrentlyFetched(
    idsToFetch: string[],
    sensorDataItems: SensorJSONAPIAttributes[],
    toDoSensorIds: string[],
    runningPromises: Promise<SensorJSONAPIAttributes[]>[],
  ): Bluebird<SensorJSONAPIAttributes[]> {
    const promise = this.fetchSensors(idsToFetch).then(
      (fetchedSensorRecords) => {
        fetchedSensorRecords.forEach((sensorRecord) => {
          sensorDataItems.push(sensorRecord);
          remove(toDoSensorIds, (id) => id === sensorRecord.id);
        });
        if (toDoSensorIds.length === 0) {
          // return the data values immediately since there is all fetched
          return sensorDataItems;
        }
        this.loadFinished(fetchedSensorRecords);
        //wait for all currently running requests and merge them into sensorAttributes
        if (runningPromises.length === 0) {
          // this should not happen, but if it does, this code fetches cached items
          toDoSensorIds.forEach((id) => {
            const item = this.cache.get(id);
            if (!isNil(item)) {
              sensorDataItems.push(item);
            }
          });
          // removed items that have just been inserted from cache
          remove(toDoSensorIds, (id) => this.cache.has(id));
          return sensorDataItems;
        } else {
          return Promise.all(runningPromises).reduce(
            (allSensors, fetchedSensors) => {
              return allSensors.concat(fetchedSensors);
            },
            [] as SensorJSONAPIAttributes[],
          );
        }
      },
    );
    return promise;
  }

  private initStructuresForLoad(
    sensorIds: string | number | (string | number)[],
    useCache: boolean,
  ): {
    sensorDataItems: SensorJSONAPIAttributes[];
    sensorIdArray: string[];
    toDoSensorIds: string[];
    notCurrentlyFetchedIds: string[];
    currentlyFetchedIds: string[];
  } {
    const sensorIdArray = (
      isArray(sensorIds) ? sensorIds : [sensorIds.toString()]
    ).map((item) => item.toString());

    const toDoSensorIds = clone(sensorIdArray);

    const notContainedInCacheIds = useCache
      ? filter(
          sensorIdArray,
          (sensorId) => !this.cache.has(sensorId.toString()),
        )
      : [];

    // init the response array with cached contents
    const sensorDataItems: SensorJSONAPIAttributes[] = useCache
      ? reject<SensorJSONAPIAttributes>(
          sensorIdArray.map((id) => this.cache.get(id.toString())),
          isNil,
        )
      : [];

    // remove sensors that are already cached from todos
    remove(
      toDoSensorIds,
      (id) => find(sensorDataItems, (item) => item.id === id) !== undefined,
    );

    // array of sensors that are not currently fetched and therefore need to be loaded
    const notCurrentlyFetchedIds = useCache
      ? filter(
          notContainedInCacheIds,
          (id) =>
            // ids that are not currently fetched
            find(this.currentlyFetched, (fetched) => fetched[0] === id) ===
            undefined,
        )
      : sensorIdArray;

    const currentlyFetchedIds = filter(
      notContainedInCacheIds,
      (id) =>
        find(this.currentlyFetched, (fetched) => fetched[0] === id) !==
        undefined,
    );
    return {
      sensorDataItems,
      sensorIdArray,
      toDoSensorIds,
      notCurrentlyFetchedIds,
      currentlyFetchedIds,
    };
  }

  /** Adds externally loaded Sensors to cache
   *
   *
   * @param {SensorJSONAPIAttributes[]} sensors
   * @param {boolean} reinsert Update cache with the given items
   * @memberof SensorLoader
   */
  addLoadedSensorsToCache(sensors: SensorJSONAPIAttributes[]) {
    this.loadFinished(sensors);
  }

  getLastValueWithin(
    sensorId: number | string,
    timerange: DateRange,
    fallbackToLastValue = false,
  ): Promise<SensorDataAttribute> {
    if (isNil(sensorId)) return null;

    return SialogicQueryClient.fetchQuery({
      queryKey: [
        "sensorLastValue",
        {
          id: sensorId,
          begin: timerange?.start?.toISOString(),
          end: timerange?.end?.toISOString(),
          fallback: fallbackToLastValue,
        },
      ],
      queryFn: ({ queryKey }) => {
        const keyData = queryKey[1] as {
          id: IDType;
          begin: string;
          end: string;
          fallback: boolean;
        };
        const url = last_value_within_api_sensor_path(keyData.id, {
          min_time: keyData.begin,
          max_time: keyData.end,
          fallback_to_last: keyData.fallback === true ? "true" : "false",
          format: "json",
          _options: true,
        });
        return loadDataFromUrl<SensorDataAttribute>(url);
      },
    });
  }

  getFirstValueWithin(
    sensorId: IDType,
    timerange: DateRange,
    fallbackToLastValue = false,
  ): Promise<SensorDataAttribute> {
    if (isNil(sensorId)) return null;

    return SialogicQueryClient.fetchQuery({
      queryKey: [
        "firstSensorValue",
        sensorId,

        {
          start: timerange?.start?.toISOString(),
          end: timerange?.end?.toISOString(),
          fallbackToLastValue,
        },
      ],
      queryFn: () => {
        const url = first_value_within_api_sensor_path(sensorId, {
          min_time: timerange?.start?.toISOString(),
          max_time: timerange?.end?.toISOString(),
          fallback_to_last: fallbackToLastValue === true ? "true" : "false",
          format: "json",
          _options: true,
        });

        return loadDataFromUrl<SensorDataAttribute>(url);
      },
    });
  }

  private fetchSensors(
    sensorIds: Array<number | string>,
  ): Bluebird<SensorJSONAPIAttributes[]> {
    if (isNil(sensorIds) || (isArray(sensorIds) && isEmpty(sensorIds))) {
      return Promise.resolve([] as SensorJSONAPIAttributes[]);
    }

    const loadingPromise = loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, SensorJSONAPIAttributes>
    >(sensorApiUrl(sensorIds)).then((apiDoc) => {
      const sensorDataItems =
        jsonApiResourceCollectionToFlatObjects<SensorJSONAPIAttributes>(apiDoc);
      this.loadFinished(sensorDataItems);

      return sensorDataItems;
    });

    sensorIds.forEach((id) => {
      this.currentlyFetched.push([id.toString(), loadingPromise]);
    });

    return loadingPromise.catch(CancellationError, (cancelError) => {
      logger.info("Cancel Error during sensor load", cancelError);
      return [];
    });
  }

  /** Returns the promises for fetched sensor ids */
  private getCurrentlyFetchedPromisesForIds(
    ids: string[],
  ): Promise<SensorJSONAPIAttributes[]>[] {
    // filter running promises
    const items = filter(
      this.currentlyFetched,
      (currentlyFetchedIdAndPromise) =>
        ids.indexOf(currentlyFetchedIdAndPromise[0]) !== -1,
    ).map((fetched) => fetched[1]);
    return items;
  }

  private loadFinished(sensorData: SensorJSONAPIAttributes[]) {
    const ids: string[] = [];
    each(sensorData, (sensorItem) => {
      this.cache.set(sensorItem.id as string, sensorItem);
      ids.push(sensorItem.id as string);
      remove(this.currentlyFetched, (item) => item[0] === sensorItem.id);
    });
  }
}
