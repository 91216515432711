import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { isEmpty, isNil, merge } from "lodash";
import React, { useState, useEffect } from "react";
import { WidgetController } from "../../controller/widget_controller";
import { ImageWidgetConfigSerialized } from "../../widgets/image_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ImageWidgetProps } from "./image_widget.types";
import { WidgetBox } from "./widget_box";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";

export const ImageWidget: React.FunctionComponent<ImageWidgetProps> = ({
  encloseInWidgetBox = true,
  ...props
}) => {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  useEffect(() => {
    // componentDidMount logic
    return () => {
      // componentWillUnmount logic
      const instance = WidgetController.getInstance();
      if (!isNil(instance)) {
        // unregister listeners from widget controller
      }
    };
  }, []);

  const imageStyle = {
    maxWidth: props.maxWidth || "inherit",
    minWidth: props.minWidth,
  };

  const content = (
    <Box
      justifyContent="center"
      alignItems="center"
      maxWidth="100%"
      maxHeight="100%"
      height="100%"
    >
      <img src={props.url} alt={props.alt} style={imageStyle} />
    </Box>
  );

  return (
    <>
      {!encloseInWidgetBox ? (
        content
      ) : (
        <WidgetBox
          {...props}
          titleLinkUrl={props.contentLinkUrl}
          onClick={
            isEmpty(props.contentLinkUrl)
              ? () => {
                  setImageDialogOpen(true);
                }
              : null
          }
        >
          {content}
        </WidgetBox>
      )}

      <Dialog
        open={imageDialogOpen}
        onClose={() => {
          setImageDialogOpen(false);
        }}
        maxWidth="lg"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <DialogTitle>
          {props.title}
          <Box displayPrint="none" position="absolute" right={8} top={8}>
            <IconButton
              aria-label="close"
              onClick={(e) => {
                e.stopPropagation();
                setImageDialogOpen(false);
              }}
              size="large"
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <img
            style={{ maxWidth: "100%", height: "auto" }}
            src={props.url}
            alt={props.alt}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

function serializedConfigToProps(
  config: ImageWidgetConfigSerialized,
): ImageWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    title: config.widget_name,
    contentLinkUrl: config.link_url,
    contentLinkTarget: config.link_target,
    linkTarget: config.link_target,
    url: config.url,
    alt: config.alt,
    minWidth: config.min_width,
    maxWidth: config.max_width,
  } as ImageWidgetProps);
}

export const ImageWidgetDefinition: SialogicWidgetDefinition = {
  component: ImageWidget,
  serializedConfigToProps: serializedConfigToProps,
};
