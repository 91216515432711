import { defaultTo, isNil } from "lodash";
export type ValueDifferenceWidgetCalculationMode =
  | "base_value_offset"
  | "time_offset"
  | "value";

/**
 * Computes Vallue difference value
 *
 * @export
 * @param {number} baseValue
 * @param {number} newValue
 * @param {ValueDifferenceWidgetCalculationMode} calculationMode
 * @return {*}  {number}
 */
export function computeOffsetValue(
  baseValue: number,
  newValue: number,
  calculationMode: ValueDifferenceWidgetCalculationMode,
): number {
  if (calculationMode === "value") return defaultTo(newValue, 0);

  if (isNil(baseValue)) {
    if (calculationMode === "time_offset") {
      return newValue;
    } else {
      return null;
    }
  }

  if (isNil(newValue)) {
    if (calculationMode === "time_offset") {
      return 0;
    } else {
      return null;
    }
  }

  return newValue - baseValue;
}
