/* eslint-disable @typescript-eslint/no-unused-vars */
import { find, flatten, isEmpty, isNil, map } from "lodash";
import * as React from "react";

import { Box, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { DateRange } from "moment-range";
import { loadSensors, SensorJSONAPIAttributes } from "../../json_api/sensor";
import { LineDiagramWidget } from "../widgets/line_diagram_widget";
import { SensorValueWidget } from "../widgets/sensor_value_widget";
import { SensorInfo } from "./sensor_info";

import { line } from "d3";

import { SensorLineType } from "../../models/sensor";
import { LineShape } from "../../charting/plotly_time_series_line_diagram_base.types";
import { IDType } from "../../utils/urls/url_utils";
import { useQuery } from "@tanstack/react-query";
import { SensorLoader } from "../../json_api/sensor_loader";
import { SensorIncludes } from "../../utils/urls";
import { logger } from "../../utils/logger";
interface SensorDisplayProps {
  sensors: SensorJSONAPIAttributes[];
  sensorIds?: IDType[];
  timeRange?: [Date, Date];
  fullHeight?: boolean;
  onChangeTimeRange?: (range: [Date, Date]) => void;
}
export function lineShapeForValueLineType(type: SensorLineType): LineShape {
  switch (type) {
    case "binary":
      return "hv";
    case "step":
      return "hv";
    case "enumerated_value":
      return "hv";
    default:
      return "linear";
  }
}

const SENSOR_INCLUDES: SensorIncludes[] = [
  "sensor_type",
  "value_ranges",
  "asset",
];
export const SensorDisplay: React.FunctionComponent<SensorDisplayProps> = (
  props,
) => {
  const [sensors, setSensors] = React.useState<SensorJSONAPIAttributes[]>(
    props.sensors,
  );

  const sensorsQuery = useQuery({
    queryKey: [
      "sensors",
      { sensor_ids: props.sensorIds, include: SENSOR_INCLUDES },
    ],
    queryFn: (data) => {
      return loadSensors(props.sensorIds, null, SENSOR_INCLUDES);
    },
    enabled: !isEmpty(props.sensorIds),
  });

  React.useEffect(() => {
    if (sensorsQuery.isSuccess && sensorsQuery.data) {
      setSensors(sensorsQuery.data);
    }
  }, [sensorsQuery.data]);

  React.useEffect(() => {
    if (sensorsQuery.error) {
      logger.logError(sensorsQuery.error);
    }
  }, [sensorsQuery.error]);

  const samplingRateSensor = find(
    props.sensors,
    (s) => !isNil(s.sampling_rate_unit) && !isNil(s.sampling_rate_value),
  );

  const samplineRate = samplingRateSensor
    ? {
        value: samplingRateSensor.sampling_rate_value,
        unit: samplingRateSensor.sampling_rate_unit,
      }
    : null;

  return (
    <Grid container spacing={2}>
      {sensorsQuery.error ? (
        <Grid item xs={12}>
          <Paper>
            <Box m={2}>
              <Typography variant="h5">{I18n.t("frontend.error")}</Typography>
              <Typography>
                {I18n.t("frontend.sensors.sensor_display.error_loading")}
              </Typography>
              <Typography variant="body2">
                {sensorsQuery.error.message}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ) : null}
      <Grid item xs={12} md={8} lg={9}>
        {sensorsQuery.isLoading ? (
          <Skeleton variant="rectangular" height={600} />
        ) : (
          <LineDiagramWidget
            allowFullscreen
            sensorIds={map(props.sensors, (s) => s.id)}
            dataUrls={null}
            showStateSelection
            showStatistics
            oneYAxisPerUnitOnly
            contextStateMachineIds={flatten(
              props.sensors.map((s) =>
                map(
                  s.asset?.asset_states,
                  (stateInfo, state_name) => stateInfo?.csm_id,
                ),
              ),
            )}
            lineOptions={{
              lineMode: "lines+markers",
              lineShape: props.sensors.map((s) =>
                lineShapeForValueLineType(s.line_shape),
              ),
            }}
            timeRange={
              isNil(props.timeRange)
                ? null
                : new DateRange(props.timeRange[0], props.timeRange[1])
            }
            onChangeTimeRange={props.onChangeTimeRange}
            diagramHeight={props.fullHeight ? "calc(100vh - 250px)" : 600}
            samplingRate={samplineRate}
            storeSettingsInAnchor={false}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper>
          <Box m={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {I18n.t("frontend.sensors.sensor_display.values_header")}
                </Typography>
              </Grid>
              {sensorsQuery.isLoading ? (
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
              ) : (
                map(props.sensors, (s, index) => (
                  <Grid item container xs={12} key={index}>
                    <Grid item xs={12}>
                      <SensorValueWidget
                        sensor={s}
                        mode="rows"
                        useValueRange
                        noLinks
                        updateEnabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SensorInfo sensor={s} collapsible />
                      <Divider className="my-2" />
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
