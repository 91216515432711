import { isNil, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { getTimeString } from "../../utils/time_strings";

import { KpiWidgetConfigSerialized } from "../../widgets/kpi_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ValueDisplay } from "../common/value_display";
import { KpiWidgetProps, KpiWidgetState } from "./kpi_widget.types";
import { WidgetBox } from "./widget_box";

export class KpiWidget
  extends React.Component<KpiWidgetProps, KpiWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<KpiWidgetProps> = {
    encloseInIBox: true,
  };

  // TODO Implement
  static serializedConfigToProps(
    config: KpiWidgetConfigSerialized,
  ): KpiWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      sensors: config.sensors,
      units: config.units,
      icon: config.icon,
      vertical: config.vertical,
      precision: config.precision,
    } as KpiWidgetProps);
  }

  constructor(props: KpiWidgetProps) {
    super(props);
    this.state = {
      value: props.value,
      timestamp: props.timestamp,
      title: props.title as string,
      titleLinkUrl: props.titleLinkUrl,
      contentLinkUrl: props.contentLinkUrl,
    };
  }

  componentDidMount(): void {
    // initialize component, e.g. some loading from API
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // register listeners to widget controller
      // for sensors :
      /// WidgetController.getInstance().sensorDataChannel.removeEventListener(this, this.props.sensorId);
    }
  }

  componentDidUpdate(oldProps: KpiWidgetProps): void {}

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  render(): React.ReactNode {
    // implement display content here ...
    const valueDisplay = (
      <ValueDisplay
        value={this.state.value}
        unit={this.props.units?.display_unit || this.props.units?.unit}
        iconName={this.props.icon?.name}
        iconSize={this.props.icon?.size}
        precision={this.props.precision}
        timestamp={getTimeString(null, this.state.timestamp)}
        vertical={this.props.vertical}
      />
    );

    return (
      <>
        {!this.props.encloseInIBox ? (
          valueDisplay
        ) : (
          <WidgetBox
            {...this.props}
            title={this.state.title}
            titleLinkUrl={this.state.titleLinkUrl}
            contentLinkUrl={this.state.contentLinkUrl}
          >
            {valueDisplay}
          </WidgetBox>
        )}
      </>
    );
  }
}
