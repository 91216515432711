import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CalendarToday, Cancel, Close } from "@mui/icons-material";
import { CollectionResourceDoc } from "jsonapi-typescript";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { SubscriptionJSONAPIAttributes } from "../../json_api/subscription";
import { api_organization_subscriptions_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { PageSettings } from "../common/page_size";
import { PageSizeSelect } from "../common/page_size_select";
import { SubscriptionPeriodList } from "../subscription_periods/subscription_period_list";
import { LoadingIcon } from "../common/icon";
interface SubscriptionCardProps {
  subscription?: SubscriptionJSONAPIAttributes;
  onRequestPeriodsDisplay: (subsciption: SubscriptionJSONAPIAttributes) => void;
}

export const SubscriptionCard: React.FunctionComponent<
  SubscriptionCardProps
> = (props) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Chip
            size="small"
            color="primary"
            label={I18n.t(
              `activerecord.attributes.subscription.states.${props.subscription.state}`,
            )}
            variant="outlined"
          />
        }
        title={props.subscription.subscription_plan?.name}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container xs={12}>
            <Grid item xs={12} lg={4}>
              {I18n.t("activerecord.attributes.subscription.active_from")}:
            </Grid>
            <Grid item xs={12} lg={8}>
              {moment(props.subscription.active_from).format("L LT")}
            </Grid>
          </Grid>

          {isNil(props.subscription.active_to) ? null : (
            <Grid item container xs={12}>
              <Grid item xs={12} lg={4}>
                {I18n.t("activerecord.attributes.subscription.active_to")}
              </Grid>
              <Grid item xs={12} lg={8}>
                {moment(props.subscription.active_to).format("L LT")}
              </Grid>
            </Grid>
          )}
        </Grid>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              props.onRequestPeriodsDisplay(props.subscription);
            }}
            startIcon={<CalendarToday />}
          >
            {I18n.t("activerecord.models.subscription_period.other")}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};
