import {
  cloneDeep,
  findIndex,
  isNil,
  isNull,
  merge,
  split,
  values,
} from "lodash";

import { Moment } from "moment";
import * as React from "react";
import { Plotly, PlotlyLayoutYaxisNames } from "../../charting/plotly_package";
import { ColorUtils } from "../../utils/colors";
import {
  registerSensorUpdates,
  unregisterSensorUpdates,
} from "../../utils/sensor_updates";
import { convertToUnit } from "../../utils/unit_conversion";
import { IDType } from "../../utils/urls/url_utils";
import { convertBarChartData } from "../../widgets/bar_chart_widget";
import { BarChartWidgetConfigSerialized } from "../../widgets/bar_chart_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { PlotlyChart } from "../common/plotly_chart";
import { BarChartData, BarChartWidgetProps } from "./bar_chart_widget.types";
import { WidgetBox } from "./widget_box";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { useCallback, useMemo, useEffect, useRef, useState } from "react";

const fillColors: string[] = ColorUtils.getColorsRgba(0.75);

const margins = {
  t: 10,
  b: 60,
  l: 40,
  r: 40,
};

const font = {
  family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
  size: 11,
  color: "rgb(103, 106, 108)",
};

const getChartFillColor = (chartIndex: number): string => {
  return fillColors[chartIndex % fillColors.length];
};

function serializedConfigToProps(
  config: BarChartWidgetConfigSerialized,
): BarChartWidgetProps {
  const barWidth = split(config.bar_width, ",").map((width) =>
    parseFloat(width),
  );

  const chartData = convertBarChartData(config.chart_data);
  const sensorIds: IDType[] = [];
  chartData.datasets.forEach((dataset) => {
    sensorIds.push(...dataset.sensor_ids);
  });

  const barChartProps = {
    barWidth,
    orientation: config.orientation ?? "v",
    sensorIds,
    initialChartData: chartData,
    timeScopeName: config.time_scope,
    barChartHeight: config.bar_chart_height,
  } as BarChartWidgetProps;
  return merge(widgetBoxPropsFromSerializedConfig(config), barChartProps);
}

export const BarChartWidget: React.FunctionComponent<BarChartWidgetProps> = ({
  sensorIds = [],
  initialChartData = { datasets: [] },
  orientation = "v",
  barWidth,
  ...props
}) => {
  const [chartData, setChartData] = useState(initialChartData);
  const [dataRevision, setDataRevision] = useState(1);
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      registerSensorUpdates({ handleSensorValueUpdate }, sensorIds);
    }, 10);

    return () => {
      clearTimeout(timeoutId);
      unregisterSensorUpdates(
        { handleSensorValueUpdate },
        sensorIds.map(Number),
      );
    };
  }, [sensorIds]);

  const prevSensorIdsRef = useRef<IDType[]>(sensorIds);
  const prevTimeRangeRef = useRef<{ start: Moment; end: Moment } | undefined>(
    props.timeRange,
  );

  useEffect(() => {
    if (
      !props.timeRange?.start?.isSame(prevTimeRangeRef.current?.start) ||
      !props.timeRange?.end?.isSame(prevTimeRangeRef.current?.end) ||
      sensorIds !== prevSensorIdsRef.current
    ) {
      unregisterSensorUpdates(
        { handleSensorValueUpdate },
        prevSensorIdsRef.current.map(Number),
      );
      registerSensorUpdates({ handleSensorValueUpdate }, sensorIds);
    }

    prevSensorIdsRef.current = sensorIds;
    prevTimeRangeRef.current = props.timeRange;
  }, [props.timeRange, sensorIds]);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: number,
      time: Moment,
      unit?: string,
    ): void => {
      if (
        (!isNil(props.timeRange?.start) &&
          time.isBefore(props.timeRange.start)) ||
        (!isNil(props.timeRange?.end) && time.isAfter(props.timeRange.end))
      ) {
        // ignore updates outside of the time range
        return;
      }

      setChartData((prevState) => {
        const newChartData = { ...prevState };
        let dataUpdated = false;

        newChartData.datasets.forEach((dataset, index) => {
          const dataIndex = findIndex(
            dataset.sensor_ids,
            (theSensorId) => theSensorId == sensorId,
          );

          if (dataIndex < 0) {
            return;
          }
          if (dataset.timestamps[dataIndex] > time.toDate()) {
            return;
          }

          const newDataset = cloneDeep(dataset);
          if (!isNil(unit) && newDataset.unit !== unit) {
            value = convertToUnit(value, unit, newDataset.unit);
          }

          // if unit conversion fails null may be returned
          if (isNil(value)) {
            return;
          }

          newDataset.timestamps[dataIndex] = time.toDate();
          newDataset.y[dataIndex] = value;
          newDataset.text[dataIndex] = value.toLocaleString(I18n.locale, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          });

          newChartData.datasets[index] = newDataset;
          dataUpdated = true;
        });

        if (!dataUpdated) {
          return prevState;
        }

        setDataRevision((prevRevision) => prevRevision + 1);
        return newChartData;
      });
    },
    [props.timeRange],
  );

  // memoize the chart data
  const plotlyChartData = useMemo<Partial<Plotly.PlotData>[]>(() => {
    return chartData.datasets.map((dataset, index) => ({
      type: "bar",
      yaxis: index === 0 ? "y" : `y${index + 1}`,
      fillcolor: getChartFillColor(index),
      name: dataset.name,
      text: dataset.text,
      textposition: "auto",
      hoverinfo: orientation == "h" ? "y" : "x",
      x: orientation == "h" ? dataset.y : dataset.x,
      y: orientation == "h" ? dataset.x : dataset.y,
      width: isNull(barWidth) ? 0.5 : barWidth,
      orientation: isNil(orientation) ? "v" : orientation,
    }));
  }, [chartData, orientation, barWidth]);

  const layout = useMemo((): Partial<Plotly.Layout> => {
    const yAxes = chartData.datasets.map((dataset, axisIndex) => {
      return {
        title: dataset.unit,
        side: axisIndex % 2 === 0 ? "left" : "right",
        fixedrange: true,
        autorange: !isNil(dataset.range)
          ? !(isFinite(dataset.range[0]) && isFinite(dataset.range[1]))
          : true,
        range:
          !isNil(dataset.range) &&
          isFinite(dataset.range[0]) &&
          isFinite(dataset.range[1])
            ? dataset.range
            : undefined,
        overlaying: axisIndex > 0 ? "y" : undefined,
        automargin: true,
      } as Partial<Plotly.LayoutAxis>;
    });
    const axisLayout: Partial<Plotly.Layout> = {};

    values(yAxes).forEach((axis, index) => {
      const key: PlotlyLayoutYaxisNames =
        index === 0 ? "yaxis" : (`yaxis${index + 1}` as PlotlyLayoutYaxisNames);

      axisLayout[key] = axis;
    });

    const layout: Partial<Plotly.Layout> = {
      font,
      datarevision: dataRevision,
      uirevision: 0,
      barmode: props.stacked ? "stack" : "group",
      autosize: true,
      showlegend: false,
      margin: margins,
      height: fullscreen ? 800 : props.barChartHeight,
      xaxis: {
        fixedrange: true,
        autorange: true,
      },
      ...axisLayout,
    };
    return layout;
  }, [
    chartData,
    dataRevision,
    props.stacked,
    props.barChartHeight,
    fullscreen,
  ]);

  const content = (
    <PlotlyChart
      className={`sensor-diagram-container ${props.divClass}`}
      data={plotlyChartData}
      layout={layout}
      config={{
        responsive: true,
        displaylogo: false,
        displayModeBar: false,
        locale: I18n.locale,
      }}
    />
  );

  return (
    <WidgetBox
      {...props}
      allowFullscreen
      onFullscreen={(fullscreen) => {
        setFullscreen(fullscreen);
      }}
    >
      {content}
    </WidgetBox>
  );
};

export const BarChartWidgetDefinition: SialogicWidgetDefinition<
  typeof BarChartWidget,
  typeof serializedConfigToProps
> = {
  component: BarChartWidget,
  serializedConfigToProps,
};
