import { Grid } from "@mui/material";
import { isEmpty, isNil, map, merge } from "lodash";
import * as React from "react";
import {
  dashboardGridSizeProps,
  propsForWidgetType,
  sortedRowsFromItems,
} from "../../utils/dashboard_layout_tools";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { WidgetGroupConfigSerialized } from "../../widgets/widget_group.types";
import { WidgetComponentsByType } from "../dashboard/widget_types";
import { WidgetBox } from "./widget_box";
import { WidgetGroupProps } from "./widget_group.types";
import { DashboardWidgetWithErrors } from "../dashboard/dashboard_widget_with_errors";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";

/** A Widget group widget is some kind of a small dashboard as it holds multiple widgets that are aligned and layouts
 * using the same mechanisms that are used for dashboards
 *
 *
 * @export
 * @class WidgetGroup
 * @extends {Component<WidgetGroupProps, WidgetGroupState>}
 */
export const WidgetGroup: React.FunctionComponent<WidgetGroupProps> = (
  props,
) => {
  const content = (
    <Grid container spacing={2}>
      {map(props.widgetConfigs, (widgetConfigsForRow, row) => {
        return (
          <Grid item xs={12} key={row} alignItems="stretch">
            <Grid container spacing={1}>
              {map(widgetConfigsForRow, ({ config, props }, position) => {
                if (isNil(props)) {
                  return null;
                }
                const WidgetComponent =
                  WidgetComponentsByType[config.widget_type];
                return (
                  <Grid
                    {...dashboardGridSizeProps(config.size)}
                    item
                    key={position}
                  >
                    {isEmpty(config.errors) ? (
                      React.createElement(WidgetComponent.component, props)
                    ) : (
                      <DashboardWidgetWithErrors
                        errors={config.errors}
                        widgetProps={props}
                        config={config}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
  return <WidgetBox {...props}>{content}</WidgetBox>;
};

function serializedConfigToProps(
  config: WidgetGroupConfigSerialized,
): WidgetGroupProps {
  const sorted = sortedRowsFromItems(config.widgets);
  const rowWidgetProps = map(sorted, (widgetRow, row) =>
    // Create a group that hold the layout data included in the original config and the generated props for the widget
    map(widgetRow, (widgetConfig, index) => ({
      config: widgetConfig,
      props: propsForWidgetType(widgetConfig, `r${row}_c${index}`),
    })),
  );

  return merge(widgetBoxPropsFromSerializedConfig(config), {
    widgetConfigs: rowWidgetProps,
  } as WidgetGroupProps);
}

export const WidgetGroupDefinition: SialogicWidgetDefinition<
  typeof WidgetGroup,
  typeof serializedConfigToProps
> = {
  component: WidgetGroup,
  serializedConfigToProps: serializedConfigToProps,
};
