import * as React from "react";
import { Plotly } from "../../charting/plotly_package";
import { Box } from "@mui/material";

interface PlotlyChartProps {
  data: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
  config?: Partial<Plotly.Config>;
  divId?: string;
  className?: string;
  style?: React.CSSProperties;
}

export class PlotlyChart extends React.Component<PlotlyChartProps> {
  chartElement: HTMLDivElement;
  chart: Plotly.PlotlyHTMLElement;

  isUpdated: Promise<any>;

  constructor(props: PlotlyChartProps) {
    super(props);
    this.isUpdated = null;
    this.chartElement;
    this.chart = null;
  }

  componentDidMount(): void {}

  initChart(element: HTMLDivElement) {
    if (element !== this.chartElement) {
      if (this.chart && this.chartElement) {
        Plotly.purge(this.chartElement);
      }
    }

    this.chartElement = element;
    if (element) {
      this.isUpdated = Plotly.newPlot(
        this.chartElement,
        this.props.data,
        this.props.layout,
        this.props.config,
      ).then((chart) => {
        this.chart = chart;
        Plotly.Plots.resize(this.chartElement);
      });
      void this.isUpdated;
    }
  }

  componentDidUpdate(prevProps: PlotlyChartProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.layout !== this.props.layout ||
      prevProps.config !== this.props.config
    ) {
      return this.updateChart();
    }
  }

  componentWillUnmount() {
    Plotly.purge(this.chartElement);
  }

  render(): React.ReactNode {
    return (
      <Box
        id={this.props.divId}
        ref={(ref: HTMLDivElement) => {
          this.initChart(ref);
        }}
        className={this.props.className}
        style={this.props.style}
      />
    );
  }

  private updateChart(): Promise<any> {
    this.isUpdated = Plotly.react(
      this.chartElement,
      this.props.data,
      this.props.layout,
      this.props.config,
    ).then((chart) => {
      this.chart = chart;
      Plotly.Plots.resize(this.chartElement);
    });
    return this.isUpdated;
  }
}
