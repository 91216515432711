/**
 * A list of resources with actions grouped by categories
 */

export const DefaultActions = ["create", "index", "read", "update", "destroy"];
import { flatten } from "lodash";
import { OrganizationRelationTypes } from "../../models/organization_relation";

export const ResourcePermissionStructure = {
  Asset: {
    Asset: {
      actions: DefaultActions,
    },
    AssetType: {
      actions: DefaultActions,
    },
    AssetTemplate: {
      actions: DefaultActions,
    },
    ThreeDModel: {
      actions: DefaultActions,
    },
  },
  Sensor: {
    Sensor: {
      actions: DefaultActions,
    },
    "EventPatterns::EventPattern": {
      actions: DefaultActions,
    },
    SensorValueRange: {
      actions: DefaultActions,
    },
    SensorGroup: {
      actions: DefaultActions,
    },
    SensorType: {
      actions: DefaultActions,
    },
  },
  DeviceCommunication: {
    Device: {
      actions: DefaultActions,
    },
    MqttUser: {
      actions: DefaultActions,
    },
  },
  Event: {
    AssetEvent: {
      actions: DefaultActions,
    },
    AssetEventType: {
      actions: DefaultActions,
    },
  },

  Dashboard: {
    "Dashboards::Dashboard": {
      actions: DefaultActions,
    },
    "Widgets::Widget": {
      actions: DefaultActions,
    },
  },
  Logbook: {
    Comment: {
      actions: DefaultActions,
    },
    Logbook: {
      actions: DefaultActions,
    },
  },
  Maintenance: {
    MaintenanceType: {
      actions: DefaultActions,
    },
    MaintenanceJob: {
      actions: DefaultActions,
    },
    MaintenancePlan: {
      actions: DefaultActions,
    },
    Maintenance: {
      actions: DefaultActions,
    },
  },
  Measurement: {
    "MeasurementTypes::MeasurementType": {
      actions: DefaultActions,
    },
    MeasurementCategorization: {
      actions: DefaultActions,
    },
    MeasurementPlan: {
      actions: DefaultActions,
    },
    Measurement: {
      actions: DefaultActions,
    },
  },
  Report: {
    ReportPlan: {
      actions: DefaultActions,
    },
    Report: {
      actions: DefaultActions,
    },
  },
  Organization: {
    Organization: {
      actions: DefaultActions.concat("enable_module", "disable_module").concat(
        flatten(
          OrganizationRelationTypes.map((rel_type) =>
            DefaultActions.map((action) => `${action}_related_${rel_type}`),
          ),
        ),
      ),
    },
  },

  UserManagement: {
    Permission: {
      actions: DefaultActions,
    },
    Role: {
      actions: DefaultActions,
    },
    RoleDefinition: {
      actions: DefaultActions,
    },
    UserGroup: {
      actions: DefaultActions,
    },
    User: {
      actions: DefaultActions,
    },
    GuestUser: {
      actions: DefaultActions,
    },
  },
};
