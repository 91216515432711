import { Timeline } from "@knight-lab/timelinejs";
import { Grid } from "@mui/material";
import { each, isEmpty, isEqual, isNil, map, merge } from "lodash";
import * as React from "react";
import { AssetMaintenanceTimelineWidgetConfigSerialized } from "../../widgets/asset_maintenance_timeline_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import {
  AssetMaintenanceTimelineWidgetProps,
  AssetMaintenanceTimelineWidgetState,
} from "./asset_maintenance_timeline_widget.types";
import { WidgetBox } from "./widget_box";
export class AssetMaintenanceTimelineWidget extends React.Component<
  AssetMaintenanceTimelineWidgetProps,
  AssetMaintenanceTimelineWidgetState
> {
  static defaultProps: Partial<AssetMaintenanceTimelineWidgetProps> = {
    allowFullscreen: true,
  };

  static serializedConfigToProps(
    config: AssetMaintenanceTimelineWidgetConfigSerialized,
  ): AssetMaintenanceTimelineWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      urls: config.urls,
    } as AssetMaintenanceTimelineWidgetProps);
  }

  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;

  chartRefs: React.RefObject<HTMLDivElement>[];
  timelines: Timeline[];
  constructor(props: AssetMaintenanceTimelineWidgetProps) {
    super(props);
    this.chartRefs = map(props.urls, () => React.createRef());
    this.state = {};
    this.timelines = [];
  }

  componentDidMount(): void {
    this.initTimelineDiagram();
  }

  componentWillUnmount(): void {
    each(this.timelines, (timeline) => {
      timeline;
    });
  }

  componentDidUpdate(
    prevProps: Readonly<AssetMaintenanceTimelineWidgetProps>,
    prevState: Readonly<AssetMaintenanceTimelineWidgetState>,
    snapshot?: any,
  ): void {
    if (!isNil(prevProps) && !isEqual(prevProps, this.props)) {
      this.initTimelineDiagram();
    }
  }

  render() {
    return (
      <WidgetBox {...this.props}>
        <Grid container spacing={2}>
          {this.chartRefs.map((ref, index) => (
            <Grid item xs={12} key={index}>
              <div className="timeline" ref={ref} />
            </Grid>
          ))}
        </Grid>
      </WidgetBox>
    );
  }

  private initTimelineDiagram() {
    const momentLocale = moment.localeData();
    each(this.props.urls, (url, index) => {
      // create timeline and append to container

      const timelineElement = $(this.chartRefs[index].current);

      const timeline = new Timeline(timelineElement[0], url, {
        language: this.context.locale,
        timenav_position: "top",
        //ease: Ease.easeIn,
        scale_factor: 1.0,
        marker_width_min: 500,
        optimal_tick_width: 500,
        timenav_height_percentage: 55,
        start_at_end: true,
        duration: 700,
        zoom_sequence: [0.3, 0.5, 1, 2, 3, 5, 8, 13, 21],
      });

      timeline.language = merge<object, object>(
        timeline.language,
        this.fetchLanguage(),
      );

      this.timelines.push(timeline);
    });
  }

  fetchLanguage(): object {
    if (
      isEmpty(
        (I18n.translations as Record<string, Record<string, string>>)[
          this.context.locale
        ]["timelineJS3"],
      )
    ) {
      //english fallback
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      return {};
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      return I18n.translations[this.context.locale]?.["timelineJS3"];
    }
  }
}
