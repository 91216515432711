import { isNil } from "lodash";
import {
  MappedSensor,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { BaseMapping } from "./mapping_base";

export interface ClickableMappingConfig extends SensorMappingAttributes {
  action: "";
}

export class ClickableMapping extends BaseMapping<SVGElement> {
  constructor(
    config: ClickableMappingConfig,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config, (event, element, config) => {
      this.handleClick(event, element, config, onClick);
    });
  }

  handleClick(
    event: MouseEvent,
    element: SVGElement,
    config: SensorMappingAttributes,
    fallback: (
      event: MouseEvent,
      element: SVGElement,
      config: SensorMappingAttributes,
    ) => void = null,
  ): void {
    if (isNil(this.element)) return;
  }

  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    super.setElementInfoFromSvg(svgElement);
    if (isNil(this.element)) return;
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    // nothing to do here, clickable is clickable
    return;
  }
}
