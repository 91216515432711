import { Box, Grid, Typography } from "@mui/material";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import {
  EventPatternJSONAPIAttributes,
  EventPatternJSONObject,
} from "../../json_api/event_pattern";
import { ModelErrors, modelPropertyError } from "../../json_api/jsonapi_tools";
import { SensorJSONObject } from "../../json_api/sensor";
import { AssetEventTypeSelect } from "../asset_event_types/asset_event_type_select";
import { EventSeveritySelect } from "../asset_events/event_severity_selection";
export interface EventPatternCommonFormFieldsProps {
  eventPattern: EventPatternJSONAPIAttributes;
  sensor?: SensorJSONObject;
  readonly?: boolean;
  errors?: ModelErrors<EventPatternJSONAPIAttributes>;

  onPatternUpdate?: (eventPattern: EventPatternJSONObject) => void;
}

export const EventPatternCommonFormFields: React.FunctionComponent<
  EventPatternCommonFormFieldsProps
> = (props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          marginTop={{ xs: 1, lg: 0 }}
          borderTop={{ xs: 1, lg: 0 }}
          paddingTop={{ xs: 1, lg: 0 }}
        >
          <Typography variant="subtitle1">
            {I18n.t("frontend.event_patterns.form.event_config_header")}
          </Typography>
          <AssetEventTypeSelect
            eventTypeLoadFilter={{ category: ["monitoring"] }}
            required
            readonly={props.readonly}
            assetId={
              props.eventPattern.asset_id || props.eventPattern.sensor?.asset_id
            }
            availableTypes={
              props.readonly && !isNil(props.eventPattern.asset_event_type)
                ? [props.eventPattern.asset_event_type]
                : null
            }
            error={
              modelPropertyError(props.errors, "asset_event_type_id") ||
              modelPropertyError(props.errors, "asset_event_type")
            }
            selectedTypeId={
              props.eventPattern.asset_event_type_id ||
              props.eventPattern.asset_event_type?.id
            }
            onTypeSelect={(t) => {
              const newPattern = {
                ...props.eventPattern,
                asset_event_type_id: t?.id,
              };
              if (t) {
                // also set the asset event type object if it was provided.
                // if null / undefined we will not override the object
                newPattern.asset_event_type = t;
              }
              props.onPatternUpdate(newPattern);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <EventSeveritySelect
          readonly={props.readonly}
          size="small"
          severity={defaultTo(props.eventPattern.severity_level, "info")}
          onSeveritySelect={(t) => {
            props.onPatternUpdate({
              ...props.eventPattern,
              severity_level: t,
            });
          }}
          error={modelPropertyError(props.errors, "severity_level")}
        />
      </Grid>
    </>
  );
};
