import Bluebird, { CancellationError } from "bluebird";
import { defaultTo, get, isEmpty, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { SensorLoader } from "../../json_api/sensor_loader";
import { SensorValueType } from "../../models/sensor";
import { logger } from "../../utils/logger";
import { getStatusLabel } from "../../utils/status_helper";
import { getTimeRangeString, getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { sensorUrl } from "../../utils/urls";
import { ValueDisplay } from "../common/value_display";
import {
  ValueDifferenceWidgetCalculationMode,
  computeOffsetValue,
} from "./algorithm/offset_values";
import { SensorValueWidget } from "./sensor_value_widget";
import { WidgetBox } from "./widget_box";

import { ValueDifferenceWidgetConfigSerialized } from "../../widgets/value_difference_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { DashboardContext } from "../dashboard/dashboard_context";
import {
  ValueDifferenceWidgetProps,
  ValueDifferenceWidgetState,
} from "./value_difference_widget.types";

export class ValueDifferenceWidget
  extends React.Component<
    ValueDifferenceWidgetProps,
    ValueDifferenceWidgetState
  >
  implements SensorEventSubscriber
{
  private loadSensorDataPromise: Bluebird<void>;

  //Here we go, we set contextType of the component to our DashboardContext.
  static contextType = DashboardContext;
  context!: React.ContextType<typeof DashboardContext>;

  static defaultProps: Partial<ValueDifferenceWidgetProps> = merge(
    SensorValueWidget.defaultProps,
    {
      calculationMode: "time_offset" as ValueDifferenceWidgetCalculationMode,
    },
  );

  static serializedConfigToProps(
    config: ValueDifferenceWidgetConfigSerialized,
  ): ValueDifferenceWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      assetId: config.asset_id,
      baseValue: config.base_value,
      offsetValue: config.offset_value,
      calculationMode: config.calc_mode,

      iconName: config.icon_name,
      iconSize: config.icon_size,

      measurementType: config.measurement_type,
      minWidthPx: config.min_width_px,
      sensorId: config.sensor_id,
      precision: config.precision,
      sensorType: config.sensor_type,
      showTimeRange: defaultTo(config.show_time_range, false),

      textShadow: config.text_shadow,
      timeScopeName: config.timescope,
      timestamp: config.timestamp ? moment(config.timestamp) : null,
      totalValueRange: config.total_value_range,
      unit: config.unit,
      updateEnabled: !config.disable_update,
      value: config.value,
      vertical: config.vertical,
      fallbackToLastValue: config.fallback_to_last_value,
      ignoreTimeScope: config.ignore_time_scope,
    });
  }

  constructor(props: ValueDifferenceWidgetProps) {
    super(props);

    const link =
      !isNil(props.sensorId) && !isNil(props.assetId)
        ? sensorUrl(
            {
              id: props.sensorId,
              assetId: props.assetId,
            },
            "html",
          )
        : null;
    this.state = {
      title: props.title as string,
      value: this.props.value,
      timeScopeName: this.props.timeScopeName,
      baseValue: this.props.baseValue,
      timestamp: this.props.timestamp,
      titleLinkUrl: props.titleLinkUrl ?? link,
      contentLinkUrl: props.contentLinkUrl ?? link,
      status: this.props.status,
    };
    this.loadSensorDataPromise = null;
  }

  initialStateFromProps() {}

  componentDidMount(): void {
    this.loadSensorData(this.props);
    this.toggleSensorUpdates(this.props);
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    if (!isNumber(value)) {
      return;
    }

    this.setState((prevState) => {
      let theValue = value;
      if (!isNil(unit) && !isNil(this.props.unit)) {
        theValue = convertToUnit(value, unit, this.props.unit);
      }

      if (
        !this.props.updateEnabled ||
        isNil(this.state.baseValue) ||
        (!isNil(this.props.timeRange.start) &&
          time.isBefore(this.props.timeRange.start)) ||
        (!isNil(this.props.timeRange.end) &&
          time.isAfter(this.props.timeRange.end))
      ) {
        return {};
      }

      return {
        ...prevState,
        value: computeOffsetValue(
          this.state.baseValue,
          theValue,
          this.props.calculationMode,
        ),
        timestamp: time,
      };
    });
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        this.props.sensorId as number,
      );
    }

    if (!isNil(this.loadSensorDataPromise)) {
      this.loadSensorDataPromise.cancel();
      this.loadSensorDataPromise = null;
    }
  }
  componentDidUpdate(oldProps: ValueDifferenceWidgetProps): void {
    if (
      (!isNil(this.props.timeRange?.start) &&
        !isNil(this.props.timeRange?.end) &&
        // time range is defined and it has changed changed
        (!this.props.timeRange.start?.isSame(oldProps.timeRange.start) ||
          !this.props.timeRange.end?.isSame(oldProps.timeRange.end))) ||
      // relevant sensor changed
      oldProps.sensorId !== this.props.sensorId
    ) {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        oldProps.sensorId as number,
      );
      this.loadSensorData(this.props);
    }

    // apply the new base value if it has changed and the calculation mode is base value offset, otherwise it will be set during sensor data load
    if (
      this.props.baseValue !== oldProps.baseValue &&
      this.props.calculationMode === "base_value_offset"
    ) {
      this.setState((prevProps) => ({
        ...prevProps,
        baseValue: this.props.baseValue,
      }));
    }
    if (this.props.updateEnabled !== oldProps.updateEnabled) {
      this.toggleSensorUpdates(this.props);
    }
  }

  render(): React.ReactNode {
    const footer = this.getFooter();

    return (
      <WidgetBox
        {...this.props}
        title={this.state.title}
        titleLinkUrl={this.state.titleLinkUrl}
        contentLinkUrl={this.state.contentLinkUrl}
        footer={footer}
      >
        <ValueDisplay
          mode="rows"
          vertical={this.props.vertical}
          value={this.state.value}
          unit={this.props.unit}
          precision={this.props.precision}
          hideValue={this.props.hideValue}
          sensorType={
            this.props.sensorType ?? this.state.sensor?.sensor_type_name
          }
          measurementType={
            this.props.measurementType ?? this.state.sensor?.measurement_type
          }
          iconName={this.props.iconName}
          iconSize={this.props.iconSize}
          timestamp={getTimeString(
            this.state.timeScopeName,
            this.state.timestamp,
          )}
          shadowText={this.props.textShadow}
          status={getStatusLabel(this.props.status)}
        />
      </WidgetBox>
    );
  }

  getFooter(): React.ReactNode {
    if (!this.props.showTimeRange || isNil(this.props.timeRange?.start)) {
      return null;
    } else {
      return (
        <small
          title={I18n.t("frontend.widgets.value_difference_widget.time_range")}
        >
          {getTimeRangeString(
            this.props.timeRange.start,
            this.props.timeRange.end,
          )}
        </small>
      );
    }
  }

  private loadSensorData(props: ValueDifferenceWidgetProps) {
    this.loadSensorDataPromise = Bluebird.all([
      SensorLoader.getInstance().getSensors(props.sensorId),
      SensorLoader.getInstance().getLastValueWithin(
        props.sensorId,
        props.timeRange,
        props.fallbackToLastValue,
      ),
      // when calculation mode is base value offset we don't need to fetch the base value, otherwise fetch it
      this.props.calculationMode == "base_value_offset"
        ? null
        : SensorLoader.getInstance().getFirstValueWithin(
            props.sensorId,
            props.timeRange,
            props.fallbackToLastValue,
          ),
    ])
      .then(([sensors, sensorDataAttribute, baseValueSensorDataAttribute]) => {
        if (isNil(sensors) || isEmpty(sensors)) {
          throw new Error(
            `Requested sensors could not be fetched: ${props.sensorId}`,
          );
        }
        const sensor = sensors[0];

        const link = sensorUrl(
          {
            id: props.sensorId,
            assetId: props.assetId,
          },
          "html",
        );

        // when calculation mode is base value offset keep using the base value from the props
        // as it has not been fetched from the server
        const newBaseValue =
          this.props.calculationMode === "base_value_offset"
            ? this.state.baseValue
            : (get(baseValueSensorDataAttribute, "value") as number);

        this.setState((prevState) => ({
          ...prevState,

          value: computeOffsetValue(
            newBaseValue,
            get(sensorDataAttribute, "value") as number,
            this.props.calculationMode,
          ),
          baseValue: newBaseValue,
          timestamp: moment(sensorDataAttribute.timestamp),
          timeScopeName: props.timeScopeName,
          title: isEmpty(props.title) ? sensor.name : (props.title as string),
          titleLinkUrl: link,
          contentLinkUrl: link,
        }));
        this.toggleSensorUpdates(props);
      })
      .catch(CancellationError, (e) => {
        logger.trace("Cancellation Error in Value Difference Widget");
        return;
      })
      .catch((e) => {
        logger.error(
          `Error loading sensor data ${props.sensorId} in Value Difference Widget`,
          e,
        );
      });
  }

  protected toggleSensorUpdates(props: ValueDifferenceWidgetProps): void {
    if (WidgetController.getInstance()) {
      if (props.updateEnabled) {
        WidgetController.getInstance().sensorDataChannel.subscribe(
          this.props.sensorId as number,
        );
        WidgetController.getInstance().sensorDataChannel.addEventListener(
          this,
          this.props.sensorId as number,
        );
      } else {
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          this.props.sensorId as number,
        );
      }
    }
  }
}
