import { isNil } from "lodash";
import * as React from "react";

import { Grid } from "@mui/material";
import {
  ChartStatType,
  ChartStatistics,
  chartStatisticsTextLine,
} from "../chart_data/chart_data_statistics";

export interface ChartStatisticsDisplayProps {
  chartStatistics: ChartStatistics[];
  color: (index: number) => string;
}

function statsLine(
  statistics: ChartStatistics,
  statsName: ChartStatType,
  label: string,
) {
  return (
    <>
      <strong className="ml-1 mr-1">{label}:</strong>
      {chartStatisticsTextLine(statsName, statistics)}
    </>
  );
}

export const ChartStatisticsDisplay: React.FunctionComponent<
  ChartStatisticsDisplayProps
> = (props) => {
  return (
    <Grid container spacing={2}>
      {props.chartStatistics?.map((statistics, index) => {
        if (isNil(statistics)) {
          return null;
        } else {
          return (
            <Grid item xs={12} lg={6} key={index}>
              <i
                className="fa fa-circle ml-1"
                style={{ color: props.color(index) }}
              />
              <small className="ml-1">
                {statsLine(statistics, "min", "Min")}
                {statsLine(statistics, "max", "Max")}
                {statsLine(statistics, "minMaxDiv", "Diff")}
                {statsLine(statistics, "average", "\u00D8")}
              </small>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};
