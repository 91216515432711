import Bluebird, { Promise } from "bluebird";
import { defaultTo, find, isEmpty, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import { DateRange } from "moment-range";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { PlotlyGaugeChart } from "../../charting/plotly_gauge_chart";
import { WidgetController } from "../../controller/widget_controller";
import { SensorLoader } from "../../json_api/sensor_loader";
import { SensorValueType } from "../../models/sensor";
import { logger } from "../../utils/logger";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { PercentageBar } from "../common/percentage_bar";
import { WidgetBox } from "./widget_box";

import { Grid, Typography } from "@mui/material";
import { SensorPercentageValueCalculator } from "./algorithm/sensor_percentage_value_calculator";

import { PercentageWidgetConfigSerialized } from "../../widgets/percentage_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import {
  PercentageWidgetProps,
  PercentageWidgetState,
} from "./percentage_widget.types";
import { WidgetTimestampGridItem } from "./widget_timestamp";

export class PercentageWidget
  extends React.Component<PercentageWidgetProps, PercentageWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<PercentageWidgetProps> = {
    mode: "bar",

    fallbackToLastValue: false,
    ignoreTimeScope: false,
  };

  static serializedConfigToProps(
    config: PercentageWidgetConfigSerialized,
  ): PercentageWidgetProps {
    let range;
    let limitToRange;
    if (config.limit_to_range === true) {
      limitToRange = true;
      range = { min: 0, max: 100 };
    } else if (!isEmpty(config.limit_to_range)) {
      limitToRange = true;
      const limits = range as { min: number; max: number };
      range = limits;
    } else {
      limitToRange = false;
    }
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      dataUpdateEnabled: !config.disable_update,
      value: config?.value as number,
      range,
      limitToRange,
      numeratorValue: config.numerator?.value,
      numeratorSensorId: config.numerator?.sensor_id,
      numeratorBaseValue: config.numerator?.base_value,
      denominatorSensorId: config.denominator?.sensor_id,
      denominatorValue: config.denominator?.value,
      denominatorBaseValue: config.denominator?.base_value,
      timestamp: config?.timestamp ? moment(config.timestamp) : null,
      mode: defaultTo(config.display_mode, "bar"),

      timeScopeName: config.timescope,
      sensorType: config.sensor_type,
      measurementType: config.measurement_type,

      iconName: config?.icon_name,
      iconSize: config?.icon_size,

      precision: config?.precision,
      ignoreTimeScope: config.ignore_time_scope,
      fallbackToLastValue: config.fallback_to_last_value,
      gaugeHeight: config.gauge_height,
    });
  }

  private loadSensorPromise: Bluebird<void>;

  private valueCalculator: SensorPercentageValueCalculator;
  constructor(props: PercentageWidgetProps) {
    super(props);
    this.valueCalculator = new SensorPercentageValueCalculator(
      props.numeratorBaseValue,
      props.denominatorBaseValue,
      props.limitToRange ? props.range : undefined,
    );
    this.state = {
      numeratorValue: props.numeratorValue,
      numeratorTimestamp: props.timestamp,
      denominatorValue: props.denominatorValue,
      denominatorTimestamp: props.timestamp,
      value: props.value || this.valueCalculator.calculateValue(),
      timeScopeName: props.timeScopeName,
      timestampLabel: getTimeString(props.timeScopeName, props.timestamp),
      fullscreen: false,
    };
  }

  componentDidMount(): void {
    if (this.props.numeratorSensorId && this.props.denominatorSensorId) {
      void this.loadSensorData(this.props);
    }
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      instance.sensorDataChannel.removeEventListener(
        this,
        this.props.denominatorSensorId,
      );
      instance.sensorDataChannel.removeEventListener(
        this,
        this.props.numeratorSensorId,
      );
    }

    if (!isNil(this.loadSensorPromise)) {
      this.loadSensorPromise.cancel();
      this.loadSensorPromise = null;
    }
  }

  componentDidUpdate(oldProps: PercentageWidgetProps): void {
    if (
      !this.props.timeRange?.start?.isSame(oldProps.timeRange.start) ||
      !this.props.timeRange?.end?.isSame(oldProps.timeRange.end) ||
      this.props.numeratorSensorId !== oldProps.numeratorSensorId ||
      this.props.denominatorSensorId !== oldProps.denominatorSensorId
    ) {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        oldProps.numeratorSensorId,
      );
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        this,
        oldProps.denominatorSensorId,
      );
      void this.loadSensorData(this.props);
      return;
    }

    if (this.props.updateEnabled !== oldProps.updateEnabled) {
      this.toggleSensorUpdates(this.props);
    }
  }

  calculateValue(
    numerator: number,
    numeratorTimestamp: Moment,
    denominator: number,
    denominatorTimestamp: Moment,
  ): number {
    this.valueCalculator.setNumeratorValue(numerator, numeratorTimestamp);
    this.valueCalculator.setDenominatorValue(denominator, denominatorTimestamp);

    return this.valueCalculator.calculateValue();
  }

  lastValueTimestamp(): Moment {
    return this.valueCalculator.lastValueTimestamp();
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    const timeRange = this.props.timeRange;
    if (
      !isNumber(value) ||
      (!isNil(this.props.timeRange) && !timeRange.contains(time))
    ) {
      return;
    }

    let theValue = value;
    if (sensorId == this.props.numeratorSensorId) {
      this.setState((prevState) => {
        if (!isNil(unit) && !isNil(this.props.denominatorUnit)) {
          theValue = convertToUnit(value, unit, this.props.denominatorUnit);
        }
        theValue = this.calculateValue(
          theValue,
          time,
          prevState.denominatorValue,
          prevState.denominatorTimestamp,
        );

        return {
          numeratorValue: value,
          numeratorTimestamp: time,
          value: theValue,
          timestampLabel: getTimeString(prevState.timeScopeName, time),
        };
      });
    } else if (sensorId == this.props.denominatorSensorId) {
      this.setState((prevState) => {
        if (!isNil(unit) && !isNil(this.props.denominatorUnit)) {
          theValue = convertToUnit(value, unit, this.props.denominatorUnit);
        }
        theValue = this.calculateValue(
          prevState.numeratorValue,
          prevState.numeratorTimestamp,
          theValue,
          time,
        );

        return {
          denominatorValue: value,
          denominatorTimestamp: time,
          value: theValue,
          timestampLabel: getTimeString(prevState.timeScopeName, time),
        };
      });
    }
  }

  render(): React.ReactNode {
    return (
      <WidgetBox
        {...this.props}
        onFullscreen={(fullscreen) => this.setState({ fullscreen })}
      >
        {this.getContentComponent()}
      </WidgetBox>
    );
  }

  private getContentComponent() {
    if (this.props.mode === "gauge") {
      return (
        <Grid container direction="column" justifyContent="center">
          <Grid item xs={12}>
            <PlotlyGaugeChart
              divId={`widget-${this.props.widgetId}-diagram-container`}
              value={this.state.value}
              height={this.state.fullscreen ? 600 : this.props.gaugeHeight}
              maxWidth={this.state.fullscreen ? "80%" : null}
              unit="%"
            />
          </Grid>

          {isNil(this.state.timestampLabel) ? null : (
            <WidgetTimestampGridItem
              timestamp={this.state.timestampLabel}
              align="center"
            />
          )}
        </Grid>
      );
    } else {
      return (
        <Grid container direction="column" justifyContent="space-between">
          <Grid item xs={12}>
            <PercentageBar
              value={this.state.value}
              max={100}
              timestamp={this.state.timestampLabel}
            />
          </Grid>
        </Grid>
      );
    }
  }

  private loadSensorData(props: PercentageWidgetProps): Promise<any> {
    this.loadSensorPromise = SensorLoader.getInstance()
      .getSensors([props.numeratorSensorId, props.denominatorSensorId])
      .then((sensors) => {
        if (isNil(sensors) || isEmpty(sensors) || sensors.length < 2) {
          throw new Error(
            `Requested sensors could not be fetched: [${[
              props.numeratorSensorId,
              props.denominatorSensorId,
            ].join(", ")}]`,
          );
        }
        return Promise.all([
          Promise.resolve(sensors),
          SensorLoader.getInstance().getLastValueWithin(
            sensors[0].id,
            this.props.timeRange,
            this.props.fallbackToLastValue,
          ),
          SensorLoader.getInstance().getLastValueWithin(
            sensors[1].id,
            this.props.timeRange,
            this.props.fallbackToLastValue,
          ),
        ]);
      })
      .then(([sensors, sensorValue1, sensorValue2]) => {
        const numeratorSensorAttributes = find(
          sensors,
          (sensorAttributeSet) =>
            sensorAttributeSet.id == props.numeratorSensorId.toString(),
        );
        const numeratorSensorValue =
          numeratorSensorAttributes == sensors[0] ? sensorValue1 : sensorValue2;
        const denominatorSensorAttributes = find(
          sensors,
          (sensorAttributeSet) =>
            sensorAttributeSet.id == props.denominatorSensorId.toString(),
        );
        const denominatorSensorValue =
          denominatorSensorAttributes == sensors[0]
            ? sensorValue1
            : sensorValue2;

        const nomValue: number = numeratorSensorValue.value as number;
        const nomTime: Moment = moment(numeratorSensorValue.timestamp);
        const denomValue: number = denominatorSensorValue.value as number;
        const denomTime: Moment = moment(denominatorSensorValue.timestamp);
        const value = this.calculateValue(
          nomValue,
          nomTime,
          denomValue,
          denomTime,
        );

        this.setState((prevState) => {
          return {
            value: value,
            numeratorValue: nomValue,
            numeratorTimestamp: nomTime,
            denominatorValue: denomValue,
            denominatorTimestamp: denomTime,
            timestampLabel: getTimeString(
              prevState.timeScopeName,
              this.valueCalculator.lastValueTimestamp(),
            ),
            title: props.title,
            titleLinkUrl: props.titleLinkUrl,
          };
        });

        this.toggleSensorUpdates(props);
      })
      .catch((e) => {
        logger.error(
          `Error loading sensor data [${[
            props.numeratorSensorId,
            props.denominatorSensorId,
          ].join(" ,")}] for Percentage Widget`,
          e,
        );
      });
    return this.loadSensorPromise;
  }

  private toggleSensorUpdates(props: PercentageWidgetProps) {
    if (WidgetController.getInstance()) {
      if (props.updateEnabled) {
        WidgetController.getInstance().sensorDataChannel.subscribe(
          props.numeratorSensorId,
        );
        WidgetController.getInstance().sensorDataChannel.subscribe(
          props.denominatorSensorId,
        );
        WidgetController.getInstance().sensorDataChannel.addEventListener(
          this,
          props.numeratorSensorId,
        );
        WidgetController.getInstance().sensorDataChannel.addEventListener(
          this,
          props.denominatorSensorId,
        );
      } else {
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          props.numeratorSensorId,
        );
        WidgetController.getInstance().sensorDataChannel.removeEventListener(
          this,
          props.denominatorSensorId,
        );
      }
    }
  }
}
