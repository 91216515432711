/// <reference types="../../../definitions/index" />;
import * as React from "react";
import { IconButton } from "../../common/icon_button";

import { Cancel, Check } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

interface NotificationSettingsInputProps {
  value?: string;
  onChange: (value: string) => void;
}

interface NotificationSettingsInputState {
  value: string;
  isEditing: boolean;
}

/**
 * A selection input for notification settings.
 */
export class NotificationSettingsInput extends React.Component<
  NotificationSettingsInputProps,
  NotificationSettingsInputState
> {
  constructor(props: NotificationSettingsInputProps) {
    super(props);

    this.state = {
      value: this.props.value || "none",
      isEditing: false,
    };
  }

  componentDidUpdate(prevProps: NotificationSettingsInputProps): void {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value || "none",
      });
    }
  }

  render(): React.ReactNode {
    return (
      <Grid container>
        <Grid
          item
          container
          xs={12}
          onClick={() => this.onChangeEditMode(true)}
          style={{ cursor: "pointer" }}
        >
          <Grid item xs="auto">
            <Typography variant="caption" className="auto-hyphen" mr={1}>
              {I18n.t(
                "frontend.maintenance_plan_form.notification_settings.notifications",
              )}
              :
            </Typography>
          </Grid>
          <Tooltip title={I18n.t("frontend.click_to_edit")}>
            <Grid item xs="auto">
              <IconButton
                className="mr-1 text-success"
                icon="pencil"
                onClick={() => this.onChangeEditMode(true)}
              />
              <span className="editable-value">
                {this.getNotificationSettingLabel()}
              </span>
            </Grid>
          </Tooltip>
        </Grid>
        <Dialog
          open={this.state.isEditing}
          onClose={() => this.onChangeEditMode(false)}
        >
          <DialogTitle>
            {I18n.t(
              "frontend.maintenance_plan_form.notification_settings.notifications",
            )}
          </DialogTitle>
          <DialogContent>
            <Box padding={2}>
              <TextField
                fullWidth
                select
                name="notification"
                value={this.state.value || "none"}
                label={I18n.t(
                  "frontend.maintenance_plan_form.notification_settings.notifications",
                )}
                size="small"
                onChange={(event) =>
                  this.onChangeNotificationSetting(event.target.value)
                }
              >
                <MenuItem value="none">
                  {I18n.t(
                    "frontend.maintenance_plan_form.notification_settings.none",
                  )}
                </MenuItem>
                <MenuItem value="same_day">
                  {I18n.t(
                    "frontend.maintenance_plan_form.notification_settings.same_day",
                  )}
                </MenuItem>
                <MenuItem value="one_day_ahead">
                  {I18n.t(
                    "frontend.maintenance_plan_form.notification_settings.one_day_ahead",
                  )}
                </MenuItem>
                <MenuItem value="two_days_ahead">
                  {I18n.t(
                    "frontend.maintenance_plan_form.notification_settings.two_days_ahead",
                  )}
                </MenuItem>
                <MenuItem value="one_week_ahead">
                  {I18n.t(
                    "frontend.maintenance_plan_form.notification_settings.one_week_ahead",
                  )}
                </MenuItem>
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => {
                  this.onApplyChange();
                }}
                className="apply-notification-setting"
                startIcon={<Check fontSize="inherit" />}
              >
                {I18n.t("frontend.submit")}
              </Button>
              <Button
                onClick={() => {
                  this.onCancelChange();
                }}
                startIcon={<Cancel fontSize="inherit" />}
              >
                {I18n.t("frontend.cancel")}
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }

  private onChangeEditMode(active: boolean): void {
    this.setState({
      isEditing: active,
    });
  }

  private onChangeNotificationSetting(value: string): void {
    this.setState({
      value: value,
    });
  }

  private onApplyChange(): void {
    this.props.onChange(this.state.value);

    this.setState({
      isEditing: false,
    });
  }

  private onCancelChange(): void {
    this.setState({
      value: this.props.value || "none",
      isEditing: false,
    });
  }

  private getNotificationSettingLabel(): string {
    return I18n.t(
      `frontend.maintenance_plan_form.notification_settings.${this.state.value}`,
    );
  }
}
