import * as React from "react";
import { IDType } from "../utils/urls/url_utils";
import { AssetPartsWidgetConfigSerialized } from "./asset_parts_widget.types";
import Widget from "./widget";

import { AppRoot } from "../components/common/app_root";
import { AssetPartsWidgetDefinition } from "../components/widgets/asset_parts_widget";
/**
 * Javascript implementation of the AssetPartsWidget.
 *
 * @class AssetPartsWidget
 * @extends {Widget}
 */
export default class AssetPartsWidget extends Widget {
  static getDomClassName(): string {
    return "asset-parts-widget";
  }

  static widgetClassName(): string {
    return "AssetPartsWidget";
  }

  assetID: IDType;

  readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data("config") as AssetPartsWidgetConfigSerialized;
    this.assetID = config?.asset_id;
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);

    if (this.config) {
      const props = AssetPartsWidgetDefinition.serializedConfigToProps(
        this.config,
      );

      this.root.render(
        <AppRoot>
          <AssetPartsWidgetDefinition.component {...props} encloseInIBox />
        </AppRoot>,
      );
    }
  }

  cleanup(): void {
    super.cleanup();
  }
}
